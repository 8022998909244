import axios from 'axios';
import config from '../config';
import { handleAxiosError } from './error';

const { budgetApiUrl } = config;

export const FETCHING_CONSOLIDATED = 'FETCHING_CONSOLIDATED';
export const FETCHED_CONSOLIDATED = 'FETCHED_CONSOLIDATED';
export const TOGGLED_CONSOLIDATED_MODAL = 'TOGGLED_CONSOLIDATED_MODAL';
export const UPDATING_CONSOLIDATED = 'UPDATING_CONSOLIDATED';
export const FETCHED_EXCHANGE_RATES = 'FETCHED_EXCHANGE_RATES';
export const FETCHED_CONSOLIDATED_STRUCTURE = 'FETCHED_CONSOLIDATED_STRUCTURE';
export const FETCHED_CONSOLIDATED_GROUPED = 'FETCHED_CONSOLIDATED_GROUPED';
export const MOVED_CONSOLIDATED_GROUPED = 'MOVED_CONSOLIDATED_GROUPED';

export const fetchingConsolidated = (initial = false) => ({ type: FETCHING_CONSOLIDATED, initial });
export const fetchedConsolidated = consolidated => ({ type: FETCHED_CONSOLIDATED, consolidated });
export const updating = updating => ({ type: UPDATING_CONSOLIDATED, updating });
export const fetchedExchangeRates = exchangeRates => ({ type: FETCHED_EXCHANGE_RATES, exchangeRates });
export const fetchedConsolidatedStructure = structure => ({ type: FETCHED_CONSOLIDATED_STRUCTURE, structure });
export const fetchedConsolidatedGrouped = consolidatedGrouped => ({
  type: FETCHED_CONSOLIDATED_GROUPED,
  consolidatedGrouped
});
export const movedConsolidatedGrouped = (movement) => ({ type: MOVED_CONSOLIDATED_GROUPED, movement })

export const fetchConsolidated = (unitId, initial = true) => async dispatch => {
  dispatch(fetchingConsolidated(initial));
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/consolidated/unit/${unitId}`,
      withCredentials: true
    });
    dispatch(fetchedConsolidated(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const fetchConsolidatedDistributors = () => async dispatch => {
  dispatch(fetchingConsolidated());
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/consolidated/distributors`,
      withCredentials: true
    });
    dispatch(fetchedConsolidated(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const toggleModal = (id, open) => async dispatch => {
  dispatch({ type: TOGGLED_CONSOLIDATED_MODAL, id, open });
};

export const addConsolidatedGroup = group => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'post',
      url: `${budgetApiUrl}/consolidated/unit/${group.unitId}/groups`,
      data: group,
      withCredentials: true
    });
    dispatch(fetchConsolidated(group.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const updateConsolidatedGroup = group => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/consolidated/unit/${group.unitId}/groups/${group.id}`,
      data: group,
      withCredentials: true
    });
    dispatch(fetchConsolidated(group.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const moveConsolidatedGroup = (group, direction) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/consolidated/unit/${group.unitId}/groups/${group.id}/move`,
      data: { direction },
      withCredentials: true
    });
    dispatch(fetchConsolidated(group.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const deleteConsolidatedGroup = group => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'delete',
      url: `${budgetApiUrl}/consolidated/unit/${group.unitId}/groups/${group.id}`,
      withCredentials: true
    });
    dispatch(fetchConsolidated(group.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const addConsolidatedRecord = (group, record) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'post',
      url: `${budgetApiUrl}/consolidated/unit/${record.unitId}/groups/${group.id}/records`,
      data: record,
      withCredentials: true
    });
    dispatch(fetchConsolidated(record.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const updateConsolidatedRecord = (group, record) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/consolidated/unit/${record.unitId}/groups/${group.id}/records/${record.id}`,
      data: record,
      withCredentials: true
    });
    dispatch(fetchConsolidated(record.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const moveConsolidatedRecord = (group, record, direction) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/consolidated/unit/${record.unitId}/groups/${group.id}/records/${record.id}/move`,
      data: { direction },
      withCredentials: true
    });
    dispatch(fetchConsolidated(record.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const deleteConsolidatedRecord = (group, record) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'delete',
      url: `${budgetApiUrl}/consolidated/unit/${record.unitId}/groups/${group.id}/records/${record.id}`,
      withCredentials: true
    });
    dispatch(fetchConsolidated(record.unitId, false));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const fetchExchangeRates = () => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/consolidated/exchange-rates`,
      withCredentials: true
    });
    dispatch(fetchedExchangeRates(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const updateExchangeRates = rates => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/consolidated/exchange-rates`,
      data: rates,
      withCredentials: true
    });
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const fetchConsolidatedStructure = unitId => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/consolidated/structure`,
      query: { unitId },
      withCredentials: true
    });
    dispatch(fetchedConsolidatedStructure(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
}

export const fetchConsolidatedGrouped = () => async dispatch => {
  dispatch(fetchingConsolidated(true));
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/group-consolidated`,
      withCredentials: true
    });
    dispatch(fetchedConsolidatedGrouped(res.data))
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
}

export const addConsolidatedGroupedRecord = record => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'post',
      url: `${budgetApiUrl}/group-consolidated/records`,
      data: record,
      withCredentials: true
    });
    dispatch(fetchConsolidatedGrouped());
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
}

export const updateConsolidatedGroupedRecord = record => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/group-consolidated/records/${record.id}`,
      data: record,
      withCredentials: true
    });
    dispatch(fetchConsolidatedGrouped());
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
}

export const moveConsolidatedGroupedRecord = (record, direction) => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/group-consolidated/records/${record.id}/move`,
      data: { direction },
      withCredentials: true
    });
    dispatch(movedConsolidatedGrouped({ record, direction }))
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};

export const deleteConsolidatedGroupedRecord = record => async dispatch => {
  dispatch(updating(true));
  try {
    await axios({
      method: 'delete',
      url: `${budgetApiUrl}/group-consolidated/records/${record.id}`,
      withCredentials: true
    });
    dispatch(fetchConsolidatedGrouped());
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updating(false));
  }
};
