import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Popper from '@material-ui/core/Popper';
import Dot from '../component/sales/Dot';
import moment from 'moment'
import { scaleTime as d3ScaleTime, timeMonth, } from 'd3';
import Paper from '@material-ui/core/Paper';
import Title from '../component/common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { months, SALE_TYPE } from '../../../../common/src/util/enum';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../common/src/util/number';
import areEqual from "./areEqual";
import { useLocalStorage } from './storage';

const useStyles = makeStyles((theme) => ({
  popper: {
    padding: theme.spacing(1),
  },
  popperLabel: {
    minWidth: 75,
    display: 'inline-block',
    fontWeight: theme.typography.fontWeightBold,
  },
  accordionDetails: {
    // height: 250,
  },
  chart: {
    height: 250,
    width: '100%',
  },
  accordion: {
    boxShadow: 'none',
    marginLeft: `-${theme.spacing(2)}px !important`
  },
  popperWrapper: {
    zIndex: 1201
  }
}));

const getTicks = (data) => {
  if (!data || !data.length) {
    return [];
  }

  const domain = [new Date(data[0].time), new Date(data[data.length - 1].time)];
  const scale = d3ScaleTime().domain(domain).range([0, 1]);
  const ticks = scale.ticks(timeMonth, 1);
  return ticks.map(entry => +entry);
};

const getTicksData = (data, ticks) => {
  if (!data || !data.length) {
    return [];
  }
  const dataMap = new Map(data.map((i) => [i.time, i]));
  ticks.forEach(function (item, index, array) {
    if (!dataMap.has(item)) {
      data.push({ time: item });
    }
  });
  return data;
};

const dateFormat = (time) => {
  return moment(time).format('MMM YYYY');
};

const Chart = (props) => {
  const { isSaleChart, isCostsChart } = props;
  const theme = useTheme();

  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useLocalStorage('chartExpanded', true);
  const { dataOnChart } = useSelector(state => state.unit);
  const { budgets, outcomes, unit } = dataOnChart;
  let content, title;

  const hasData = budgets && budgets.length ? budgets : outcomes;

  if ((isSaleChart && (!hasData || !unit)) || (isCostsChart && (!hasData || !unit))) {
    title = 'No data to display on chart';
    content = <div>No data to show</div>;

  } else if (unit && hasData.length) {
    title = `${unit.name} - ${hasData[0].name}`;
    const budgetData = [];
    const outcomeData = [];

    for (const item of budgets) {
      for (let m = 0; m < months.length; m++) {
        const month = months[m];
        budgetData.push({
          time: new Date(item.year, m).getTime(),
          budgetAmount: item[month],
          name: item.name,
          budget: item.budget,
          saleTypeId: item.saleTypeId,
          month
        })
      }
    }

    for (const item of outcomes) {
      for (let m = 0; m < months.length; m++) {
        const month = months[m];
        outcomeData.push({
          time: new Date(item.year, m).getTime(),
          outcomeAmount: item[month],
          name: item.name,
          budget: item.budget,
          saleTypeId: item.saleTypeId,
          month
        })
      }
    }

    const sortedBudgetData = budgetData.sort((a, b) => a.time - b.time);
    const budgetTicksArr = getTicks(sortedBudgetData);
    const sortedOutcomeData = outcomeData.sort((a, b) => a.time - b.time);
    const outcomeTicksArr = getTicks(sortedOutcomeData);
    const arrTicks = [...budgetTicksArr, ...outcomeTicksArr].sort((a, b) => a - b);
    const data = [...budgetData, ...outcomeData].sort((a, b) => a.time - b.time);

    const showTooltip = (payload, event) => {
      setAnchorEl(event.currentTarget);
      setTooltip(payload);
    };

    const hideTooltip = () => setTooltip(false);

    const getValueLabel = (tooltip) => {
      const { saleTypeId, budget } = tooltip;

      if (isSaleChart) {
        if (saleTypeId === SALE_TYPE.AMOUNT) {
          return 'Outcome';
        } else if (saleTypeId === SALE_TYPE.AMOUNT_BUDGET) {
          return 'Budget';
        } else if (saleTypeId === SALE_TYPE.DAYS) {
          return 'Days';
        }
      } else if (isCostsChart) {
        return budget ? 'Budget' : 'Outcome';
      }
    }

    const renderSideLabel = () => {
      return (
        <YAxis stroke={theme.palette.text.secondary}>
          <Label
            orientation="right"
            dx={-16}
            dy={60}
            angle={270}
            position="left"
            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
          >
            {isSaleChart ? 'Sales' : 'Costs'}
          </Label>
          <Label
            orientation="right"
            dx={-16}
            dy={32}
            angle={270}
            position="left"
            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
          >
            {'('}
          </Label>
          {
            outcomeData.length && (
              <Label
                orientation="right"
                dx={-16}
                dy={0}
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.primary.main }}
              >
                {'Outcome'}
              </Label>
            )
          }
          {
            budgetData.length && (
              [
                outcomeData.length && (
                  <Label
                    key={'dash'}
                    orientation="right"
                    dx={-16}
                    dy={-38}
                    angle={270}
                    position="left"
                    style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                  >
                    {'/'}
                  </Label>
                ),
                <Label
                  key={'budget'}
                  orientation="right"
                  dx={-16}
                  dy={outcomeData.length ? -70 : 5}
                  angle={270}
                  position="left"
                  style={{ textAnchor: 'middle', fill: '#8884d8' }}
                >
                  {'Budget'}
                </Label>
              ]
            )
          }
          <Label
            orientation="right"
            dx={-16}
            dy={budgetData.length && outcomeData.length ? -97 : outcomeData.length ? -33 : -23}
            angle={270}
            position="left"
            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
          >
            {')'}
          </Label>
        </YAxis>
      )
    }

    content = (
      <>
        <div className={classes.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{
                top: 16,
                right: 16,
                bottom: 0,
                left: 32,
              }}
            >
              <XAxis
                dataKey="time"
                ticks={arrTicks}
                tickCount={arrTicks.length}
                tickFormatter={dateFormat}
                stroke={theme.palette.text.secondary}
                interval="preserveStartEnd"
                minTickGap={20}
                padding={{ left: 10, right: 10 }}
              />
              {renderSideLabel()}

              <CartesianGrid strokeDasharray="3 3"/>

              <Line
                type="linear"
                dataKey="budgetAmount"
                stroke="#8884d8"
                connectNulls={true}
                isAnimationActive={true}
                dot={<Dot showTooltip={showTooltip} hideTooltip={hideTooltip}/>}
              />
              <Line
                type="linear"
                dataKey="outcomeAmount"
                stroke={theme.palette.primary.main}
                connectNulls={true}
                isAnimationActive={true}
                dot={<Dot showTooltip={showTooltip} hideTooltip={hideTooltip}/>}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <Popper
          className={classes.popperWrapper}
          open={!!tooltip}
          anchorEl={anchorEl}
        >
          <Paper className={classes.popper}>
            <div className={classes.popperLabel}>Name:</div>
            {tooltip.name}<br/>
            <div className={classes.popperLabel}>Date:</div>
            {dateFormat(tooltip.time)}<br/>
            <div className={classes.popperLabel}>{getValueLabel(tooltip)}:</div>
            {formatNumber(tooltip.budgetAmount || tooltip.outcomeAmount)}<br/>
          </Paper>
        </Popper>
      </>
    );
  }


  return (
    <>
      <Accordion
        expanded={expanded}
        className={classes.accordion}
        onChange={(event, isExpanded) => setExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Title>{title}</Title>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {content}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default memo(Chart, areEqual);

Chart.propTypes = {
  isSaleChart: PropTypes.bool,
  isCostsChart: PropTypes.bool,
}
