import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
}));

const ConsolidatedGroupModal = ({ open, onCancel, onConfirm, title, group = {}, }) => {
  const classes = useStyles();

  const [name, setName] = useState(group.name || '');
  const [showTotal, setShowTotal] = useState(group.showTotal || false);

  const updating = useSelector(state => state.consolidated.updating);

  useEffect(() => {
    if (open) {
      setName(group.name || '');
    }
  }, [open]);

  const valid = !!name.trim();

  return (
    <Dialog
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.fullWidth}>
          <TextField label="Name"
                     value={name}
                     onChange={e => setName(e.target.value)}/>
        </FormControl>
        <FormControl component="fieldset" className={classes.fullWidth}>
          <FormControlLabel label="Show Total row"
                            control={
                              <Checkbox checked={showTotal}
                                        onChange={() => setShowTotal(!showTotal)}
                                        color="primary"/>
                            }/>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                disabled={updating}
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!valid || updating}
                onClick={() => {
                  onConfirm({ id: group.id, name: name.trim(), showTotal });
                }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConsolidatedGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(ConsolidatedGroupModal);
