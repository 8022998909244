import * as _ from 'lodash';
import { deepCopy } from '../../../../common/src/util/object';
import {
  FETCHED_CONSOLIDATED,
  FETCHED_CONSOLIDATED_GROUPED,
  FETCHED_CONSOLIDATED_STRUCTURE,
  FETCHED_EXCHANGE_RATES,
  FETCHING_CONSOLIDATED,
  MOVED_CONSOLIDATED_GROUPED,
  TOGGLED_CONSOLIDATED_MODAL,
  UPDATING_CONSOLIDATED,
} from '../action/consolidated';

const initialState = {
  fetching: true,
  updating: false,
  consolidated: {
    sales: [],
    costs: [],
    groups: [],
    root: true
  },
  exchangeRates: [],
  structure: {
    groups: {},
    accounts: {},
    records: {}
  },
  consolidatedGrouped: {
    companies: [],
    records: [],
    structure: {
      records: [],
      groupRecords: []
    }
  },
  openModals: {}
};

const ifChanged = (previous, current) => _.isEqual(previous, current) ? previous : current;

export default (state = deepCopy(initialState), action) => {
  switch (action.type) {
    case FETCHING_CONSOLIDATED:
      return {
        ...state,
        fetching: true,
        consolidated: action.initial ? initialState.consolidated : state.consolidated
      };

    case FETCHED_CONSOLIDATED:
      return {
        ...state,
        fetching: false,
        updating: false,
        consolidated: {
          sales: ifChanged(state.consolidated.sales, action.consolidated.sales),
          costs: ifChanged(state.consolidated.costs, action.consolidated.costs),
          groups: ifChanged(state.consolidated.groups, action.consolidated.groups),
          root: action.consolidated.root
        }
      };

    case TOGGLED_CONSOLIDATED_MODAL:
      return { ...state, openModals: { ...state.openModals, [action.id]: action.open } };

    case UPDATING_CONSOLIDATED:
      return { ...state, updating: action.updating, openModals: action.updating ? state.openModals : {} };

    case FETCHED_EXCHANGE_RATES:
      return { ...state, exchangeRates: action.exchangeRates };

    case FETCHED_CONSOLIDATED_STRUCTURE:
      return { ...state, structure: action.structure }

    case FETCHED_CONSOLIDATED_GROUPED:
      return { ...state, consolidatedGrouped: action.consolidatedGrouped, fetching: false }

    case MOVED_CONSOLIDATED_GROUPED:
      const { record, direction } = action.movement;
      const records = state.consolidatedGrouped.records;
      const neighbor = records.find(r => r.position === (direction === 'up' ? record.position - 1 : record.position + 1));
      if (neighbor) {
        const updated = _.chain(records)
          .map(r => {
            if (r.id === record.id) {
              return { ...r, position: neighbor.position }
            } else if (r.id === neighbor.id) {
              return { ...r, position: record.position }
            } else {
              return r;
            }
          })
          .orderBy(['position', 'year', 'type'], ['asc', 'desc', 'asc'])
          .value();
        console.log(updated);
        return {
          ...state,
          consolidatedGrouped: {
            ...state.consolidatedGrouped,
            records: updated
          }
        };
      }
      return state;

    default:
      return state;
  }
};
