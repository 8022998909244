const isValueValid = value => value === '' || value === null || /^\d+$/.test(String(value));

// Locales reference: https://www.w3schools.com/jsref/jsref_tolocalestring.asp
const DEFAULT_LOCALE = 'sv-SE';

const formatNumber = (value, locale = DEFAULT_LOCALE) => {
  switch (typeof value) {
    case 'number':
      return Number(value).toLocaleString(locale);
    case 'string':
      if (value === '')
        return '';
      const number = parseNumber(value);
      if (!isNaN(number))
        return Number(value).toLocaleString(locale);
      return value;
    default:
      if (value === null || value === undefined)
        return '';
      return value;
  }
};

// Regex taken from: https://github.com/AndreasPizsa/parse-decimal-number
// Locale reference: https://docs.oracle.com/cd/E19455-01/806-0169/overview-9/index.html
const PATTERNS = [
  { thousands: ' ', decimal: ',', groupMinSize: 3 }, // swedish
  { thousands: ',', decimal: '.', groupMinSize: 3 }, // english
  { thousands: ' ', decimal: '.', groupMinSize: 3 }, // other
];
PATTERNS.forEach(PATTERN => {
  const { thousands, groupMinSize, decimal } = PATTERN;
  PATTERN.regex = new RegExp('^\\s*([+\-]?(?:(?:\\d{1,3}(?:\\' + thousands + '\\d{' + groupMinSize + ',3})+)|\\d*))(?:\\' + decimal + '(\\d*))?\\s*$');
});

// Parses a string value to a number according to the PATTERNS.
// Return NaN in case there is no match.
const parseNumber = value => {

  if (Number.isFinite(value))
    return value;

  if (typeof value !== 'string')
    return NaN;

  for (const PATTERN of PATTERNS) {
    const result = value.match(PATTERN.regex);
    if (!result || result.length !== 3)
      continue;
    const integerPart = result[1].replace(new RegExp('\\' + PATTERN.thousands, 'g'), '');
    const fractionPart = result[2];
    return parseFloat(integerPart + '.' + fractionPart);
  }
  return NaN;
};

module.exports.formatNumber = formatNumber;
module.exports.parseNumber = parseNumber;
