import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toReadablePermissionLevel } from '../../../../../common/src/util/enum';
import Title from '../common/Title';
import { fetchUnitUsers } from '../../action/unit';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import UserLink from '../common/UserLink';

const useStyles = makeStyles((theme) => ({
  userRoot: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    }
  },
  userPermission: {
    marginLeft: 'auto'
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function People() {
  const classes = useStyles();

  const unit = useSelector(state => state.unit);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unit.selectedUnitId)
      dispatch(fetchUnitUsers(unit.selectedUnitId));
  }, [dispatch, unit.selectedUnitId]);

  const selectedUnit = unit.units && unit.units.find(u => u.id === unit.selectedUnitId);

  let people;
  if (selectedUnit && unit.fetchedUnitUsers[selectedUnit.id]) {
    people = (
      <React.Fragment key="users">
        {
          unit.fetchedUnitUsers[selectedUnit.id].map(user => (
              <div key={user.id} className={classes.userRoot}>
                <UserLink user={user}/>
                <Typography variant="caption" color="inherit" className={classes.userPermission}>
                  {toReadablePermissionLevel(user.permission.level)}
                </Typography>
              </div>
            ),
          )
        }
      </React.Fragment>
    )
  } else {
    people = 'No units selected';
  }

  const loader = unit.fetchingUnitUsers ? <CircularProgress size={16}/> : '';

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Title>Permissions {loader}</Title>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {people}
      </AccordionDetails>
    </Accordion>
  );
}
