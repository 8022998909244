import React, {
  memo,
  useEffect,
  useState
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../common/Title';
import CustomIcon from '../common/icons/';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import {
  months,
  Months,
  PERMISSION_LEVEL,
  SALE_STATUS,
  SALE_TYPE,
  UNIT_TYPE,
} from '../../../../../common/src/util/enum';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import UnitMenu from './UnitMenu';
import {
  getSalesForChart,
  groupSales,
} from '../../util/sale';
import calculatePercentageDiff from '../../util/calculatePercentageDiff';
import {
  fetchSaleAssignmentsPerUnit,
  showDataOnChart,
  updateSale
} from '../../action/unit';
import SaleMenu from '../common/SaleMenu';

import {
  deepCopy,
  deepValue
} from '../../../../../common/src/util/object';
import {
  formatNumber,
  parseNumber,
} from '../../../../../common/src/util/number';
import SaleStatusIcon from '../common/SaleStatusIcon';
import { useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Paper from '@material-ui/core/Paper';
import * as _ from 'lodash';
import { loadLocal } from '../../util/storage';
import areEqual from '../../util/areEqual';
import EditableCell from '../common/EditableCell';
import {
  currentBudgetYear,
  nextBudgetYear
} from '../../../../../common/src/util/date';

const useStyles = makeStyles(theme => ({
  nameColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuColumn: {
    padding: 0,
  },
  rowSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  yearExpandColumn: {
    maxWidth: 30,
  },
  yearColumn: {
    maxWidth: 40,
  },
  strongCell: {
    fontWeight: 500,
  },
  table: {
    overflow: 'auto',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  rowDividerSmall: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  statusColumn: {
    padding: 0,
    paddingTop: 5,
  },
  header: {
    display: 'flex',
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(2),
  },
  activeToggle: {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
}));

const isValueValid = value => value === '' || value === null || !isNaN(parseNumber(value));

const errorKey = (saleId, property) => `sale_${saleId}_${property}`;

const noop = () => {};

const calculateDailyAverage = (row, salesGroups) => {
  let rowDailyAverage;
  const { year } = row;

  let daysRow;
  for (const saleGroup of salesGroups)
    for (const sale of saleGroup)
      if (sale.year === year && sale.saleTypeId === SALE_TYPE.DAYS)
        daysRow = sale;

  if (daysRow) {
    let totalAmount = 0;
    let totalDays = 0;
    for (const month of months) {
      const days = parseNumber(daysRow[month]);
      const amount = parseNumber(row[month]);
      if (!isNaN(days) && !isNaN(amount)) {
        totalAmount += amount;
        totalDays += days;
      }
    }
    if (totalDays > 0)
      rowDailyAverage = Math.round(totalAmount / totalDays);
  }
  return rowDailyAverage;
};

const Sale = props => {
  const classes = useStyles();

  const { unitId } = props;

  const dispatch = useDispatch();

  const [unit, sales, fetchingUnitSales] = useSelector(state => {
    const { fetchedUnitSales, fetchingUnitSales } = state.unit;
    const unitSales = fetchedUnitSales.find(unitSales => unitSales.unit.id === unitId) || {};
    const { unit, sales } = unitSales;
    return [unit, sales, fetchingUnitSales];
  });
  const assignments = useSelector(state => state.unit.saleAssignmentsPerUnit[unitId] || []);
  const { user } = useSelector(state => state.user);

  const [showPercentage, setShowPercentage] = useState([]);
  const [expandedNames, setExpandedNames] = useState([]);
  const [selectedSaleId, setSelectedSaleId] = useState(false);
  const [errorValues, setErrorValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const location = useLocation();
  const highlightSalesIds = deepValue(location, 'state.highlightSalesIds') || [];
  const highlightUnitsIds = deepValue(location, 'state.highlightUnitsIds') || [];

  const currentYear = currentBudgetYear();
  const nextYear = nextBudgetYear();

  const _showDataOnChart = (saleId, allSales) => {
    const { selectedBudgets, selectedOutcomes } = getSalesForChart(saleId, allSales);
    dispatch(showDataOnChart({ budgets: selectedBudgets, outcomes: selectedOutcomes, unit }));
  };

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false)
  }, []);

  useEffect(() => {
    if (!sales || sales.length === 0)
      dispatch(showDataOnChart({ unit }));
  }, [sales, unit, dispatch]);

  useEffect(() => {
    if (highlightSalesIds.length && sales && sales.length)
      for (const sale of sales)
        if (highlightSalesIds.includes(sale.id)) {
          setSelectedSaleId(sale.id);
          break;
        }
  }, [highlightUnitsIds, highlightSalesIds, sales, unitId]);

  useEffect(() => {
    dispatch(fetchSaleAssignmentsPerUnit());
  }, [unitId]);

  const expandName = name => () => {
    if (expandedNames.some(n => n === name))
      setExpandedNames(expandedNames.filter(n => n !== name));
    else {
      setExpandedNames([name, ...expandedNames]);
    }
    setShowPercentage(showPercentage => showPercentage.filter(item => item.name !== name));
  };

  const selectSaleRow = (saleId, allSales) => () => {
    if (isMounted && saleId !== selectedSaleId) {
      setSelectedSaleId(saleId);
      _showDataOnChart(saleId, allSales);
    }
  };

  let table;
  if (sales && sales.length === 0)
    table = 'No data to show.';
  else if (sales) {
    const permissions = loadLocal('permissions');
    const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);

    const changeSale = (saleId, property) => event => {

      event.preventDefault();

      const sale = sales.find(s => s.id === saleId);
      if (!sale)
        return;

      const newValue = event.target.innerText.replace(/<[^>]+>/g, ''); // HTML

      let oldValue = formatNumber(sale[property]);
      if (errorValues[errorKey(saleId, property)] !== undefined)
        oldValue = errorValues[errorKey(saleId, property)];

      if (newValue === oldValue)
        return;

      const isValid = isValueValid(newValue);

      const errorValuesCopy = deepCopy(errorValues);
      if (isValid) {
        delete errorValuesCopy[errorKey(saleId, property)];
        const value = newValue === '' ? null : Math.round(parseNumber(newValue));
        dispatch(updateSale(unitId, sale, { [property]: value }));
      } else {
        errorValuesCopy[errorKey(saleId, property)] = deepCopy(newValue);
      }
      setErrorValues(errorValuesCopy);
    };

    const onKeyDown = event => {
      if (event.key === 'Enter')
        event.target.blur();
    };

    const onBlur = (saleId, property) => event => {
      setIsEditing(false);
      changeSale(saleId, property)(event);
    };

    const onFocus = () => {
      setIsEditing(true);
      setTimeout(() => document.execCommand('selectAll', false, null));
    };

    const excludePrevYearBudgets = (item => {
      if (item.year >= currentYear) {
        return true;
      } else if (item.saleTypeId === SALE_TYPE.AMOUNT) {
        return true
      }
      return false;
    });

    const excludeDeletedRows = (row) => {
      const isValid = isCfo ? false : user?.id !== row.approverId;
      const firstOne = _.sortBy(sales, (item) => {
        return [item.name, item.year];
      }).reverse().find(item => item.name === row.name);

      if (firstOne) {
        return !(firstOne.saleStatusId === SALE_STATUS.DELETED && isValid);
      }
      return true;
    }

    const salesGroups = groupSales(sales.filter(excludePrevYearBudgets).filter(excludeDeletedRows));
    let defaultSelectedSaleId = salesGroups[salesGroups.length - 1]?.[0].id;

    const allSales = [];
    salesGroups.forEach(saleGroup => saleGroup.forEach(row => allSales.push(row)));
    let previousDaysRow = false;
    let totalDividerRendered = false;
    let previousRow;

    const isDistributor = unit.type === UNIT_TYPE.DISTRIBUTOR;
    const hasSaleRow = salesGroups.some(saleGroup => saleGroup.some(row => row.saleTypeId === SALE_TYPE.DAYS));
    const showDailyAverage = hasSaleRow;

    const setPercentage = (sale) => {
      setShowPercentage(showPercentage => showPercentage.find(item => item.name === sale.name) ?
        showPercentage.find(item => item.name === sale.name && item.id !== sale.id) ?
          [...showPercentage.filter(item => item.name !== sale.name && item.id !== sale.id), sale] :
          showPercentage.filter(item => item.name !== sale.name) :
        [...showPercentage, sale],
      );
    };

    const tableHead = (
      <TableHead>
        <TableRow>
          <TableCell className={classes.headColumn}>&nbsp;</TableCell>
          <TableCell className={classes.headColumn}>&nbsp;</TableCell>
          <TableCell className={classes.headColumn}>District</TableCell>
          <TableCell className={classes.headColumn}>Name</TableCell>
          <TableCell align="right" className={classes.headColumn}>Year</TableCell>
          <TableCell className={classes.headColumn}>&nbsp;</TableCell>
          {Months.map(month => <TableCell align="right" key={month} className={classes.headColumn}>{month}</TableCell>)}
          <TableCell align="right" className={classes.headColumn}>Total</TableCell>
          {showDailyAverage ? <TableCell align="right" className={classes.headColumn}>Daily Average</TableCell> : null}
        </TableRow>
      </TableHead>
    );
    table = (
      <div className={classes.table}>
        <Table>
          {tableHead}
          <TableBody>
            {salesGroups
              .map(saleGroup => saleGroup.filter(excludePrevYearBudgets))
              .map(saleGroup => saleGroup
                .filter(row => !(salesGroups.length <= 2 && row.total)) // if only one entry, don't show total row. 2 = 1 sole entry + 1 total row
                .map((row, rowIndex) => {
                  let { district, name, saleTypeId, year, total: isTotal, empty: isEmpty, id, saleStatusId } = row;
                  let hasNextYear;
                  let firstItem = null;

                  salesGroups.forEach(group => {
                    firstItem = group.find(item => item.name === name);

                    if (firstItem) {
                      hasNextYear = firstItem.year === nextYear;
                      hasNextYear = hasNextYear && !!months.some(month => firstItem[month] !== null);
                    }
                  });

                  const isNextYear = row.year === nextYear;
                  const isContributionRequested = saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED;
                  const isRowAmountBudgetType = saleTypeId === SALE_TYPE.AMOUNT_BUDGET;
                  const isRowAmountType = saleTypeId === SALE_TYPE.AMOUNT || saleTypeId === SALE_TYPE.AMOUNT_BUDGET;
                  const isRowAmount = saleTypeId === SALE_TYPE.AMOUNT;
                  const isRowDaysType = saleTypeId === SALE_TYPE.DAYS;
                  const isMainRow = rowIndex === 0;
                  const isExpandedName = expandedNames.some(n => n === name);

                  if (!isMainRow && !isExpandedName && !isEmpty)
                    return undefined;

                  if (isRowDaysType && isDistributor)
                    return undefined;

                  let yearExpand;
                  if (isMainRow || isEmpty) {
                    yearExpand = (
                      <IconButton
                        className={clsx('expand-child-row-button', {
                          'expanded-button': isExpandedName,
                        })}
                        size="small"
                        onClick={expandName(name)}
                        disabled={saleGroup.length === 1}
                      >
                        {isExpandedName ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                      </IconButton>
                    );
                  }

                  const rowTotal = isEmpty ? null : months.reduce((rv, month) => rv + Number(row[month]), 0);

                  let rowDailyAverage;
                  if (showDailyAverage && !isRowDaysType)
                    rowDailyAverage = calculateDailyAverage(row, salesGroups);

                  if (!isMainRow && !isEmpty) {
                    name = '';
                    district = '';
                  } else if (isRowDaysType)
                    name = 'Days';

                  let tableRowClass;
                  if (!selectedSaleId && (id === defaultSelectedSaleId || isDistributor))
                    setTimeout(() => selectSaleRow(id, allSales)());
                  if (id === selectedSaleId)
                    tableRowClass = classes.rowSelected;

                  const canSelectAndHover = isRowAmountType || isRowDaysType;
                  const selectHandler = canSelectAndHover && !isEditing ? rowId => selectSaleRow(rowId, allSales) : noop;

                  const strong = isRowDaysType || isTotal ? classes.strongCell : undefined;

                  let divider;
                  if (!totalDividerRendered && isTotal) {
                    totalDividerRendered = true;
                    divider = classes.rowDivider;
                  } else if (previousDaysRow && !isRowDaysType) {
                    divider = classes.rowDivider;
                  } else if (previousRow && previousRow.name !== row.name) {
                    divider = classes.rowDividerSmall;
                  }
                  previousDaysRow = isRowDaysType;
                  previousRow = row;

                  const showSaleMenu = isRowAmountBudgetType && !isTotal && !isEmpty && year === nextYear;
                  const showStatusIcon = showSaleMenu;
                  const validValues = !Object.keys(errorValues).some(key => key.startsWith(`sale_${id}_`));

                  const days = sales.find(s => s.saleTypeId === SALE_TYPE.DAYS && s.year === row.year);
                  const previousSale = saleGroup.find(s => s.year === row.year - 1);
                  const hasMonthValues = months.some(month => row[month]);
                  let calculatedPercentageTotalRow = isMainRow && !isEmpty && showPercentage.find(item => item.name === row.name);
                  calculatedPercentageTotalRow = calculatedPercentageTotalRow ?
                    months.reduce((rv, month) => rv + Number(calculatedPercentageTotalRow[month]), 0) : null;

                  // ONLY CFO
                  if (!isCfo && row.saleStatusId === SALE_STATUS.DELETED) return null;

                  const hasChildPercentage = saleGroup.find((item, index) => {
                    const isRowDaysType = item.saleTypeId === SALE_TYPE.DAYS;
                    const hasMonthValues = months.some(month => item[month]);
                    const isMainRow = index === 0;
                    let hasNextYear;

                    hasNextYear = saleGroup[0].year === nextYear;
                    hasNextYear = hasNextYear && !!months.some(month => saleGroup[0][month] !== null);

                    return !isRowDaysType && !isMainRow && hasMonthValues && hasNextYear && item.year === currentYear;
                  });

                  const hasChildPercentageButton = isMainRow && hasChildPercentage;

                  return (
                    <TableRow
                      key={id}
                      onClick={selectHandler(id, allSales)}
                      className={clsx(classes.row, tableRowClass, {
                        'has-child-percentage-button': !!hasChildPercentageButton,
                      })}
                    >
                      <TableCell className={clsx(classes.menuColumn, divider)}>
                        {showSaleMenu ? <SaleMenu sale={row} previousSale={previousSale} days={days} unitId={unitId} validValues={validValues}/> : ''}
                      </TableCell>
                      <TableCell className={clsx(classes.statusColumn, strong, divider)}>
                        {showStatusIcon ? <SaleStatusIcon sale={row} user={user} unit={unit}/> :
                          !isRowDaysType && !isMainRow && hasMonthValues && hasNextYear && row.year === currentYear ? (
                            <Tooltip title="Show Budget in percentage">
                              <IconButton
                                size="small"
                                className={clsx('toggle-percentage-button', {
                                  [classes.activeToggle]: showPercentage.find(item => item.id === row.id),
                                })}
                                onClick={() => setPercentage(row)}
                              >
                                <CustomIcon icon={'percentage'}/>
                              </IconButton>
                            </Tooltip>
                          ) : ''}
                      </TableCell>
                      <TableCell className={clsx(strong, divider)}>{isRowDaysType ? '' : district}</TableCell>
                      <TableCell className={clsx(classes.nameColumn, strong, divider)}>{name}</TableCell>
                      <TableCell align="right" className={clsx(classes.yearColumn, strong, divider)}>{year} {isRowAmountBudgetType ? 'Budget' : isRowAmount ? 'Outcome' : ''}</TableCell>
                      <TableCell align="left" className={clsx(classes.yearExpandColumn, strong, divider)}>{yearExpand}</TableCell>
                      {months.map(month => {

                        const value = errorValues[errorKey(id, month)] || row[month];
                        const valid = isValueValid(value);
                        const calculatedPercentageRow = isMainRow && showPercentage.find(item => item.name === row.name)
                        const editable = (isRowAmountBudgetType || isRowDaysType) &&
                          isContributionRequested &&
                          !isEmpty &&
                          !isTotal &&
                          year === nextYear;

                        return (
                          <TableCell align="right" key={month} className={clsx(strong, divider)}>
                            {
                              calculatedPercentageRow ?
                                <p>{calculatePercentageDiff(value, calculatedPercentageRow[month])}</p> :
                                <EditableCell
                                  value={valid ? formatNumber(value) : value}
                                  onBlur={onBlur(id, month)}
                                  onKeyDown={onKeyDown}
                                  onFocus={onFocus}
                                  editable={editable}
                                  valid={valid}
                                />
                            }
                          </TableCell>
                        );
                      })}
                      <TableCell align="right" className={clsx(strong, divider)}>
                        {
                          calculatedPercentageTotalRow ? calculatePercentageDiff(rowTotal, calculatedPercentageTotalRow) : formatNumber(rowTotal)
                        }
                      </TableCell>
                      {showDailyAverage ? <TableCell align="right" className={clsx(strong, divider)}>{formatNumber(rowDailyAverage)}</TableCell> : null}
                    </TableRow>
                  );
                }))}
          </TableBody>
        </Table>
      </div>
    );
  }

  const loader = fetchingUnitSales ? <CircularProgress size={16}/> : '';
  const title = unit ? `Sales - ${unit.name}` : 'Sales';

  const [unitMenu, unitMenuDialogs] = UnitMenu(unit, sales, assignments);

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        {unitMenu}
        <Title>{title} {loader}</Title>
      </div>
      <div>
        {table}
        {unitMenuDialogs}
      </div>
    </Paper>
  );
};

Sale.propTypes = {
  unitId: PropTypes.number.isRequired,
};

export default memo(Sale, areEqual);
