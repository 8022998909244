const { deepFreeze } = require('./object');

const PERMISSION_RESOURCE_TYPE = deepFreeze({
  SYSTEM: 'system',
  UNIT: 'unit',
  SALE: 'sale',
});

const PERMISSION_LEVEL = deepFreeze({
  READ: 10,
  WRITE: 20,
  OWNER: 30,
  MANAGER: 35,
  CFO: 40,
});
const toReadablePermissionLevel = level => {
  switch (level) {
    case PERMISSION_LEVEL.READ:
      return 'Observer';
    case PERMISSION_LEVEL.WRITE:
      return 'Contributor';
    case PERMISSION_LEVEL.OWNER:
      return 'Approver/Contributor';
    case PERMISSION_LEVEL.MANAGER:
      return 'Manager'
    case PERMISSION_LEVEL.CFO:
      return 'CFO';
    default:
      return '';
  }
};

const SALE_TYPE = deepFreeze({
  AMOUNT: 10,
  DAYS: 20,
  AMOUNT_BUDGET: 30,
});
const SALE_TYPES = Object.keys(SALE_TYPE).map(TYPE => SALE_TYPE[TYPE]);
const isValidSaleType = type => SALE_TYPES.includes(type);

const SALE_STATUS = deepFreeze({
  DELETED: 0,
  CONTRIBUTION_REQUESTED: 10,
  APPROVAL_REQUESTED: 20,
  APPROVED: 30,
  FINAL: 40,
});
const SALE_STATUSES = Object.keys(SALE_STATUS).map(STATUS => SALE_STATUS[STATUS]);
const isValidSaleStatus = status => SALE_STATUSES.includes(status);
const toReadableSaleStatus = saleStatusId => {
  switch (saleStatusId) {
    case SALE_STATUS.DELETED:
      return 'Deleted';
    case SALE_STATUS.CONTRIBUTION_REQUESTED:
      return 'Contribution requested';
    case SALE_STATUS.APPROVAL_REQUESTED:
      return 'Approval requested';
    case SALE_STATUS.APPROVED:
      return 'Final approval requested';
    case SALE_STATUS.FINAL:
      return 'Final';
    default:
      return '';
  }
};

const UNIT_TYPE = deepFreeze({
  COMPANY: 'company',
  REGION: 'region',
  DEPARTMENT: 'department',
  DISTRIBUTOR: 'distributor',
});

const Months = deepFreeze(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
const months = deepFreeze(Months.map(month => month.toLowerCase()));

const NOTIFICATION_TYPE = deepFreeze({
  SALES_CONTRIBUTION_REQUEST: 'SALES_CONTRIBUTION_REQUEST',
  SALES_APPROVAL_REQUEST: 'SALES_APPROVAL_REQUEST',
  SALES_FINAL_APPROVAL_REQUEST: 'SALES_FINAL_APPROVAL_REQUEST',
  SALES_MESSAGE: 'SALES_MESSAGE',
  SALES_INITIATION_REQUESTED: 'SALES_INITIATION_REQUESTED',
  SALES_INITIATION_COMPLETED: 'SALES_INITIATION_COMPLETED',
  COSTS_CONTRIBUTION_REQUEST: 'COSTS_CONTRIBUTION_REQUEST',
  COSTS_FINAL_APPROVAL_REQUEST: 'COSTS_FINAL_APPROVAL_REQUEST',
  COSTS_MESSAGE: 'COSTS_MESSAGE',
  SALES_DUE_APPROVED: 'SALES_DUE_APPROVED',
  SALES_DUE_CONTRIBUTION_REQUESTED: 'SALES_DUE_CONTRIBUTION_REQUESTED',
  SALES_DUE_APPROVAL_REQUESTED: 'SALES_DUE_APPROVAL_REQUESTED',
  COSTS_DUE_APPROVED: 'COSTS_DUE_APPROVED',
  COSTS_DUE_CONTRIBUTION_REQUESTED: 'COSTS_DUE_CONTRIBUTION_REQUESTED',
  COSTS_DUE_APPROVAL_REQUESTED: 'COSTS_DUE_APPROVAL_REQUESTED',
});

const titleRegardingToStatus = (status) => {
  const statuses = {
    SALES_DUE_CONTRIBUTION_REQUESTED: 'Sales contribution reminder',
    SALES_CONTRIBUTION_REQUEST: 'Sales contribution requested',
    SALES_DUE_APPROVAL_REQUESTED: 'Sales approval reminder',
    SALES_APPROVAL_REQUEST: 'Sales approval requested',
    SALES_FINAL_APPROVAL_REQUEST: 'Sales final approval requested',
    SALES_MESSAGE: 'Sales message received',
    SALES_DUE_APPROVED: 'Sales approved',
    SALES_INITIATION_REQUESTED: 'Budget initiation requested',
    SALES_INITIATION_COMPLETED: 'Budget initiation completed',
    COSTS_DUE_APPROVED: 'Costs approved',
    COSTS_DUE_CONTRIBUTION_REQUESTED: 'Costs contribution reminder',
    COSTS_CONTRIBUTION_REQUEST: 'Costs contribution requested',
    COSTS_FINAL_APPROVAL_REQUEST: 'Costs final approval requested',
    COSTS_MESSAGE: 'Costs message received',
    COSTS_DUE_APPROVAL_REQUESTED: 'Costs approval reminder',
  }
  return statuses[status];
}

const detailsRegardingToStatus = (status, { userLink, unitLink, firstApproverDisplayName }) => {
  const statuses = {
    SALES_DUE_CONTRIBUTION_REQUESTED: `Sales contribution requested by ${userLink} for ${unitLink}. Now close to due date.`,
    SALES_CONTRIBUTION_REQUEST: `Sales contribution requested by ${userLink} for ${unitLink}.`,
    SALES_DUE_APPROVAL_REQUESTED: `Sales approval requested by ${userLink} for ${unitLink}. Now close to due date.`,
    SALES_APPROVAL_REQUEST: `Sales approval requested by ${userLink} for ${unitLink}`,
    SALES_FINAL_APPROVAL_REQUEST: `Sales final approval requested by ${userLink} for ${unitLink}.`,
    SALES_MESSAGE: `Sales message received from ${userLink} for ${unitLink}.`,
    SALES_DUE_APPROVED: `Sales approved from ${userLink} for ${unitLink}.`,
    SALES_INITIATION_REQUESTED: firstApproverDisplayName ? `Budget initiation requested from ${firstApproverDisplayName} by ${userLink} for ${unitLink} where you are assigned as 2nd level approver.` : `Budget initiation requested by ${userLink} for ${unitLink}.`,
    SALES_INITIATION_COMPLETED: `Budget initiation completed by ${userLink} for ${unitLink}.`,
    COSTS_DUE_APPROVED: `Costs approved from ${userLink} for ${unitLink}.`,
    COSTS_DUE_CONTRIBUTION_REQUESTED: `Cost contribution requested by ${userLink} for ${unitLink}. Now close to due date.`,
    COSTS_CONTRIBUTION_REQUEST: `Cost contribution requested by ${userLink} for ${unitLink}.`,
    COSTS_FINAL_APPROVAL_REQUEST: `Cost approval requested by ${userLink} for ${unitLink}.`,
    COSTS_MESSAGE: `Costs message received from ${userLink} on cost entry for ${unitLink}.`,
    COSTS_DUE_APPROVAL_REQUESTED: `Cost approval requested by ${userLink} for ${unitLink}. Now close to due date.`,
  };
  return statuses[status];
};

module.exports = {
  PERMISSION_RESOURCE_TYPE,
  PERMISSION_LEVEL,
  SALE_TYPE,
  SALE_STATUS,
  UNIT_TYPE,
  NOTIFICATION_TYPE,
  toReadablePermissionLevel,
  toReadableSaleStatus,
  Months,
  months,
  isValidSaleType,
  isValidSaleStatus,
  titleRegardingToStatus,
  detailsRegardingToStatus,
};
