import config from '../config';
import axios from 'axios';
import { handleAxiosError } from './error';

export const CHECKED_STATUS = 'CHECKED_STATUS';

const { budgetApiUrl } = config;

export const checkedStatus = status => ({
  type: CHECKED_STATUS,
  status,
});

export const checkStatus = () => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/status`,
      withCredentials: true,
      validateStatus: status => status === 200 || status === 500,
    });
    dispatch(checkedStatus(res.data));
  } catch (err) {
    dispatch(checkedStatus({
      allOk: false,
    }));
    handleAxiosError(err, dispatch);
  }
};
