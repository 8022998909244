import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    height: 'calc(100vh - 100px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const CircularProgressCentered = ({ size }) => {
  const classes = useStyles();

  return (
    <div className={classes.progressWrapper}>
      <CircularProgress size={size || 16}/>
    </div>
  )
};

export default memo(CircularProgressCentered);