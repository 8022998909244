import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  input: {
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
    margin: theme.spacing(1),
  },
  inputCheckbox: {
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
    marginLeft: theme.spacing(1),
  }
}));

const AccountGroupModal = (
  {
    open,
    onCancel,
    onConfirm,
    title,
    group,
  }) => {
  const classes = useStyles();
  const [name, setName] = useState(group.name || '');
  const [income, setIncome] = useState(!!group.income);
  const [credit, setCredit] = useState(!!group.credit);

  const updating = useSelector(state => state.account.updatingAccountGroup);

  useEffect(() => {
    if (open) {
      setName(group.name || '');
      setIncome(!!group.income);
      setCredit(!!group.credit);
    }
  }, [open]);

  const valid = !!name.trim();

  return (
    <Dialog open={open}
            onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <TextField label="Name"
                     value={name}
                     onChange={e => setName(e.target.value)}/>
        </FormControl>
        <FormControl component="fieldset" className={classes.inputCheckbox}>
          <FormControlLabel label="Income"
                            control={
                              <Checkbox checked={income}
                                        onChange={() => setIncome(!income)}
                                        color="primary"/>
                            }/>
        </FormControl>
        <FormControl component="fieldset" className={classes.inputCheckbox}>
          <FormControlLabel label="Credit"
                            control={
                              <Checkbox checked={credit}
                                        onChange={() => setCredit(!credit)}
                                        color="primary"/>
                            }/>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                disabled={updating}
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!valid || updating}
                onClick={() => {
                  onConfirm({ id: group.id, name: name.trim(), income, credit });
                }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(AccountGroupModal);
