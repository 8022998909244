import { deepFreeze } from '../../../common/src/util/object';

// noinspection JSFileReferences
import { environment } from './env';

const config = deepFreeze({
  local: {
    environment,
    rootUrl: 'http://localhost:3000',
    budgetApiUrl: 'http://localhost:4000',
    sentry: {
      enabled: false,
      dsn: 'https://caf1f7a008b84da89c4d4fc60f70f6c2@o695437.ingest.sentry.io/5775612',
    },
  },
  test: {
    environment,
    rootUrl: 'https://veidec.reeinvent.com',
    budgetApiUrl: 'https://veidec.reeinvent.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://caf1f7a008b84da89c4d4fc60f70f6c2@o695437.ingest.sentry.io/5775612',
    },
  },
  staging: {
    environment,
    rootUrl: 'https://staging.budget.veidec.com',
    budgetApiUrl: 'https://staging.budget.veidec.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://caf1f7a008b84da89c4d4fc60f70f6c2@o695437.ingest.sentry.io/5775612',
    },
  },
  production: {
    environment,
    rootUrl: 'https://budget.veidec.com',
    budgetApiUrl: 'https://budget.veidec.com/api',
    sentry: {
      enabled: true,
      dsn: 'https://caf1f7a008b84da89c4d4fc60f70f6c2@o695437.ingest.sentry.io/5775612',
    },
  },
});


const environmentConfig = config[environment];

if (!environmentConfig)
  throw new Error(`Configuration not found for environment: ${environment}`);

export default environmentConfig;
