import React, { memo, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { nextBudgetYear } from '../../../../../common/src/util/date';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { FormControl } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { handleAxiosError } from '../../action/error';
import { useDispatch } from 'react-redux';
import config from '../../config';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 300,
    margin: theme.spacing(1),
  },
  disabled: {
    cursor: 'default',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

const YearSwitchDialog = ({ open, onConfirm, onClose, year }) => {
  const classes = useStyles();

  const [date, setDate] = useState(moment().set('year', year));
  const onChange = value => setDate(value);

  useEffect(() => {
    if (open) {
      setDate(moment().set('year', year));
    }
  }, [open, year]);

  return (
    <Dialog open={open}
            onClose={onClose}>
      <DialogTitle>Switch budget year for all Budget Tool users</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker views={['year']}
                        label="Year"
                        helperText="Year to switch budgeting to"
                        value={date}
                        onChange={onChange}/>
          </MuiPickersUtilsProvider>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                onClick={() => onConfirm(date.year())}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const FakeButton = ({ children, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Button color="primary"
            variant="contained"
            disableRipple={disabled}
            disableFocusRipple={disabled}
            disableTouchRipple={disabled}
            className={clsx({ [classes.disabled]: disabled })}
            onClick={disabled ? () => {} : onClick}>
      {children}
    </Button>
  );
};

const YearSwitcher = ({ isCfo }) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onClick = () => {
    setDialogOpen(true);
  }
  const onConfirm = async year => {
    try {
      await axios({
        method: 'put',
        url: `${config.budgetApiUrl}/configuration/next-budget-year`,
        data: {
          value: year
        },
        withCredentials: true,
      });
      window.location.reload();
    } catch (err) {
      handleAxiosError(err, dispatch);
    }
  }
  const onClose = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <FakeButton disabled={!isCfo}
                  onClick={onClick}>
        {nextBudgetYear()}
      </FakeButton>
      <YearSwitchDialog open={dialogOpen}
                        year={nextBudgetYear()}
                        onConfirm={onConfirm}
                        onClose={onClose}/>
    </>
  );
}

export default memo(YearSwitcher);
