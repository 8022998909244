import React, { memo, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { matchPath } from "react-router";
import PropTypes from 'prop-types';
import clsx from "clsx";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import SalesTutorial from "./pages/salesTutorial";
import CostsTutorial from "./pages/costsTutorial";
import TasksTutorial from "./pages/tasksTutorial";
import UnitsTutorial from "./pages/unitsTutorial";
import UsersTutorial from "./pages/usersTutorial";
import { useLocation } from "react-router-dom";
import { updateUser } from '../../action/user';
import areEqual from "../../util/areEqual";

const useStyles = makeStyles(theme => ({
  backgroundLayer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 1300,
    top: 0,
    left: 0
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: 'white'
  },
}));

const pages = [{
  name: 'costs',
  component: CostsTutorial,
  activeUrls: ['/unit/:unitId/costs'],
}, {
  name: 'sales',
  component: SalesTutorial,
  activeUrls: ['/unit/:unitId/sale'],
}, {
  name: 'tasks',
  component: TasksTutorial,
  activeUrls: ['/', '/tasks'],
}, {
  name: 'units',
  component: UnitsTutorial,
  activeUrls: ['/units'],
}, {
  name: 'users',
  component: UsersTutorial,
  activeUrls: ['/users'],
}];

const Tutorial = ({ open, onOpenTutorial }) => {
  const classes = useStyles();
  const [showContent, setShowContent] = useState(false);
  const tutorialSeen = useSelector(state => state.user.user.tutorialSeen);
  const [pageComponent, setPageComponent] = useState(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const wrapper = useRef();
  const openPrev = useRef();

  useEffect(() => {
    const body = document.querySelector('body');

    if (!document.querySelector('.tutorial-wrapper')) {
      wrapper.current = document.createElement('div');
      wrapper.current.classList.add('tutorial-wrapper');
      body.prepend(wrapper.current);
    }

    return () => {
      body.removeChild(wrapper.current);
      onOpenTutorial(false);
    }
  }, []);

  useEffect(() => {
    if (open && !openPrev.current) {
      setPageComponent(pages.find(page => {
        return page.activeUrls.find(url => {
          return matchPath(pathname, {
            path: url,
            exact: true,
            strict: false
          });
        });
      }));
    }
    else if (!open && openPrev.current) {
      setPageComponent(null);
      onOpenTutorial(false);
    }
    openPrev.current = open;
  }, [open]);

  useEffect(() => {
    if (pageComponent) {
      const { name } = pageComponent;

      if (!tutorialSeen[name]) {
        dispatch(updateUser({
          tutorialSeen: { ...tutorialSeen, [name]: true }
        }));
      }
    }
  }, [pageComponent]);

  const closeLayer = () => {
    setShowContent(false);
  }

  if (!open || !pageComponent) return null;

  const PageComponent = pageComponent.component;

  return createPortal (
    <div
      className={clsx(classes.backgroundLayer)}
    >
      <>
        <PageComponent
          closeLayer={closeLayer}
          showContent={showContent}
          setShowContent={setShowContent}
          openTutorial={onOpenTutorial}
        >
          {
            showContent && (
              <IconButton
                size={'small'}
                className={classes.closeButton}
              >
                <CancelIcon />
              </IconButton>
            )
          }
        </PageComponent>
      </>
    </div>,
    wrapper.current
  )
};

Tutorial.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpenTutorial: PropTypes.func.isRequired,
}

export default memo(Tutorial, areEqual);