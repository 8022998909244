import { useState } from 'react';
import * as _ from 'lodash';

export const saveLocal = (key, value) => {
  const datum = _.isObjectLike(value) ? JSON.stringify(value) : value;
  localStorage.setItem(key, datum);
};

export const loadLocal = key => {
  const datum = localStorage.getItem(key);
  let value = datum;
  if (value) {
    try {
      value = JSON.parse(datum);
    } catch (e) {
      // ignored
    }
  }
  return value;
};

export const removeLocal = key => {
  localStorage.removeItem(key);
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = loadLocal(key);
      return value !== undefined ? value : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      saveLocal(key, valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
