import config from '../config';
import axios from 'axios';
import { handleAxiosError } from './error';

export const FETCHING_TASKS = 'FETCHING_TASKS';
export const FETCHED_TASKS = 'FETCHED_TASKS';

const { budgetApiUrl } = config;

export const fetchingTasks = () => ({
  type: FETCHING_TASKS,
});

export const fetchedTasks = tasks => ({
  type: FETCHED_TASKS,
  tasks,
});

export const fetchTasks = () => async dispatch => {
  dispatch(fetchingTasks());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/task`,
      method: 'GET',
      withCredentials: true,
    });
    dispatch(fetchedTasks(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};
