import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../dashboard/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { fetchUser } from '../../action/user';
import { DIFF_TYPE, diffType } from '../../../../../common/src/util/jsondiff';
import { deepValue, isObject } from '../../../../../common/src/util/object';
import Box from '@material-ui/core/Box';
import { userDisplayName } from '../../util/user';
import { timeAgo } from '../../util/time';
import UserLink from './UserLink';

const useStyles = makeStyles((theme) => ({
  history: {
    maxHeight: props => props.maxHeight || 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  version: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    wordBreak: 'break-word',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  time: {
    textAlign: 'left',
  },
}));

const formatValue = (key, value, mapping, userById) => {
  if (value === null || value === '')
    return 'empty';

  if (mapping[key] && mapping[key].translation)
    switch (typeof mapping[key].translation) {
      case 'function':
        return mapping[key].translation(value);
      case 'string':
        if (mapping[key].translation === 'userId')
          if (userById[value])
            return userDisplayName(userById[value]);
          else
            return `#${value}`;
        return value;
      default:
        return value;
    }

  return value;
};

const formatKey = (key, mapping) => deepValue(mapping, `${key}.label`) || key;

function Version(props) {
  const { delta, user, time, classes, mapping, userById, message } = props;

  const userLink = <UserLink userId={user.id} userDisplayName={userDisplayName(user)}/>;

  return (
    <div className={classes.version}>
      <div className={classes.text}>
        <Box variant="body2" component="div">
          {message && <Typography variant="body2">Added message "<b>{message}</b>".</Typography>}
          {isObject(delta) ?
            Object.keys(delta)
              .filter(k => mapping[k])
              .map(k => {
                const mutation = delta[k];
                const key = formatKey(k, mapping);
                const type = diffType(mutation);
                const order = deepValue(mapping, `${k}.order`) || Number.MAX_SAFE_INTEGER;
                switch (type) {
                  case DIFF_TYPE.ADDED:
                    const value = formatValue(k, mutation[0], mapping, userById);
                    return [
                      <Typography variant="body2" key={k}>Set <b>{key}</b> to <b>{value}</b>.</Typography>,
                      order,
                    ];
                  case DIFF_TYPE.MODIFIED:
                    const oldValue = formatValue(k, mutation[0], mapping, userById);
                    const newValue = formatValue(k, mutation[1], mapping, userById);
                    return [
                      <Typography variant="body2" key={k}>Changed <b>{key}</b> from <b>{oldValue}</b> to <b>{newValue}</b>.</Typography>,
                      order,
                    ];
                  case DIFF_TYPE.DELETED:
                    return [
                      <Typography variant="body2" key={k}>Removed <b>${key}</b>.</Typography>,
                      order,
                    ];
                  default:
                    return ['', order];
                }
              })
              .sort((mutation1, mutation2) => mutation1[1] - mutation2[1])
              .map(mutation => mutation[0])
            : ''}
        </Box>
      </div>
      <div className={classes.time}>
        <Typography variant="caption" component="div">
          {timeAgo(time)} by {userLink}
        </Typography>
      </div>
    </div>
  );
}

function LoadingVersion(props) {
  const { classes } = props;
  return (
    <div className={classes.version}>
      <div className={classes.user}>
        <Skeleton variant="circle">
          <Avatar/>
        </Skeleton>
      </div>
      <div className={classes.text}>
        <Skeleton variant="rect" width="100%">
          <div style={{ paddingTop: '20%' }}/>
        </Skeleton>
      </div>
    </div>
  );
}

export default function History(props) {
  const history = props.history || [];
  const { mapping } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const userById = useSelector(state => state.user.userById);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const endRef = useRef(null);
  const scrollToBottom = () => {
    if (endRef && !scrolledToBottom) {
      endRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrolledToBottom(true);
    }
  };
  useEffect(scrollToBottom, [scrolledToBottom, endRef, history, userById]);

  useEffect(() => {
    const usersIds = new Set(history.map(version => version.userId));
    for (const userId of usersIds)
      if (!userById[userId])
        dispatch(fetchUser(userId));
  }, [userById, history, dispatch]);

  const versions = useMemo(() => history.map(version => {
    const { userId, delta, at: time, message } = version;
    const user = userById[userId];
    const key = `version-${time}`;
    return user ? (
      <Version
        key={key}
        delta={delta}
        time={time}
        user={user}
        classes={classes}
        mapping={mapping}
        userById={userById}
        message={message}
      />
    ) : <LoadingVersion key={key} classes={classes}/>;
  }), [history, userById, classes, mapping]);

  return (
    <div className={classes.history}>
      {versions}
      <div ref={endRef}/>
    </div>
  );
}

/**
 *
 * mapping = {
 * 'someProperty, e.g. saleStatusId': {
 * }
 *    label: 'sale status',
 *    order: 123, // lower has higher importance
 *    translation: (value) => {...translation logic of value to something human readable...}
 * }
 *
 **/
History.propTypes = {
  history: PropTypes.array,
  mapping: PropTypes.object,
};
