import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { differenceBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { addAccountGroup, fetchAssignments, toggleModal } from '../../action/account';
import AccountGroup from './AccountGroup';
import { fetchUnits, fetchUnitUsers } from '../../action/unit';
import CircularProgressCentered from '../common/CircularProgressCentered';
import AccountTitle from '../common/PageTitle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import protectedCfoRoute from "../common/hocs/protectedCfoRoute";
import AccountGroupModal from './AccountGroupModal';

const CreateGroupMenu = ({ unitId }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const groupModalId = 'group-create';
  const accountGroupModalOpen = useSelector(state => state.account.openModals[groupModalId] || false);

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onCreateAccountGroupClicked = () => {
    dispatch(toggleModal(groupModalId, true));
    closeMenu();
  };
  const onCreateAccountGroupConfirm = group => {
    dispatch(addAccountGroup(group, unitId));
  };

  return (
    <>
      <IconButton aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu id="long-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={menuOpen}
            onClose={closeMenu}>
        <MenuItem onClick={onCreateAccountGroupClicked}>
          Add a new group
        </MenuItem>
      </Menu>
      <AccountGroupModal open={accountGroupModalOpen}
                         onCancel={() => dispatch(toggleModal(groupModalId, false))}
                         onConfirm={onCreateAccountGroupConfirm}
                         title="New account group"
                         group={{}}/>
    </>
  );
}

const ManageAccounts = () => {
  const params = useParams();
  const unitId = parseInt(params.unitId);
  const dispatch = useDispatch();

  const fetchingAssignments = useSelector(state => state.account.fetchingAssignments);
  const groups = useSelector(state => state.account.groups);
  const unit = useSelector(state => state.unit);
  const previousGroupValue = useRef(groups);

  useEffect(() => {
    if (!unit.units) {
      dispatch(fetchUnits());
    }
  }, []);
  useEffect(() => {
    if (unitId) {
      dispatch(fetchAssignments(unitId));
      dispatch(fetchUnitUsers(unitId));
    }
  }, [unitId]);

  useEffect(() => {
    if (previousGroupValue.current && previousGroupValue.current.length !== groups.length) {
      const newGroup = differenceBy(groups, previousGroupValue.current, 'id');

      if (newGroup && newGroup.length === 1) {
        const elementClass = `.group-${newGroup[0].id}`;
        const element = document.querySelector(elementClass);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }
    }
    previousGroupValue.current = groups;
  }, [groups]);

  const getUnitTitle = () => {
    if (unit.units) {
      const foundUnit = unit.units.find(item => item.id === unitId);
      return foundUnit ? foundUnit.name : '';
    }
    return '';
  }

  if (fetchingAssignments) {
    return <CircularProgressCentered size={24}/>;
  }

  return (
    <>
      <AccountTitle title={getUnitTitle()}>
        <CreateGroupMenu unitId={unitId}/>
      </AccountTitle>
      <Grid container spacing={2}>
        {groups.filter(group => group.size === 0 || group.types.length > 0).map(group => (
          <Grid item xs={12} md={6} lg={6} key={`accounts-${group.id}`}>
            <AccountGroup unitId={unitId} group={group}/>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default memo(protectedCfoRoute(ManageAccounts));
