import React, { memo } from 'react';
import { ReactComponent as Percentage } from './percentage/icon.svg';
import { makeStyles } from '@material-ui/core';

const icons = {
  'percentage': Percentage
}

const useStyles = makeStyles(() => ({
  size: {
    width: 15,
    height: 15,
    margin: 3,
    '& path': {
      fill: 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        fill: 'rgba(0, 0, 0, 0.8)',
      }
    }
  }
}));

const CustomIcon = ({ icon }) => {
  const classes = useStyles();
  const Icon = icons[icon];

  if (Icon) return <Icon className={classes.size} />;
  return null;
}

export default memo(CustomIcon);