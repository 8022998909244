import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link } from 'react-router-dom';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../common/Title';
import CustomIcon from '../common/icons/';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ContributionRequestedIcon from '@material-ui/icons/Edit';
import ApprovalRequestedIcon from '@material-ui/icons/HourglassEmpty';
import ApprovedIcon from '@material-ui/icons/Done';
import FinalIcon from '@material-ui/icons/DoneAll';
import { excludeDeletedRows, excludePrevYearBudgets } from './common';
import {
  months,
  Months,
  PERMISSION_LEVEL,
  SALE_STATUS,
  SALE_TYPE,
  UNIT_TYPE,
} from '../../../../../common/src/util/enum';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import UnitMenu from './UnitMenu';
import { getSalesForChart, } from '../../util/sale';
import { groupSalesTotalByUnitId } from '../../../../../common/src/util/sale';
import calculatePercentageDiff from '../../util/calculatePercentageDiff';
import { fetchSaleAssignmentsPerUnit, showDataOnChart } from '../../action/unit';
import { formatNumber, parseNumber, } from '../../../../../common/src/util/number';
import SaleStatusIcon from '../common/SaleStatusIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Paper from '@material-ui/core/Paper';
import * as _ from 'lodash';
import { loadLocal } from '../../util/storage';
import areEqual from '../../util/areEqual';
import EditableCell from '../common/EditableCell';
import { currentBudgetYear, nextBudgetYear } from '../../../../../common/src/util/date';

const useStyles = makeStyles(theme => ({
  nameColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuColumn: {
    padding: 0,
  },
  rowSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  yearExpandColumn: {
    maxWidth: 30,
  },
  yearColumn: {
    maxWidth: 40,
  },
  strongCell: {
    fontWeight: 500,
  },
  table: {
    overflow: 'auto',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  rowDividerSmall: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  statusColumn: {
    padding: 0,
    paddingTop: 5,
  },
  header: {
    display: 'flex',
    margin: theme.spacing(1),
    marginLeft: 0,
    alignItems: 'center',
    marginTop: -5
  },
  paper: {
    padding: theme.spacing(2),
  },
  editablePlaceholder: {
    borderRadius: 4,
    border: '2px solid rgba(0,0,0,0.03)',
  },
  activeToggle: {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  regionLink: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  plus: {
    position: 'relative',
    '&::after': {
      content: '"+"',
      display: 'block',
      width: 10,
      height: 10,
      position: 'absolute',
      right: -15,
      top: -7,
    },
  },
  progressCircle: {
    marginLeft: theme.spacing(2)
  }
}));

const isValueValid = value => value === '' || value === null || !isNaN(parseNumber(value));

const errorKey = (saleId, property) => `sale_${saleId}_${property}`;

const noop = () => {};

const calculateDailyAverage = (row, salesGroups) => {
  let rowDailyAverage;
  const { year } = row;

  let daysRow;
  for (const saleGroup of salesGroups)
    for (const sale of saleGroup)
      if (sale.year === year && sale.saleTypeId === SALE_TYPE.DAYS)
        daysRow = sale;

  if (daysRow) {
    let totalAmount = 0;
    let totalDays = 0;
    for (const month of months) {
      const days = parseNumber(daysRow[month]);
      const amount = parseNumber(row[month]);
      if (!isNaN(days) && !isNaN(amount)) {
        totalAmount += amount;
        totalDays += days;
      }
    }
    if (totalDays > 0)
      rowDailyAverage = Math.round(totalAmount / totalDays);
  }
  return rowDailyAverage;
};

const TotalSalesByRegion = (props) => {
  const classes = useStyles();

  const { unitId } = props;

  const dispatch = useDispatch();

  let { fetchingUnitSales, fetchedUnitSales, units } = useSelector(state => state.unit);
  const { user } = useSelector(state => state.user);
  const [showPercentage, setShowPercentage] = useState([]);
  const [expandedNames, setExpandedNames] = useState([]);
  const [selectedSaleId, setSelectedSaleId] = useState(false);
  const [errorValues, setErrorValues] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const unit = units.find(item => item.id === unitId);
  const isParentCompany = !unit.parentUnitId;
  const showExcelLoader = useSelector(state => state.unit.showGeneratingExcelLoader);

  const currentYear = currentBudgetYear();
  const nextYear = nextBudgetYear();

  const _showDataOnChart = (saleId, allSales) => {
    const { selectedBudgets, selectedOutcomes } = getSalesForChart(saleId, allSales);
    dispatch(showDataOnChart({ budgets: selectedBudgets, outcomes: selectedOutcomes, unit }));
  };

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false)
  }, []);

  useEffect(() => {
    dispatch(fetchSaleAssignmentsPerUnit());
  }, [unitId]);

  const expandName = (id) => () => {
    if (expandedNames.some(n => n === id)) {
      setExpandedNames(expandedNames.filter(n => n !== id));
    } else {
      setExpandedNames([id, ...expandedNames]);
    }
    setShowPercentage(showPercentage => showPercentage.filter(item => `${item.name}-${item.unitId}` !== id));
  };

  const selectSaleRow = (saleId, allSales) => () => {
    if (isMounted && saleId !== selectedSaleId) {
      setSelectedSaleId(saleId);
      _showDataOnChart(saleId, allSales);
    }
  };

  let table;
  if (fetchedUnitSales && fetchedUnitSales.length === 0)
    table = 'No data to show.';
  else if (fetchedUnitSales) {
    const permissions = loadLocal('permissions');
    const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);

    const filteredFetchedUnitSales = fetchedUnitSales.filter((item) => {
      if (item.unit.id === unitId) {
        return false;
      } else if (item.unit.type === UNIT_TYPE.COMPANY) {
        return false;
      }
      return true;
    });

    let regionsWithoutHiddenRows = filteredFetchedUnitSales.map(group => {
      const obj = { ...group };
      obj.sales = group.sales
        .filter(item => item.saleTypeId !== SALE_TYPE.DAYS)
        .filter(excludePrevYearBudgets)
        .filter(row => excludeDeletedRows({ row, isCfo, groupedData: filteredFetchedUnitSales, user }));
      return obj;
    });

    // If unit is root company which contains companies -> regions
    let groupedSalesData;
    let daysList = [];
    if (isParentCompany) {
      const parents = [];

      groupedSalesData = regionsWithoutHiddenRows.map(item => {
        const foundParent = parents.find(parent => parent.unitId === item.unit.parentUnitId);
        let temporaryUnit;

        if (!parents.find(parent => parent.unitId === item.unit.parentUnitId)) {
          temporaryUnit = fetchedUnitSales.find((sale) => sale.unit.id === item.unit.parentUnitId);
          if (temporaryUnit) {
            temporaryUnit = temporaryUnit.unit;
            parents.push(temporaryUnit);
          }
        }

        const unit = foundParent || temporaryUnit;
        return {
          unit: { ...unit, parentUnitId: item.unit.parentUnitId },
          parentUnitId: item.unit.parentUnitId,
          sales: item.sales,
        }
      });

      groupedSalesData = _.groupBy(groupedSalesData, 'parentUnitId');
      groupedSalesData = Object.values(groupedSalesData).map(item => {
        return ({ unit: item[0].unit, sales: item.map(subItem => subItem.sales).flat() })
      });
    } else {
      const hasNextYearData = filteredFetchedUnitSales
        .map(group => group.sales)
        .flat()
        .find(item => item.year === nextYear && item.saleTypeId !== SALE_TYPE.DAYS);

      daysList = _.sortBy(filteredFetchedUnitSales
        .map(group => {
          const obj = { ...group };
          obj.sales = group.sales.filter(item => item.saleTypeId === SALE_TYPE.DAYS);
          return obj;
        })
        .filter((item, index) => index === 0)
        .map(group => group.sales)
        .flat()
        .filter(item => item.year >= currentYear - 1)
        .filter(item => {
          if (!hasNextYearData) {
            return item.year !== nextYear;
          }
          return true;
        }), 'year')
        .reverse();
    }

    let {
      groupedRegions: salesGroups,
      regionsTotals,
    } = groupSalesTotalByUnitId(groupedSalesData ? groupedSalesData : regionsWithoutHiddenRows);
    const allSales = regionsTotals;

    if (daysList.length) {
      salesGroups.unshift(daysList);
    }

    let defaultSelectedSaleId = salesGroups[salesGroups.length - 1][0]?.id;
    let totalDividerRendered = false;
    let previousRow;

    const isDistributor = unit.type === UNIT_TYPE.DISTRIBUTOR;
    const hasSaleRow = salesGroups.some(saleGroup => saleGroup.some(row => row.saleTypeId === SALE_TYPE.DAYS));
    const showDailyAverage = hasSaleRow;

    const setPercentage = (sale) => {
      const id = `${sale.name}-${sale.unitId}`;
      setShowPercentage(showPercentage => {
        return showPercentage.find(item => `${item.name}-${item.unitId}` === id) ?
          showPercentage.find(item => `${item.name}-${item.unitId}` === id && item.id !== sale.id) ?
            [...showPercentage.filter(item => `${item.name}-${item.unitId}` !== id && item.id !== sale.id), sale] :
            showPercentage.filter(item => `${item.name}-${item.unitId}` !== id) :
          [...showPercentage, sale]
      });
    };

    const tableHead = (
      <TableHead>
        <TableRow>
          {!isParentCompany && <TableCell className={classes.headColumn}>&nbsp;</TableCell>}
          <TableCell className={classes.headColumn}>&nbsp;</TableCell>
          <TableCell className={classes.headColumn}>Name</TableCell>
          <TableCell align="right" className={classes.headColumn}>Year</TableCell>
          <TableCell className={classes.headColumn}>&nbsp;</TableCell>
          {Months.map(month => <TableCell align="right" key={month} className={classes.headColumn}>{month}</TableCell>)}
          <TableCell align="right" className={classes.headColumn}>Total</TableCell>
          {showDailyAverage ? <TableCell align="right" className={classes.headColumn}>Daily Average</TableCell> : null}
        </TableRow>
      </TableHead>
    );

    const renderPercentageToggleButton = ({ row, isRowDaysType, isMainRow, hasMonthValues, hasNextYear }) => {
      if (!isMainRow && !isRowDaysType && hasMonthValues && hasNextYear && row.year === currentYear) {
        return (
          <Tooltip title="Show Budget in percentage">
            <IconButton
              size="small"
              className={clsx('toggle-percentage-button', {
                [classes.activeToggle]: showPercentage.find(item => item.id === row.id),
              })}
              onClick={() => setPercentage(row)}
            >
              <CustomIcon icon={'percentage'}/>
            </IconButton>
          </Tooltip>
        );
      }
    }

    const renderField = ({ months, id, row, isEmpty, isTotal, isContributionRequested, isMainRow, isRowAmountBudgetType, year, strong, divider }) => {
      return months.map(month => {
        const value = errorValues[errorKey(id, month)] || row[month];
        const valid = isValueValid(value);
        const calculatedPercentageRow = isMainRow && showPercentage.find(item => item.name === row.name)
        const editable = (isRowAmountBudgetType) &&
          isContributionRequested &&
          !isEmpty &&
          !isTotal &&
          year === nextYear;

        return (
          <TableCell align="right" key={month} className={clsx(strong, divider)}>
            {
              calculatedPercentageRow ?
                <p>{calculatePercentageDiff(value, calculatedPercentageRow[month])}</p> :
                <EditableCell
                  value={valid ? formatNumber(value) : value}
                  valid={valid}
                  editable={editable}
                />
            }
          </TableCell>
        );
      })
    };

    const renderColumnName = ({ row, isTotal, isRowDaysType, isMainRow, name }) => {
      if (isMainRow) {
        if (isTotal) {
          return 'Total';
        } else if (isRowDaysType) {
          return 'Days';
        } else return <Link className={classes.regionLink} to={`/unit/${isParentCompany ? row.parentUnitId : row.unitId}/sale`}>{name}</Link>;
      }
    };

    table = (
      <div className={classes.table}>
        <Table>
          {tableHead}
          <TableBody>
            {salesGroups
              .map(saleGroup => saleGroup.filter(excludePrevYearBudgets))
              .map(saleGroup => saleGroup
                .filter(row => !(salesGroups.length <= 2 && row.total)) // if only one entry, don't show total row. 2 = 1 sole entry + 1 total row
                .map((row, rowIndex) => {
                  let { saleTypeId, name, year, total: isTotal, empty: isEmpty, id, saleStatusId } = row;
                  let hasNextYear = false;

                  salesGroups.forEach(group => {
                    if (group[0]) {
                      hasNextYear = group[0].year === nextYear;
                    }
                  });

                  const isContributionRequested = saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED;
                  const isRowAmountBudgetType = saleTypeId === SALE_TYPE.AMOUNT_BUDGET;
                  const isRowAmountType = saleTypeId === SALE_TYPE.AMOUNT || saleTypeId === SALE_TYPE.AMOUNT_BUDGET;
                  const isRowAmount = saleTypeId === SALE_TYPE.AMOUNT;
                  const isMainRow = rowIndex === 0;
                  const isRowDaysType = saleTypeId === SALE_TYPE.DAYS;
                  const isExpandedName = expandedNames.some(n => n === `${name}-${row.unitId}`)
                  const isNextYear = year === nextYear;

                  if (!isMainRow && !isExpandedName && !isEmpty)
                    return undefined;

                  const group = isMainRow && regionsWithoutHiddenRows.find((item) => item.unit.id === row.unitId);
                  let minSaleStatusId;
                  let areAllStatusesTheSame;

                  if (group) {
                    group.sales = group.sales.filter(item => item.saleStatusId > SALE_STATUS.DELETED);
                    const { saleStatusId = SALE_STATUS.CONTRIBUTION_REQUESTED } = _.minBy(group.sales, 'saleStatusId');

                    minSaleStatusId = saleStatusId;
                    areAllStatusesTheSame = group.sales.every(item => item.saleStatusId === minSaleStatusId);
                  }

                  const renderStatusIcon = (saleStatusId) => {
                    if (isNextYear) {
                      let component;

                      switch (saleStatusId) {
                        case SALE_STATUS.CONTRIBUTION_REQUESTED:
                          component = <>
                            <Tooltip title={'Contribution requested'}>
                              <ContributionRequestedIcon fontSize="small"/>
                            </Tooltip>
                            {areAllStatusesTheSame === false && <span className={classes.plus}></span>}
                          </>
                          break;
                        case SALE_STATUS.APPROVAL_REQUESTED:
                          component = <>
                            <Tooltip title={'Approval requested'}>
                              <ApprovalRequestedIcon fontSize="small"/>
                            </Tooltip>
                            {areAllStatusesTheSame === false && <span className={classes.plus}></span>}
                          </>
                          break;
                        case SALE_STATUS.APPROVED:
                          component = <>
                            <Tooltip title={'Approved'}>
                              <ApprovedIcon fontSize="small"/>
                            </Tooltip>
                            {areAllStatusesTheSame === false && <span className={classes.plus}></span>}
                          </>
                          break;
                        case SALE_STATUS.FINAL:
                          component = <>
                            <Tooltip title={'Final'}>
                              <FinalIcon fontSize="small"/>
                            </Tooltip>
                            {areAllStatusesTheSame === false && <span className={classes.plus}></span>}
                          </>
                          break;
                      }
                      return component;
                    }
                  }

                  let yearExpand;
                  if (isMainRow || isEmpty) {
                    yearExpand = (
                      <IconButton
                        className={clsx('expand-child-row-button', {
                          'expanded-button': isExpandedName,
                        })}
                        size="small"
                        onClick={expandName(`${name}-${row.unitId}`)}
                        disabled={saleGroup.length === 1}
                      >
                        {isExpandedName ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                      </IconButton>
                    );
                  }
                  const rowTotal = isEmpty ? null : months.reduce((rv, month) => rv + Number(row[month]), 0);

                  let rowDailyAverage;
                  if (showDailyAverage && !isRowDaysType)
                    rowDailyAverage = calculateDailyAverage(row, salesGroups);

                  if (!isMainRow && !isEmpty) {
                    name = '';
                  }

                  let tableRowClass;
                  if (!selectedSaleId && (id === defaultSelectedSaleId || isDistributor)) {
                    setTimeout(() => selectSaleRow(id, allSales)(), 100);
                  }
                  if (id === selectedSaleId)
                    tableRowClass = classes.rowSelected;

                  const canSelectAndHover = isRowAmountType;
                  const selectHandler = canSelectAndHover ? rowId => selectSaleRow(rowId, allSales) : noop;

                  const strong = isTotal ? classes.strongCell : undefined;

                  let divider;
                  if (!totalDividerRendered && isTotal) {
                    totalDividerRendered = true;
                    divider = classes.rowDivider;
                  } else if (previousRow && previousRow.name !== row.name) {
                    divider = classes.rowDividerSmall;
                  }
                  previousRow = row;

                  const showStatusIcon = !isRowDaysType && !isTotal && !isEmpty && year === nextYear;
                  const hasMonthValues = months.some(month => row[month]);

                  const hasChildPercentage = saleGroup.find((item, index) => {
                    const isRowDaysType = item.saleTypeId === SALE_TYPE.DAYS;
                    const hasMonthValues = months.some(month => item[month]);
                    const isMainRow = index === 0;
                    let hasNextYear;

                    hasNextYear = saleGroup[0].year === nextYear;
                    hasNextYear = hasNextYear && !!months.some(month => saleGroup[0][month] !== null);

                    return !isRowDaysType && !isMainRow && hasMonthValues && hasNextYear && item.year === currentYear;
                  });

                  let calculatedPercentageTotalRow = isMainRow && !isEmpty && showPercentage.find(item => item.name === row.name);
                  calculatedPercentageTotalRow = calculatedPercentageTotalRow ?
                    months.reduce((rv, month) => rv + Number(calculatedPercentageTotalRow[month]), 0) : null;
                  const showHiddenRows = isCfo ? false : user.id !== row.approverId;

                  // ONLY CFO AND APPROVER HAS ACCESS TO DELETED ROWS
                  if (showHiddenRows && row.saleStatusId === SALE_STATUS.DELETED) return null;

                  // hide total row for root company
                  if (unit.type === UNIT_TYPE.COMPANY && !unit.parentUnitId && isTotal) return null;

                  const hasChildPercentageButton = isMainRow && hasChildPercentage;

                  return (
                    <TableRow
                      key={id}
                      className={clsx(classes.tableRow, tableRowClass, {
                        'has-child-percentage-button': !!hasChildPercentageButton,
                      })}
                      onClick={selectHandler(id, allSales)}
                    >
                      {!isParentCompany && <TableCell className={clsx(divider)}>{!isRowDaysType && !isTotal && renderStatusIcon(minSaleStatusId)}</TableCell>}
                      <TableCell className={clsx(classes.statusColumn, strong, divider)}>
                        {showStatusIcon ?
                          <SaleStatusIcon sale={row} user={user} unit={unit}/> :
                          renderPercentageToggleButton({ row, hasNextYear, isMainRow, hasMonthValues, isRowDaysType })}
                      </TableCell>
                      <TableCell className={clsx(classes.nameColumn, strong, divider)}>
                        {renderColumnName({ row, isTotal, isRowDaysType, isMainRow, name })}
                      </TableCell>
                      <TableCell align="right" className={clsx(classes.yearColumn, strong, divider)}>{year} {isRowAmountBudgetType ? 'Budget' : isRowAmount ? 'Outcome' : ''}</TableCell>
                      <TableCell align="left" className={clsx(classes.yearExpandColumn, strong, divider)}>{yearExpand}</TableCell>
                      {renderField({ months, id, row, isEmpty, isTotal, isContributionRequested, isMainRow, isRowAmountBudgetType, year, strong, divider })}
                      <TableCell align="right" className={clsx(strong, divider)}>
                        {
                          calculatedPercentageTotalRow ? calculatePercentageDiff(rowTotal, calculatedPercentageTotalRow) : formatNumber(rowTotal)
                        }
                      </TableCell>
                      {showDailyAverage ? <TableCell align="right" className={clsx(strong, divider)}>{formatNumber(rowDailyAverage)}</TableCell> : null}
                    </TableRow>
                  );
                }))}
          </TableBody>
        </Table>
      </div>
    );
  }

  const loader = fetchingUnitSales ? <CircularProgress size={16}/> : '';
  const title = unit ? `Sales - ${unit.name}` : 'Sale Totals';
  const sales = fetchedUnitSales.map(item => item.sales).flat();
  const [unitMenu, unitMenuDialogs] = UnitMenu(unit, sales, []);

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        {unitMenu}
        <Title>{title} {loader}</Title>
        { showExcelLoader && <CircularProgress className={classes.progressCircle} size={20} /> }
      </div>
      <div>
        {table}
        {unitMenuDialogs}
      </div>
    </Paper>
  );
}

export default memo(TotalSalesByRegion, areEqual);

TotalSalesByRegion.propTypes = {
  unitId: PropTypes.number.isRequired,
};

