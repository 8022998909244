import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Units from '../units/Units';
import Cost from './Cost';
import { fetchCosts } from '../../action/cost';
import { fetchUnitUsers } from '../../action/unit';
import CostHeader from './CostHeader';
import protectedRoute from '../common/hocs/protectedRoute';
import CircularProgressCentered from '../common/CircularProgressCentered';
import Chart from "../../util/Chart";
import CostTotal from './CostTotal';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    marginTop: theme.spacing(1)
  }
}));

function Costs() {
  const params = useParams();
  const unitId = parseInt(params.unitId);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (unitId) {
      dispatch(fetchCosts(unitId));
      dispatch(fetchUnitUsers(unitId));
    }
  }, [dispatch, unitId]);

  // keep these separated without destructuring, as we do not want to be notified of changes to state.cost, but
  // of changes to individual state.cost properties
  const fetchingCosts = useSelector(state => state.cost.fetchingCosts);
  const costs = useSelector(state => state.cost.costs);
  const unit = useSelector(state => state.unit.units && state.unit.units.find(unit => unit.id === unitId));
  const { isCfoOf } = useSelector(state => state.user);

  const [selectedRow, setSelectedRow] = useState(null);

  if (fetchingCosts) {
    return <CircularProgressCentered size={24}/>;
  }

  let content;
  if (unitId && costs && unit) {
    content = (
      <>
        <CostHeader unit={unit} categories={costs.categories}>
          <Chart isCostsChart={true}/>
        </CostHeader>
        <Grid container spacing={2} className={classes.contentWrapper}>
          {costs.categories.map(category => (
            <Grid item xs={12} key={category.name}>
              <Cost
                unit={unit}
                unitId={unit.id}
                category={category}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </Grid>
          ))}
          {isCfoOf(unit.id) &&
          <CostTotal
            categories={costs.categories}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
          }
        </Grid>
      </>
    );
  } else {
    content = (
      <Grid item xs={12} md={12} lg={12}>
        <Units
          hideActions={true}
          onSelect={unitId => history.push(`/unit/${unitId}/costs`)}
          expandAll={true}
          companiesOnly
        />
      </Grid>
    );
  }

  return content;
}

export default protectedRoute(Costs);
