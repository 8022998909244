import React, { memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  saleInitializeText: {
    width: 350,
    color: 'black'
  }
}));

const Text = ({ children }) => {
  const classes = useStyles();

  if (!children) return null;
  return (
    <p className={classes.saleInitializeText}>
      { children }
    </p>
  );
};

export default memo(Text);