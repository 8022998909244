import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from "../common/PageTitle";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import { updateUser } from '../../action/user';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  label: {
    marginBottom: 10
  }
}));

const EmailSettings = memo(() => {
  const { user } = useSelector(state => state.user);
  const [value, setValue] = React.useState(user?.emailNotificationSettings || 'daily');
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    setValue(event.target.value);
    dispatch(updateUser({ emailNotificationSettings: event.target.value }))
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.label}>Email notifications</FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          <FormControlLabel value="daily" control={<Radio />} label="Daily" />
          <FormControlLabel value="instant" control={<Radio />} label="Instant" />
        </RadioGroup>
      </FormControl>
    </>
  )
});

const UserSettings = () => {
  const classes = useStyles();

  return (
    <>
      <PageTitle title={'User Settings'} spacingSize={2} />
      <Paper className={classes.paper}>
        <EmailSettings />
      </Paper>
    </>
  )
};

export default memo(UserSettings);