import React from 'react'
import { Redirect, useParams } from 'react-router-dom';
import * as _ from "lodash";
import { PERMISSION_LEVEL } from "../../../../../../common/src/util/enum";
import { loadLocal } from '../../../util/storage';

const protectedRoute = (Component, level = PERMISSION_LEVEL.READ) => (props) => {
  const user = loadLocal('user');
  const permissions = loadLocal('permissions');
  const unitId = parseInt(useParams().unitId);

  const canAccess = !unitId || _.some(permissions, permission =>
    permission.level >= level &&
    permission.unitId === unitId
  );

  if (!user) {
    return null;
  } else if (!canAccess) {
    return <Redirect to={'/'}/>
  }

  return <Component {...props} />
}

export default protectedRoute;
