import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchTasks } from '../../action/task';
import Sales from './Sales';
import Costs from './Costs';
import Budget from './Budget';

export default function UnitsPage() {
  const dispatch = useDispatch();
  const { location: { hash } } = useHistory();
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (!user)
      return;
    const sessionHash = sessionStorage.getItem('hash');
    dispatch(fetchTasks());

    if (hash === '#notification-view' || sessionHash === '#notification-view') {
      const notificationTriggerElement = document.querySelector('#notification-anchor-element');

      if (notificationTriggerElement) {
        notificationTriggerElement.click();
        sessionStorage.removeItem('hash');
      }
    }
  }, [dispatch, hash, user]);

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={12} lg={12}>
        <Budget/>
        <Sales/>
        <Costs/>
      </Grid>
    </Grid>
  );
}
