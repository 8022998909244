let moment;

module.exports.momentInit = (momentInstance) => moment = momentInstance;

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'LLLL';
const isValidDateFormat = value => moment(value, DATE_FORMAT, true).isValid();
isValidDateFormat.text = `Expects date format ${DATE_FORMAT}`;
const formatDate = date => date ? moment(date).format(DATE_FORMAT) : '';
const formatTime = date => date ? moment(date).format(TIME_FORMAT) : '';

module.exports.DATE_FORMAT = DATE_FORMAT;
module.exports.isValidDateFormat = isValidDateFormat;
module.exports.formatDate = formatDate;
module.exports.formatTime = formatTime;

const now = new Date();
let _nextBudgetYear = now.getMonth() <= 5 ? now.getFullYear() : now.getFullYear() + 1;
module.exports.nextBudgetYear = () => _nextBudgetYear;
module.exports._setNextBudgetYear = year => _nextBudgetYear = year;
module.exports.currentBudgetYear = () => module.exports.nextBudgetYear() - 1;
