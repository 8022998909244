import update from 'immutability-helper';
import { deepCopy } from '../../../../common/src/util/object';
import {
  DRY_RAN_INITATE_COSTS,
  FETCHED_CATEGORY_COSTS,
  FETCHED_COSTS,
  FETCHING_COSTS,
  UPDATED_COST,
} from '../action/cost';
import summarize from '../../../../common/src/util/summarize';

const initialState = {
  fetchingCosts: false,
  costs: null,
  dryRunInitiatedCosts: { added: [], reassigned: [] },
  incomeTotals: {},
};

const calculateIncomeTotals = categories => {
  return _.chain(categories)
    .filter(c => c.income)
    .flatMap(g => g.types)
    .flatMap(t => t.costs)
    .groupBy(c => c.year)
    .mapValues(costs => _.chain(costs)
      .groupBy(c => c.budget)
      .mapValues(c => summarize(c))
      .value())
    .value();
};

export default (state = deepCopy(initialState), action) => {
  switch (action.type) {
    case FETCHING_COSTS:
      return { ...state, fetchingCosts: true, costs: null };

    case FETCHED_COSTS:
      return {
        ...state,
        fetchingCosts: false,
        costs: action.costs,
        categories: action.costs.categories,
        incomeTotals: calculateIncomeTotals(action.costs.categories)
      };

    case UPDATED_COST:
      const { cost: updated } = action;
      let categories = state.categories;
      let incomeTotals = state.incomeTotals;
      for (let [categoryIdex, category] of categories.entries()) {
        for (let [typeIndex, type] of category.types.entries()) {
          for (let [costIndex, cost] of type.costs.entries()) {
            if (cost.typeId === updated.typeId && cost.year === updated.year && cost.budget === updated.budget) {
              type = { ...type, costs: [...type.costs] };
              type.costs[costIndex] = updated;
              category = { ...category, types: [...category.types] };
              category.types[typeIndex] = type;
              categories = [...categories]
              categories[categoryIdex] = category;
              if (category.income) {
                incomeTotals = calculateIncomeTotals(categories);
              }
            }
          }
        }
      }
      return { ...state, categories, incomeTotals };

    case DRY_RAN_INITATE_COSTS:
      return { ...state, dryRunInitiatedCosts: { added: action.added, reassigned: action.reassigned } };

    case FETCHED_CATEGORY_COSTS: {
      const index = state.costs.categories.findIndex(category => category.id === action.category.id);
      const categories = update(state.costs.categories, { [index]: { $set: action.category } });
      const costs = { ...state.costs, categories };
      const incomeTotals = calculateIncomeTotals(categories);
      return {
        ...state,
        costs,
        categories,
        incomeTotals
      }
    }

    default:
      return state;
  }
};
