import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Dashboard from './component/dashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from './component/common/Theme';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import reducer from './reducer';
import { sync } from './util/time';
import { initSentry } from './util/sentry';
import { momentInit } from '../../../common/src/util/date';
import { loadConfiguration } from './util/configuration';

momentInit(moment);

sync();
initSentry();
(async () => {
  await loadConfiguration();
})();

const store = createStore(
  reducer,
  applyMiddleware(thunk, logger),
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <CssBaseline/>
      <Dashboard/>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
);
