import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../dashboard/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { fetchUser } from '../../action/user';
import { timeAgo } from '../../util/time';
import UserLink from './UserLink';
import { userDisplayName } from '../../util/user';

const useStyles = makeStyles((theme) => ({
  messages: {
    maxHeight: props => props.maxHeight || 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    wordBreak: 'break-word',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  time: {
    textAlign: 'left',
  },
}));

function Message(props) {
  const { text, user, time, classes } = props;

  const userLink = <UserLink userId={user.id} userDisplayName={userDisplayName(user)}/>;

  return (
    <div className={classes.message}>
      <div className={classes.text}>
        <Typography variant="body2">{text}</Typography>
      </div>
      <div className={classes.time}>
        <Typography variant="caption" component="div">
          {timeAgo(time)} by {userLink}
        </Typography>
      </div>
    </div>
  );
}

function LoadingMessage(props) {
  const { classes } = props;
  return (
    <div className={classes.message}>
      <div className={classes.user}>
        <Skeleton variant="circle">
          <Avatar/>
        </Skeleton>
      </div>
      <div className={classes.text}>
        <Skeleton variant="rect" width="100%">
          <div style={{ paddingTop: '20%' }}/>
        </Skeleton>
      </div>
    </div>
  );
}

export default function Messages(props) {
  const messages = props.messages || [];

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const userById = useSelector(state => state.user.userById);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const endRef = useRef(null);
  const scrollToBottom = () => {
    if (endRef && !scrolledToBottom) {
      endRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrolledToBottom(true);
    }
  };
  useEffect(scrollToBottom, [scrolledToBottom, endRef, messages, userById]);

  useEffect(() => {
    const usersIds = new Set(messages.map(message => message.userId));
    for (const userId of usersIds)
      if (!userById[userId])
        dispatch(fetchUser(userId));
  }, [userById, messages, dispatch]);

  const allMessages = useMemo(() => messages.length > 0 ?
    messages.map(message => {
      const { userId, message: text, at: time } = message;
      const user = userById[userId];
      const key = `message-${time}`;
      return user ? (
        <Message
          key={key}
          text={text}
          time={time}
          user={user}
          classes={classes}
        />
      ) : <LoadingMessage key={key} classes={classes}/>;
    })
    : <Typography variant="body2">No messages to show</Typography>
    , [messages, userById, classes]);

  return (
    <div className={classes.messages}>
      {allMessages}
      <div ref={endRef}/>
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.array,
};
