const backend = '../../../backend/budget-api/node_modules/';
const _ = (
  process.browser ?
    require('lodash') :
    require(`${backend}lodash`)
);
const { months } = require('./enum');

const summarize = (any, ...keys) => {
  if (_.isPlainObject(any)) {
    return (keys.length > 0 ? keys : months).reduce((sum, key) => sum + any[key] || 0, 0);
  }
  if (_.isArray(any)) {
    if (keys.length > 1) {
      const sum = _.zipObject(keys, new Array(keys.length).fill(0));
      for (const record of any) {
        for (const key of keys) {
          sum[key] += record[key] || 0;
        }
      }
      return sum;
    } else if (keys.length === 1) {
      return any.reduce((sum, record) => sum + record[keys[0]] || 0, 0);
    } else {
      return any.reduce((sum, record) => sum + summarize(record), 0);
    }
  }
  return 0;
}

module.exports = summarize;
