import React, {memo, useEffect, useState} from 'react';
import Text from '../common/text';
import Content from "../common/content";
import {makeStyles} from "@material-ui/core/styles";
import ClickCircle from '../common/clickCircle';
import StepDots from "../common/stepDots";
import scrollElementIntoView from '../common/scrollElementIntoView';
import sleep from "../common/sleep";
import {debounce} from "lodash";

const useStyles = makeStyles(theme => ({
  blurClass: {
    filter: 'blur(2px)'
  },
  disableClick: {
    pointerEvents: 'none',
    zIndex: '1301 !important'
  }
}));

const steps = [{
  dotsActionButton: '#unit-id',
  menu: '#unit-id-menu',
  contentPosition: {
    top: (top) => top + 60,
    left: (left) => left - 300,
  },
  content: (
    <Text>
      <b>Initiate costs</b> - Create a task and attach a message for the contributor to provide budget per account.
    </Text>
  )
}, {
  dotsActionButton: '#costs-menu-button',
  menu: '#costs-menu',
  contentPosition: {
    top: (top) => top - 30,
    left: (left) => left + 320,
  },
  circlePosition: {
    top: (top) => top + 10,
    left: (left) => left + 10,
  },
  prepareStep: [
    {
      clickElement: '#expand-more-costs'
    },
    {
      clickElement: '#expand-costs-button'
    },
  ],
  content: (
    <Text>
      <b>Details</b> - In details you can read who are involved and follow the changes done. You can also read the messages.
      <br />
      <br />
      <b>Return to request contribution status</b> - The approver either approves or sends back to contributor with a message.
      <br />
      <br />
      <b>Request approval</b> - When contributor is ready a request for approval with a message is sent to the approver.
      <br />
      <br />
      <b>Approve and finalize</b> - When budget can be accepted it will be approved and finalized.
    </Text>
  )
}];

const validItemsFilter = item => typeof item === 'number';

const getAvailableSteps = () => {
  const stepsNumber = [];

  steps.forEach((item, index) => {
    if (item.dotsActionButton && document.querySelector(item.dotsActionButton)){
      stepsNumber.push(index);
    }
    else {
      stepsNumber.push(false);
    }
  });
  return stepsNumber;
}

const CostsTutorial = (props) => {
  const { openTutorial, showContent, setShowContent, children } = props;
  const classes = useStyles();

  const [step, setStep] = useState(getAvailableSteps().filter(validItemsFilter)[0]);
  const [positions, setPositions] = useState({});
  const [availableSteps, setAvailableSteps] = useState([]);
  const rootElement = document.querySelector('#root');
  const menuWrapper = React.useRef();

  const onEscape = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  };

  const resetElements = () => {
    if (menuWrapper.current) {
      setShowContent(false);
      menuWrapper.current.classList.remove(classes.disableClick);
      menuWrapper.current.firstChild.click();
      document.removeEventListener('keydown', onEscape);
    }
    openTutorial(false);
  }

  const hideContentBeforeNextStep = () => {
    setShowContent(false);
    rootElement.classList.remove(classes.blurClass);

    if (menuWrapper.current) {
      menuWrapper.current.classList.remove(classes.disableClick);
      menuWrapper.current.firstChild.click();
    }
  }

  useEffect(() => {
    setAvailableSteps(getAvailableSteps());

    document.addEventListener('keydown', onEscape);

    return () => {
      rootElement.classList.remove(classes.blurClass);
      resetElements();
    }
  }, []);

  useEffect(() => {
    (async () => {
      hideContentBeforeNextStep();
      await sleep(250);
      await handleInitStep();
    })();
  }, [step]);

  const handleInitStep = async () => {
    const menuButton = step !== undefined && steps[step] && document.querySelector(steps[step].dotsActionButton);

    if (menuButton) {
      await scrollElementIntoView(menuButton);
      const { left, top } = menuButton.getBoundingClientRect();
      setPositions({ top, left });

      await sleep(500);
      menuButton.click();
      rootElement.classList.add(classes.blurClass);
      setShowContent(true);

      await sleep(150);
      menuWrapper.current = document.querySelector(steps[step].menu);
      if (menuWrapper.current) menuWrapper.current.classList.add(classes.disableClick);
    }
  }

  const onSepChange = (step) => {
    const availableFilteredSteps =  availableSteps.filter(validItemsFilter);
    let nextStep = availableFilteredSteps[step];

    if (!nextStep) {
      nextStep = availableFilteredSteps.indexOf(step);
    }
    setStep(nextStep);
  }

  if (!positions.top) return null;

  const stepsLength = availableSteps.filter(validItemsFilter).length;
  const stepIndex = availableSteps.filter(validItemsFilter).indexOf(step);

  return (
    <>
      <ClickCircle
        top={positions.top + 19}
        left={positions.left + 19}
      />
      {
        showContent && (
          <>
            { React.cloneElement(children, { onClick: resetElements }) }
            <Content
              step={stepIndex}
              stepsLength={stepsLength}
              top={steps[step].contentPosition.top(positions.top)}
              left={steps[step].contentPosition.left(positions.left)}
              onSepChange={onSepChange}
              openTutorial={resetElements}
            >
              { steps[step].content }
            </Content>
            {
              stepsLength > 1 && (
                <StepDots
                  stepsLength={stepsLength}
                  availableSteps={availableSteps.filter(validItemsFilter)}
                  activeStep={step}
                  onSepChange={onSepChange}
                />
              )
            }
          </>
        )
      }
    </>
  )
};

export default memo(CostsTutorial);

