import axios from 'axios';
import config from '../config';
import { handleAxiosError } from './error';
import { SALE_STATUS } from '../../../../common/src/util/enum';

export const FETCHING_COSTS = 'FETCHING_COSTS';
export const FETCHED_COSTS = 'FETCHED_COSTS';
export const UPDATED_COST = 'UPDATED_COST';
export const DRY_RAN_INITATE_COSTS = 'DRY_RAN_INITATE_COSTS';
export const FETCHED_CATEGORY_COSTS = 'FETCHED_CATEGORY_COSTS';

const { budgetApiUrl } = config;

export const fetchingCosts = () => ({ type: FETCHING_COSTS });
export const fetchedCosts = costs => ({ type: FETCHED_COSTS, costs });
export const updatedCost = cost => ({ type: UPDATED_COST, cost });
export const dryRanInitiateCosts = ({ added, reassigned }) => ({ type: DRY_RAN_INITATE_COSTS, added, reassigned });
export const fetchedCategoryCosts = category => ({ type: FETCHED_CATEGORY_COSTS, category });

export const fetchCosts = (unitId, groupId) => async dispatch => {
  if (!groupId) {
    dispatch(fetchingCosts());
  }
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/unit/${unitId}/costs`,
      params: { categoryId: groupId },
      withCredentials: true
    });
    if (!groupId) {
      dispatch(fetchedCosts(res.data));
    } else {
      dispatch(fetchedCategoryCosts(res.data.categories[0]));
    }
  } catch (err) {
    handleAxiosError(err, dispatch, true);
  }
};

export const updateCost = (cost, mutation, invalid) => async dispatch => {
  try {
    dispatch(updatedCost({ ...cost, ...mutation }));
    if (!invalid) {
      const res = await axios({
        method: 'put',
        url: `${budgetApiUrl}/unit/${cost.unitId}/costs/${cost.id}`,
        data: mutation,
        withCredentials: true
      });
      dispatch(updatedCost(res.data));
    }
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const calculateCost = (cost, values) => async dispatch => {
  try {
    const res = await axios({
      method: 'put',
      url: `${budgetApiUrl}/unit/${cost.unitId}/costs/${cost.id}/calculate`,
      data: { values },
      withCredentials: true
    });
    dispatch(updatedCost(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const submitCostMessage = (cost, message) => async dispatch => {
  try {
    const res = await axios({
      method: 'post',
      url: `${budgetApiUrl}/unit/${cost.unitId}/costs/${cost.id}/messages`,
      data: { message },
      withCredentials: true
    });
    dispatch(updatedCost(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const initiateCosts = (unitId, year, approverId, message, dueDate, groupId, dryRun) => async dispatch => {
  try {
    const res = await axios({
      method: 'post',
      url: `${budgetApiUrl}/unit/${unitId}/costs/initiate`,
      data: { year, approverId, message, due: dueDate, categoryId: groupId, dryRun },
      withCredentials: true
    });
    if (!dryRun) {
      dispatch(fetchCosts(unitId, groupId));
    } else {
      dispatch(dryRanInitiateCosts(res.data));
    }
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};


const updateStatus = async (dispatch, unitId, cost, statusId, message) => {
  try {
    const res = await axios({
      method: 'put',
      url: `${budgetApiUrl}/unit/${unitId}/costs/${cost.id}/status`,
      data: { statusId, message },
      withCredentials: true
    });
    dispatch(updatedCost(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const requestApproval = (cost, message) => async dispatch => {
  await updateStatus(dispatch, cost.unitId, cost, SALE_STATUS.APPROVAL_REQUESTED, message);
};
export const requestContribution = (cost, message) => async dispatch => {
  await updateStatus(dispatch, cost.unitId, cost, SALE_STATUS.CONTRIBUTION_REQUESTED, message);
};
export const approve = (cost, message) => async dispatch => {
  await updateStatus(dispatch, cost.unitId, cost, SALE_STATUS.FINAL, message);
};
export const deleteCost = (cost, message) => async dispatch => {
  await updateStatus(dispatch, cost.unitId, cost, SALE_STATUS.DELETED, message);
};
