import { animateScroll as scroll } from 'react-scroll';

export const selectAll = () => document.execCommand('selectAll', false, null);

export const clearSelection = () => {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}

export const animateScroll = (name, { duration = 800, smooth = 'easeInOutQuart', offset = 0 } = {}) => {
  const elements = document.getElementsByName(name);
  if (elements.length !== 1) {
    console.error(`Cannot scroll to ${name}: found ${elements.length} elements matching that name`);
  }
  let element = elements[0];
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  const headerHeight = document.getElementsByTagName('header')[0].clientHeight;
  offsetTop = offsetTop + offset - headerHeight; // compensating for the spacer

  scroll.scrollTo(offsetTop, {
    duration,
    smooth,
    containerId: 'main',
  });
};

export const triggerInputChange = (element, value = '') => {
  const setValue = Object.getOwnPropertyDescriptor(element.__proto__, 'value').set;
  setValue.call(element, value);
  const event = new Event('input', { bubbles: true });
  element.dispatchEvent(event);
};
