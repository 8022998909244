import React, { memo, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableBody, TableHead, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import { SALE_STATUS } from '../../../../../common/src/util/enum';
import TextField from '@material-ui/core/TextField';
import { approve, requestApproval } from '../../action/cost';
import { nextBudgetYear } from '../../../../../common/src/util/date';

const useStyles = makeStyles((theme) => ({
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  includedColumn: {
    width: 50,
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  message: {
    width: '100%',
  },
}));

const getApplicableCosts = (costs, action, userId, isCfo) => {
  const nextYear = nextBudgetYear();
  switch (action) {
    case SALE_STATUS.APPROVAL_REQUESTED:
      return costs.filter(cost =>
        cost.year === nextYear &&
        (
          (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId !== cost.approverId) || isCfo)) ||
          (cost.statusId === SALE_STATUS.FINAL && isCfo)
        ));
    case SALE_STATUS.FINAL:
      return costs.filter(cost =>
        cost.year === nextYear &&
        (
          (cost.statusId === SALE_STATUS.APPROVAL_REQUESTED && (cost.approverId === userId || isCfo)) ||
          (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId === cost.approverId) || isCfo))
        ));
    default:
      return [];
  }
}

const getTitle = status => {
  switch (status) {
    case SALE_STATUS.APPROVAL_REQUESTED:
      return 'Account group approval';
    case SALE_STATUS.FINAL:
      return 'Approve and finalize account group';
  }
};

const CostGroupApprovalModal = ({ open, onClose, nextStatus, costs, userId, isCfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [includedCosts, setIncludedCosts] = useState([]);
  const toggleCostIncluded = cost => {
    const index = includedCosts.indexOf(cost);
    if (index > -1) {
      setIncludedCosts(update(includedCosts, { $splice: [[index, 1]] }));
    } else {
      setIncludedCosts(update(includedCosts, { $push: [cost] }));
    }
  }
  const applicableCosts = getApplicableCosts(costs, nextStatus, userId, isCfo);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (open && costs) {
      setIncludedCosts(applicableCosts);
    }
  }, [open, costs]);

  const actions = {
    [SALE_STATUS.APPROVAL_REQUESTED]: requestApproval,
    [SALE_STATUS.FINAL]: approve,
  };

  const onConfirm = () => {
    for (const cost of includedCosts) {
      dispatch(actions[nextStatus](cost, message));
    }
    onClose();
  }

  const valid = includedCosts.length > 0;
  const title = getTitle(nextStatus);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table style={{ width: '400px' }}>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.includedColumn, classes.headColumn)}>
                Included
              </TableCell>
              <TableCell className={classes.headColumn}>
                Number
              </TableCell>
              <TableCell className={classes.headColumn}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicableCosts.map((cost, index) => {
              const included = includedCosts.includes(cost);
              return (
                <TableRow key={index}>
                  <TableCell className={clsx(classes.includedColumn, classes.rowDivider)}>
                    <Checkbox color="primary"
                              checked={included}
                              onChange={() => toggleCostIncluded(cost)}/>
                  </TableCell>
                  <TableCell className={classes.rowDivider}>
                    {cost.number}
                  </TableCell>
                  <TableCell className={classes.rowDivider}>
                    {cost.type}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <br/>
        <TextField
          label="Optional message"
          multiline
          value={message}
          onChange={event => setMessage(event.target.value)}
          className={classes.message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={!valid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CostGroupApprovalModal);
