import { PERMISSION_LEVEL, SALE_STATUS, toReadableSaleStatus } from '../../../../../common/src/util/enum';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { deepValue } from '../../../../../common/src/util/object';
import React from 'react';

import DeletedIcon from '@material-ui/icons/DeleteOutline';
import ContributionRequestedIcon from '@material-ui/icons/Edit';
import ApprovalRequestedIcon from '@material-ui/icons/HourglassEmpty';
import ApprovedIcon from '@material-ui/icons/Done';
import FinalIcon from '@material-ui/icons/DoneAll';

export default function SaleStatusIcon(props) {
  const { user, sale, unit } = props;
  const userId = user ? user.id : undefined;
  const { saleStatusId, contributorId, approverId } = sale;

  switch (saleStatusId) {
    case SALE_STATUS.DELETED:
      return (
        <Tooltip title={toReadableSaleStatus(saleStatusId)}>
          <DeletedIcon fontSize="small"/>
        </Tooltip>
      );
    case SALE_STATUS.CONTRIBUTION_REQUESTED:
      const amIContributor = contributorId === userId;
      return (
        <Tooltip title={toReadableSaleStatus(saleStatusId) + (amIContributor ? ' from you' : ' from someone else')}>
          <ContributionRequestedIcon fontSize="small" color={amIContributor ? 'secondary' : 'inherit'}/>
        </Tooltip>
      );
    case SALE_STATUS.APPROVAL_REQUESTED:
      const amIApprover = approverId === userId;
      return (
        <Tooltip title={toReadableSaleStatus(saleStatusId) + (amIApprover ? ' from you' : ' from someone else')}>
          <ApprovalRequestedIcon fontSize="small" color={amIApprover ? 'secondary' : 'inherit'}/>
        </Tooltip>
      );
    case SALE_STATUS.APPROVED:
      let isCfo = deepValue(unit, 'permission.level', 0) >= PERMISSION_LEVEL.CFO;
      return (
        <Tooltip title={toReadableSaleStatus(saleStatusId) + (isCfo ? ' from you' : ' from CFO')}>
          <ApprovedIcon fontSize="small" color={isCfo ? 'secondary' : 'inherit'}/>
        </Tooltip>
      );
    case SALE_STATUS.FINAL:
      return (
        <Tooltip title={toReadableSaleStatus(saleStatusId)}>
          <FinalIcon fontSize="small"/>
        </Tooltip>
      );
    default:
      return <></>;
  }
}
