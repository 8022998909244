import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Title from "./Title";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    width: '100%',
    margin:  `0 0 ${theme.spacing(2)}px 0`,
    position: 'sticky',
    top: 80,
    zIndex: 1
  },
  paper: {
    padding: props => `${theme.spacing(props.spacingSize || 1)}px 0 ${theme.spacing(props.spacingSize || 1)}px  ${theme.spacing(2)}px`,
  },
  topLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const PageTitle = (props) => {
  const { title, children, newLineChildren } = props;
  const classes = useStyles(props);

  return (
    <Grid item className={classes.paperWrapper}>
      <Paper className={classes.paper}>
        <div className={classes.topLine}>
          <Title variant={'h6'}>{title}</Title>
          {children}
        </div>
        { newLineChildren }
      </Paper>
    </Grid>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  spacingSize: PropTypes.number,
  newLineChildren: PropTypes.node,
  children: PropTypes.node
}

export default memo(PageTitle);
