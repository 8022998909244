import { deepCopy } from '../../../../common/src/util/object';
import update from 'immutability-helper';
import {
  ADDED_ACCOUNT_GROUP,
  DELETED_ACCOUNT_GROUP,
  FETCHED_ASSIGNMENTS,
  FETCHING_ASSIGNMENTS,
  TOGGLED_ACCOUNT_MODAL,
  UPDATED_ACCOUNT,
  UPDATED_ACCOUNT_GROUP,
  UPDATING_ACCOUNT,
  UPDATING_ACCOUNT_GROUP,
  UPSERTED_ASSIGNMENT
} from '../action/account';

const initialState = {
  fetchingAssignments: false,
  groups: [],
  openModals: {},
  updatingAccount: false,
  updatingAccountGroup: false,
};

export default (state = deepCopy(initialState), action) => {
  switch (action.type) {
    case ADDED_ACCOUNT_GROUP:
      const group = { ...action.group, types: [], size: 0 };
      return { ...state, groups: [...state.groups, group], openModals: {} };

    case TOGGLED_ACCOUNT_MODAL:
      return { ...state, openModals: { ...state.openModals, [action.id]: action.open } };

    case UPDATING_ACCOUNT:
      return { ...state, updatingAccount: action.updatingAccount };

    case UPDATING_ACCOUNT_GROUP:
      return { ...state, updatingAccountGroup: action.updatingAccountGroup };

    case UPDATED_ACCOUNT_GROUP: {
      const groups = [...state.groups];
      const index = groups.findIndex(group => group.id === action.group.id);
      groups[index] = action.group;
      return { ...state, groups, openModals: {} };
    }

    case DELETED_ACCOUNT_GROUP: {
      const groups = [...state.groups];
      const index = groups.findIndex(group => group.id === action.group.id);
      groups.splice(index, 1);
      return { ...state, groups };
    }

    case UPDATED_ACCOUNT: {
      // CASE WHEN YOU MOVED ACCOUNT TO OTHER GROUP
      if (action.account.movedToGroup && action.account.movedFromGroup) {
        const newAccount = {
          id: action.account.id,
          number: action.account.number,
          name: action.account.name,
          categoryId: action.account.categoryId
        };
        const prevGroup = state.groups.find(item => item.id === action.account.movedFromGroup);
        const newGroup = state.groups.find(item => item.id === action.account.movedToGroup);
        const prevGroupIndex = state.groups.indexOf(prevGroup);
        const newGroupIndex = state.groups.indexOf(newGroup);
        const typeIndexInPrevGroup = prevGroup.types.find((item) => item.id === action.account.id);
        const typeIndexInPrevGroupIndex = prevGroup.types.indexOf(typeIndexInPrevGroup);

        return update(state, {
          openModals: { $set: {} },
          groups: {
            [prevGroupIndex]: {
              types: {
                $splice: [[typeIndexInPrevGroupIndex, 1]]
              }
            },
            [newGroupIndex]: {
              types: {
                $push: [newAccount]
              }
            }
          }
        })
      }

      const groups = [...state.groups];
      for (let [groupIndex, group] of state.groups.entries()) {
        for (let [accountIndex, account] of group.types.entries()) {
          if (account.id === action.account.id) {
            group = { ...group, types: [...group.types] }
            group.types[accountIndex] = action.account;
            groups[groupIndex] = group;
          }
        }
      }
      return { ...state, groups, openModals: {} };
    }

    case FETCHING_ASSIGNMENTS: {
      return { ...state, fetchingAssignments: true };
    }
    case FETCHED_ASSIGNMENTS: {
      return { ...state, fetchingAssignments: false, groups: action.groups };
    }
    case UPSERTED_ASSIGNMENT: {
      let groups = state.groups;
      for (let [groupIndex, group] of groups.entries()) {
        for (let [assignmentIndex, assignment] of group.types.entries()) {
          if (assignment.id === action.assignment.typeId) {
            group = { ...group, types: [...group.types] };
            group.types[assignmentIndex] = {
              ...assignment,
              assignmentId: action.assignment.id,
              contributor: action.assignment.contributor
            };
            groups = [...groups];
            groups[groupIndex] = group;
          }
        }
      }
      return { ...state, groups };
    }

    default:
      return state;
  }
};
