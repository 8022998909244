import React, { memo, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableBody, TableHead, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import { SALE_STATUS } from '../../../../../common/src/util/enum';
import {
  canApproveBudgetSales,
  canContributeBudgetSales,
  canFinalizeBudgetSales
} from '../../../../../common/src/util/permission';
import { deepValue } from '../../../../../common/src/util/object';
import TextField from '@material-ui/core/TextField';
import { updateSale } from '../../action/unit';
import { nextBudgetYear } from '../../../../../common/src/util/date';

const useStyles = makeStyles((theme) => ({
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  includedColumn: {
    width: 50,
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  message: {
    width: '100%',
  },
}));

const getApplicableSales = (sales, action) => {
  const nextYear = nextBudgetYear();
  switch (action) {
    case SALE_STATUS.APPROVAL_REQUESTED:
      return sales.filter(sale =>
        // _.every(months, month => _.isNumber(sale[month])) &&
        sale.saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED &&
        sale.year === nextYear &&
        canContributeBudgetSales(deepValue(sale, 'permission.level')));
    case SALE_STATUS.APPROVED:
      return sales.filter(sale =>
        sale.saleStatusId === SALE_STATUS.APPROVAL_REQUESTED &&
        sale.year === nextYear &&
        canApproveBudgetSales(deepValue(sale, 'permission.level')));
    case SALE_STATUS.FINAL:
      return sales.filter(sale =>
        sale.saleStatusId === SALE_STATUS.APPROVED &&
        sale.year === nextYear &&
        canFinalizeBudgetSales(deepValue(sale, 'permission.level')));
    default:
      return [];
  }
}

const getTitle = status => {
  switch (status) {
    case SALE_STATUS.APPROVAL_REQUESTED:
      return 'Request region approval';
    case SALE_STATUS.APPROVED:
      return 'Approve region';
    case SALE_STATUS.FINAL:
      return 'Finalize region';
  }
};

const RequestRegionApprovalModal = (
  {
    open,
    onClose,
    nextStatus,
    sales,
  }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [includedSales, setIncludedSales] = useState([]);
  const toggleSaleIncluded = sale => {
    const index = includedSales.indexOf(sale);
    if (index > -1) {
      setIncludedSales(update(includedSales, { $splice: [[index, 1]] }));
    } else {
      setIncludedSales(update(includedSales, { $push: [sale] }));
    }
  }
  const applicableSales = getApplicableSales(sales, nextStatus);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (open && sales) {
      setIncludedSales(applicableSales);
    }
  }, [open, sales]);

  const onConfirm = () => {
    for (const sale of includedSales) {
      dispatch(updateSale(sale.unitId, sale, { saleStatusId: nextStatus, message }));
    }
    onClose();
  }

  const valid = includedSales.length > 0;
  const title = getTitle(nextStatus);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table style={{ width: '400px' }}>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.includedColumn, classes.headColumn)}>
                Included
              </TableCell>
              <TableCell className={classes.headColumn}>
                District
              </TableCell>
              <TableCell className={classes.headColumn}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicableSales.map((sale, index) => {
              const included = includedSales.includes(sale);
              return (
                <TableRow key={index}>
                  <TableCell className={clsx(classes.includedColumn, classes.rowDivider)}>
                    <Checkbox color="primary"
                              checked={included}
                              onChange={() => toggleSaleIncluded(sale)}/>
                  </TableCell>
                  <TableCell className={classes.rowDivider}>
                    {sale.district}
                  </TableCell>
                  <TableCell className={classes.rowDivider}>
                    {sale.name}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <br/>
        <TextField
          label="Optional message"
          multiline
          value={message}
          onChange={event => setMessage(event.target.value)}
          className={classes.message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={!valid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(RequestRegionApprovalModal);
