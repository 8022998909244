import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import AccountModal from './AccountModal';
import { addAccount, deleteAccountGroup, toggleModal, updateAccountGroup } from '../../action/account';
import AccountGroupModal from './AccountGroupModal';
import ConfirmDeleteButtons from '../common/ConfirmDeleteButtons';

const AccountGroupMenu = ({ unitId, group }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuRef = useRef();
  const [confirmDeleteMenuOpen, setConfirmDeleteMenuOpen] = useState(false);

  const accountModalId = `account-create-${group.id}`;
  const accountModalOpen = useSelector(state => state.account.openModals[accountModalId] || false);
  const [account, setAccount] = useState({});

  const groupModalId = `group-edit-${group.id}`;
  const accountGroupModalOpen = useSelector(state => state.account.openModals[groupModalId] || false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenus = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setConfirmDeleteMenuOpen(false);
  };

  const onNewAccountClicked = () => {
    setMenuOpen(false);
    setAccount({});
    dispatch(toggleModal(accountModalId, true));
  };
  const onNewAccountConfirm = account => {
    dispatch(addAccount(group, { ...account, unitId }));
  };

  const onEditAccountGroupClicked = () => {
    setMenuOpen(false);
    dispatch(toggleModal(groupModalId, true));
  };
  const onEditAccountGroupConfirm = group => {
    dispatch(updateAccountGroup(group, unitId));
  };

  const onDeleteClicked = () => {
    setConfirmDeleteMenuOpen(true);
  };
  const onConfirmDelete = () => {
    dispatch(deleteAccountGroup(group));
    closeMenus();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu open={menuOpen}
            anchorEl={menuAnchorEl}
            keepMounted
            onClose={closeMenus}>
        <MenuItem key={`group-new-account-${group.id}`}
                  onClick={onNewAccountClicked}>
          New account
        </MenuItem>
        <MenuItem key={`group-edit-${group.id}`}
                  onClick={onEditAccountGroupClicked}>
          Edit
        </MenuItem>
        <MenuItem key={`group-delete-${group.id}`}
                  ref={menuRef}
                  onClick={onDeleteClicked}
                  disabled={group.types.length > 0}>
          Delete
        </MenuItem>
      </Menu>
      <AccountModal open={accountModalOpen}
                    onCancel={() => dispatch(toggleModal(accountModalId, false))}
                    onConfirm={onNewAccountConfirm}
                    title="New account"
                    account={account}
                    unitId={unitId}/>
      <AccountGroupModal open={accountGroupModalOpen}
                         onCancel={() => dispatch(toggleModal(groupModalId, false))}
                         onConfirm={onEditAccountGroupConfirm}
                         title="Edit account group"
                         group={group}/>
      <ConfirmDeleteButtons open={menuOpen && confirmDeleteMenuOpen}
                            anchorEl={menuRef}
                            onSuccess={onConfirmDelete}
                            onClose={closeMenus}/>
    </div>
  );
};

AccountGroupMenu.propTypes = {
  group: PropTypes.object,
};

export default memo(AccountGroupMenu);
