import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import ConfirmDeleteButtons from '../common/ConfirmDeleteButtons';
import {
  deleteConsolidatedGroupedRecord,
  moveConsolidatedGroupedRecord,
  toggleModal,
  updateConsolidatedGroupedRecord
} from '../../action/consolidated';
import ConsolidatedGroupedRecordModal from './ConsolidatedGroupedRecordModal';

const ConsolidatedGroupedRecordMenu = ({ companies, records, record }) => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openConfirmDeleteMenu, setOpenConfirmDeleteMenu] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const modalId = `grouped-record-edit-${record.id}`;
  const recordModalOpen = useSelector(state => state.consolidated.openModals[modalId] || false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setOpenConfirmDeleteMenu(false);
  };
  const onCloseMenus = () => {
    setOpenConfirmDeleteMenu(false);
    setMenuOpen(false);
  }

  const onEditClicked = () => {
    onCloseMenus();
    dispatch(toggleModal(modalId, true));
  };
  const onEditRecordConfirm = record => {
    dispatch(updateConsolidatedGroupedRecord(record));
  };

  const onMoveRecordClicked = direction => {
    setMenuOpen(false);
    dispatch(moveConsolidatedGroupedRecord(record, direction));
  }

  const onDeleteClicked = () => {
    setOpenConfirmDeleteMenu(true);
  };
  const onConfirmDelete = () => {
    dispatch(deleteConsolidatedGroupedRecord(record));
    onCloseMenus();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu open={menuOpen}
            anchorEl={menuAnchorEl}
            keepMounted
            onClose={closeMenu}>
        <MenuItem key={`grouped-record-edit-${record.id}`}
                  onClick={onEditClicked}>
          Edit
        </MenuItem>
        <MenuItem key={`grouped-record-move-${record.id}-up`}
                  onClick={() => onMoveRecordClicked('up')}
                  disabled={record.position === 1}>
          Move up
        </MenuItem>
        <MenuItem key={`grouped-record-move-${record.id}-down`}
                  onClick={() => onMoveRecordClicked('down')}
                  disabled={record.position === records.length}>
          Move down
        </MenuItem>
        <MenuItem ref={menuRef}
                  key={`grouped-record-delete-${record.id}`}
                  onClick={onDeleteClicked}>
          Delete
        </MenuItem>
      </Menu>

      <ConsolidatedGroupedRecordModal open={recordModalOpen}
                                      onCancel={() => dispatch(toggleModal(modalId, false))}
                                      onConfirm={onEditRecordConfirm}
                                      title="Edit"
                                      record={record}
                                      companies={companies}/>
      <ConfirmDeleteButtons open={menuOpen && openConfirmDeleteMenu}
                            anchorEl={menuRef}
                            onSuccess={onConfirmDelete}
                            onClose={onCloseMenus}/>
    </div>
  );
};

ConsolidatedGroupedRecordMenu.propTypes = {
  companies: PropTypes.array,
  record: PropTypes.object,
};

export default memo(ConsolidatedGroupedRecordMenu);
