import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { months, Months, toReadableSaleStatus } from '../../../../../common/src/util/enum';
import Messages from '../common/Messages';
import History from '../common/History';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import UserLink from '../common/UserLink';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const mapping = {
  year: {
    order: 20,
  },
  statusId: {
    order: 30,
    label: 'cost status',
    translation: value => toReadableSaleStatus(value).toLowerCase(),
  },
  contributorId: {
    order: 100,
    label: 'contributor',
    translation: 'userId',
  },
  approverId: {
    order: 110,
    label: 'approver',
    translation: 'userId',
  },
  contributorNotifiedAt: {
    order: 120,
    label: 'contributor notification sending time',
    translation: value => moment(value).format('LLLL'),
  },
  approverNotifiedAt: {
    order: 130,
    label: 'approver notification sending time',
    translation: value => moment(value).format('LLLL'),
  },
  contributorReadAt: {
    order: 140,
    label: 'contributor notification seen time',
    translation: value => moment(value).format('LLLL'),
  },
  approverReadAt: {
    order: 150,
    label: 'approver notification seen time',
    translation: value => moment(value).format('LLLL'),
  }
};
months.forEach((month, index) => mapping[month] = { label: Months[index], order: 40 + index });

const useStyles = makeStyles((theme) => ({
  message: {
    width: '100%',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    width: 110,
    display: 'inline-block',
    padding: theme.spacing(1),
  },
}));

export default function CostDetailsDialog(
  {
    open,
    onCancel,
    onConfirm,
    title,
    cost,
    type,
    contributor,
    approver
  }) {
  const { messages, history } = (cost || {}).metadata;

  const classes = useStyles();

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (open) {
      setMessage('');
    }
  }, [open]);

  const missingMonths = Months
    .filter(Month => cost[Month.toLowerCase()] === null)
    .map(Month => `"${Month}"`)
    .join(', ');

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Title>Details</Title>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className={classes.label}>Account</div>
              {type.number}<br/>
              <div className={classes.label}>Name</div>
              {type.name}<br/>
              <div className={classes.label}>Year</div>
              {cost.year}<br/>
              <div className={classes.label}>Status</div>
              {toReadableSaleStatus(cost.statusId)}<br/>
              <div className={classes.label}>Due date</div>
              {cost.due}<br/>
              <div className={classes.label}>Contributor</div>
              <UserLink user={contributor}/><br/>
              <div className={classes.label}>Approver</div>
              <UserLink user={approver}/><br/>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Title>Messages</Title>
          </AccordionSummary>
          <AccordionDetails>
            <Messages messages={messages}/>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Title>History</Title>
          </AccordionSummary>
          <AccordionDetails>
            <History history={history} mapping={mapping}/>
          </AccordionDetails>
        </Accordion>

        <br/>

        {missingMonths.length > 0 &&
        <Typography color="error">Note that some values are missing for {missingMonths}</Typography>
        }

        <TextField
          label="Optional message"
          multiline
          value={message}
          onChange={event => setMessage(event.target.value)}
          className={classes.message}
        />

      </DialogContent>
      <DialogActions>
        <Button color="primary"
                onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                onClick={() => onConfirm(message)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CostDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cost: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  contributor: PropTypes.object,
  approver: PropTypes.object,
};
