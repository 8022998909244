import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import * as _ from 'lodash';
import summarize from '../../../../../common/src/util/summarize';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, TableBody, TableHead } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Months, months } from '../../../../../common/src/util/enum';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { formatNumber } from '../../../../../common/src/util/number';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  table: {
    overflow: 'auto',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  nameColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  yearColumn: {
    maxWidth: 40,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  rowSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
}));


export default function CostTotal({ categories, selectedRow, setSelectedRow }) {
  const classes = useStyles();

  const [expandedStates, setExpandedStates] = useState({});
  const toggleGroupExpanded = accountId => {
    setExpandedStates({ ...expandedStates, [accountId]: !expandedStates[accountId] });
  };

  const boolean = value => value === 'true';
  const aggregate = credit => _.chain(categories)
    .filter(c => c.credit === credit)
    .flatMap(g => g.types)
    .flatMap(t => t.costs)
    .groupBy(c => c.year)
    .mapValues((costs, year) => _.chain(costs)
      .groupBy(c => c.budget)
      .mapValues((cost, budget) => ({ year, budget: boolean(budget), ...summarize(cost, ...months) }))
      .forEach(cost => cost.total = summarize(cost))
      .value())
    .value();

  const credits = aggregate(true);
  const debits = aggregate(false);

  const merge = (base, object, source, f) => {
    return _.mergeWith(_.cloneDeep(object), source, (a1, b1) => {
      return _.mergeWith(a1, b1, (a2, b2) => {
        if (!a2 || !b2) {
          return null;
        }
        const result = { ..._.omit(a2, ...months), ...base };
        for (const month of months) {
          result[month] = f(_.get(a2, month, 0), _.get(b2, month, 0));
        }
        result.total = f(a2.total || 0, b2.total || 0);
        return result;
      });
    });
  }

  const flatten = merged => _.chain(merged)
    .values()
    .flatMap(a => _.values(a))
    .filter(a => !!a)
    .orderBy([c => c.budget, c => c.year], ['desc', 'desc'])
    .value();

  const resultat = merge({ type: 'resultat' }, credits, debits, (a, b) => a - b);
  const resultatCosts = flatten(resultat);

  const percentage = (value, of) => {
    return value === undefined || of === null || of === 0 ? null : value / of * 100;
  }
  const margin = merge({ type: 'margin' }, resultat, credits, (a, b) => percentage(a, b));
  const marginCosts = flatten(margin);

  if (resultatCosts.length === 0) {
    return null;
  }

  const category = {
    name: 'Total',
    types: [
      { name: 'Results', costs: resultatCosts },
      { name: 'Profit margin %', costs: marginCosts }
    ]
  };

  const formatPercentage = value => value ? value.toFixed(1) : null;

  return (
    <Grid item xs={12} key={category.name}>
      <>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <div className={classes.accordionSummary}>
              <Title>Total</Title>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headColumn}>Name</TableCell>
                    <TableCell align="left" className={classes.headColumn}>Year</TableCell>
                    <TableCell className={classes.headColumn}>&nbsp;</TableCell>
                    {Months.map(month => <TableCell align="right" key={month}
                                                    className={classes.headColumn}>{month}</TableCell>)}
                    <TableCell align="right" className={classes.headColumn}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.types.map(group => group.costs.map((cost, index) => {
                    const { name } = group;
                    const lastYear = index === 0;
                    const groupExpanded = !!expandedStates[cost.type];
                    if (!lastYear && !groupExpanded) {
                      return undefined;
                    }
                    const key = `${cost.type}-${cost.year}-${cost.budget}`;
                    const rowDivider = lastYear ? classes.rowDivider : '';
                    const onClick = () => setSelectedRow(key);
                    const format = cost.type === 'resultat' ? formatNumber : formatPercentage;

                    return (
                      <TableRow className={clsx(classes.row, { [classes.rowSelected]: key === selectedRow })}
                                onClick={onClick}>
                        <TableCell className={clsx(classes.nameColumn, rowDivider)}>{lastYear && name}</TableCell>
                        <TableCell align="left" className={clsx(classes.yearColumn, rowDivider)}>
                          {cost.year} {cost.budget ? 'Budget' : 'Outcome'}
                        </TableCell>
                        <TableCell align="left"
                                   className={clsx(classes.yearExpandColumn, rowDivider)}>
                          {lastYear && (
                            <IconButton
                              size="small"
                              onClick={() => toggleGroupExpanded(cost.type)}
                              disabled={group.costs.length === 1}
                            >
                              {groupExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                          )}
                        </TableCell>
                        {months.map(month => {
                          return (
                            <TableCell key={`${key}-${month}`}
                                       align="right"
                                       className={rowDivider}>
                              {format(cost[month])}
                            </TableCell>
                          );
                        })}
                        <TableCell align="right"
                                   className={rowDivider}>
                          {format(cost.total)}
                        </TableCell>
                      </TableRow>
                    )
                  }))}
                </TableBody>
              </Table>
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    </Grid>
  );
}
