import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { userDisplayName } from '../../util/user';
import PropTypes from 'prop-types';
import { fetchUser } from '../../action/user';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `12px ${theme.spacing(2)}px 5px ${theme.spacing(2)}px`,
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginTop: '-2px',
    paddingRight: theme.spacing(1),
  },
}));

export default function User(props) {
  let { user: inUser, userId, userDisplayName: name, username } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const userById = useSelector(state => state.user.userById);
  const [user, setUser] = useState(inUser);
  username = username || user?.username;

  useEffect(() => {

    if (inUser) {
      setUser(inUser);
      return;
    }

    if (userId) {
      const fetchedUser = userById[userId];
      if (fetchedUser)
        setUser(fetchedUser);
      else
        dispatch(fetchUser(userId));
    }
  }, [inUser, userId, userById, dispatch]);

  name = name || userDisplayName(user);
  const email = user ? user.email : '';

  return (
    <div className={classes.root}>

      <div className={classes.detail}>
        <Typography variant="body1">{name}</Typography>
      </div>

      <div className={classes.detail}>
        <Tooltip title="Login">
          <VpnKeyIcon className={classes.icon}/>
        </Tooltip>
        <Typography variant="caption">{username}</Typography><br/>
      </div>

      <div className={classes.detail}>
        <Tooltip title="Email">
          <EmailIcon className={classes.icon}/>
        </Tooltip>
        <Typography variant="caption">{email}</Typography>
      </div>
    </div>
  );
}

User.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.number,
  userDisplayName: PropTypes.string,
  username: PropTypes.string,
};
