import React from 'react'
import { Redirect } from 'react-router-dom';
import * as _ from "lodash";
import { PERMISSION_LEVEL } from "../../../../../../common/src/util/enum";
import { loadLocal } from '../../../util/storage';

const protectedCfoRoute = (Component) => (props) => {
  const user = loadLocal('user');
  const permissions = loadLocal('permissions');
  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);

  if (!user) {
    return null;
  } else if (!isCfo) {
    return <Redirect to={'/'}/>
  }

  return <Component {...props} />
}

export default protectedCfoRoute;
