import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications, updateNotifications, } from '../../action/notification';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { now } from '../../util/time';
import Popover from '@material-ui/core/Popover';
import ReadIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Title from '../common/Title';
import Notification from './Notification';
import { NOTIFICATION_TYPE } from '../../../../../common/src/util/enum';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingRight: 0,
    width: 500,
    overflow: 'hidden',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
  notifications: {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingTop: theme.spacing(1),
  },
  notificationsHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notificationsActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginRight: theme.spacing(1),
  },
  onlyNonSeen: {
    marginTop: theme.spacing(-0.5),
  },
  seeAll: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  outOf: {
    marginTop: theme.spacing(1),
  },
  loadMore: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  }
}));

const SCROLL_LOAD_MORE_OFFSET_PX = 5;

export default function Notifications(props) {
  const classes = useStyles();
  const { openTutorial } = props;
  const dispatch = useDispatch();

  let {
    notifications,
    fetchingNotifications,
    totalCount,
    seenCount,
  } = useSelector(state => state.notification);

  const [anchorEl, setAnchorEl] = useState(null);
  const [onlyNonSeen, setOnlyNonSeen] = useState(false);

  // const reminderNotificationsToHide = [
  //   NOTIFICATION_TYPE.SALES_DUE_CONTRIBUTION_REQUESTED,
  //   NOTIFICATION_TYPE.SALES_DUE_APPROVAL_REQUESTED,
  //   NOTIFICATION_TYPE.COSTS_DUE_CONTRIBUTION_REQUESTED,
  //   NOTIFICATION_TYPE.COSTS_DUE_APPROVAL_REQUESTED,
  // ];
  //
  // let hiddenNotificationsLength = 0;
  // notifications = notifications.filter(notification => {
  //   if (reminderNotificationsToHide.includes(notification?.metadata?.type)) {
  //     hiddenNotificationsLength++;
  //     return false;
  //   }
  //   return true;
  // });

  const limit = 20;
  // const nonSeenCount = (totalCount - hiddenNotificationsLength) - seenCount;
  const nonSeenCount = totalCount - seenCount;
  const open = Boolean(anchorEl);
  const canLoadMore = onlyNonSeen ? notifications.length < nonSeenCount : notifications.length < totalCount;

  useEffect(() => {
    dispatch(fetchNotifications({ limit, onlyNonSeen }));
  }, [dispatch, onlyNonSeen]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!open)
        dispatch(fetchNotifications({ limit, onlyNonSeen }));
    }, 15000); // every 15 seconds
    return () => clearInterval(interval);
  }, [dispatch, open, onlyNonSeen]);

  useEffect(() => {
    if (openTutorial && open) {
      handleClick();
    }
  }, [openTutorial]);

  const handleClick = event => {
    if (open) {
      setAnchorEl(null);
      setTimeout(() => dispatch(fetchNotifications({ limit, onlyNonSeen })), 300);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const loadMore = () => {
    if (canLoadMore) {
      let olderThanId;
      if (notifications.length)
        olderThanId = notifications[notifications.length - 1].id;
      dispatch(fetchNotifications({ limit, onlyNonSeen, olderThanId, append: true }));
    }
  }

  let loader;
  if (fetchingNotifications)
    loader = <CircularProgress size={16}/>;

  let badgeContent, content;
  if (!notifications || notifications.length === 0) {
    content = 'There are no notifications to show.';
    badgeContent = 0;
  } else {
    badgeContent = nonSeenCount;

    const items = notifications.map(notification =>
      <Notification
        key={notification.id}
        notification={notification}
      />);

    content = (
        <>
          {items}
          {canLoadMore &&
          <div key="load-more-button" className={classes.loadMore} onMouseDown={e => e.preventDefault()}>
            <Button size="small" color="primary" variant="text" onClick={loadMore}>
              Load more
            </Button>
          </div>
          }
        </>
    )
  }

  const handleScroll = e => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= SCROLL_LOAD_MORE_OFFSET_PX)
      loadMore();
  };

  const onlyNonSeenSwitch = (
    <div className={classes.onlyNonSeen}>
      <FormControlLabel
        labelPlacement="end"
        label="Show unread only"

        control={
          <Switch
            size="small"
            checked={onlyNonSeen}
            onChange={event => setOnlyNonSeen(event.target.checked)}
            color="primary"
          />
        }
      />
    </div>
  );

  const seeAllButton = (
    <div className={classes.seeAll}>
      <Tooltip title="Mark all as read">
        <FormControlLabel
          labelPlacement="end"
          label="Mark all as read"
          disabled={nonSeenCount === 0}
          control={
            <IconButton
              size="small"
              onClick={() => dispatch(updateNotifications({ seenAt: now() }, { limit, onlyNonSeen }))}
            >
              <ReadIcon fontSize="small" color="primary"/>
            </IconButton>
          }
        />
      </Tooltip>
    </div>
  );

  return (
    <>
      <IconButton
        id={'notification-anchor-element'} // required for tutorial
        onClick={handleClick}
        color="inherit"
      >
        <Badge badgeContent={badgeContent} color="error">
          <NotificationsIcon/>
        </Badge>
      </IconButton>
      <Popover
        id={'notification-view-popover'} // required for tutorial
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Paper elevation={3} className={classes.paper}>
          <div className={classes.notificationsHead}>
            <div>
              <Title>
                Notifications {loader}
              </Title>
              <div className={classes.outOf}>Unread {nonSeenCount} out of {totalCount}</div>
            </div>

            <div className={classes.notificationsActions}>
              {onlyNonSeenSwitch}
              {seeAllButton}
            </div>
          </div>
          <Divider light/>
          <div className={classes.notifications} onScroll={handleScroll}>
            {content}
            {loader}
          </div>
        </Paper>
      </Popover>
    </>
  );
}

Notifications.propTypes = {
  openTutorial: PropTypes.bool
}
