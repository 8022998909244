import React, { memo } from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import areEqual from "../../../util/areEqual";

const useStyles = makeStyles(theme => ({
  content: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: '0 10px 8px 10px',
    border: '1px solid rgba(0,0,0,0.1)',
    left: (props) => props.left,
    top: (props) => props.top,
    animation: `$animate 500ms ease forwards`
  },
  outlinedButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    marginRight: 10,
  },
  secondaryButton: {
    color: 'black'
  },
  disableClick: {
    pointerEvents: 'none'
  },
  "@keyframes animate": {
    "0%": {
      transform: "translateY(20px)",
      opacity: 0
    },
    "100%": {
      transform: "translateY(0px)",
      opacity: 1
    }
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const Content = (props) => {
  const { openTutorial, children, step, stepsLength, onSepChange, onSkip } = props;
  const classes = useStyles(props);
  if (!props.top) return null;

  const onNext = () => onSepChange(step + 1);

  return (
    <div className={clsx(classes.content)}>
      { children }
      <div className={classes.buttonsWrapper}>
        {
          (stepsLength && stepsLength > 1 && stepsLength - 1 !== step) ? (
            <Button
              className={classes.outlinedButton}
              variant={'outlined'}
              onClick={onNext}
            >
              Next
            </Button>
          ) : null
        }
        <Button
          className={classes.secondaryButton}
          variant={(stepsLength > 1) && (stepsLength && stepsLength - 1 !== step) ? 'text' : 'outlined'}
          onClick={() => onSkip ? onSkip() : openTutorial(false)}
        >
          Skip
        </Button>
      </div>
    </div>
  )
}

export default memo(Content, areEqual);