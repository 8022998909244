import axios from 'axios';
import config from '../config';
import { handleAxiosError } from './error';

const { budgetApiUrl } = config;

export const FETCHING_ASSIGNMENTS = 'FETCHING_ASSIGNMENTS';
export const FETCHED_ASSIGNMENTS = 'FETCHED_ASSIGNMENTS';
export const UPSERTED_ASSIGNMENT = 'UPSERTED_ASSIGNMENT';

export const TOGGLED_ACCOUNT_MODAL = 'TOGGLED_ACCOUNT_MODAL';
export const ADDED_ACCOUNT_GROUP = 'ADDED_ACCOUNT_GROUP';
export const UPDATING_ACCOUNT_GROUP = 'UPDATING_ACCOUNT_GROUP';
export const UPDATED_ACCOUNT_GROUP = 'UPDATED_ACCOUNT_GROUP';
export const DELETED_ACCOUNT_GROUP = 'DELETED_ACCOUNT_GROUP';
export const UPDATING_ACCOUNT = 'UPDATING_ACCOUNT';
export const UPDATED_ACCOUNT = 'UPDATED_ACCOUNT';

export const fetchingAssignments = () => ({ type: FETCHING_ASSIGNMENTS });
export const fetchedAssignments = groups => ({ type: FETCHED_ASSIGNMENTS, groups });
export const upsertedAssignment = assignment => ({ type: UPSERTED_ASSIGNMENT, assignment });

export const addedAccountGroup = group => ({ type: ADDED_ACCOUNT_GROUP, group });
export const updatingAccountGroup = updatingAccountGroup => ({ type: UPDATING_ACCOUNT_GROUP, updatingAccountGroup });
export const updatedAccountGroup = group => ({ type: UPDATED_ACCOUNT_GROUP, group });
export const deletedAccountGroup = group => ({ type: DELETED_ACCOUNT_GROUP, group });
export const updatingAccount = updatingAccount => ({ type: UPDATING_ACCOUNT, updatingAccount });
export const updatedAccount = (group, account) => ({ type: UPDATED_ACCOUNT, group, account });


export const toggleModal = (id, open) => async dispatch => {
  dispatch({ type: TOGGLED_ACCOUNT_MODAL, id, open });
}

export const fetchAssignments = unitId => async dispatch => {
  dispatch(fetchingAssignments());
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/accounts/assignments/unit/${unitId}`,
      withCredentials: true
    });
    dispatch(fetchedAssignments(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch, true);
  }
};

export const addAccountGroup = (group, unitId) => async dispatch => {
  dispatch(updatingAccountGroup(true));
  try {
    const res = await axios({
      method: 'post',
      url: `${budgetApiUrl}/accounts`,
      data: { ...group, unitId },
      withCredentials: true
    });
    dispatch(addedAccountGroup(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updatingAccountGroup(false));
  }
};

const fetchAccountGroup = async (dispatch, id, unitId) => {
  const res = await axios({
    method: 'get',
    url: `${budgetApiUrl}/accounts/assignments/unit/${unitId}`,
    params: { categoryId: id },
    withCredentials: true
  });
  const category = res.data.length === 1 ? res.data[0] : null;
  if (category) {
    dispatch(updatedAccountGroup(category));
  }
}

export const updateAccountGroup = (group, unitId) => async dispatch => {
  dispatch(updatingAccountGroup(true));
  try {
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/accounts/${group.id}`,
      withCredentials: true,
      data: { ...group, unitId }
    });
    await fetchAccountGroup(dispatch, group.id, unitId);
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updatingAccountGroup(false));
  }
};

export const deleteAccountGroup = group => async dispatch => {
  dispatch(updatingAccountGroup(true));
  try {
    await axios({
      method: 'delete',
      url: `${budgetApiUrl}/accounts/${group.id}`,
      withCredentials: true,
    });
    dispatch(deletedAccountGroup(group));
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updatingAccountGroup(false));
  }
};

export const addAccount = (group, account) => async dispatch => {
  dispatch(updatingAccount(true));
  try {
    await axios({
      method: 'post',
      url: `${budgetApiUrl}/accounts/${group.id}/accounts`,
      data: account,
      withCredentials: true
    });
    await fetchAccountGroup(dispatch, group.id, account.unitId);
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updatingAccount(false));
  }
};

export const updateAccount = (group, account, moveToGroup) => async dispatch => {
  dispatch(updatingAccount(true));
  try {
    const oldGroupId = group.id;
    const newGroupId = moveToGroup?.id;
    account = newGroupId && newGroupId !== oldGroupId ? { ...account, moveToGroupId: moveToGroup.id } : account;
    await axios({
      method: 'put',
      url: `${budgetApiUrl}/accounts/${group.id}/accounts/${account.id}`,
      data: account,
      withCredentials: true
    });
    await fetchAccountGroup(dispatch, oldGroupId, account.unitId);
    if (newGroupId && newGroupId !== oldGroupId) {
      await fetchAccountGroup(dispatch, newGroupId, account.unitId);
    }
  } catch (err) {
    handleAxiosError(err, dispatch);
  } finally {
    dispatch(updatingAccount(false));
  }
};

export const deleteAccount = (group, account) => async dispatch => {
  try {
    const res = await axios({
      method: 'delete',
      url: `${budgetApiUrl}/accounts/${group.id}/accounts/${account.id}`,
      withCredentials: true
    });
    await fetchAccountGroup(dispatch, group.id, account.unitId);
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const assignAccount = assignment => async dispatch => {
  try {
    let res;
    if (!assignment.id) {
      res = await axios({
        method: 'post',
        url: `${budgetApiUrl}/accounts/assignments/unit/${assignment.unitId}`,
        data: assignment,
        withCredentials: true
      });
    } else {
      res = await axios({
        method: 'put',
        url: `${budgetApiUrl}/accounts/assignments/unit/${assignment.unitId}/${assignment.id}`,
        data: assignment,
        withCredentials: true
      });
    }
    dispatch(upsertedAssignment(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const unassignAccount = assignment => async dispatch => {
  try {
    const res = await axios({
      method: 'delete',
      url: `${budgetApiUrl}/accounts/assignments/unit/${assignment.unitId}/${assignment.id}`,
      withCredentials: true
    });
    dispatch(upsertedAssignment({ typeId: res.data.typeId, id: null, contributor: null }));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};
