import { deepCopy } from '../../../../common/src/util/object';
import * as env from '../env';

import {
  CHECKED_STATUS,
} from '../action/status';

import {
  SIGNED_OUT,
} from '../action/user';

const initialState = {
  checkedStatus: false,
  backendStatus: undefined,
  frontendStatus: env,
};

const reducer = (state = deepCopy(initialState), action) => {
  switch (action.type) {

    case CHECKED_STATUS:
      return { ...state, backendStatus: action.status, checkedStatus: true };

    case SIGNED_OUT:
      return deepCopy(initialState);

    default:
      return state;
  }
};

export default reducer;
