import { deepCopy } from '../../../../common/src/util/object';

import {
  FETCHING_NOTIFICATIONS,
  FETCHED_NOTIFICATIONS,
  UPDATED_NOTIFICATION,
  DELETED_NOTIFICATION,
} from '../action/notification';

import {
  SIGNED_OUT,
} from '../action/user';

const initialState = {
  fetchingNotifications: false,
  notifications: [],
  totalCount: 0,
  seenCount: 0,
};

const reducer = (state = deepCopy(initialState), action) => {
  switch (action.type) {

    case FETCHING_NOTIFICATIONS:
      return { ...state, fetchingNotifications: true };

    case FETCHED_NOTIFICATIONS: {
      let { notifications, totalCount, seenCount } = action.data;

      if (action.append)
        notifications = [...state.notifications, ...notifications];

      // Unique
      const map = new Map();
      for (let notification of notifications)
        map.set(notification.id, notification);
      notifications = [...map.values()];

      // Sort by id descending
      notifications.sort((a, b) => b.id - a.id);

      return { ...state, fetchingNotifications: false, notifications, totalCount, seenCount };
    }

    case UPDATED_NOTIFICATION: {
      const { notification, totalCount, seenCount } = action;
      const { notifications } = state;
      for (let n = 0; n < notifications.length; n++)
        if (notifications[n].id === notification.id)
          notifications[n] = notification;
      if (totalCount !== undefined)
        state.totalCount = totalCount;
      if (seenCount !== undefined)
        state.seenCount = seenCount;
      return { ...state };
    }

    case DELETED_NOTIFICATION: {
      const { id, totalCount, seenCount } = action;
      const { notifications } = state;
      for (let n = 0; n < notifications.length; n++)
        if (notifications[n].id === id) {
          notifications.splice(n, 1);
          break;
        }
      if (totalCount !== undefined)
        state.totalCount = totalCount;
      if (seenCount !== undefined)
        state.seenCount = seenCount;
      return { ...state };
    }

    case SIGNED_OUT:
      return deepCopy(initialState);

    default:
      return state;
  }
};

export default reducer;
