import { SALE_STATUS, toReadableSaleStatus } from '../../../../../common/src/util/enum';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import React from 'react';

import DeletedIcon from '@material-ui/icons/DeleteOutline';
import ContributionRequestedIcon from '@material-ui/icons/Edit';
import ApprovalRequestedIcon from '@material-ui/icons/HourglassEmpty';
import FinalIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';

export default function CostStatusIcon({ statusId, contributorId, approverId, userId }) {
  switch (statusId) {
    case SALE_STATUS.DELETED:
      return (
        <Tooltip title={toReadableSaleStatus(statusId)}>
          <DeletedIcon fontSize="small"/>
        </Tooltip>
      );
    case SALE_STATUS.CONTRIBUTION_REQUESTED:
      const amIContributor = contributorId === userId;
      return (
        <Tooltip title={toReadableSaleStatus(statusId) + (amIContributor ? ' from you' : ' from someone else')}>
          <ContributionRequestedIcon fontSize="small" color={amIContributor ? 'secondary' : 'inherit'}/>
        </Tooltip>
      );
    case SALE_STATUS.APPROVAL_REQUESTED:
      const amIApprover = approverId === userId;
      return (
        <Tooltip title={toReadableSaleStatus(statusId) + (amIApprover ? ' from you' : ' from someone else')}>
          <ApprovalRequestedIcon fontSize="small" color={amIApprover ? 'secondary' : 'inherit'}/>
        </Tooltip>
      );
    case SALE_STATUS.FINAL:
      return (
        <Tooltip title={toReadableSaleStatus(statusId)}>
          <FinalIcon fontSize="small"/>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Invalid status">
          <WarningIcon fontSize="small" color="secondary"/>
        </Tooltip>
      );
  }
}
