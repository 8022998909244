import { deepCopy } from '../../../../common/src/util/object';

import {
  FETCHING_TASKS,
  FETCHED_TASKS,
} from '../action/task';

import {
  SIGNED_OUT,
} from '../action/user';

import { UPDATED_SALE } from '../action/unit';

const initialState = {
  fetchingTasks: false,
  tasks: {},
};

const reducer = (state = deepCopy(initialState), action) => {
  switch (action.type) {

    case FETCHING_TASKS:
      return { ...state, fetchingTasks: true, tasks: {} };

    case FETCHED_TASKS:
      return { ...state, fetchingTasks: false, tasks: action.tasks };

    case SIGNED_OUT:
      return deepCopy(initialState);

    case UPDATED_SALE: {
      if (state.tasks.sales)
        for (let s = 0; s < state.tasks.sales.length; s++) {
          const sale = state.tasks.sales[s];
          if (sale.id === action.sale.id) {
            state.tasks.sales[s] = action.sale;
            return { ...state, tasks: deepCopy(state.tasks) };
          }
        }
      return { ...state };
    }

    default:
      return state;
  }
};

export default reducer;
