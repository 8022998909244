import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { updateSale } from '../../action/unit';
import { useDispatch } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import DecimalField from '../common/DecimalField';
import * as _ from 'lodash';
import areEqual from '../../util/areEqual';
import { parseNumber } from '../../../../../common/src/util/number';

const { months } = require('../../../../../common/src/util/enum');

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  }
}));

const CalculateFromPrevious = (props) => {
  const classes = useStyles();
  const {
    open,
    onConfirm,
    onClose,
    unitId,
    sale,
    previousSale,
    days
  } = props;

  const [percentage, setPercentage] = useState('0');
  const [mode, setMode] = useState('even');
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const onPercentageChange = ({ target: { value } }, valid) => {
    setPercentage(value);
    setErrors({ percentage: !valid });
  }

  const daysComplete = days && _.every(months, month => _.isNumber(days[month]));

  const _onConfirm = () => {
    const total = months.reduce((accumulator, month) => accumulator + previousSale[month], 0);
    const increase = total + total * (parseNumber(percentage) / 100);
    const mutation = {};
    if (mode === 'even') {
      const average = Math.round(increase / months.length);
      months.forEach(month => mutation[month] = average);
    } else if (mode === 'days') {
      const daysInMonths = months.map(month => days[month] || 0);
      const totalDays = daysInMonths.reduce((a, v) => a + v);
      const share = daysInMonths.map(daysInMonth => daysInMonth / totalDays);
      months.forEach((month, index) => mutation[month] = Math.round(share[index] * increase));
    }
    dispatch(updateSale(unitId, sale, mutation));
    onConfirm();
  }

  useEffect(() => {
    if (open) {
      if (daysComplete) {
        setMode('days');
      }
      setPercentage('0');
    }
  }, [open, sale, previousSale, days]);

  const valid = Object.values(errors).every(value => !value);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Calculate from last year</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <FormLabel component="legend">Mode</FormLabel>
          <RadioGroup value={mode} onChange={event => setMode(event.target.value)}>
            <FormControlLabel value="even" control={<Radio/>} label="Even distribution"/>
            <FormControlLabel value="days" control={<Radio/>} label="Days distribution" disabled={!daysComplete}/>
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.input}>
          <DecimalField label="Percentage"
                        helperText="Percent increase/decrease relative to the previous year"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={percentage}
                        onChange={onPercentageChange}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={_onConfirm} color="primary" disabled={!valid} autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(CalculateFromPrevious, areEqual);
