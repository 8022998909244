import React, { Fragment, useEffect, useState } from 'react';
import * as _ from 'lodash';
import clsx from 'clsx';
import { matchPath } from "react-router";
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserModulesPermissions, fetchUserPermissions, signOut } from '../../action/user';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EuroIcon from '@material-ui/icons/Euro';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import PeopleIcon from '@material-ui/icons/People';
import FunctionsIcon from '@material-ui/icons/Functions';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  item: {
    '&.active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));

const listItems = [{
  title: 'Tasks',
  url: '/tasks',
  activeUrls: ['/', '/tasks'],
  icon: <AssignmentIcon/>
}, {
  title: 'Units',
  url: '/units',
  activeUrls: ['/units', '/unit/:unitId/accounts'],
  icon: <LocationCityIcon/>
}, {
  title: 'Sales',
  url: '/sales',
  activeUrls: ['/sales', '/unit/:unitId/sale'],
  modulePermission: 'sales',
  icon: <ShoppingCartIcon/>
}, {
  title: 'Costs',
  url: '/costs',
  activeUrls: ['/costs', '/unit/:unitId/costs'],
  modulePermission: 'costs',
  icon: <EuroIcon/>
}, {
  title: 'Consolidated',
  url: '/consolidated',
  activeUrls: ['/consolidated', '/unit/:unitId/consolidated', '/consolidated/distributors'],
  icon: <FunctionsIcon/>,
  modulePermission: 'consolidated'
}, {
  title: 'Users',
  url: '/users',
  activeUrls: ['/users'],
  icon: <PeopleIcon/>,
  cfoOnly: true
}, {
  title: 'Settings',
  url: '/system-settings',
  activeUrls: ['/system-settings'],
  icon: <SettingsIcon/>,
  cfoOnly: true
}];

export default function Menu(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const permissions = useSelector(state => state.user.permissions);
  const modulesPermissions = useSelector(state => state.user.modulesPermissions);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    dispatch(fetchUserPermissions());
    dispatch(fetchUserModulesPermissions(user.id));
  }, [dispatch]);

  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);
  const isReader = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.READ);
  const isApprover = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.OWNER);
  const isManager = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.MANAGER);
  const [expandedItems, setExpandedItems] = useState({});

  const getActiveParam = (item) => {
    return !!item.activeUrls.find((url) => {
      return matchPath(pathname, {
        path: url,
        exact: true,
        strict: false
      });
    });
  };

  const checkModulePermission = (item) => {
    if (!item.modulePermission) return true;
    if (item.modulePermission === 'sales') {
      return isCfo || isReader || isApprover || modulesPermissions[item.modulePermission];
    } else if (item.modulePermission === 'consolidated') {
      return isCfo || isManager;
    }
    return isCfo || isManager || isReader || modulesPermissions[item.modulePermission];
  }

  const renderItem = (item, index, parentKey) => {
    if (item.cfoOnly && !isCfo) {
      return null;
    }
    const nested = parentKey !== undefined;
    const key = nested ? `${item.title}-${parentKey}-${index}` : `${item.title}-${index}`;
    const additionalParams = {};
    if (item.activeUrls) {
      additionalParams.isActive = () => getActiveParam(item);
    }
    if (item.url) {
      additionalParams.component = NavLink;
      additionalParams.exact = true;
      additionalParams.to = item.url;
      additionalParams.onClick = () => history.push(item.url);
    } else {
      additionalParams.onClick = () => setExpandedItems({ ...expandedItems, [key]: !expandedItems[key] });
    }
    return (
      <Fragment key={key}>
        <ListItem
          className={clsx(classes.item, nested && classes.nested)}
          button
          {...additionalParams}
        >
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.title}/>
          {item.children && (expandedItems[key] ? <ExpandLessIcon/> : <ExpandMoreIcon/>)}
        </ListItem>
        {
          renderCollapsableItems(item, key)
        }
      </Fragment>
    )
  };

  const renderCollapsableItems = (item, key) => {
    if (item.children) {
      const isExpanded = item.children.some(child => getActiveParam(child));

      return (
        <Collapse in={isExpanded || expandedItems[key]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child, childIndex) => renderItem(child, childIndex, key))}
          </List>
        </Collapse>
      )
    }
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.menuOpen && classes.drawerPaperClose),
      }}
      open={props.menuOpen}>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeftIcon/>
        </IconButton>
      </div>
      <Divider/>
      {
        modulesPermissions && (
          <>
            <List>
              {listItems.filter(checkModulePermission).map((item, index) => renderItem(item, index))}
            </List>
            <Divider/>

            <List>
              <ListItem button onClick={() => dispatch(signOut())}>
                <ListItemIcon>
                  <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Sign out"/>
              </ListItem>
            </List>
          </>
        )
      }
    </Drawer>
  );
}

Menu.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
};

