import React, { memo, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  buttonsGroup: {
    position: 'fixed',
    zIndex: 1999,
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: 4,
    visibility: 'visible'
  },
  text: {
    marginBottom: theme.spacing(2)
  },
  hideElement: {
    visibility: 'hidden'
  },
  arrowLeft: {
    '&::before' : {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      left: -7,
      bottom: 20,
      borderTop: '7px solid transparent',
      borderBottom: '7px solid transparent',
      borderRight: '7px solid white'
    }
}
}));

const ConfirmDeleteButtons = ({ open, onSuccess, onClose, anchorEl }) => {
  const classes = useStyles();
  const elementRef = useRef();
  const [top, setTop] = useState();
  const [left, setLeft] = useState();

  useEffect(() => {
    if (elementRef.current) {
      const positionData = anchorEl.current.getBoundingClientRect();
      setTop(positionData.bottom - elementRef.current.offsetHeight + 8);
      setLeft(positionData.left + anchorEl.current.offsetWidth + 15);
    }
  }, [open, anchorEl]);

  if (!open || (!anchorEl || !anchorEl.current)) return null;

  return (
    <div
      ref={elementRef}
      className={classNames(classes.buttonsGroup, classes.arrowLeft, {
        [classes.hideElement]: !top || !left
      })}
      style={{
        top,
        left
      }}
    >
      <Typography variant="body2" className={classes.text}>Are you sure?</Typography>
      <ButtonGroup
        size="small"
        disableElevation variant="contained"
      >
        <Button color={'primary'} onClick={onSuccess}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </ButtonGroup>
    </div>
  )
};

ConfirmDeleteButtons.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })
}

export default memo(ConfirmDeleteButtons);