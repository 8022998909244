import React, {memo, useEffect, useState} from 'react';
import Text from '../common/text';
import Content from "../common/content";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ClickCircle from '../common/clickCircle';
import StepDots from "../common/stepDots";
import scrollElementIntoView from '../common/scrollElementIntoView';
import sleep from "../common/sleep";
import hasModulePermission from "../../../util/hasModulePermission";

const useStyles = makeStyles(theme => ({
  blurClass: {
    filter: 'blur(2px)'
  },
  disableClick: {
    pointerEvents: 'none',
    zIndex: '1301 !important'
  },
  hide: {
    display: 'none'
  }
}));

const commonParams = {
  contentPosition: {
    top: (top) => top + 40,
    left: (left) => left,
  },
  circlePosition: {
    top: (top) => top + 18,
    left: (left) => left + 70,
  },
};

const lastElementContentPosition = {
  top: (top) => top + 40,
  left: (left) => left - 230,
};

const steps = [{
  dotsActionButton: '.tree-item > div',
  focusElement: '#view-sales-button',
  ...commonParams,
  content: (
    <Text>
      <b>View sales</b> - Link to sales view for that unit.
    </Text>
  )
}, {
  dotsActionButton: '.tree-item > div',
  focusElement: '#sale-assignment-button',
  ...commonParams,
  content: (
    <Text>
      <b>Sale assignments</b> - Here you can assign approvers and contributors.
    </Text>
  )
}, {
  dotsActionButton: '.tree-item > div',
  focusElement: '#view-costs-button',
  contentPosition: {
    top: (top) => top + 40,
    left: (left) => left - 200,
  },
  circlePosition: commonParams.circlePosition,
  content: (
    <Text>
      <b>View costs</b> - View costs Link to costs view for that unit.
    </Text>
  )
}, {
  dotsActionButton: '.tree-item > div',
  focusElement: '#cost-accounts-button',
  contentPosition: {
    top: (top) => top + 40,
    left: (left) => left - 190,
  },
  circlePosition: commonParams.circlePosition,
  content: (
    <Text>
      <b>Cost accounts</b> - Here you can assign contributors for accounts and add or restructure.
    </Text>
  )
}];

const getAvailableSteps = () => {
  const stepsNumber = [];
  steps.forEach((item, index) => {
    const focusElement = document.querySelector(item.focusElement);
    if (focusElement) {
      stepsNumber.push(index);
    }
    else stepsNumber.push(false);
  });
  return stepsNumber;
}

const validItemsFilter = item => typeof item === 'number';

const UnitsTutorial = (props) => {
  const { openTutorial, showContent, setShowContent, children } = props;
  const classes = useStyles();

  const [showClickSimulate, setShowClickSimulate] = useState(false);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [step, setStep] = useState(getAvailableSteps().find(validItemsFilter) || 0);
  const [positions, setPositions] = useState({});
  const rootElement = document.querySelector('#root');
  const focusElement = React.useRef();
  const prevStep = React.useRef(undefined);
  const clonedButton = React.useRef();


  const onKeyDown = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  };

  const removeClonedElements = () => {
    if (clonedButton.current) {
      clonedButton.current.remove();
    }
    const unnecessaryButton = document.querySelector('body > button');

    if (unnecessaryButton) {
      unnecessaryButton.remove();
    }
  };

  const resetElements = () => {
    if (focusElement.current) {
      setShowContent(false);
      showActiveTooltips();
      rootElement.classList.remove(classes.blurClass);
      focusElement.current.classList.remove(classes.disableClick);
      removeClonedElements();
      document.removeEventListener('keydown', onKeyDown);
    }
    openTutorial(false);
  };

  const hideContentBeforeNextStep = () => {
    setShowContent(false);
    rootElement.classList.remove(classes.blurClass);

    if (focusElement.current) {
      focusElement.current.classList.remove(classes.disableClick);
    }
  };

  useEffect(() => {
    setAvailableSteps(getAvailableSteps());
    document.addEventListener('keydown', onKeyDown);

    return () => {
      resetElements();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (prevStep.current !== step) {
        removeClonedElements();
        hideContentBeforeNextStep();
        await handleStep();
      }

      prevStep.current = step;
    })();
  }, [step]);

  const hideActiveTooltips = () => {
    const tooltips = document.querySelectorAll('[role="tooltip"]');

    if (tooltips.length) {
      tooltips.forEach(item => item.classList.add(classes.hide));
    }
  };

  const showActiveTooltips = () => {
    const tooltips = document.querySelectorAll('[role="tooltip"]');

    if (tooltips.length) {
      tooltips.forEach(item => item.classList.remove(classes.hide));
    }
  };

  const handleStep = async () => {
    await sleep(250);
    const menuButton = document.querySelector(steps[step].dotsActionButton);
    const focusElement = document.querySelector(steps[step].focusElement);

    if (menuButton) {
      if (focusElement) {
        await handleFocusElementAction();
      }
      else {
        await handleStepInstructions(menuButton);
      }
      hideActiveTooltips();
    }
    else if (steps[step].prepareStep) {
      await handlePrepareStep()
    }
  };

  const handleStepInstructions = async (menuButton) => {
    await scrollElementIntoView(menuButton);
    const { left, top } = menuButton.getBoundingClientRect();
    setPositions({ top, left });
    await handleClickSimulator();

    await sleep(500);
    menuButton.click();
    rootElement.classList.add(classes.blurClass);

    await handleFocusElementAction();
  };

  const handleFocusElementAction = async () => {
    await sleep(150);
    focusElement.current = document.querySelector(steps[step].focusElement);

    if (focusElement.current) {
      const { left, top } = focusElement.current.getBoundingClientRect();

      setPositions({ top, left });
      rootElement.classList.add(classes.blurClass);
      setShowContent(true);
      focusElement.current.classList.add(classes.disableClick);
      clonedButton.current = focusElement.current.cloneNode(true);
      clonedButton.current.style.cssText = `position: fixed; color: rgba(0,0,0,0.7); border-color: rgba(0,0,0,0.5); background-color: white; z-index: 1301 !important; top: ${top}px; left: ${left}px;`;
      document.querySelector('body').appendChild(clonedButton.current);
    }
  };

  const handlePrepareStep = async () => {
    const clickElement = document.querySelector(steps[step].prepareStep.clickElement);

    if (clickElement) {
      await scrollElementIntoView(clickElement);
      const { left, top } = clickElement.getBoundingClientRect();

      setPositions({ top, left });
      await handleClickSimulator();
      await sleep(250);
      clickElement.click();

      await sleep(250);
      const menuButton = document.querySelector(steps[step].dotsActionButton);
      if (menuButton) {
        await handleStepInstructions(menuButton);
      }
    }
  };

  const handleClickSimulator = async () => {
    setShowClickSimulate(true);
    await sleep(750);
    setShowClickSimulate(false);
  };

  const onSepChange = (step) => {
    const nextStep = availableSteps[step] ? availableSteps[step] : availableSteps.filter(validItemsFilter)[step];
    setStep(nextStep);
  };

  if (!positions.top) return null;

  const stepsLength = availableSteps.filter(validItemsFilter).length;
  const contentPosition = stepsLength <= 2 ? lastElementContentPosition : steps[step].contentPosition;

  return (
    <>
      {
        showClickSimulate && (
          <ClickCircle
            top={steps[step].circlePosition.top(positions.top)}
            left={steps[step].circlePosition.left(positions.left)}
          />
        )
      }
      {
        showContent && (
          <>
            { React.cloneElement(children, { onClick: resetElements }) }
            <Content
              step={step}
              stepsLength={stepsLength}
              top={contentPosition.top(positions.top)}
              left={contentPosition.left(positions.left)}
              onSepChange={onSepChange}
              openTutorial={resetElements}
            >
              { steps[step].content }
            </Content>
            {
              stepsLength > 1 && (
                <StepDots
                  stepsLength={stepsLength}
                  activeStep={availableSteps.filter(validItemsFilter).indexOf(step)}
                  onSepChange={onSepChange}
                />
              )
            }
          </>
        )
      }
    </>
  )
};

export default memo(UnitsTutorial);

