import React, { memo, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { PERMISSION_LEVEL, SALE_STATUS, SALE_TYPE } from '../../../../../common/src/util/enum';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Link from '@material-ui/core/Link';
import { useHistory, useLocation } from 'react-router-dom';
import SaleStatusIcon from '../common/SaleStatusIcon';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import { fetchUser } from '../../action/user';
import { deepCopy } from '../../../../../common/src/util/object';
import Typography from '@material-ui/core/Typography';
import { now, timeAgo } from '../../util/time';
import { currentBudgetYear, formatDate, nextBudgetYear } from '../../../../../common/src/util/date';
import SaleMenu from '../common/SaleMenu';
import UserLink from '../common/UserLink';
import moment from 'moment';
import MessageIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';
import { fetchSaleAssignmentsPerUnit } from '../../action/unit';
import areEqual from '../../util/areEqual';

const {
  DELETED, // eslint-disable-line no-unused-vars
  CONTRIBUTION_REQUESTED,
  APPROVAL_REQUESTED,
  APPROVED,
  FINAL, // eslint-disable-line no-unused-vars
} = SALE_STATUS;

const useStyles = makeStyles(theme => ({
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
  },
  iconColumn: {
    padding: 0,
    paddingTop: 5,
    margin: 0,
    width: 20,
  },
  rowDivider: {
    borderTop: `4px solid ${theme.palette.background.default}`,
  },
  countColumn: {
    textAlign: 'right',
  },
  expandColumn: {
    width: 20,
  },
  nameColumn: {
    whiteSpace: 'nowrap',
  },
  unitColumn: {
    whiteSpace: 'nowrap',
  },
  actionColumn: {
    whiteSpace: 'nowrap',
  },
  caption: {},
  highlighted: {
    backgroundColor: theme.palette.background.default,
  },
  menuColumn: {
    padding: 0,
    margin: 0,
    width: 30,
  },
  dueSoon: {
    color: theme.palette.secondary.main,
  },
}));

const evaluateTasksGroups = (userId, sales) => {
  const tasks = [];

  /**
   * Assigned to me
   */

  const contributionRequested = sales.filter(sale =>
    sale.contributorId === userId &&
    sale.saleStatusId === CONTRIBUTION_REQUESTED);
  if (contributionRequested.length)
    tasks.push({
      action: 'Waiting for your contribution',
      count: contributionRequested.length,
      sales: contributionRequested,
    });

  const approvalRequested = sales.filter(sale =>
    sale.approverId === userId &&
    sale.saleStatusId === APPROVAL_REQUESTED);
  if (approvalRequested.length)
    tasks.push({
      action: 'Waiting for your approval',
      count: approvalRequested.length,
      sales: approvalRequested,
    });

  const finaApprovalRequested = sales.filter(sale =>
    sale.permission.level === PERMISSION_LEVEL.CFO &&
    sale.saleStatusId === APPROVED);
  if (finaApprovalRequested.length)
    tasks.push({
      action: 'Waiting for your final approval',
      count: finaApprovalRequested.length,
      sales: finaApprovalRequested,
    });

  /**
   * Waiting for others to complete
   */

  const waitingForContribution = sales.filter(sale =>
    (sale.approverId === userId || sale.metadata?.approverIds?.includes(userId)) &&
    sale.saleStatusId === CONTRIBUTION_REQUESTED &&
    sale.contributorId !== userId);
  if (waitingForContribution.length)
    tasks.push({
      action: `Waiting for someone's contribution`,
      actionDetailed: `Waiting for contribution from :contributor:`,
      count: waitingForContribution.length,
      sales: waitingForContribution,
    });

  const waitingForApproval = sales.filter(sale =>
    sale.permission.level === PERMISSION_LEVEL.CFO &&
    sale.saleStatusId === APPROVAL_REQUESTED &&
    sale.approverId !== userId);
  if (waitingForApproval.length)
    tasks.push({
      action: `Waiting for someone's approval`,
      actionDetailed: `Waiting for approval from :approver:`,
      count: waitingForApproval.length,
      sales: waitingForApproval,
    });

  /**
   * Completed by me
   */

  const contributionCompleted = sales.filter(sale =>
    sale.contributorId === userId &&
    sale.saleStatusId !== CONTRIBUTION_REQUESTED);
  if (contributionCompleted.length)
    tasks.push({
      action: 'Your completed contributions',
      actionDetailed: `Completed contribution by you`,
      count: contributionCompleted.length,
      sales: contributionCompleted,
    });

  const approvalCompleted = sales.filter(sale =>
    (sale.approverId === userId || sale.metadata?.approverIds?.includes(userId)) &&
    ![CONTRIBUTION_REQUESTED, APPROVAL_REQUESTED].includes(sale.saleStatusId));
  if (approvalCompleted.length)
    tasks.push({
      action: 'Your completed approvals',
      actionDetailed: `Completed approval by you`,
      count: approvalCompleted.length,
      sales: approvalCompleted,
    });

  const finaApprovalCompleted = sales.filter(sale =>
    sale.permission.level === PERMISSION_LEVEL.CFO &&
    sale.saleStatusId === FINAL);
  if (finaApprovalCompleted.length)
    tasks.push({
      action: 'Your completed final approvals',
      actionDetailed: `Completed final approval by you`,
      count: finaApprovalCompleted.length,
      sales: finaApprovalCompleted,
    });

  return tasks
};

const Sales = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const highlightSalesIds = location?.state?.highlightSalesIds || [];

  const dispatch = useDispatch();
  const { tasks, fetchingTasks } = useSelector(state => state.task);
  const { user, userById } = useSelector(state => state.user);

  const [expanded, setExpanded] = useState(true);
  const [expandedTasksGroups, setExpandedTasksGroups] = useState(new Set());

  const { sales: tasksSales, units } = tasks;
  const nextYear = nextBudgetYear();
  const currentYear = currentBudgetYear();

  let loader, content;

  useEffect(() => {
    if (tasksSales)
      for (const sale of tasksSales) {
        const { approverId, contributorId } = sale;

        if (!userById[approverId])
          dispatch(fetchUser(approverId));

        if (!userById[contributorId])
          dispatch(fetchUser(contributorId));
      }
  }, [tasksSales]); // eslint-disable-line

  let tasksGroups = useMemo(() => {
    if (!fetchingTasks && user && tasksSales && tasksSales.length)
      return evaluateTasksGroups(
        user.id,
        deepCopy(tasksSales)
          .filter(({ year, saleTypeId }) => year > currentYear && saleTypeId === SALE_TYPE.AMOUNT_BUDGET)
          .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)),
      );
    return [];
  }, [fetchingTasks, user, tasksSales, nextYear]);

  useEffect(() => {

    if (!tasksGroups || !highlightSalesIds?.length)
      return;

    const expandedTasksGroups = new Set();
    tasksGroups.forEach((taskGroup, t) => {
      const { sales } = taskGroup;
      if (sales.some(sale => highlightSalesIds?.includes(sale.id))) {
        expandedTasksGroups.add(t);
        setExpanded(true);
      }
    });

    setExpandedTasksGroups(expandedTasksGroups);

  }, [highlightSalesIds, tasksGroups]);

  useEffect(() => {
    dispatch(fetchSaleAssignmentsPerUnit());
  }, []);

  if (fetchingTasks || !user) {
    loader = <CircularProgress size={16}/>;
  } else if (!tasksSales || tasksSales.length === 0 || tasksGroups.length === 0) {
    content = 'You are not assigned to any tasks.';
  } else {

    const unitById = {};
    units.forEach(unit => unitById[unit.id] = unitById[unit.id] || unit);


    const expandTaskGroup = taskGroup => event => {
      event.preventDefault();
      event.stopPropagation();
      if (!expandedTasksGroups.delete(taskGroup))
        expandedTasksGroups.add(taskGroup);
      setExpandedTasksGroups(new Set(expandedTasksGroups));
    };

    content = (
      <div className={classes.table}>
        <Table>
          <TableBody>
            {
              tasksGroups.map((taskGroup, t) => {

                  const rows = [];

                  const { action, actionDetailed, count, sales } = taskGroup;

                  const isExpanded = expandedTasksGroups.has(t);
                  const expand = (
                    <IconButton
                      id={'expand-sales-button'} // required for tutorial
                      className={clsx({
                        'expanded': isExpanded,
                      })}
                      size="small"
                    >
                      {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  );

                  const divider = clsx(t !== 0 && classes.rowDivider);

                  rows.push(
                    <TableRow key={`${t}-row`} onClick={expandTaskGroup(t)} style={{ cursor: 'pointer' }}>
                      <TableCell className={divider} colSpan={5}>{action}</TableCell>
                      <TableCell className={clsx(classes.countColumn, divider)}>{count}</TableCell>
                      <TableCell align="left" className={clsx(classes.expandColumn, divider)}>{expand}</TableCell>
                    </TableRow>,
                  );

                  if (isExpanded) {

                    rows.push(
                      ...sales.map(sale => {

                        const unit = unitById[sale.unitId];
                        if (!unit)
                          return null;

                        const { id, contributorId, approverId, metadata } = sale;
                        const { messages, history: versions } = metadata;
                        const contributor = userById[contributorId];
                        const approver = userById[approverId];

                        let evaluatedAction = actionDetailed || action || '';

                        const contributorMatch = evaluatedAction.match(/(.*):contributor:(.*)/);
                        const approverMatch = evaluatedAction.match(/(.*):approver:(.*)/);

                        let hasMessage = false;
                        let message;
                        const lastMessageChange = _.findLast(versions, version =>
                          !!version?.delta?.saleStatusId ||
                          !!version?.delta?.approverId ||
                          version?.delta?.contributorId);
                        if (lastMessageChange) {
                          message = messages.find(m => m.at === lastMessageChange.at);
                          hasMessage = !!message;
                        }

                        if (contributorMatch)
                          evaluatedAction = (
                            <span>
                              {contributorMatch[1] || ''}
                              <UserLink user={contributor}/>
                              {contributorMatch[2] || ''}
                            </span>
                          );
                        else if (approverMatch)
                          evaluatedAction = (
                            <span>
                              {approverMatch[1] || ''}
                              <UserLink user={approver}/>
                              {approverMatch[2] || ''}
                            </span>
                          );

                        const isHighlighted = highlightSalesIds?.includes(id);

                        const navigateToSale = () => history.push({
                          pathname: `/unit/${unit.id}/sale`,
                          state: {
                            highlightUnitsIds: [unit.id],
                            highlightSalesIds: [sale.id],
                          },
                        });

                        let due;
                        if (sale.due) {
                          const isDueSoon = moment(sale.due).diff(now(), 'days') <= 1;
                          const isFinal = sale.saleStatusId === FINAL;
                          due = <span className={clsx(isDueSoon && !isFinal && classes.dueSoon)}>{` Due ${formatDate(sale.due)}`}.</span>;
                        }

                        return (
                          <TableRow key={`${t}-row-${id}`} className={clsx(isHighlighted && classes.highlighted)}>
                            <TableCell className={classes.menuColumn}>
                              <SaleMenu sale={sale} unitId={unit.id} validValues={true} expandMessages={hasMessage}/>
                            </TableCell>
                            <TableCell className={classes.unitColumn}>
                              <Link className={classes.link} onClick={navigateToSale}>{unit.name}</Link>
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              <SaleStatusIcon user={user} sale={sale} unit={unit}/>
                            </TableCell>
                            <TableCell className={classes.nameColumn}>
                              <Link className={classes.link} onClick={navigateToSale}>{sale.name}</Link>
                            </TableCell>
                            <TableCell className={classes.actionColumn} colSpan={2}>
                              {evaluatedAction}.<br/>
                              <Typography variant="caption" component="div" className={classes.caption}>
                                Updated {timeAgo(sale.updatedAt)}. {due}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              {
                                hasMessage &&
                                <Tooltip title={<>User left a message below. See details for more information.<br/><br/>{message.message}</>}>
                                  <IconButton>
                                    <MessageIcon color="secondary" fontSize="small"/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    );
                  }

                  return rows;
                },
              )
            }
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <>
      <Accordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon id={'expand-more-sales'}/>}>
          <Title>Sales Tasks {loader}</Title>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default memo(Sales, areEqual);
