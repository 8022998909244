import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Link from '@material-ui/core/Link';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { now, timeAgo } from '../../util/time';
import { formatDate } from '../../../../../common/src/util/date';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import moment from 'moment';
import MessageIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';
import UserLink from '../common/UserLink';
import { fetchUser, fetchUserPermissions } from '../../action/user';

const useStyles = makeStyles(theme => ({
  table: {
    overflow: 'auto',
    width: '100%',
  },
  link: {
    cursor: 'pointer',
  },
  iconColumn: {
    padding: 0,
    paddingTop: 5,
    margin: 0,
    width: 20,
  },
  rowDivider: {
    borderTop: `4px solid ${theme.palette.background.default}`,
  },
  countColumn: {
    textAlign: 'right',
  },
  expandColumn: {
    width: 20,
  },
  unitColumn: {
    whiteSpace: 'nowrap',
  },
  actionColumn: {
    whiteSpace: 'nowrap',
  },
  menuColumn: {
    padding: 0,
    margin: 0,
    width: 30,
  },
  dueSoon: {
    color: theme.palette.secondary.main,
  },
  highlighted: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function Budget() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { tasks, fetchingTasks } = useSelector(state => state.task);
  const { user, userById: usersById, permissions } = useSelector(state => state.user);
  const { initiations: tasksBudget, units } = tasks;

  const [expanded, setExpanded] = useState(true);
  const [expandedTasksGroups, setExpandedTasksGroups] = useState(new Set());
  const highlightUnitsIds = location?.state?.highlightUnitsIds || [];

  useEffect(() => {
    if (tasksBudget?.length) {
      for (const task of tasksBudget) {
        if (!usersById[task.assigneeId]) {
          dispatch(fetchUser(task.assigneeId));
        }
      }
    }
  }, [tasks]);

  useEffect(() => {
    dispatch(fetchUserPermissions());
  }, [dispatch]);


  const taskGroups = [];

  const initiationRequested = tasksBudget?.filter(task => !task.completed && task.assigneeId === user?.id);
  if (initiationRequested?.length) {
    taskGroups.push({
      action: 'Waiting for your budget initiation',
      tasks: initiationRequested,
    });
  }

  const waitingForInitiation = tasksBudget?.filter(task => !task.completed && task.assignerId === user?.id);
  if (waitingForInitiation?.length) {
    taskGroups.push({
      action: 'Waiting for initiation from someone else',
      tasks: waitingForInitiation,
    });
  }

  const initiationCompleted = tasksBudget?.filter(task => task.completed);
  if (initiationCompleted?.length) {
    taskGroups.push({
      action: 'Initiation completed',
      tasks: initiationCompleted,
    });
  }


  useEffect(() => {

    if (!tasksBudget || !highlightUnitsIds?.length)
      return;

    const expandedTasksGroups = new Set();
    taskGroups.forEach((taskGroup, t) => {
      if (highlightUnitsIds?.some(unitId => taskGroup?.tasks?.some(({ unitId: otherUnitId }) => unitId === otherUnitId))) {
        expandedTasksGroups.add(t);
        setExpanded(true);
      }
    });

    setExpandedTasksGroups(expandedTasksGroups);

  }, [highlightUnitsIds, tasksBudget]);

  let loader, content;
  if (fetchingTasks || !user) {
    loader = <CircularProgress size={16}/>;
  } else if (!tasksBudget || tasksBudget.length === 0) {
    const isContributorOnly = permissions?.every(({ level }) => level <= PERMISSION_LEVEL.WRITE);
    if (isContributorOnly)
      return null;
    content = 'You are not assigned to any tasks.';
  } else {
    const unitsById = _.keyBy(units, unit => unit.id);

    const expandTaskGroup = taskGroup => event => {
      event.preventDefault();
      event.stopPropagation();
      if (!expandedTasksGroups.delete(taskGroup))
        expandedTasksGroups.add(taskGroup);
      setExpandedTasksGroups(new Set(expandedTasksGroups));
    };

    content = (
      <div className={classes.table}>
        <Table>
          <TableBody>
            {
              taskGroups.map((taskGroup, t) => {
                  const rows = [];
                  const { action, tasks } = taskGroup;
                  const isExpanded = expandedTasksGroups.has(t);
                  const expand = (
                    <IconButton size="small">
                      {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  );

                  const divider = clsx(t !== 0 && classes.rowDivider);

                  rows.push(
                    <TableRow
                      key={`${t}-row`} onClick={expandTaskGroup(t)} style={{ cursor: 'pointer' }}>
                      <TableCell className={divider} colSpan={5}>{action}</TableCell>
                      <TableCell className={clsx(classes.countColumn, divider)}>{tasks.length}</TableCell>
                      <TableCell align="left" className={clsx(classes.expandColumn, divider)}>{expand}</TableCell>
                    </TableRow>,
                  );

                  if (isExpanded) {
                    rows.push(
                      ...tasks.map(task => {
                        const unit = unitsById[task.unitId];
                        const { id } = task;
                        const assignee = usersById[task.assigneeId];
                        const hasMessage = !!task.message && !task.completed && user?.id === task.assigneeId;

                        const isHighlighted = highlightUnitsIds?.includes(task.unitId);

                        const navigateToUnit = () => history.push({ pathname: `/unit/${unit?.id}/sale` });

                        let due;
                        if (task.due) {
                          const isDueSoon = moment(task.due).diff(now(), 'days') <= 1;
                          due = (
                            <span className={clsx(isDueSoon && classes.dueSoon)}>
                              {` Due ${formatDate(task.due)}`}.
                            </span>
                          );
                        }

                        const action = user?.id === task.assignerId ?
                          (task.completed ?
                              <>Initiated by <UserLink user={assignee}/>.</> :
                              <>Waiting for initiation by <UserLink user={assignee}/>.</>
                          ) : (task.completed ?
                              <>Initiated by you.</> :
                              <>Waiting for your initiation.</>
                          );

                        return (
                          <TableRow key={`${t}-row-${id}`} className={clsx(isHighlighted && classes.highlighted)}>
                            <TableCell className={classes.menuColumn}>
                            </TableCell>
                            <TableCell className={classes.unitColumn}>
                              <Link className={classes.link} onClick={navigateToUnit}>{unit.name}</Link>
                            </TableCell>
                            <TableCell className={classes.actionColumn} colSpan={4}>
                              <span>{action}</span>
                              <br/>
                              <Typography variant="caption" component="div" className={classes.caption}>
                                Updated {timeAgo(task.updatedAt)}. {due}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              {
                                hasMessage &&
                                <Tooltip title={
                                  <>User left a message below. See details for more information.
                                    <br/><br/>
                                    {task.message}
                                  </>
                                }>
                                  <IconButton>
                                    <MessageIcon color="secondary" fontSize="small"/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    );
                  }
                  return rows;
                },
              )
            }
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <>
      <Accordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Title>Budget Initiation Tasks {loader}</Title>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
