const { months, SALE_TYPE, SALE_STATUS } = require('../../../../common/src/util/enum');
const { generateTotals, evaluateYear } = require('../../../../common/src/util/sale');
const { deepCopy } = require('../../../../common/src/util/object');

// [[sales grouped by name and sorted by year descending]]
const groupSales = s => {
  if (s.length === 0)
    return [];
  const sales = deepCopy(s);

  const removeDaysFromCalculatingYears = (item) => {
    return item.name !== 'Days';
  };
  const maxYear = evaluateYear(sales.filter(removeDaysFromCalculatingYears), Math.max);
  const minYear = evaluateYear(sales.filter(removeDaysFromCalculatingYears), Math.min);


  let salesGroups = sales.reduce((rv, sale) => {
    if (sale.saleTypeId === SALE_TYPE.DAYS)
      (rv.days = rv.days || []).push(sale);
    else if (sale.saleTypeId === SALE_TYPE.AMOUNT || sale.saleTypeId === SALE_TYPE.AMOUNT_BUDGET)
      (rv[sale.name] = rv[sale.name] || []).push(sale);
    return rv;
  }, {});

  // Sort by year in descending order
  salesGroups = Object.keys(salesGroups).map(name => salesGroups[name]
    .sort((s1, s2) => {
      if (s1.saleTypeId === SALE_TYPE.AMOUNT_BUDGET && s2.saleTypeId !== SALE_TYPE.AMOUNT_BUDGET)
        return -1;
      if (s2.saleTypeId === SALE_TYPE.AMOUNT_BUDGET && s1.saleTypeId !== SALE_TYPE.AMOUNT_BUDGET)
        return 1;
      return s2.year - s1.year
    }));

  // Insert missing max year
  // Object.keys(salesGroups).forEach(name => {
  //   const containsMaxYear = salesGroups[name].some(sale => sale.year === maxYear);
  //   if (!containsMaxYear) {
  //     const sale = deepCopy(salesGroups[name][0]);
  //     sale.year = maxYear;
  //     sale.empty = true;
  //     sale.id = `empty.${sale.id}`;
  //     months.forEach(month => sale[month] = null);
  //     salesGroups[name].unshift(sale);
  //   }
  // });

  // Sort by days first, then by district, then by name
  salesGroups = salesGroups.sort((s1g, s2g) => {
    // By DAYS sales type
    if (s1g[0].saleTypeId === SALE_TYPE.DAYS)
      return -1;
    if (s2g[0].saleTypeId === SALE_TYPE.DAYS)
      return 1;

    // By district
    const district1 = s1g[0].district || '';
    const district2 = s2g[0].district || '';
    const districtCompare = district1.localeCompare(district2);
    if (districtCompare !== 0)
      return districtCompare;

    // By name
    return s1g[0].name.localeCompare(s2g[0].name)
  });

  // Calculate total
  // Make total row if Budget exists in sales
  const totals = [];
  if (sales.some(item => item.saleTypeId === SALE_TYPE.AMOUNT_BUDGET)) {
    totals.push(...generateTotals({ maxYear, minYear, sales, saleTypeId: SALE_TYPE.AMOUNT_BUDGET }));
  }
  // totals.push(...generateTotals({ maxYear, minYear, sales, saleTypeId: SALE_TYPE.AMOUNT_BUDGET }));
  // Make total row if Outcome exists in sales
  if (sales.some(item => item.saleTypeId === SALE_TYPE.AMOUNT)) {
    totals.push(...generateTotals({ maxYear, minYear, sales, saleTypeId: SALE_TYPE.AMOUNT }));
  }
  salesGroups.push(totals);

  const removeEmptyArrays = (list) => list.length;

  return salesGroups.filter(removeEmptyArrays);
};

const getSalesForChart = (saleId, allSales) => {
  const selectedSale = allSales.find(sale => sale.id === saleId);
  const { name, unitId } = selectedSale;
  let selectedOutcomes = allSales.filter(sale => sale.name === name && sale.unitId === unitId && sale.saleTypeId === SALE_TYPE.AMOUNT);
  const selectedBudgets = allSales.filter(sale => sale.name === name && sale.unitId === unitId && sale.saleTypeId === SALE_TYPE.AMOUNT_BUDGET);

  return { selectedBudgets, selectedOutcomes };
}

const evaluateMaxYear = sales => evaluateYear(sales, Math.max);
const evaluateMinYear = sales => evaluateYear(sales, Math.min);

export {
  evaluateMaxYear,
  evaluateMinYear,
  groupSales,
  getSalesForChart,
}
