import React, { useState } from 'react';
import { AccordionSummary, Grid, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import { Months, months } from '../../../../../common/src/util/enum';
import summarize from '../../../../../common/src/util/summarize';
import { formatNumber } from '../../../../../common/src/util/number';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  yearExpanderColumn: {
    maxWidth: 30,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  idColumn: {
    width: 60
  },
  rowHighlighted: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  error: {
    background: `${theme.palette.error.light}`
  }
}));

export default function ConsolidatedCosts({ costs }) {
  const classes = useStyles();
  const history = useHistory();

  const [expanded, setExpanded] = useState({});
  const toggleExpanded = id => setExpanded({ ...expanded, [id]: !expanded[id] });
  const isExpanded = id => !!expanded[id];

  _.chain(costs)
    .groupBy(cost => cost.id)
    .values()
    .forEach(group => group[0].first = true)
    .value();

  const showTotals = true;

  return (
    <Grid item xs={12}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div className={classes.accordionSummary}>
            <Title>Costs</Title>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.headColumn, classes.idColumn)}>Group</TableCell>
                  <TableCell className={classes.headColumn}>Name</TableCell>
                  <TableCell className={classes.headColumn}>Year</TableCell>
                  <TableCell className={classes.headColumn}>&nbsp;</TableCell>
                  {Months.map(month => (
                    <TableCell key={`head-${month}`} align="right" className={classes.headColumn}>
                      {month}
                    </TableCell>
                  ))}
                  <TableCell align="right" className={classes.headColumn}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costs.map(cost => {
                  if (!cost.first && !isExpanded(cost.id)) {
                    return null;
                  }
                  const rowDivider = cost.first ? classes.rowDivider : null;
                  return (
                    <TableRow key={`row-${cost.name}-${cost.year}-${cost.type}-${cost.id}`}
                              className={clsx(classes.tableRow, { [classes.error]: cost.error })}
                              onClick={() => history.push(cost.unitId ? `/unit/${cost.unitId}/costs` : '/costs')}>
                      <TableCell align="right" className={clsx(rowDivider, classes.idColumn)}>
                        {cost.first && cost.id}
                      </TableCell>
                      <TableCell className={rowDivider}>
                        {cost.first && cost.name}
                      </TableCell>
                      <TableCell className={rowDivider}>
                        {cost.year} {cost.type === 'budget' ? 'Budget' : 'Outcome'}
                      </TableCell>
                      <TableCell className={clsx(rowDivider, classes.yearExpanderColumn)}
                                 onClick={e => e.stopPropagation()}>
                        {cost.first &&
                        <IconButton size="small"
                                    onClick={() => toggleExpanded(cost.id)}
                                    disabled={false}>
                          {isExpanded(cost.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                        }
                      </TableCell>
                      {!cost.error ?
                        months.map(month => (
                          <TableCell key={`cell-${cost.name}-${month}`} align="right" className={rowDivider}>
                            {formatNumber(cost[month])}
                          </TableCell>
                        )) : (
                          <TableCell colSpan={12} align="center" className={rowDivider}>
                            {cost.error}
                          </TableCell>
                        )
                      }
                      <TableCell align="right" className={rowDivider}>
                        {formatNumber(summarize(cost))}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {showTotals &&
                <TableRow className={classes.rowHighlighted}>
                  <TableCell className={classes.rowDivider}>&nbsp;</TableCell>
                  <TableCell className={classes.rowDivider}>&nbsp;</TableCell>
                  <TableCell className={classes.rowDivider}>Total</TableCell>
                  <TableCell className={classes.rowDivider}>&nbsp;</TableCell>
                  {months.map(month => (
                    <TableCell key={`total-${month}`} align="right" className={classes.rowDivider}>
                      {formatNumber(summarize(costs.filter(cost => cost.first), month))}
                    </TableCell>
                  ))}
                  <TableCell align="right" className={classes.rowDivider}>
                    {formatNumber(summarize(costs.filter(cost => cost.first)))}
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ConsolidatedCosts.propTypes = {
  costs: PropTypes.array.isRequired,
};
