import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import UserSearch from '../common/UserSearch';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 300,
    margin: theme.spacing(1),
  }
}));

export default function AccountAssignmentModal(
  {
    open,
    onCancel,
    onConfirm,
    title,
    unitId,
  }) {

  const classes = useStyles();
  const users = useSelector(state => state.unit.fetchedUnitUsers[unitId]);
  const loadingUsers = useSelector(state => state.unit.fetchingUnitUsers);
  const [user, setUser] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <UserSearch label="Assigned contributor"
                      loading={loadingUsers}
                      setUser={setUser}
                      user={user}
                      users={users}/>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!user}
                onClick={() => {
                  onConfirm(user);
                }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountAssignmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  unitId: PropTypes.number.isRequired
};
