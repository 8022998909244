import React, { useRef } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { parseNumber } from '../../../../../common/src/util/number';
import { triggerInputChange } from '../../util/dom';

export default function DecimalField(props) {
  const input = useRef();

  const onChange = event => {
    const value = event.target.value;
    const clean = value === '' || /^-?\d*([.,]\d*)?$/g.test(value);
    if (!clean) {
      event.preventDefault();
      return;
    }
    const valid = /^-?\d+([.,]?\d+)?$/g.test(value);
    if (valid && !value.endsWith('0')) {
      let numeric = parseNumber(value);
      if (props.min || props.max) {
        numeric = _.clamp(numeric, props.min, props.max);
      }
      event.target.value = _.toString(numeric);
    }
    props.onChange(event, valid);
  };
  const onBlur = () => {
    const value = input.current.value;
    if (value.endsWith('0')) {
      const numeric = parseNumber(value);
      if (!isNaN(numeric)) {
        triggerInputChange(input.current, _.toString(numeric));
      }
    }
  };

  return (
    <TextField inputRef={input}
               label={props.label}
               helperText={props.helperText}
               InputProps={props.InputProps}
               value={props.value}
               disabled={props.disabled}
               onChange={onChange}
               onBlur={onBlur}
               error={props.error}/>
  )
}

DecimalField.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.bool
};
