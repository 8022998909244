import config from '../config';
import axios from 'axios';
import { handleAxiosError } from './error';

export const SIGNING_IN = 'SIGNING_IN';
export const SIGNED_IN = 'SIGNED_IN';
export const SIGNING_OUT = 'SIGNING_OUT';
export const SIGNED_OUT = 'SIGNED_OUT';
export const FETCHED_USER = 'FETCHED_USER';
export const FETCHED_USER_PERMISSIONS = 'FETCHED_USER_PERMISSIONS';
export const FETCHED_ALL_USERS_PERMISSIONS = 'FETCHED_ALL_USERS_PERMISSIONS';
export const DELETED_PERMISSION = 'DELETED_PERMISSION';
export const SAVED_PERMISSION = 'SAVED_PERMISSION';
export const ADDED_USER = 'ADDED_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const FETCHED_USER_MODULES_PERMISSIONS = 'FETCHED_USER_MODULES_PERMISSIONS';

const { budgetApiUrl } = config;

const deleteCookie = (sKey, sPath, sDomain) => {
  document.cookie = encodeURIComponent(sKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '');
};

export const signingIn = () => ({
  type: SIGNING_IN,
});

export const signedIn = user => ({
  type: SIGNED_IN,
  user,
});

export const signIn = (username, password) => async dispatch => {
  dispatch(signingIn());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/user/sign-in`,
      method: 'POST',
      data: { username, password },
      withCredentials: true,
    });
    dispatch(signedIn(res.data.user));
  } catch (_) {
    return dispatch(signedOut('Invalid credentials'));
  }
};

export const signInWithToken = token => async dispatch => {
  dispatch(signingIn());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/user/sign-in`,
      method: 'POST',
      data: { token },
      withCredentials: true,
    });
    dispatch(signedIn(res.data.user));
  } catch (_) {
    return dispatch(signedOut('Invalid or expired token'));
  }
};

export const signInWithEmail = username => async dispatch => {
  dispatch(signingIn());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/user/sign-in-email`,
      method: 'POST',
      data: { username },
      withCredentials: true,
    });
    return dispatch(signedOut(res.data.message || 'A sign in link has been sent to your email'));
  } catch (e) {
    return dispatch(signedOut(e.response?.data?.error || 'Invalid credentials'));
  }
};


export const signingOut = () => {
  deleteCookie('session-local');
  return {
    type: SIGNING_OUT,
  }
};

export const signedOut = (signInError = undefined) => ({
  type: SIGNED_OUT,
  signInError,
});

export const signOut = () => async dispatch => {
  dispatch(signingOut());
  await axios({
    url: `${budgetApiUrl}/user/sign-out`,
    method: 'POST',
    withCredentials: true,
  });
  return dispatch(signedOut());
};

export const checkSignIn = () => async dispatch => {
  dispatch(signingIn());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/user/sign-in`,
      method: 'GET',
      withCredentials: true,
    });

    dispatch(signedIn(res.data.user));
  } catch (_) {
    return dispatch(signedOut());
  }
};

export const fetchedUser = user => ({
  type: FETCHED_USER,
  user,
});

export const fetchedUserPermissions = permissions => ({ type: FETCHED_USER_PERMISSIONS, permissions });
export const fetchedUserModulesPermissions = modulesPermissions => ({ type: FETCHED_USER_MODULES_PERMISSIONS, modulesPermissions });

const fetchingUserById = {};
export const fetchUser = userId => async dispatch => {

  if (fetchingUserById[userId])
    return;
  fetchingUserById[userId] = true;

  try {
    const res = await axios({
      url: `${budgetApiUrl}/user/${userId}`,
      method: 'GET',
      withCredentials: true,
    });

    dispatch(fetchedUser(res.data.user));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }

  fetchingUserById[userId] = false;
};

export const fetchUserPermissions = () => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/user/sign-in/permissions`,
      withCredentials: true,
    });
    dispatch(fetchedUserPermissions(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const fetchUserModulesPermissions = (userId) => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/user/${userId}/modules/permissions`,
      withCredentials: true,
    });
    dispatch(fetchedUserModulesPermissions(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const fetchedAllUsersPermissions = (users, externalUsers, units) => ({
  type: FETCHED_ALL_USERS_PERMISSIONS,
  users,
  externalUsers,
  units,
});

export const fetchAllUsersPermissions = () => async dispatch => {
  try {
    const res = await axios({
      method: 'get',
      url: `${budgetApiUrl}/user`,
      withCredentials: true,
    });
    const { users, externalUsers, units } = res.data;
    dispatch(fetchedAllUsersPermissions(users, externalUsers, units));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const deletedPermission = permissionId => ({
  type: DELETED_PERMISSION,
  permissionId,
});

export const deletePermission = permissionId => async dispatch => {
  try {
    await axios({
      method: 'delete',
      url: `${budgetApiUrl}/user/permission/${permissionId}`,
      withCredentials: true,
    });
    dispatch(deletedPermission(permissionId));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const savedPermission = permission => ({
  type: SAVED_PERMISSION,
  permission,
});

export const savePermission = permission => async dispatch => {
  try {
    const res = await axios({
      method: 'post',
      url: `${budgetApiUrl}/user/permission`,
      withCredentials: true,
      data: permission,
    });
    dispatch(savedPermission(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const addedUser = user => ({
  type: ADDED_USER,
  user,
});

export const updatedUser = user => ({
  type: UPDATED_USER,
  user,
})

export const addUser = user => async dispatch => {
  try {
    const res = await axios({
      method: 'post',
      url: `${budgetApiUrl}/user`,
      withCredentials: true,
      data: user,
    });
    dispatch(addedUser(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const updateUser = user => async dispatch => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${budgetApiUrl}/user/update`,
      withCredentials: true,
      data: user,
    });
    dispatch(updatedUser(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
}


