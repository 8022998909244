import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { matchPath, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from './Avatar';
import TutorialIcon from '@material-ui/icons/Help'
import PropTypes from 'prop-types';
import Notifications from '../notifications';
import BreadCrumbs from '../breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnits } from '../../action/unit';
import { updateUser } from '../../action/user';
import Tutorial from '../tutorial';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import areEqual from '../../util/areEqual';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import * as _ from 'lodash';
import hasModulePermission from '../../util/hasModulePermission';
import Status from './Status';
import YearSwitcher from '../yearSwitcher';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  breadCrumbs: {
    flexGrow: 1,
  },
  tutorialIcon: {
    color: 'white',
  },
  disabledButton: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  appBarSpacer: theme.mixins.toolbar,
}));

const allowedTutorialPaths = [
  {
    name: 'costs',
    path: ['/unit/:unitId/costs'],
    requiredElementSelector: ['#unit-id', '#costs-menu-button'],
  },
  {
    name: 'sales',
    path: ['/unit/:unitId/sale'],
  },
  {
    name: 'tasks',
    path: ['/', '/tasks'],
  },
  {
    name: 'units',
    path: ['/units'],
  },
  {
    name: 'users',
    path: ['/users'],
  },
];

const evaluateBreadCrumbsList = ({ pathname, units }) => {
  const tokens = pathname.split('/').filter(Boolean);
  let list = [{ title: 'Budget Tool', to: '/' }];

  switch (tokens[0]) {

    case 'tasks':
      list.push({ title: 'Tasks', to: '/tasks' });
      break;

    case 'units':
      list.push({ title: 'Units', to: '/units' });
      break;

    case 'sales':
      list.push({ title: 'Sales', to: '/sales' });
      break;

    case 'costs':
      list.push({ title: 'Costs', to: '/costs' });
      break;

    case 'consolidated':
      list.push({ title: 'Consolidated', to: '/consolidated' });
      if (tokens[1] === 'distributors')
        list.push({ title: 'Distributors', to: '/consolidated/distributors' });
      if (tokens[1] === 'grouped')
        list.push({ title: 'Group Summary', to: '/consolidated/grouped' });
      break;

    case 'unit':
      const unitId = parseInt(tokens[1]);
      const type = tokens[2];
      const isSales = type === 'sale';
      const isCosts = type === 'costs';
      const isAccounts = type === 'accounts';
      const isConsolidated = type === 'consolidated';

      if (units && unitId && (isSales || isCosts || isAccounts || isConsolidated)) {

        if (isSales)
          list.push({ title: 'Sales', to: '/sales' });
        if (isCosts)
          list.push({ title: 'Costs', to: '/costs' });
        if (isAccounts)
          list.push({ title: 'Accounts', to: '/units' });
        if (isConsolidated)
          list.push({ title: 'Consolidated', to: '/consolidated' });


        const idx = list.length;

        let currentUnit = units.find(unit => unit.id === unitId);
        while (currentUnit) {
          list.splice(idx, 0, {
            title: currentUnit.name,
            to: `/unit/${currentUnit.id}/${type}`,
          });
          currentUnit = units.find(unit => unit.id === currentUnit.parentUnitId); // eslint-disable-line no-loop-func
        }

      }
      break;

    case 'users':
      list.push({ title: 'Users', to: '/users' });
      break;

    case 'settings':
      list.push({ title: 'User Settings', to: '/settings' });
      break;
    default:
      break;
  }

  return list;
};

const TutorialDialog = memo(({ open, onClose, onSuccess }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Show page tutorial?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To learn more about available functions
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Skip
        </Button>
        <Button onClick={onSuccess} color="primary" autoFocus>
          Show
        </Button>
      </DialogActions>
    </Dialog>
  );
}, areEqual);

export default function Header(props) {
  const classes = useStyles();
  const [openTutorial, setOpenTutorial] = useState(false);
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false);
  const [tutorialEnabled, setTutorialEnabled] = useState(undefined);
  const { pathname } = useLocation();
  const { tasks } = useSelector(state => state.task);
  const units = useSelector(state => state.unit.units);
  const tutorialSeen = useSelector(state => state.user.user.tutorialSeen);
  const breadCrumbsList = useMemo(() => evaluateBreadCrumbsList({ pathname, units }), [pathname, units]);
  const dispatch = useDispatch();
  const permissions = useSelector(state => state.user.permissions);
  const openTutorialPrev = useRef(false);
  const timerRef = useRef(undefined);
  const prevPathname = useRef();
  const forcedCloseTutorialDialog = useRef(false);
  const foundTutorialRef = useRef();
  const tutorialSeenRef = useRef(tutorialSeen);

  const modulesPermissions = useSelector(state => state.user.modulesPermissions);
  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);
  const isReader = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.READ);
  const isApprover = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.OWNER);

  const onOpenTutorial = () => setOpenTutorial(true);

  const matchPathname = (path) => {
    return matchPath(pathname, {
      path,
      exact: true,
      strict: false,
    });
  };

  const watchDomMutations = () => {
    const targetNode = document.querySelector('#root');
    const config = { attributes: true, childList: true, subtree: true };

    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (!tutorialEnabled &&
          mutation.type === 'childList' &&
          foundTutorialRef.current?.requiredElementSelector &&
          foundTutorialRef.current.requiredElementSelector.some(selector => mutation.target.id === selector.slice(1))
        ) {
          setTutorialEnabled(foundTutorialRef.current);

          if (!tutorialSeenRef.current[foundTutorialRef.current.name] && !forcedCloseTutorialDialog.current) {
            setOpenTutorialDialog(true);
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  };

  useEffect(() => {
    watchDomMutations();
  }, []);

  useEffect(() => {
    tutorialSeenRef.current = tutorialSeen;
  }, [tutorialSeen]);

  useEffect(() => {
    setOpenTutorial(false);
    setOpenTutorialDialog(false);

    if (prevPathname.current !== pathname) {
      forcedCloseTutorialDialog.current = false;
    }
    prevPathname.current = pathname;
  }, [pathname]);

  useEffect(() => {
    if (!units)
      dispatch(fetchUnits());
  }, [dispatch, units]);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    let foundTutorial = allowedTutorialPaths.find(item => {
      return item.path.find(path => {
        return matchPathname(path)
      });
    });

    foundTutorialRef.current = foundTutorial;

    if (foundTutorial) {
      const { name } = foundTutorial;

      timerRef.current = setTimeout(() => {
        let isValid = handleTutorialValidationCases(foundTutorial)
        setTutorialEnabled(() => !isValid ? undefined : foundTutorial);

        if (foundTutorial && !tutorialSeen[name] && isValid && !forcedCloseTutorialDialog.current) {
          setOpenTutorialDialog(true);
        }
      }, 500);
    } else if (tutorialEnabled) {
      setTutorialEnabled(undefined);
    }
  }, [pathname, tasks, modulesPermissions, permissions]);

  useEffect(() => {
    if (!openTutorial && openTutorialPrev.current) {
      setOpenTutorialDialog(false);
    }

    openTutorialPrev.current = openTutorial;
  }, [openTutorial]);

  const handleTutorialValidationCases = (foundTutorial) => {
    const { name } = foundTutorial;
    let isValid = true;

    switch (name) {
      case 'units':
        if (
          !hasModulePermission({ moduleName: 'sales', modulesPermissions, isCfo, isReader, isApprover }) &&
          !hasModulePermission({ moduleName: 'costs', modulesPermissions, isCfo, isReader, isApprover })
        ) {
          isValid = false;
        }
        break;

      case 'costs':
        if (!foundTutorial.requiredElementSelector.find(selector => document.querySelector(selector))) {
          isValid = false;
        }
        break;

      case 'tasks':
      case 'users':
      case 'sales':
        isValid = true;
        break;

      default:
        isValid = false;
    }

    return isValid;
  }

  const onTutorialSuccess = () => {
    setOpenTutorialDialog(false);
    setOpenTutorial(true);
  }

  const onSkipTutorial = () => {
    setOpenTutorialDialog(false);
    forcedCloseTutorialDialog.current = true;

    dispatch(updateUser({
      tutorialSeen: {
        sales: true,
        costs: true,
        tasks: true,
        units: true,
        users: true,
      },
    }));
  }

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, props.menuOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(classes.menuButton, props.menuOpen && classes.menuButtonHidden)}
          >
            <MenuIcon/>
          </IconButton>

          <Status/>

          <YearSwitcher isCfo={isCfo}/>
          <div className={classes.breadCrumbs}>
            <BreadCrumbs breadCrumbsList={breadCrumbsList}/>
          </div>

          <Tooltip title={!!tutorialEnabled ? 'View tutorial' : 'Tutorial is disabled for this page'}>
            <span>
              <IconButton
                disabled={!tutorialEnabled}
                onClick={onOpenTutorial}
              >
                <TutorialIcon
                  className={clsx(classes.tutorialIcon, {
                    [classes.disabledButton]: !tutorialEnabled,
                  })}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Notifications openTutorial={openTutorial}/>
          <Avatar/>
        </Toolbar>
      </AppBar>
      <TutorialDialog
        open={openTutorialDialog && !openTutorial}
        onSuccess={onTutorialSuccess}
        onClose={onSkipTutorial}
      />
      <Tutorial
        open={openTutorial}
        onOpenTutorial={setOpenTutorial}
      />
    </>
  );
}

Header.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
};


