const { PERMISSION_LEVEL } = require('./enum');

// eslint-disable-next-line no-unused-vars
const { READ, WRITE, OWNER, CFO } = PERMISSION_LEVEL;

/**
 * SALES
 */

module.exports.initiateBudgetSalesLevel = OWNER;
module.exports.canInitiateBudgetSales = level => level >= module.exports.initiateBudgetSalesLevel;

module.exports.approveBudgetSalesLevel = OWNER;
module.exports.canApproveBudgetSales = level => level >= module.exports.approveBudgetSalesLevel;

module.exports.contributeBudgetSalesLevel = WRITE;
module.exports.canContributeBudgetSales = level => level >= module.exports.contributeBudgetSalesLevel;

module.exports.deleteBudgetSalesLevel = OWNER;
module.exports.canDeleteBudgetSales = level => level >= module.exports.deleteBudgetSalesLevel;

module.exports.restoreBudgetSalesLevel = CFO;
module.exports.canRestoreBudgetSales = level => level >= module.exports.restoreBudgetSalesLevel;

module.exports.assignApproverBudgetSalesLevel = OWNER;
module.exports.canAssignApproverBudgetSales = level => level >= module.exports.assignApproverBudgetSalesLevel;

module.exports.finalizeBudgetSalesLevel = CFO;
module.exports.canFinalizeBudgetSales = level => level >= module.exports.finalizeBudgetSalesLevel;

module.exports.assignContributorBudgetSales = WRITE;
module.exports.canAssignContributorBudgetSales = level => level >= module.exports.assignContributorBudgetSales;
