import config from '../config';
import axios from 'axios';
import { deepCopy, deepMerge } from '../../../../common/src/util/object';
import { handleAxiosError } from './error';

export const FETCHING_UNITS = 'FETCHING_UNITS';
export const FETCHED_UNITS = 'FETCHED_UNITS';
export const SELECT_UNIT = 'SELECT_UNIT';
export const EXPAND_UNITS_NODES = 'EXPAND_UNITS_NODES';
export const FETCHING_UNIT_USERS = 'FETCHING_UNIT_USERS';
export const FETCHED_UNIT_USERS = 'FETCHED_UNIT_USERS';
export const FETCHING_UNIT_SALES = 'FETCHING_UNIT_SALES';
export const FETCHED_UNIT_SALES = 'FETCHED_UNIT_SALES';
export const SAVING_UNIT_SALES = 'SAVING_UNIT_SALES';
export const SAVED_UNIT_SALES = 'SAVED_UNIT_SALES';
export const SHOW_SALES_ON_CHART = 'SHOW_SALES_ON_CHART';
export const UPDATED_SALE = 'UPDATED_SALE';
export const FETCHED_SALE_ASSIGNMENTS = 'FETCHED_SALE_ASSIGNMENTS';
export const FETCHED_SALE_ASSIGNMENTS_PER_UNIT = 'FETCHED_SALE_ASSIGNMENTS_PER_UNIT';
export const FETCHED_INITATIONS = 'FETCHED_INITIATIONS';
export const UPDATE_SHOW_EXCEL_LOADER = 'UPDATE_SHOW_EXCEL_LOADER';

const { budgetApiUrl } = config;

export const fetchingUnits = () => ({
  type: FETCHING_UNITS,
});

export const fetchedUnits = units => ({
  type: FETCHED_UNITS,
  units,
});

const updateExcelLoader = showLoader => ({
  type: UPDATE_SHOW_EXCEL_LOADER,
  showLoader
});

export const fetchUnits = () => async dispatch => {
  dispatch(fetchingUnits());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/unit`,
      method: 'GET',
      withCredentials: true,
    });
    dispatch(fetchedUnits(res.data.units));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const selectUnit = unitId => ({
  type: SELECT_UNIT,
  unitId,
});

export const expandUnitsNodes = nodesIds => ({
  type: EXPAND_UNITS_NODES,
  nodesIds,
});

export const fetchingUnitUsers = () => ({
  type: FETCHING_UNIT_USERS,
});

export const fetchedUnitUsers = (users, unitId) => ({
  type: FETCHED_UNIT_USERS,
  users,
  unitId,
});

export const fetchUnitUsers = unitId => async dispatch => {
  dispatch(fetchingUnitUsers());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/unit/${unitId}/user`,
      method: 'GET',
      withCredentials: true,
    });
    dispatch(fetchedUnitUsers(res.data.users, unitId));
  } catch (err) {
    handleAxiosError(err, dispatch, true);
  }
};

export const fetchingUnitSales = () => ({
  type: FETCHING_UNIT_SALES,
});

export const fetchedUnitSales = sales => ({
  type: FETCHED_UNIT_SALES,
  sales,
});

export const fetchUnitSales = unitId => async dispatch => {
  dispatch(fetchingUnitSales());
  try {
    const res = await axios({
      url: `${budgetApiUrl}/unit/${unitId}/sale`,
      method: 'GET',
      withCredentials: true,
    });

    const { sales } = res.data;
    dispatch(fetchedUnitSales(sales));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const downloadBudgetExcel = (unit, year) => async dispatch => {
  try {
    dispatch(updateExcelLoader(true));
    const res = await axios({
      url: `${budgetApiUrl}/unit/${unit.id}/${year}/download/budget`,
      responseType: 'blob',
      method: 'GET',
      withCredentials: true,
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${unit.name.split(' ').join('-').toLowerCase()}-budget-${year}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();

    dispatch(updateExcelLoader(false));
  } catch (err) {
    dispatch(updateExcelLoader(false));
    handleAxiosError(err, dispatch);
  }
};

export const savingUnitSales = () => ({
  type: SAVING_UNIT_SALES,
});

export const savedUnitSales = sales => ({
  type: SAVED_UNIT_SALES,
  sales,
});

export const saveUnitSales = data => async dispatch => {
  dispatch(savingUnitSales());
  try {
    const { unitId } = data;
    const res = await axios({
      url: `${budgetApiUrl}/unit/${unitId}/sale`,
      method: 'POST',
      withCredentials: true,
      data,
    });
    const { sales } = res.data;
    dispatch(savedUnitSales(sales));
    dispatch(fetchInitiations(unitId));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const showDataOnChart = dataOnChart => ({
  type: SHOW_SALES_ON_CHART,
  dataOnChart,
});

export const updatedSale = sale => ({
  type: UPDATED_SALE,
  sale,
});

export const updateSale = (unitId, sale, mutation, message = undefined) => async dispatch => {

  const oldSale = deepCopy(sale);
  const newSale = deepMerge(
    deepCopy(sale),
    mutation,
    message ? { message } : {},
  );

  try {
    dispatch(updatedSale(newSale));

    const res = await axios({
      url: `${budgetApiUrl}/unit/${unitId}/sale/${sale.id}`,
      method: 'POST',
      withCredentials: true,
      data: mutation,
    });

    dispatch(updatedSale(res.data.sale));
  } catch (err) {
    handleAxiosError(err, dispatch);
    dispatch(updatedSale(oldSale));
  }
};

export const fetchedSaleAssignments = saleAssignments => ({ type: FETCHED_SALE_ASSIGNMENTS, saleAssignments });

export const fetchSaleAssignments = () => async dispatch => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${budgetApiUrl}/sales/assignments`,
      withCredentials: true
    });
    dispatch(fetchedSaleAssignments(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const updateSaleAssignments = (unitId, approvers, contributor) => async dispatch => {
  try {
    await axios({
      method: 'PUT',
      url: `${budgetApiUrl}/sales/assignments`,
      data: {
        unitId,
        approverIds: approvers.map(approver => approver.id),
        contributorId: contributor ? contributor.id : null
      },
      withCredentials: true
    });
    dispatch(fetchSaleAssignments());
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const fetchedSaleAssignmentsPerUnit = saleAssignments => ({
  type: FETCHED_SALE_ASSIGNMENTS_PER_UNIT,
  saleAssignments,
});

export const fetchSaleAssignmentsPerUnit = () => async dispatch => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${budgetApiUrl}/sales/assignments`,
      params: {
        perUnit: true
      },
      withCredentials: true,
    });
    dispatch(fetchedSaleAssignmentsPerUnit(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const saveInitiation = initiation => async dispatch => {
  try {
    await axios({
      method: 'POST',
      url: `${budgetApiUrl}/sales/initiations/unit/${initiation.unitId}`,
      data: initiation,
      withCredentials: true
    });
    dispatch(fetchUnitSales(initiation.unitId));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const fetchedInitiations = initiations => ({ type: FETCHED_INITATIONS, saleInitiations: initiations });

export const fetchInitiations = unitId => async dispatch => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${budgetApiUrl}/sales/initiations/unit/${unitId}`,
      withCredentials: true
    });
    dispatch(fetchedInitiations(res.data));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};
