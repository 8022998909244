import { combineReducers } from 'redux';
import user from './user';
import task from './task';
import notification from './notification';
import unit from './unit';
import cost from './cost';
import account from './account';
import error from './error';
import status from './status';
import consolidated from './consolidated';

const reducers = combineReducers({
  user,
  task,
  notification,
  unit,
  cost,
  account,
  error,
  status,
  consolidated
});

export default reducers;
