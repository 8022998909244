import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, Menu, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { months, Months, PERMISSION_LEVEL, SALE_STATUS } from '../../../../../common/src/util/enum';
import Table from '@material-ui/core/Table';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomIcon from '../common/icons';
import { formatNumber, parseNumber } from '../../../../../common/src/util/number';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { calculateCost, updateCost } from '../../action/cost';
import calculatePercentageDiff from '../../util/calculatePercentageDiff';
import { animateScroll, clearSelection, selectAll } from '../../util/dom';
import CostMenu from './CostMenu';
import CostStatusIcon from './CostStatusIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { CostInitiateDialog } from './CostInitiateDialog';
import areEqual from '../../util/areEqual';
import EditableCell from '../common/EditableCell';
import { showDataOnChart } from '../../action/unit';
import expressions from '../../../../../common/src/util/expressions';
import CostGroupApprovalModal from './CostGroupApprovalModal';
import { currentBudgetYear, nextBudgetYear } from '../../../../../common/src/util/date';
import { getPrognosis } from '../../../../../common/src/util/sale';
import summarize from '../../../../../common/src/util/summarize';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  table: {
    overflow: 'auto',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  menuColumn: {
    padding: 0,
  },
  statusColumn: {
    padding: 0,
    paddingTop: 5,
  },
  nameColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  yearColumn: {
    maxWidth: 40,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  rowSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  editablePlaceholder: {
    borderRadius: 4,
    border: '2px solid rgba(0,0,0,0.03)',
  },
  activeToggle: {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
}));

const getCostsForChart = (cost, group) => {
  const selectedOutcomes = group.costs.filter((item) => !item.budget);
  const selectedBudgets = group.costs.filter((item) => item.budget);

  const setItemName = item => item.name = group.name;

  selectedOutcomes.forEach(setItemName);
  selectedBudgets.forEach(setItemName);
  return { selectedBudgets, selectedOutcomes };
};

const LastTableRow = memo((
  {
    id,
    year,
    isBudget,
    isFirstRow,
    yearExpander,
    summarize,
    name,
    categoryName,
    totalRows,
    selectedRow,
    setSelectedRow,
    onSelectRow,
    rowDivider,
  }) => {
  const data = React.useRef({});
  const classes = useStyles();

  useEffect(() => {
    data.current = { id, budget: isBudget, year, name: `${name} - Total` };
    months.forEach(month => {
      data.current[month] = summarize(year, month, isBudget);
    });
  }, []);

  const onClick = () => {
    onSelectRow(data.current, totalRows, `${categoryName} - Total`);
    setSelectedRow(id);
  };

  return (
    <TableRow
      className={clsx(
        classes.row,
        selectedRow === id && classes.rowSelected,
      )}
      onClick={onClick}
    >
      <TableCell className={rowDivider}>&nbsp;</TableCell>
      <TableCell className={rowDivider}>&nbsp;</TableCell>
      <TableCell className={rowDivider}>&nbsp;</TableCell>
      <TableCell className={rowDivider}>{isFirstRow && 'Total'}</TableCell>
      <TableCell align="left" className={rowDivider}>{`${year} ${isBudget ? 'Budget' : 'Outcome'}`}</TableCell>
      <TableCell className={rowDivider}>{isFirstRow && yearExpander}</TableCell>
      <TableCell className={rowDivider}>&nbsp;</TableCell>
      {months.map(month => {
        return (
          <TableCell align="right" key={month} className={rowDivider}>
            {formatNumber(summarize(year, month, isBudget))}
          </TableCell>
        );
      })}
      <TableCell align="right" className={rowDivider}>
        {formatNumber(summarize(year, null, isBudget))}
      </TableCell>
      <TableCell className={rowDivider}>&nbsp;</TableCell>
      <TableCell align="right" className={rowDivider}>
        {isBudget && formatNumber(summarize(year, 'prognosis', isBudget))}
      </TableCell>
    </TableRow>
  )
});

const TableHeadRow = memo(({ months }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headColumn}>&nbsp;</TableCell>
        <TableCell className={classes.headColumn}>&nbsp;</TableCell>
        <TableCell className={classes.headColumn}>Number</TableCell>
        <TableCell className={classes.headColumn}>Name</TableCell>
        <TableCell align="left" className={classes.headColumn}>Year</TableCell>
        <TableCell className={classes.headColumn}>&nbsp;</TableCell>
        <TableCell align="right" className={classes.headColumn}>Coeff.</TableCell>
        {months.map(month => <TableCell align="right" key={month} className={classes.headColumn}>{month}</TableCell>)}
        <TableCell align="right" className={classes.headColumn}>Total</TableCell>
        <TableCell align="right" className={classes.headColumn}>Total %</TableCell>
        <TableCell align="right" className={classes.headColumn}>Year -1</TableCell>
      </TableRow>
    </TableHead>
  );
}, areEqual);

const TableCellFieldComponent = memo((
  {
    cost,
    month,
    lastYear,
    rowDivider,
    invalid,
    editable,
    onFocus,
    onBlur,
    onKeyDown,
    showPercentage,
    value,
  }) => {
  const selectedPercentage = showPercentage.find(item => item.typeId === cost.typeId);

  return (
    <TableCell align="right" className={rowDivider}>
      {
        lastYear && selectedPercentage ?
          <p>{calculatePercentageDiff(value, selectedPercentage[month])}</p> :
          <EditableCell
            value={invalid ? value : formatNumber(value)}
            editable={editable}
            valid={!invalid}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            style={{ foo: Math.random() }}
          />
      }
    </TableCell>
  )
}, areEqual);

const Row = memo((
  {
    id,
    cost,
    group,
    category,
    lastYear,
    accountId,
    user,
    isCfo,
    name,
    numeric,
    groupExpanded,
    hasNextYear,
    selectedRow,
    setSelectedRow,
    toggleGroupExpanded,
    setEditing,
    showPercentage,
    setShowPercentage,
    onSelectRow,
  }) => {
  const [invalidCells, setInvalidCells] = useState({});
  const dispatch = useDispatch();

  const incomeTotal = useSelector(state => _.get(state.cost.incomeTotals, [cost.year, cost.budget]));

  const classes = useStyles();
  const calculated = !!group.formula;
  const editable = lastYear &&
    ((user && cost.contributorId === user.id) || isCfo) &&
    cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED;
  const rowDivider = lastYear ? classes.rowDivider : '';
  const { year, budget: isBudget } = cost;
  const previousCost = group.costs.find(cost => cost.year === year - 1);

  const onFocus = () => {
    setEditing(true);
    setTimeout(selectAll);
  };

  const onBlur = (month, cellKey) => e => {
    clearSelection();
    // formatted value gets the *actual* unicode minus sign (U+2212)
    // that is not a _regular_ minus sign (U+2013)
    const sValue = e.target.innerText.replace(/<[^>]+>/g, '').replace(/−/g, '-').replace(/\s/g, '');
    const iValue = Math.round(parseNumber(sValue));
    const invalid = sValue !== '' && isNaN(iValue);
    setInvalidCells({ ...invalidCells, [cellKey]: invalid });
    setEditing(false);
    if (cost[month] !== iValue) {
      const mutation = { [month]: invalid ? sValue : !isNaN(iValue) ? iValue : null };
      dispatch(updateCost(cost, mutation, invalid));
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const toggleExpandList = (accountId) => {
    toggleGroupExpanded(accountId);
    setShowPercentage(showPercentage => showPercentage.filter(item => item.typeId !== accountId));
  }
  const hasValues = months.some(month => cost[month]);
  const hasParentYearValues = months.some(month => group.costs[0][month]);
  let calculatedPercentageTotalRow = lastYear && showPercentage.find(item => item.typeId === cost.typeId);
  calculatedPercentageTotalRow = calculatedPercentageTotalRow ?
    months.reduce((rv, month) => rv + Number(calculatedPercentageTotalRow[month]), 0) : null;

  const setPercentage = (cost) => {
    setShowPercentage(showPercentage => showPercentage.find(item => item.typeId === cost.typeId) ?
      showPercentage.find(item => item.typeId === cost.typeId && item.id !== cost.id) ?
        [...showPercentage.filter(item => item.typeId !== cost.typeId && item.id !== cost.id), cost] :
        showPercentage.filter(item => item.typeId !== cost.typeId) :
      [...showPercentage, cost],
    );
  }

  const isNextYear = year >= nextBudgetYear();

  const onClick = () => {
    onSelectRow(cost, group);
    setSelectedRow(id);
  }

  let hasCoefficient = false, coefficient = '', onCoefficientBlur = _.noop;
  const coeffCellKey = `${id}-coeff`;
  if (calculated) {
    const { variables } = expressions.parse(group.formula);
    if (variables.length === 1) {
      hasCoefficient = true;
      const variable = variables[0];
      coefficient = _.toString(_.get(cost?.metadata?.variables, variable));

      onCoefficientBlur = e => {
        const sValue = e.target.innerText.replace(/<[^>]+>/g, '').replace(/−/g, '-').replace(/\s/g, '');
        const percentage = sValue.endsWith('%');
        const iValue = parseNumber(sValue.replaceAll('%', ''));
        const invalid = _.isNaN(iValue);
        setInvalidCells({ ...invalidCells, [coeffCellKey]: invalid });
        setEditing(false);
        if (!invalid) {
          const value = percentage ? `${iValue}%` : `${iValue}`;
          dispatch(calculateCost(cost, { [variable]: value }));
        }
      }
    }
  }

  const percentage = (value, of) => (of !== undefined && of !== null && of !== 0) ?
    `${(value / of * 100).toFixed(1)}%` :
    null;
  const getTotalPercentage = () => category.income ? null : percentage(summarize(cost), incomeTotal);

  cost.prognosis = getPrognosis(cost, group);

  return (
    <TableRow
      name={id}
      className={clsx(
        classes.row,
        id === selectedRow && classes.rowSelected,
      )}
      onClick={onClick}
    >
      <TableCell className={clsx(classes.menuColumn, rowDivider)}>
        {isNextYear && isBudget && !group.automatic && (
          <CostMenu cost={cost} type={group} userId={user.id} previousCost={previousCost}/>
        )}
      </TableCell>
      <TableCell className={clsx(classes.statusColumn, rowDivider)}>
        {isNextYear && isBudget && !group.automatic ? (
          <CostStatusIcon
            statusId={cost.statusId}
            contributorId={cost.contributorId}
            approverId={cost.approverId}
            userId={user.id}
          />
        ) : hasValues && hasNextYear && hasParentYearValues && cost.year === currentBudgetYear() ? (
          <Tooltip title="Show Budget in percentage">
            <IconButton
              size="small"
              className={clsx({
                [classes.activeToggle]: showPercentage.find(item => item.id === cost.id),
              })}
              onClick={() => setPercentage(cost)}
            >
              <CustomIcon icon={'percentage'}/>
            </IconButton>
          </Tooltip>
        ) : ''}
      </TableCell>
      <TableCell className={clsx(classes.nameColumn, rowDivider)}>{lastYear && accountId}</TableCell>
      <TableCell className={clsx(classes.nameColumn, rowDivider)}>{lastYear && name}</TableCell>
      <TableCell align="left" className={clsx(classes.yearColumn, rowDivider)}>
        {year} {isBudget ? 'Budget' : 'Outcome'}
      </TableCell>
      <TableCell
        align="left"
        className={clsx(classes.yearExpandColumn, rowDivider)}
      >
        {lastYear && (
          <IconButton
            size="small"
            onClick={() => toggleExpandList(accountId)}
            disabled={group.costs.length === 1}
          >
            {groupExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        )}
      </TableCell>

      <TableCell align="right" className={rowDivider} key={coeffCellKey}>
        {hasCoefficient ?
          <Tooltip title={group.formula}>
            <EditableCell
              value={coefficient}
              editable={editable}
              valid={!invalidCells[coeffCellKey]}
              onFocus={onFocus}
              onBlur={onCoefficientBlur}
              onKeyDown={onKeyDown}
              style={{ foo: Math.random() }}
            />
          </Tooltip>
          : <span/>
        }
      </TableCell>

      {months.map(month => {
        const cellKey = `${id}-${month}`;
        const value = cost[month];
        const invalid = invalidCells[cellKey];

        // the component below has a shouldComponentUpdate and is therefore forced to rerender each time
        // by passing an invalid style prop (no DOM updates will occur) so that the callbacks (
        // especially onBlur) are updated
        return (
          <TableCellFieldComponent
            key={cellKey}
            rowDivider={rowDivider}
            cost={cost}
            lastYear={lastYear}
            month={month}
            value={value}
            invalid={invalid}
            editable={editable && !calculated}
            onFocus={onFocus}
            onBlur={onBlur(month, cellKey)}
            onKeyDown={onKeyDown}
            showPercentage={showPercentage}
          />
        );
      })}

      <TableCell align="right" className={rowDivider}>
        {
          calculatedPercentageTotalRow ?
            calculatePercentageDiff(months.reduce((acc, val) => acc + numeric(cost[val]), 0), calculatedPercentageTotalRow) :
            formatNumber(months.reduce((acc, val) => acc + numeric(cost[val]), 0))
        }
      </TableCell>
      <TableCell align="right" className={rowDivider}>
        {getTotalPercentage()}
      </TableCell>
      <TableCell align="right" className={rowDivider}>
        {formatNumber(cost.prognosis)}
      </TableCell>
    </TableRow>
  )
});

const AccountGroupMenu = ({ group, unitId, userId, isCfo }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const [initiateDialogOpen, setInitiateDialogOpen] = useState(false);
  const openInitiateDialog = () => {
    closeMenu();
    setInitiateDialogOpen(true);
  }
  const onInitiateDialogClose = () => {
    setInitiateDialogOpen(false);
  };

  const [groupApprovalModalOpen, setGroupApprovalModalOpen] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const openRequestActionModal = (e, status) => {
    closeMenu(e);
    setNextStatus(status);
    setGroupApprovalModalOpen(true);
  };
  const onGroupApprovalModalClose = () => {
    setGroupApprovalModalOpen(false);
  }

  const costs = _.flatMap(group.types, type => type.costs.map(cost => ({
    ...cost,
    type: type.name,
    number: type.number,
  })));
  const nextYear = nextBudgetYear();
  const hasCostsToRequestApproval = _.some(costs, cost =>
    cost.year === nextYear &&
    (
      (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId !== cost.approverId) || isCfo)) ||
      (cost.statusId === SALE_STATUS.FINAL && isCfo)),
  );
  const hasCostsToApprove = _.some(costs, cost =>
    cost.year === nextYear &&
    (
      (cost.statusId === SALE_STATUS.APPROVAL_REQUESTED && (cost.approverId === userId || isCfo)) ||
      (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId === cost.approverId) || isCfo))
    ),
  );

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu open={menuOpen}
            anchorEl={menuAnchorEl}
            keepMounted
            onClose={closeMenu}>
        <MenuItem key="initiate"
                  onClick={openInitiateDialog}>
          Initiate costs
        </MenuItem>
        <MenuItem key="requestGroupApproval"
                  onClick={e => openRequestActionModal(e, SALE_STATUS.APPROVAL_REQUESTED)}
                  disabled={!hasCostsToRequestApproval}>
          Request approval
        </MenuItem>
        <MenuItem key="approveGroup"
                  onClick={e => openRequestActionModal(e, SALE_STATUS.FINAL)}
                  disabled={!hasCostsToApprove}>
          Approve and finalize
        </MenuItem>
      </Menu>
      <CostInitiateDialog open={initiateDialogOpen}
                          onClose={onInitiateDialogClose}
                          unitId={unitId}
                          groupId={group.id}/>
      <CostGroupApprovalModal open={groupApprovalModalOpen}
                              onClose={onGroupApprovalModalClose}
                              nextStatus={nextStatus}
                              costs={costs}
                              userId={userId}
                              isCfo={isCfo}/>
    </div>
  );
};

export default function Cost({ unit, unitId, category: pCategory, selectedRow, setSelectedRow }) {
  const classes = useStyles();

  const { user } = useSelector(state => state.user);
  const category = useSelector(state => state.cost.categories.find(c => c.id === pCategory.id));

  const [showPercentage, setShowPercentage] = useState([]);
  const [expandedStates, setExpandedStates] = useState({});
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const toggleGroupExpanded = accountId => {
    setExpandedStates({ ...expandedStates, [accountId]: !expandedStates[accountId] });
  };

  const location = useLocation();
  const highlightCostsIds = location?.state?.highlightCostsIds;

  useEffect(() => {
    return () => {
      dispatch(showDataOnChart({ unit }));
    }
  }, []);

  // only the highlighted row change should trigger this
  useEffect(() => {
    if (highlightCostsIds?.length) {
      const cost = category.types.flatMap(type => type.costs).find(cost => highlightCostsIds.includes(cost.id));
      if (cost) {
        const key = `${cost.typeId}-${cost.year}-${cost.budget}`;
        setSelectedRow(key);
        animateScroll(key, { offset: -400 });
      }
    }
  }, [highlightCostsIds]);

  if (!user) {
    return null;
  }

  const isCfo = unit.permission.level >= PERMISSION_LEVEL.CFO;

  const years = _.chain(category.types)
    .flatMap(type => type.costs)
    .map(cost => cost.year)
    .uniq().value();
  const numeric = value => typeof value === 'number' ? value : 0;
  const summarize = (year, month, isBudget) => {
    let sum = 0;
    // this could be a lodash chain, but this way is more readable
    for (const group of category.types) {
      for (const cost of group.costs) {
        if (cost.year === year && cost.budget === isBudget) {
          if (month) {
            sum += numeric(cost[month]);
          } else {
            sum += months.reduce((acc, val) => acc + numeric(cost[val]), 0);
          }
        }
      }
    }
    return sum;
  }

  let totalRows = _.flatten(
    years.map((year) => {
      return [true, false].map(isBudget => {
        const categoryItem = category.types.find(({ costs }) => costs.some(cost => cost.year === year && cost.budget === isBudget));
        if (!categoryItem)
          return undefined;

        const key = `${year}-total-${isBudget}`;
        const data = { id: key, budget: isBudget, year, name: `${categoryItem.name} - Total` }

        months.forEach(month => {
          data[month] = summarize(year, month, isBudget);
        });

        return data;
      })
    }),
  )
    .filter(Boolean);
  totalRows.costs = totalRows;

  const onSelectRow = (cost, group, categoryName) => {
    if (categoryName) {
      group.name = categoryName;
    }

    const { selectedBudgets, selectedOutcomes } = getCostsForChart(cost, group);
    dispatch(showDataOnChart({ budgets: selectedBudgets, outcomes: selectedOutcomes, unit }));
  };

  const table = (
    <div className={classes.table}>
      <Table>
        <TableHeadRow months={Months}/>
        <TableBody>
          {category.types.map(group => group.costs.map((cost, index) => {
            const { number: accountId, name } = group;
            const lastYear = index === 0; // assuming order by year desc
            const groupExpanded = !!expandedStates[accountId];
            if (!lastYear && !groupExpanded) {
              return undefined;
            }

            const key = `${cost.typeId}-${cost.year}-${cost.budget}`;
            const hasNextYear = group.costs.length && group.costs[0].year === nextBudgetYear();

            return (
              <Row
                key={key}
                id={key}
                name={name}
                cost={cost}
                group={group}
                category={category}
                lastYear={lastYear}
                accountId={accountId}
                user={user}
                isCfo={isCfo}
                hasNextYear={hasNextYear}
                numeric={numeric}
                setEditing={setEditing}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                groupExpanded={groupExpanded}
                toggleGroupExpanded={toggleGroupExpanded}
                showPercentage={showPercentage}
                setShowPercentage={setShowPercentage}
                onSelectRow={onSelectRow}
              />
            )
          }))}
          {_
            .flatten(
              years.map((year, index) => {
                  let isFirstRowShown = false;
                  return [true, false].map(isBudget => {
                      const isFirstRow = index === 0 && !isFirstRowShown;
                      const groupExpanded = !!expandedStates['total'];

                      if (!isFirstRow && !groupExpanded)
                        return undefined;
                      isFirstRowShown = true;

                      const categoryItem = category.types.find(({ costs }) => costs.some(cost => cost.year === year && cost.budget === isBudget));
                      if (!categoryItem)
                        return undefined;

                      const yearExpander = (
                        <IconButton
                          size="small" onClick={() => toggleGroupExpanded('total')}
                          disabled={totalRows.costs.length <= 1}
                        >
                          {groupExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                      );

                      const rowDivider = isFirstRow ? classes.rowDivider : '';
                      const key = `${year}-total-${isBudget}`;

                      return (
                        <LastTableRow
                          key={key}
                          id={key}
                          name={categoryItem.name}
                          categoryName={category.name}
                          year={year}
                          isBudget={isBudget}
                          isFirstRow={isFirstRow}
                          editing={editing}
                          yearExpander={yearExpander}
                          rowDivider={rowDivider}
                          totalRows={totalRows}
                          selectedRow={selectedRow}
                          setSelectedRow={setSelectedRow}
                          summarize={summarize}
                          onSelectRow={onSelectRow}
                        />
                      )
                    },
                  )
                },
              ),
            )
            .filter(Boolean)
          }
        </TableBody>
      </Table>
    </div>
  );

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div className={clsx(classes.accordionSummary, classes.withMenu)}>
            <AccountGroupMenu group={category} unitId={unitId} userId={user.id} isCfo={isCfo}/>
            <Title>{category.name}</Title>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {table}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

Cost.propTypes = {
  category: PropTypes.object.isRequired,
  selectedRow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSelectedRow: PropTypes.func.isRequired,
};
