import React, { useState } from 'react';
import { AccordionSummary, Grid, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import { Months, months } from '../../../../../common/src/util/enum';
import { formatNumber } from '../../../../../common/src/util/number';
import { makeStyles } from '@material-ui/core/styles';
import summarize from '../../../../../common/src/util/summarize';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  yearExpanderColumn: {
    maxWidth: 30,
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  rowHighlighted: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  error: {
    background: `${theme.palette.error.light}`
  }
}));

export default function ConsolidatedSales({ sales }) {
  const classes = useStyles();
  const history = useHistory();

  const [expanded, setExpanded] = useState({});
  const toggleExpanded = unitId => setExpanded({ ...expanded, [unitId]: !expanded[unitId] });
  const isExpanded = unitId => !!expanded[unitId];

  _.chain(sales)
    .groupBy(sale => sale.unitId)
    .values()
    .forEach(group => group[0].first = true)
    .value();

  const showTotals = sales.filter(sale => sale.first).length > 1;
  const showNames = showTotals;

  return (
    <Grid item xs={12}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div className={classes.accordionSummary}>
            <Title>Sales</Title>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {showNames &&
                  <TableCell className={classes.headColumn}>Name</TableCell>
                  }
                  <TableCell className={classes.headColumn}>Year</TableCell>
                  <TableCell className={classes.headColumn}>&nbsp;</TableCell>
                  {Months.map(month => (
                    <TableCell key={`head-${month}`} align="right" className={classes.headColumn}>
                      {month}
                    </TableCell>
                  ))}
                  <TableCell align="right" className={classes.headColumn}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales.map(sale => {
                  if (!sale.first && !isExpanded(sale.unitId)) {
                    return null;
                  }
                  const rowDivider = sale.first ? classes.rowDivider : null;
                  return (
                    <TableRow key={`row-${sale.name}-${sale.year}-${sale.type}-${sale.unitId}`}
                              className={clsx(classes.tableRow, { [classes.error]: sale.error })}
                              onClick={() => history.push(sale.unitId ? `/unit/${sale.unitId}/sale` : '/sales')}>
                      {showNames &&
                      <TableCell className={rowDivider}>
                        {sale.first && sale.name}
                      </TableCell>
                      }
                      <TableCell className={rowDivider}>
                        {sale.year} {sale.type === 'budget' ? 'Budget' : 'Outcome'}
                      </TableCell>
                      <TableCell className={clsx(rowDivider, classes.yearExpanderColumn)}
                                 onClick={e => e.stopPropagation()}>
                        {sale.first &&
                        <IconButton size="small"
                                    onClick={() => toggleExpanded(sale.unitId)}
                                    disabled={false}>
                          {isExpanded(sale.unitId) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                        }
                      </TableCell>
                      {!sale.error ?
                        months.map(month => (
                          <TableCell key={`cell-${sale.name}-${month}`} align="right" className={rowDivider}>
                            {formatNumber(sale[month])}
                          </TableCell>
                        )) : (
                          <TableCell colSpan={12} align="center" className={rowDivider}>
                            {sale.error}
                          </TableCell>
                        )
                      }
                      <TableCell align="right" className={rowDivider}>
                        {formatNumber(summarize(sale))}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {showTotals &&
                <TableRow className={classes.rowHighlighted}>
                  <TableCell className={classes.rowDivider}>&nbsp;</TableCell>
                  <TableCell className={classes.rowDivider}>Total</TableCell>
                  <TableCell className={classes.rowDivider}>&nbsp;</TableCell>
                  {months.map(month => (
                    <TableCell key={`total-${month}`} align="right" className={classes.rowDivider}>
                      {formatNumber(summarize(sales.filter(sale => sale.first), month))}
                    </TableCell>
                  ))}
                  <TableCell align="right" className={classes.rowDivider}>
                    {formatNumber(summarize(sales.filter(sale => sale.first)))}
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ConsolidatedSales.propTypes = {
  sales: PropTypes.array.isRequired,
};
