import { deepCopy } from '../../../../common/src/util/object';

import {
  SHOW_ERROR,
  HIDE_ERROR,
} from '../action/error';

import {
  SIGNED_OUT,
} from '../action/user';


const initialState = {
  error: false,
};

const reducer = (state = deepCopy(initialState), action) => {
  switch (action.type) {
    case SHOW_ERROR:
      return { ...state, error: action.error };
    case HIDE_ERROR:
      return { ...state, error: false };
    case SIGNED_OUT:
      return deepCopy(initialState);
    default:
      return state;
  }
};

export default reducer;
