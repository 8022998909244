// JSON diff usage ref.: https://github.com/benjamine/jsondiffpatch

const { deepFreeze } = require('./object');
const { isNode } = require('./process');

const jsondiff = (
  isNode ?
    require('../../../backend/budget-api/node_modules/jsondiffpatch') :
    require('../../../frontend/budget-ui/node_modules/jsondiffpatch')
).create({
  textDiff: {
    minLength: Number.MAX_SAFE_INTEGER,
  },
});

const DIFF_TYPE = deepFreeze({
  ADDED: 'Added',
  MODIFIED: 'Modified',
  DELETED: 'Deleted',
  UNKNOWN: 'Unknown',
});

// JSON diff format ref.: https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
const diffType = mutation => {
  if (Array.isArray(mutation)) {
    switch (mutation.length) {
      case 1:
        return DIFF_TYPE.ADDED;
      case 2:
        return DIFF_TYPE.MODIFIED;
      case 3:
        if (mutation[2] === 0)
          return DIFF_TYPE.DELETED;
    }
  }
  return DIFF_TYPE.UNKNOWN;
};

module.exports.DIFF_TYPE = DIFF_TYPE;
module.exports.diffType = diffType;

module.exports.diff = (oldValue, newValue) => {
  return jsondiff.diff(oldValue, newValue);
};
module.exports.patch = (oldValue, delta) => {
  jsondiff.patch(oldValue, delta);
};
