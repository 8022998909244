import React, { memo, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import UserSearch from '../common/UserSearch';
import { fetchSaleAssignmentsPerUnit, fetchUnitUsers, saveInitiation } from '../../action/unit';
import moment from 'moment';
import { currentBudgetYear, DATE_FORMAT, isValidDateFormat } from '../../../../../common/src/util/date';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  message: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const StartSalesInitiationModal = ({ open, onClose, unit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const users = useSelector(state => state.unit.fetchedUnitUsers[unit.id]);
  const loadingUsers = useSelector(state => state.unit.fetchingUnitUsers);
  const assignments = useSelector(state => state.unit.saleAssignmentsPerUnit[unit.id] || []);

  useEffect(() => {
    if (open) {
      dispatch(fetchUnitUsers(unit.id));
      dispatch(fetchSaleAssignmentsPerUnit());
    }
  }, [open, unit]);

  const [errors, setErrors] = useState({});

  const currentYear = currentBudgetYear();
  const nextYear = currentYear + 1;
  const [year, setYear] = useState(nextYear);

  const initialDueDate = moment().add(7, 'days').format(DATE_FORMAT);
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [dueDateInputValue, setDueDateInputValue] = useState();
  const onChangeDueDate = (date, value) => {
    setDueDate(moment(date).format(DATE_FORMAT));
    setDueDateInputValue(value);
    setErrors({ ...errors, dueDate: isValidDateFormat(date) ? undefined : 'Invalid date format' });
  }

  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');

  const valid =
    _.chain(errors).values().every(value => !value).value() &&
    (_.isNumber(year) && !!dueDate && !!user);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (assignments.length > 0 && users) {
      const assigneeId = _.chain(assignments)
        .filter(assignment => assignment.level > 0)
        .minBy(assignment => assignment.level)
        .value()?.userId;
      const assignee = users.find(user => user.id === assigneeId);
      setUser(assignee);
    }
    setYear(nextYear);
    setDueDate(initialDueDate);
    setMessage('');
    setErrors({});
  }, [open, assignments, users]);

  const onConfirm = () => {
    dispatch(saveInitiation({
      unitId: unit.id,
      year,
      due: dueDate,
      assigneeId: user.id,
      message
    }));
    onClose();
  };

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>Initiate sales budget</DialogTitle>
      <DialogContent>
        <div className={classes.inputs}>
          <TextField
            label="Year"
            type="number"
            value={year}
            className={classes.input}
            inputProps={{ readOnly: true }}
          />
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              label="Due date"
              autoOk={true}
              showTodayButton={false}
              value={dueDate}
              format={DATE_FORMAT}
              inputValue={dueDateInputValue}
              onChange={onChangeDueDate}
              error={!!errors.dueDate}
              helperText={errors.dueDate}
              rifmFormatter={value => value}
              className={classes.input}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <UserSearch
              label="Assigned initiator"
              loading={loadingUsers}
              setUser={setUser}
              user={user}
              users={users}
            />
          </div>
        </div>
        <div className={classes.inputs}>
          <TextField
            label="Optional message"
            multiline
            value={message}
            onChange={event => setMessage(event.target.value)}
            className={classes.message}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={!valid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(StartSalesInitiationModal);
