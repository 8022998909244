import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, Grid, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { formatNumber } from '../../../../../common/src/util/number';
import { makeStyles } from '@material-ui/core/styles';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import ConsolidatedGroupedMenu from './ConsolidatedGroupedMenu';
import ConsolidatedGroupedRecordMenu from './ConsolidatedGroupedRecordMenu';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  yearExpanderColumn: {
    maxWidth: 30,
  },
  menuColumn: {
    width: 20,
    padding: 0,
  },
  idColumn: {
    width: 60
  },
  rowHighlighted: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  error: {
    background: `${theme.palette.error.light}`
  }
}));

const ConditionalTooltip = ({ title, children, if: condition }) =>
  condition ? <Tooltip title={title}>{children}</Tooltip> : children;

const format = (record, value) => {
  return record.percentage ? (value ? value.toFixed(1) : null) : formatNumber(value);
};

export default function ConsolidatedGrouped({ companies, records }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState({});
  const toggleExpanded = id => setExpanded({ ...expanded, [id]: !expanded[id] });
  const isExpanded = id => !!expanded[id];

  _.chain(records)
    .groupBy(record => record.id)
    .values()
    .forEach(group => group[0].first = true)
    .value();

  const permissions = useSelector(state => state.user.permissions);
  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);

  return (
    <Grid item xs={12}>
      <Accordion expanded={true}>
        <AccordionSummary>
          <div className={clsx(classes.accordionSummary, { [classes.withMenu]: isCfo })}>
            {isCfo &&
              <ConsolidatedGroupedMenu companies={companies}/>
            }
            <Title>Group Consolidated</Title>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.headColumn, classes.menuColumn)}>&nbsp;</TableCell>
                  <TableCell className={clsx(classes.headColumn, classes.idColumn)}>Record</TableCell>
                  <TableCell className={classes.headColumn}>Name</TableCell>
                  <TableCell className={classes.headColumn}>Year</TableCell>
                  <TableCell className={classes.headColumn}>&nbsp;</TableCell>
                  {companies.map(company => (
                    <TableCell key={`head-${company.id}`} align="right" className={classes.headColumn}>
                      {company.name}
                    </TableCell>
                  ))}
                  <TableCell align="right" className={classes.headColumn}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map(record => {
                  if (!record.first && !isExpanded(record.id)) {
                    return null;
                  }
                  const rowDivider = record.first ? classes.rowDivider : null;
                  return (
                    <TableRow key={`row-${record.name}-${record.year}-${record.type}-${record.id}`}
                              className={clsx(classes.tableRow, {
                                [classes.error]: record.error,
                                [classes.rowHighlighted]: record.highlighted,
                              })}>
                      <TableCell className={clsx(classes.menuColumn, rowDivider)}>
                        {isCfo && record.first &&
                          <ConsolidatedGroupedRecordMenu record={record} records={records} companies={companies}/>
                        }
                      </TableCell>
                      <TableCell align="right" className={clsx(rowDivider, classes.idColumn)}>
                        {record.first && record.id}
                      </TableCell>
                      <TableCell className={rowDivider}>
                        <ConditionalTooltip if={!!record.formula} title={<h2>{record.formula}</h2>}>
                            <span>
                              {record.first && record.name}
                            </span>
                        </ConditionalTooltip>
                      </TableCell>
                      <TableCell className={rowDivider}>
                        {record.year} {record.type === 'budget' ? 'Budget' : 'Outcome'}
                      </TableCell>
                      <TableCell className={clsx(rowDivider, classes.yearExpanderColumn)}
                                 onClick={e => e.stopPropagation()}>
                        {record.first &&
                          <IconButton size="small"
                                      onClick={() => toggleExpanded(record.id)}
                                      disabled={false}>
                            {isExpanded(record.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                          </IconButton>
                        }
                      </TableCell>
                      {!record.error ?
                        companies.map(company => (
                          <TableCell key={`cell-${record.name}-${company.id}`}
                                     align="right"
                                     className={rowDivider}>
                            {format(record, record[company.name])}
                          </TableCell>
                        )) : (
                          <TableCell colSpan={companies.length} align="center" className={rowDivider}>
                            {record.error}
                          </TableCell>
                        )}
                      <TableCell align="right" className={rowDivider}>
                        {format(record, record.total)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
