import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import ConsolidatedRecordModal from './ConsolidatedRecordModal';
import ConfirmDeleteButtons from '../common/ConfirmDeleteButtons';
import {
  deleteConsolidatedRecord,
  moveConsolidatedRecord,
  toggleModal,
  updateConsolidatedRecord
} from '../../action/consolidated';

const ConsolidatedRecordMenu = ({ unitId, group, record }) => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openConfirmDeleteMenu, setOpenConfirmDeleteMenu] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const modalId = `record-edit-${record.id}`;
  const recordModalOpen = useSelector(state => state.consolidated.openModals[modalId] || false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setOpenConfirmDeleteMenu(false);
  };
  const onCloseMenus = () => {
    setOpenConfirmDeleteMenu(false);
    setMenuOpen(false);
  }

  const onEditClicked = () => {
    onCloseMenus();
    dispatch(toggleModal(modalId, true));
  };
  const onEditRecordConfirm = record => {
    dispatch(updateConsolidatedRecord(group, { ...record, unitId }));
  };

  const onMoveRecordClicked = direction => {
    setMenuOpen(false);
    dispatch(moveConsolidatedRecord(group, { ...record, unitId }, direction));
  }

  const onDeleteClicked = () => {
    setOpenConfirmDeleteMenu(true);
  };
  const onConfirmDelete = () => {
    dispatch(deleteConsolidatedRecord(group, { ...record, unitId }));
    onCloseMenus();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchorEl}
        keepMounted
        onClose={closeMenu}
      >
        <MenuItem key={`record-edit-${record.id}`}
                  onClick={onEditClicked}>
          Edit
        </MenuItem>
        <MenuItem key={`record-move-${record.id}-up`}
                  onClick={() => onMoveRecordClicked('up')}
                  disabled={record.position === 1}>
          Move up
        </MenuItem>
        <MenuItem key={`record-move-${record.id}-down`}
                  onClick={() => onMoveRecordClicked('down')}
                  disabled={record.position === group.records.length}>
          Move down
        </MenuItem>
        <MenuItem ref={menuRef}
                  key={`record-delete-${record.id}`}
                  onClick={onDeleteClicked}>
          Delete
        </MenuItem>
      </Menu>

      <ConsolidatedRecordModal open={recordModalOpen}
                               onCancel={() => dispatch(toggleModal(modalId, false))}
                               onConfirm={onEditRecordConfirm}
                               title="Edit"
                               record={record}
                               unitId={unitId}/>
      <ConfirmDeleteButtons open={menuOpen && openConfirmDeleteMenu}
                            anchorEl={menuRef}
                            onSuccess={onConfirmDelete}
                            onClose={onCloseMenus}/>
    </div>
  );
};

ConsolidatedRecordMenu.propTypes = {
  group: PropTypes.object,
  record: PropTypes.object,
};

export default memo(ConsolidatedRecordMenu);
