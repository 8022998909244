import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InitiateSalesBudget from './InitiateSalesBudget';
import {
  canApproveBudgetSales,
  canContributeBudgetSales,
  canFinalizeBudgetSales,
  canInitiateBudgetSales
} from '../../../../../common/src/util/permission';
import { downloadBudgetExcel } from '../../action/unit';
import { deepValue } from '../../../../../common/src/util/object';
import RequestRegionApprovalModal from './RequestRegionApprovalModal'
import DownloadBudgetPopup from "./DownloadBudgetPopup";
import { PERMISSION_LEVEL, SALE_STATUS, SALE_TYPE, UNIT_TYPE } from '../../../../../common/src/util/enum';
import * as _ from 'lodash';
import StartSalesInitiationModal from './StartSalesInitiationModal';
import { currentBudgetYear, nextBudgetYear } from '../../../../../common/src/util/date';

export default function UnitMenu(unit, sales = [], assignments = []) {
  const user = useSelector(state => state.user.user);
  const permissions = useSelector(state => state.user.permissions);
  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);
  const dispatch = useDispatch();
  const saleInitiations = useSelector(state => state.unit.saleInitiations);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [startInitiateSalesBudgetOpen, setStartInitiateSalesBudgetOpen] = useState(false);
  const [initiateSalesBudgetOpen, setInitiateSalesBudgetOpen] = useState(false);
  const [regionActionModalOpen, setRegionActionModalOpen] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [openDownloadBudgetPopup, setOpenDownloadBudgetPopup] = useState(false);
  const downloadBudgetAnchorRef = useRef();

  const saleYears = sales.filter(({ name, saleTypeId }) => name !== 'Days' && saleTypeId === SALE_TYPE.AMOUNT_BUDGET).map(item => item.year);
  const hasNextYear = saleYears.includes(nextBudgetYear());
  const hasCurrentYear = saleYears.includes(currentBudgetYear());
  const isParentCompany = unit.type === UNIT_TYPE.COMPANY && !unit.parentUnitId ? true : unit.type === UNIT_TYPE.COMPANY && unit.parentUnitId ? true : false;

  const stopEvent = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const openMenu = event => {
    stopEvent(event);
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = event => {
    stopEvent(event);
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const openStartInitiateSalesBudget = event => {
    stopEvent(event);
    closeMenu(event);
    setStartInitiateSalesBudgetOpen(true);
  }
  const closeStartInitiateSalesBudget = event => {
    stopEvent(event);
    setStartInitiateSalesBudgetOpen(false);
  }

  const openInitiateSalesBudget = event => {
    stopEvent(event);
    closeMenu(event);
    setInitiateSalesBudgetOpen(true);
  };
  const closeInitiateSalesBudget = event => {
    stopEvent(event);
    setInitiateSalesBudgetOpen(false);
  };

  const openRequestActionModal = (e, status) => {
    stopEvent(e);
    closeMenu(e);
    setNextStatus(status);
    setRegionActionModalOpen(true);
  };
  const closeRegionActionModal = e => {
    stopEvent(e);
    setRegionActionModalOpen(false);
  }

  const onCloseDownloadPopup = () => {
    setOpenDownloadBudgetPopup(false);
    setMenuAnchorEl(null);
  }

  const onDownloadBudget = (year) => {
    dispatch(downloadBudgetExcel(unit, year));
    onCloseDownloadPopup();
  }

  const hasSalesToRequestApproval = sales.some(sale => sale.saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED);
  const hasSalesToApprove = sales.some(sale => sale.saleStatusId === SALE_STATUS.APPROVAL_REQUESTED);
  const hasSalesToFinalize = sales.some(sale => sale.saleStatusId === SALE_STATUS.APPROVED);
  const isCompany = unit.type === UNIT_TYPE.COMPANY;
  const menuItems = [];

  menuItems.push(
    isCfo && unit.type !== 'distributor' ? (
      <MenuItem
        key="initiateSalesBudget"
        onClick={openStartInitiateSalesBudget}
      >
        Initiate sales budget
      </MenuItem>
    ) : (
      <MenuItem
        key="initiateSalesBudget"
        onClick={openInitiateSalesBudget}
        disabled={
          (unit.type !== 'distributor' && !saleInitiations.some(si =>
            si.year === nextBudgetYear() &&
            si.assigneeId === user.id
          )) ||
          !canInitiateBudgetSales(deepValue(unit, 'permission.level'))
        }
      >
        Initiate sales budget
      </MenuItem>
    ),
    <MenuItem
      key="requestRegionApproval"
      onClick={e => openRequestActionModal(e, SALE_STATUS.APPROVAL_REQUESTED)}
      disabled={isCompany || !hasSalesToRequestApproval || !canContributeBudgetSales(deepValue(unit, 'permission.level'))}
    >
      Request approval
    </MenuItem>,
    <MenuItem
      key="approveRegion"
      onClick={e => openRequestActionModal(e, SALE_STATUS.APPROVED)}
      disabled={isCompany || !hasSalesToApprove || !canApproveBudgetSales(deepValue(unit, 'permission.level'))}
    >
      Approve
    </MenuItem>,
    <MenuItem
      key="finalizeRegion"
      onClick={e => openRequestActionModal(e, SALE_STATUS.FINAL)}
      disabled={isCompany || !hasSalesToFinalize || !canFinalizeBudgetSales(deepValue(unit, 'permission.level'))}
    >
      Finalize
    </MenuItem>
  );

  if (isCfo && isParentCompany) {
    menuItems.push(
      <MenuItem
        key="downloadExcel"
        onClick={() => {
          closeMenu();
          setOpenDownloadBudgetPopup(true);
          setMenuAnchorEl(downloadBudgetAnchorRef.current);
        }}
      >
        Download budget excel
      </MenuItem>
    );
  }

  return [
    <>
      <IconButton
        ref={downloadBudgetAnchorRef}
        id={'unit-id'} //required for tutorial
        onClick={openMenu}
        size="small">
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id={'unit-id-menu'} //required for tutorial
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={closeMenu}>
        {menuItems}
      </Menu>
    </>,
    <>
      <StartSalesInitiationModal
        open={startInitiateSalesBudgetOpen}
        onClose={closeStartInitiateSalesBudget}
        unit={unit}
      />
      <InitiateSalesBudget
        open={initiateSalesBudgetOpen}
        onClose={closeInitiateSalesBudget}
        unit={unit}
        sales={sales}
        assignments={assignments}
      />
      <RequestRegionApprovalModal
        open={regionActionModalOpen}
        onClose={closeRegionActionModal}
        nextStatus={nextStatus}
        sales={sales}
      />
      <DownloadBudgetPopup
        anchorEl={menuAnchorEl}
        open={openDownloadBudgetPopup}
        hasCurrentYear={hasCurrentYear}
        hasNextYear={hasNextYear}
        onClose={onCloseDownloadPopup}
        onDownload={onDownloadBudget}
      />
    </>
  ];
}
