const backend = '../../../backend/budget-api/node_modules/';
const math = (
  process.browser ?
    require('mathjs') :
    require(`${backend}mathjs`)
);

const add = (result, where, node) => {
  const set = result[where] = result[where] || new Set();
  for (const arg of node.args) {
    set.add(arg.value);
  }
};

const functions = {
  account: {
    args: [{ type: math.ConstantNode }],
    callback: (result, node) => add(result, 'accounts', node)
  },
  accounts: {
    args: { type: math.ConstantNode, length: undefined },
    callback: (result, node) => add(result, 'accounts', node)
  },
  accountGroup: {
    args: [{ type: math.ConstantNode }],
    callback: (result, node) => add(result, 'accountGroups', node)
  },
  sales: {
    args: [{ type: math.ConstantNode, optional: true }],
    callback: (result, node) => result.sales = result.sales.concat(node.args.length ? node.args[0].value : true)
  },
  costs: {
    args: [],
    callback: result => result.costs = true
  },

  record: {
    args: [{ type: math.ConstantNode }],
    callback: (result, node) => add(result, 'records', node)
  },
  records: {
    args: { type: math.ConstantNode, length: 'any' },
    callback: (result, node) => add(result, 'records', node)
  },

  groupRecord: {
    args: [{ type: math.ConstantNode }],
    callback: (result, node) => add(result, 'groupRecords', node)
  },
  groupRecords: {
    args: { type: math.ConstantNode, length: 'any' },
    callback: (result, node) => add(result, 'groupRecords', node)
  },

  sum: {},
  percentage: {
    args: { type: undefined, length: undefined }
  }
}

const schemata = {
  accounts: {
    account: functions.account,
    accounts: functions.accounts,
    sales: functions.sales,
    sum: functions.sum
  },
  consolidated: {
    account: functions.account,
    accounts: functions.accounts,
    accountGroup: functions.accountGroup,
    sales: functions.sales,
    costs: functions.costs,
    sum: functions.sum,
    percentage: functions.percentage,
    record: functions.record,
    records: functions.records
  },
  groupConsolidated: {
    record: functions.record,
    records: functions.records,
    groupRecord: functions.groupRecord,
    groupRecords: functions.groupRecords,
    sum: functions.sum,
    percentage: functions.percentage
  }
};

const parse = (expression, mode = 'accounts') => {
  const result = {
    variables: new Set(),
    accounts: new Set(),
    accountGroups: new Set(),
    sales: [],
    costs: false,
    records: new Set(),
    groupRecords: new Set(),
    percentage: false,
  };

  const recurse = node => {
    if (node instanceof math.OperatorNode) {
      for (const child of node.args) {
        recurse(child);
      }
    }

    const schema = schemata[mode];
    if (node instanceof math.FunctionNode) {
      const validator = schema[node.name];
      if (!validator) {
        throw new Error(`Unsupported function ${node.name}`);
      }
      if (validator.args.length === undefined && validator.args.type !== undefined) {
        for (const arg of node.args) {
          if (!(arg instanceof validator.args.type)) {
            throw new Error(`Invalid arguments for function '${node.name}'`);
          }
        }
      } else if (Array.isArray(validator.args)) {
        const minArgs = validator.args.filter(arg => !arg.optional).length;
        const maxArgs = validator.args.length;
        if (minArgs === maxArgs && node.args.length !== minArgs) {
          throw new Error(`Invalid number of arguments for function '${node.name}': expected ${minArgs}, got ${node.args.length}`);
        } else if (node.args.length < minArgs || node.args.length > maxArgs) {
          throw new Error(`Invalid number of arguments for function '${node.name}': expected ${minArgs} - ${maxArgs}, got ${node.args.length}`);
        }
        for (let i = 0; i < node.args.length; i++) {
          if (!(node.args[i] instanceof validator.args[i].type)) {
            throw new Error(`Invalid arguments at position ${i + 1} in function '${node.name}'`);
          }
        }
      }
      if (validator.callback) {
        validator.callback(result, node);
      }
      for (const child of node.args) {
        recurse(child);
      }
    }

    if (node instanceof math.ParenthesisNode) {
      recurse(node.content);
    }
    if (node instanceof math.SymbolNode) {
      if (mode === 'consolidated') {
        throw new Error(`Invalid reference '${node.name}'`);
      }
      if (Object.keys(schema).includes(node.name)) {
        throw new Error(`Cannot use function '${node.name}' as a variable`);
      }
      result.variables.add(node.name);
    }

    // TODO: prevent use of other nodes (object, array, property, etc.)
  };

  const root = math.parse(expression);
  if (root instanceof math.FunctionNode && root.name === 'percentage') {
    result.percentage = true;
  }
  recurse(root);

  return {
    variables: Array.from(result.variables),
    accounts: Array.from(result.accounts),
    accountGroups: Array.from(result.accountGroups),
    sales: result.sales.length ? result.sales.filter(s => s !== true) : false,
    costs: result.costs,
    records: Array.from(result.records),
    groupRecords: Array.from(result.groupRecords),
    percentage: result.percentage
  };
};

const evaluate = (expression, scope) => {
  return math.evaluate(expression, scope);
};


module.exports = {
  parse,
  evaluate
};
