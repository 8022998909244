import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DecimalField from '../common/DecimalField';
import { parseNumber } from '../../../../../common/src/util/number';

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  }
}));

export default function CalculateFromPrevious({ open, onConfirm, onClose }) {
  const classes = useStyles();

  const [percentage, setPercentage] = useState('0');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setPercentage('0');
    }
  }, [open]);

  const onPercentageChange = ({ target: { value } }, valid) => {
    setPercentage(value);
    setErrors({ percentage: !valid });
  }

  const valid = Object.values(errors).every(value => !value);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Calculate from last year</DialogTitle>
      <DialogContent>
        <FormControl className={classes.input}>
          <DecimalField label="Percentage"
                        helperText="Percent increase/decrease relative to the previous year"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        value={percentage}
                        onChange={onPercentageChange}
                        error={!!errors.percentage}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={() => onConfirm(parseNumber(percentage))} color="primary" disabled={!valid} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
