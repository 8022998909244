import React, { useRef, useState } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import AccountTitle from '../common/PageTitle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ConsolidatedGroupModal from './ConsolidatedGroupModal';
import { addConsolidatedGroup, toggleModal } from '../../action/consolidated';
import { downloadBudgetExcel } from '../../action/unit';
import { PERMISSION_LEVEL } from "../../../../../common/src/util/enum";
import CircularProgress from "@material-ui/core/CircularProgress";
import DownloadBudgetPopup from "../sales/DownloadBudgetPopup";
import { currentBudgetYear, nextBudgetYear } from '../../../../../common/src/util/date';

export default function ConsolidatedHeader({ unit, root }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openDownloadBudgetPopup, setOpenDownloadBudgetPopup] = useState(false);
  const isCfo = unit?.permission?.level >= PERMISSION_LEVEL.CFO;
  // VEIDEC AB and VEIDEC GROUP AB both have type company and parentUnitId null and countryCode null
  const consolidated = useSelector(state => state.consolidated.consolidated);
  const showExcelLoader = useSelector(state => state.unit.showGeneratingExcelLoader);
  const years = consolidated.groups ? consolidated.groups
    .map(group => group.records)
    .flat()
    .filter(({ type }) => type === 'budget')
    .map(item => item.year) : [];
  const hasNextYear = years.includes(nextBudgetYear());
  const hasCurrentYear = years.includes(currentBudgetYear());
  const downloadBudgetAnchorRef = useRef();

  const groupModalId = `group-new`;
  const consolidatedGroupModalOpen = useSelector(state => state.consolidated.openModals[groupModalId] || false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onNewGroupClicked = () => {
    setMenuOpen(false);
    dispatch(toggleModal(groupModalId, true));
  };
  const onNewGroupConfirm = group => {
    dispatch(addConsolidatedGroup({ ...group, unitId: unit.id }));
  };

  const onDownloadBudgetExcel = (year) => {
    dispatch(downloadBudgetExcel(unit, year));
    onCloseDownloadPopup();
  }

  const onCloseDownloadPopup = () => {
    setOpenDownloadBudgetPopup(false);
    setMenuAnchorEl(null);
  }

  const showNewConsolidatedSection = !root && !!unit;

  return (
    <>
      <AccountTitle title={`Consolidated - ${unit ? unit.name : 'Distributors'}`}>
        { showExcelLoader && <CircularProgress size={20} /> }
        {isCfo &&
        <>
          <IconButton
              ref={downloadBudgetAnchorRef}
              size={'small'}
              onClick={openMenu}
          >
            <MoreVertIcon/>
          </IconButton>
          <Menu open={menuOpen}
                anchorEl={menuAnchorEl}
                keepMounted
                onClose={closeMenu}>
            {showNewConsolidatedSection &&
            <MenuItem onClick={onNewGroupClicked}>
              New consolidated group
            </MenuItem>
            }
            {
              isCfo && (
                  <MenuItem
                      key="downloadExcel"
                      disabled={!consolidated.groups || !consolidated.groups.length}
                      onClick={() => {
                        closeMenu();
                        setOpenDownloadBudgetPopup(true);
                        setMenuAnchorEl(downloadBudgetAnchorRef.current);
                      }}
                  >
                    Download consolidated excel
                  </MenuItem>
              )
            }
          </Menu>
          {showNewConsolidatedSection &&
          <ConsolidatedGroupModal open={consolidatedGroupModalOpen}
                                  onCancel={() => dispatch(toggleModal(groupModalId, false))}
                                  onConfirm={onNewGroupConfirm}
                                  title="New consolidated group"/>
          }
          <DownloadBudgetPopup
              anchorEl={menuAnchorEl}
              open={openDownloadBudgetPopup}
              hasCurrentYear={hasCurrentYear}
              hasNextYear={hasNextYear}
              onClose={onCloseDownloadPopup}
              onDownload={onDownloadBudgetExcel}
          />
        </>
        }
      </AccountTitle>
    </>
  );
};

ConsolidatedHeader.propTypes = {
  unit: PropTypes.object,
};
