import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { toReadablePermissionLevel } from '../../../../../common/src/util/enum';
import COUNTRY_BY_CODE from '../../../../../common/src/util/countries';

import Title from '../common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    width: 130,
    display: 'inline-block',
    padding: theme.spacing(1),
  },
  personIcon: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  type: {
    textTransform: 'capitalize',
    display: 'inline-block'
  }
}));

export default function Detail() {
  const classes = useStyles();

  const unit = useSelector(state => state.unit);

  const selectedUnit = unit.units && unit.units.find(u => u.id === unit.selectedUnitId);

  let details;
  if (selectedUnit) {
    const { name, type, permission, countryCode } = selectedUnit;
    details = (
      <React.Fragment key="details">
        <div className={classes.details}>
          <div key="name" className={classes.label}>Name</div>
          {name}<br/>
          <div key="type" className={classes.label}>Type</div>
          <span className={classes.type}>{type}</span>
          <br/>
          {
            countryCode &&
            <>
              <div key="type" className={classes.label}>Country</div>
              {COUNTRY_BY_CODE[countryCode]}<br/>
            </>
          }
        </div>
      </React.Fragment>
    );
  } else {
    details = 'No units selected';
  }

  return (
    <Accordion defaultExpanded className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Title>Details</Title>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
}
