import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  breadcrumbsWrapper: {
    margin: theme.spacing(1),
    color: theme.palette.background.default,
  },
  link: {
    color: theme.palette.background.default,
  },
}));

const BreadCrumbs = ({ breadCrumbsList }) => {
  const classes = useStyles();

  if (!breadCrumbsList) return null;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={classes.breadcrumbsWrapper}
    >
      {
        breadCrumbsList.map((item, index) => {
          if (!item.to)
            return <Typography key={index} className={classes.link}>{item.title}</Typography>;

          return (
            <Link
              key={index}
              className={classes.link}
              to={item.to}
              component={RouterLink}
              fontWeight="fontWeightBold"
            >
              {item.title}
            </Link>
          );
        })
      }
    </Breadcrumbs>
  )
};

BreadCrumbs.propTypes = {
  breadCrumbsList: PropTypes.array,
};

export default memo(BreadCrumbs);
