import React, { memo } from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import areEqual from "../../../util/areEqual";

const useStyles = makeStyles(theme => ({
  bottomDots: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  dot: {
    display: 'inline-block',
    width: 12,
    height: 12,
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: '50%',
    '&:not(:last-child)': {
      marginRight: 10
    }
  },
  activeDot: {
    backgroundColor: theme.palette.primary.main,
    animation: `$animateCircle 500ms ease forwards`,
  },
  "@keyframes animateCircle": {
    "0%": {
      transform: "scale(1)"
    },
    "100%": {
      transform: "scale(1.5)"
    }
  },
}));

const StepDots = ({ stepsLength, activeStep, onSepChange, availableSteps }) => {
  const classes = useStyles();

  return (
    <div className={classes.bottomDots}>
      {
        availableSteps ? availableSteps.map((item, index) => (
            <span
              key={index}
              className={clsx(classes.dot, {
                [classes.activeDot]: item === activeStep
              })}
              onClick={() => onSepChange(index)}
            ></span>
          )) :
          [...new Array(stepsLength)].map((item, index) => (
            <span
              key={index}
              className={clsx(classes.dot, {
                [classes.activeDot]: index === activeStep
              })}
              onClick={() => onSepChange(index)}
            ></span>
          ))
      }
    </div>
  );
}

export default memo(StepDots, areEqual);