import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { addConsolidatedGroupedRecord, toggleModal } from '../../action/consolidated';
import ConsolidatedGroupedRecordModal from './ConsolidatedGroupedRecordModal';

const ConsolidatedGroupedMenu = ({ companies }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const recordModalId = `grouped-record-create`;
  const recordModalOpen = useSelector(state => state.consolidated.openModals[recordModalId] || false);
  const [record, setRecord] = useState({});

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const onNewRecordClicked = () => {
    setMenuOpen(false);
    setRecord({});
    dispatch(toggleModal(recordModalId, true));
  };
  const onNewRecordConfirm = record => {
    console.log(record);
    dispatch(addConsolidatedGroupedRecord(record));
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu open={menuOpen}
            anchorEl={menuAnchorEl}
            keepMounted
            onClose={closeMenu}>
        <MenuItem key={`group-new-record`}
                  onClick={onNewRecordClicked}>
          New group consolidated record
        </MenuItem>
      </Menu>
      <ConsolidatedGroupedRecordModal open={recordModalOpen}
                                      onCancel={() => dispatch(toggleModal(recordModalId, false))}
                                      onConfirm={onNewRecordConfirm}
                                      title="New grouped consolidated record"
                                      record={record}
                                      companies={companies}/>
    </div>
  );
};

export default memo(ConsolidatedGroupedMenu);
