const summarize = require('./summarize');
const { months, SALE_TYPE, SALE_STATUS } = require('../util/enum');
const { currentBudgetYear, nextBudgetYear } = require('./date');

const evaluateYear = (sales, func) => {
  let year = undefined;
  sales.forEach(sale => year = func(year || sale.year, sale.year));
  return year;
};

const generateTotals = ({ maxYear, minYear, sales, saleTypeId, idExtension, additionalParams }) => {
  let totals = [];
  const isOutcome = saleTypeId === SALE_TYPE.AMOUNT;
  maxYear = isOutcome && maxYear === nextBudgetYear() ? maxYear - 1 : maxYear;

  for (let year = maxYear; year >= minYear; year--) {
    if (!isOutcome && year <= currentBudgetYear() - 1) {
      continue;
    }
    if (year <= currentBudgetYear() - 2) {
      continue;
    }

    const total = {
      id: `total.${year}.${saleTypeId}${idExtension ? `.${idExtension}` : ''}`,
      name: 'Total',
      year,
      saleTypeId,
      isTotal: true,
      total: true,
      unitId: sales[0].unitId,
      ...additionalParams
    };
    months.forEach(month => total[month] = 0);
    for (const sale of sales)
      if (
        sale.year === year &&
        (sale.saleTypeId === saleTypeId) &&
        // BT-307 (count deleted historical records, but not budgeted)
        (sale.saleTypeId === SALE_TYPE.AMOUNT || !sale.saleStatusId !== SALE_STATUS.DELETED)
      )
        months.forEach(month => total[month] += sale[month] || 0);
    totals.push(total);
  }
  return totals;
};

const groupSalesTotalByUnitId = groupList => {
  groupList = groupList.map(group => {
    const maxYear = evaluateYear(group.sales, Math.max);
    const minYear = evaluateYear(group.sales, Math.min);
    const sales = [];

    sales.push(...generateTotals({
      maxYear,
      minYear,
      sales: group.sales,
      saleTypeId: SALE_TYPE.AMOUNT_BUDGET,
      idExtension: `${group.unit.name}.${group.unit.parentUnitId}`,
      additionalParams: { total: false, name: group.unit.name, isTotal: false }
    }));
    sales.push(...generateTotals({
      maxYear,
      minYear,
      sales: group.sales,
      saleTypeId: SALE_TYPE.AMOUNT,
      idExtension: `${group.unit.name}.${group.unit.parentUnitId}`,
      additionalParams: { total: false, name: group.unit.name, isTotal: false }
    }));
    sales.forEach(item => {
      if (group.unit.parentUnitId) {
        item.parentUnitId = group.unit.parentUnitId;
      }
    });
    return sales;
  });

  let saleTotals = groupList.flat();
  const maxYear = evaluateYear(saleTotals, Math.max);
  const minYear = evaluateYear(saleTotals, Math.min);
  const totals = [];

  totals.push(...generateTotals({ maxYear, minYear, sales: saleTotals, saleTypeId: SALE_TYPE.AMOUNT_BUDGET }));
  totals.push(...generateTotals({ maxYear, minYear, sales: saleTotals, saleTypeId: SALE_TYPE.AMOUNT }));
  saleTotals.push(...totals);
  groupList.push(totals);
  return { groupedRegions: groupList, regionsTotals: saleTotals };
};

const getPrognosis = (cost, group) => {
  if (!cost.budget) {
    return null;
  }
  const currentYear = new Date().getFullYear(); // this is in fact the current calendar year, not budget year
  const prevOutcome = group.costs.find(c => c.typeId === cost.typeId && !c.budget && c.year === cost.year - 1);
  if (cost.year > currentYear) {
    const currentMonth = new Date().getMonth();
    const prevBudget = group.costs.find(c => c.typeId === cost.typeId && c.budget && c.year === cost.year - 1);
    return (prevOutcome && prevBudget) ?
      summarize(prevOutcome, ...months.slice(0, currentMonth)) +
      summarize(prevBudget, ...months.slice(currentMonth, months.length)) :
      null;
  } else {
    return prevOutcome ? summarize(prevOutcome) : null;
  }
};


module.exports = {
  groupSalesTotalByUnitId,
  generateTotals,
  evaluateYear,
  getPrognosis
};
