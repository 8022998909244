import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import GroupIcon from '@material-ui/icons/Group';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EuroIcon from '@material-ui/icons/Euro';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Unit from './Unit';
import { deepCopy } from '../../../../../common/src/util/object';
import { PERMISSION_LEVEL, toReadablePermissionLevel, UNIT_TYPE } from '../../../../../common/src/util/enum';
import {
  expandUnitsNodes,
  fetchSaleAssignments,
  fetchUnits,
  selectUnit,
  updateSaleAssignments,
} from '../../action/unit';
import Title from '../common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { SaleAssignmentModal } from '../sales/SaleAssignmentModal';
import Tooltip from '@material-ui/core/Tooltip';
import hasModulePermission from "../../util/hasModulePermission";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tree: {
    maxHeight: props => props.limitHeight ? 'calc(100vh - 480px)' : '',
    minHeight: '300px',
    overflowY: 'auto',
    width: '100%',
  },
  actions: {
    display: 'inline',
    // flexDirection: 'column',
  },
});

const _resolveNestedUnits = (units, parentId = null) => {
  const nestedUnits = [];
  const length = units.length;

  for (let i = 0; i < length; i++) {
    const unit = units[i];
    if (unit.parentUnitId === parentId) {
      const children = _resolveNestedUnits(units, unit.id);
      if (children.length > 0) {
        unit.children = children;
      }
      unit._foundParent = true;
      nestedUnits.push(unit);
    }
  }

  // Sort by name, but keep the VEIDEC companies in the top;
  nestedUnits.sort(({ name: name1, type: type1 }, { name: name2, type: type2 }) => {
    const isCompany1 = type1 === UNIT_TYPE.COMPANY;
    const isCompany2 = type2 === UNIT_TYPE.COMPANY;
    if (isCompany1 && !isCompany2)
      return -1;
    if (!isCompany1 && isCompany2)
      return 1;
    return name1.toLowerCase().localeCompare(name2.toLowerCase())
  });

  return nestedUnits;
};

const resolveNestedUnits = units => {
  if (!units || units.length === 0)
    return [];

  const nestedUnits = _resolveNestedUnits(units);

  // Orphans
  for (let unit of units)
    if (!unit._foundParent)
      nestedUnits.push(unit);

  return nestedUnits;
};

const unitIcon = type => {
  switch (type) {
    case 'company':
      return LocationCityIcon;
    case 'distributor':
      return LocationCityIcon;
    case 'region':
      return GroupIcon;
    case 'department':
      return GroupIcon;
    default:
      return GroupIcon;
  }
};

const renderUnits = (units, companiesOnly = false, depth = 0, underlineLinks = true) => {
  const renderedUnits = [];
  for (const unit of units) {
    if (companiesOnly && unit.type !== 'company') {
      continue;
    }
    const renderedChildren = unit.children ? renderUnits(unit.children, companiesOnly, depth + 1, underlineLinks) : '';
    renderedUnits.push(
      <Unit
        nodeId={String(unit.id)}
        key={unit.id}
        labelText={unit.name}
        labelIcon={unitIcon(unit.type)}
        labelInfo={toReadablePermissionLevel(unit.permission.level)}
        depth={depth}
        underlineLinks={underlineLinks}>
        {renderedChildren}
      </Unit>,
    );
  }
  return renderedUnits;
};

const expectedUnitAssignments = unit => {
  switch (unit?.type) {
    case UNIT_TYPE.REGION:
    case UNIT_TYPE.DISTRIBUTOR:
      return 1;
    case UNIT_TYPE.COMPANY:
      return 2;
    default:
      return Number.MAX_VALUE;
  }
};

const Units = (
  {
    hideActions,
    onSelect,
    companiesOnly = false,
    limitHeight = false,
    underlineLinks = true,
    addDistributorsNode = false
  }) => {
  onSelect = onSelect ? onSelect : () => {};
  const classes = useStyles({ limitHeight });
  const [expanded, setExpanded] = useState(true);

  const [saleAssignmentModalOpen, setSaleAssignmentModalOpen] = useState(false);
  const unit = useSelector(state => state.unit);
  const permissions = useSelector(state => state.user.permissions);
  const modulesPermissions = useSelector(state => state.user.modulesPermissions);
  const isCfo = _.some(permissions, permission => permission.level >= PERMISSION_LEVEL.CFO);
  const isReader = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.READ);
  const isApprover = _.some(permissions, permission => permission.level === PERMISSION_LEVEL.OWNER);

  const assignments = useSelector(state => {
    const entry = state.unit.saleAssignments.find(sa => sa.id === unit.selectedUnitId);
    return entry ? entry.saleAssignments : [];
  });
  const users = useSelector(state => {
    const fetchedUsers = state.unit.fetchedUnitUsers[unit.selectedUnitId] || [];
    return _.keyBy(fetchedUsers, user => user.id);
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchUnits());
    if (isCfo) {
      dispatch(fetchSaleAssignments());
    }
  }, [dispatch, isCfo]);

  let loader, content;

  if (unit.fetchingUnits) {
    loader = unit.fetchingUnits ? <CircularProgress size={16}/> : '';
  } else if (!unit.units || unit.units.length === 0) {
    content = 'You are not assigned to any units.';
  } else {
    const units = resolveNestedUnits(deepCopy(unit.units));
    const list = renderUnits(units, companiesOnly, 0, underlineLinks);
    if (addDistributorsNode) {
      list.push((
        <Unit
          nodeId="-2"
          key="distributors"
          labelText="Distributors"
          labelIcon={unitIcon('distributor')}
          labelInfo={toReadablePermissionLevel(PERMISSION_LEVEL.CFO)}
          depth={0}
          underlineLinks={true}>
        </Unit>)
      );
    }

    content = (
      <div className={classes.tree}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDownIcon/>}
          defaultExpandIcon={<ArrowRightIcon/>}
          defaultEndIcon={<div style={{ width: 40 }}/>}
          onNodeSelect={(event, nodeId) => {
            const unitId = parseInt(nodeId);
            dispatch(selectUnit(unitId));
            if (event.target.getAttribute('data-url'))
              onSelect(unitId);
          }}
          onNodeToggle={(event, nodesIds) => {
            if (event.target.getAttribute('data-url'))
              return;
            dispatch(expandUnitsNodes(nodesIds))
          }}
          expanded={unit.expandedUnitsNodesIds}
          selected={String(unit.selectedUnitId)}
        >
          {list}
        </TreeView>
      </div>
    );
  }

  const hasSelectedUnit = unit.units && unit.units.find(u => u.id === unit.selectedUnitId);
  const isNotCompany = hasSelectedUnit?.type !== 'company';

  const missingAssignments = assignments.length < expectedUnitAssignments(hasSelectedUnit);

  let actions;
  if (!hideActions)
    actions = (
      <AccordionActions>
        {
          hasModulePermission({ moduleName: 'sales', modulesPermissions, isCfo, isReader, isApprover }) && (
            <Button
              id={'view-sales-button'} // required for tutorial
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/unit/${unit.selectedUnitId}/sale`)}
              startIcon={<ShoppingCartIcon/>}
              className={classes.actionButton}
              disabled={!hasSelectedUnit}
            >
              View sales
            </Button>
          )
        }
        {isCfo && hasModulePermission({ moduleName: 'sales', modulesPermissions, isCfo, isReader, isApprover }) && (
          <>
            <Tooltip title="Sale assignments missing"
                     placement="top"
                     open={!!hasSelectedUnit && missingAssignments}
                     color="secondary">
              <Button
                id={'sale-assignment-button'}
                size="small"
                variant="outlined"
                color={missingAssignments ? 'secondary' : 'primary'}
                onClick={() => setSaleAssignmentModalOpen(true)}
                startIcon={<AccountTreeIcon/>}
                className={classes.actionButton}
                disabled={!hasSelectedUnit}
              >
                Sale Assignments
              </Button>
            </Tooltip>
          </>
        )}
        {
          hasModulePermission({ moduleName: 'costs', modulesPermissions, isCfo, isReader, isApprover }) && (
            <Button
              id={'view-costs-button'} // required for tutorial
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/unit/${unit.selectedUnitId}/costs`)}
              startIcon={<EuroIcon/>}
              className={classes.actionButton}
              disabled={!hasSelectedUnit || isNotCompany}
            >
              View Costs
            </Button>
          )
        }
        {isCfo && hasModulePermission({ moduleName: 'costs', modulesPermissions, isCfo, isReader, isApprover }) && (
          <Button
            id={'cost-accounts-button'} // required for tutorial
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => history.push(`/unit/${unit.selectedUnitId}/accounts`)}
            startIcon={<AccountTreeIcon/>}
            className={classes.actionButton}
            disabled={!hasSelectedUnit || isNotCompany}
          >
            Cost Accounts
          </Button>
        )}
      </AccordionActions>
    );

  return (
    <>
      <Accordion
        expanded={expanded}
        className={classes.accordion}
        onChange={(event, isExpanded) => setExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Title>Units {loader}</Title>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
        <Divider/>
        {actions}
      </Accordion>
      <SaleAssignmentModal
        open={saleAssignmentModalOpen}
        onClose={() => setSaleAssignmentModalOpen(false)}
        onConfirm={(approvers, contributor) => {
          dispatch(updateSaleAssignments(unit.selectedUnitId, approvers, contributor));
          setSaleAssignmentModalOpen(false);
        }}
        assignments={assignments}
        users={users}
        unit={hasSelectedUnit}
      />
    </>
  );
};

export default memo(Units);
