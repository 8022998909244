import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { fetchExchangeRates, updateExchangeRates } from '../../action/consolidated';
import update from 'immutability-helper';
import { FormControl, IconButton, Paper, TextField } from '@material-ui/core';
import Title from '../common/Title';
import DecimalField from '../common/DecimalField';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  input: {
    width: `calc(33% - ${theme.spacing(4)}px)`,
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  delete: {
    marginTop: theme.spacing(1),
  },
}));

const ExchangeRatesPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [rates, setRates] = useState([]);

  const updating = useSelector(state => state.consolidated.updating);
  const exchangeRates = useSelector(state => state.consolidated.exchangeRates);

  useEffect(() => {
    dispatch(fetchExchangeRates())
  }, []);

  useEffect(() => {
    setRates(exchangeRates);
  }, [exchangeRates]);

  const doUpdate = (index, key, value) => {
    setRates(update(rates, { [index]: { [key]: { $set: value } } }));
  }
  const updateCountryCode = ({ target: { value } }, index) => {
    setErrors({ ...errors, countryCode: value.length !== 2 });
    doUpdate(index, 'countryCode', value);
  };
  const updateCurrency = ({ target: { value } }, index) => {
    setErrors({ ...errors, currency: value.length !== 3 });
    doUpdate(index, 'currency', value);
  };
  const updateRate = ({ target: { value } }, index, valid) => {
    setErrors({ ...errors, rate: !valid });
    doUpdate(index, 'rate', value);
  };
  const addExchangeRate = () => {
    setRates(update(rates, { $push: [{}] }));
  }
  const removeExchangeRate = index => {
    setRates(update(rates, { $splice: [[index, 1]] }));
  }

  const onSave = () => {
    dispatch(updateExchangeRates(rates));
  };

  const valid = _.every(_.values(errors), v => !v);

  return (
    <>
      <Paper className={classes.paper}>
        <Title>Exchange rates</Title>
        {rates.map((exchangeRate, index) => (
          <Fragment key={index}>
            <FormControl component="fieldset" className={classes.input}>
              <TextField label="Country code"
                         value={exchangeRate.countryCode}
                         disabled={updating}
                         onChange={e => updateCountryCode(e, index)}/>
            </FormControl>
            <FormControl component="fieldset" className={classes.input}>
              <TextField label="Currency code"
                         value={exchangeRate.currency}
                         disabled={updating}
                         onChange={e => updateCurrency(e, index)}/>
            </FormControl>
            <FormControl component="fieldset" className={classes.input}>
              <DecimalField label="Rate"
                            value={exchangeRate.rate}
                            disabled={updating}
                            onChange={(e, valid) => updateRate(e, index, valid)}/>
            </FormControl>
            <IconButton component="span"
                        color="primary"
                        className={classes.delete}
                        disabled={updating}
                        onClick={() => removeExchangeRate(index)}>
              <CancelIcon/>
            </IconButton>
          </Fragment>
        ))}
        <Button size="large"
                color="primary"
                className={classes.fullWidth}
                disabled={updating}
                onClick={addExchangeRate}
                startIcon={<AddCircle/>}>
          Add exchange rate
        </Button>

        <div className={classes.footer}>
          <Button size="large"
                  color="primary"
                  disabled={!valid || updating}
                  onClick={onSave}>
            Save
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default memo(ExchangeRatesPanel);
