import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import areEqual from "../../../util/areEqual";

const useStyles = makeStyles(theme => ({
  clickCircleOuter: {
    position: 'absolute',
    width: 20,
    height: 20,
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: '50%',
    left: (props) => props.left - 5,
    top: (props) => props.top - 4,
    animation: `$animateCircle 750ms ease forwards`,
  },
  clickCircle: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: 'rgba(0,0,0,0.9)',
    borderRadius: '50%',
    left: (props) => props.left,
    top: (props) => props.top,
    animation: `$animateCircle 750ms ease forwards`,
  },
  "@keyframes animateCircle": {
    "0%": {
      opacity: 1,
      transform: "scale(1)"
    },
    "100%": {
      opacity: 0,
      transform: "scale(4)"
    }
  },
  "@keyframes animateBackground": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    }
  }
}));

const ClickCircle = memo((props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.clickCircle}></div>
      <div className={classes.clickCircleOuter}></div>
    </>
  );
}, areEqual);

export default ClickCircle;