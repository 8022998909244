import React, { useRef, useState } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { PERMISSION_LEVEL, UNIT_TYPE } from '../../../../../common/src/util/enum';
import AccountTitle from '../common/PageTitle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CostInitiateDialog } from './CostInitiateDialog';
import DownloadBudgetPopup from "../sales/DownloadBudgetPopup";
import { downloadBudgetExcel } from "../../action/unit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { nextBudgetYear, currentBudgetYear } from '../../../../../common/src/util/date';

export default function CostHeader({ unit, children, categories }) {
  const isCfo = unit?.permission?.level >= PERMISSION_LEVEL.CFO;
  const costYears = categories.map(item => item.types)
    .flat()
    .map(item => item.costs)
    .flat()
    .filter(item => item.budget)
    .map(item => item.year);
  const hasNextYear = costYears.includes(nextBudgetYear());
  const hasCurrentYear = costYears.includes(currentBudgetYear());
  const isParentCompany = unit.type === UNIT_TYPE.COMPANY && !unit.parentUnitId ? true : unit.type === UNIT_TYPE.COMPANY && unit.parentUnitId ? true : false;
  const showExcelLoader = useSelector(state => state.unit.showGeneratingExcelLoader);

  const [menuOpen, setMenuOpen] = useState(false);
  const [openDownloadBudgetPopup, setOpenDownloadBudgetPopup] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const downloadBudgetAnchorRef = useRef();
  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };
  const onInitiateClicked = () => {
    closeMenu();
    setDialogOpen(true);
  }

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const onCloseDownloadPopup = () => {
    setOpenDownloadBudgetPopup(false);
    setMenuAnchorEl(null);
  };

  const onDownloadBudget = (year) => {
    dispatch(downloadBudgetExcel(unit, year));
    onCloseDownloadPopup();
  };

  return (
    <>
      <AccountTitle
        title={`Costs - ${unit.name}`}
        newLineChildren={children}
      >
        { showExcelLoader && <CircularProgress size={20} /> }
        {isCfo && (
          <>
            <IconButton
              ref={downloadBudgetAnchorRef}
              id={'unit-id'} //required for tutorial
              onClick={openMenu}
            >
              <MoreVertIcon/>
            </IconButton>
            <Menu
              id={'unit-id-menu'} //required for tutorial
              open={menuOpen}
              anchorEl={menuAnchorEl}
              keepMounted
              onClose={closeMenu}
            >
              <MenuItem onClick={onInitiateClicked}>
                Initiate costs
              </MenuItem>
              {
                isCfo && isParentCompany && (
                  <MenuItem
                    key="downloadExcel"
                    disabled={!hasNextYear && !hasCurrentYear}
                    onClick={() => {
                      closeMenu();
                      setOpenDownloadBudgetPopup(true);
                      setMenuAnchorEl(downloadBudgetAnchorRef.current);
                    }}
                  >
                    Download budget excel
                  </MenuItem>
                )
              }
            </Menu>
          </>
        )}
      </AccountTitle>
      <CostInitiateDialog
        open={dialogOpen}
        onClose={onDialogClose}
        unitId={unit.id}
      />
      <DownloadBudgetPopup
        anchorEl={menuAnchorEl}
        open={openDownloadBudgetPopup}
        hasCurrentYear={hasCurrentYear}
        hasNextYear={hasNextYear}
        onClose={onCloseDownloadPopup}
        onDownload={onDownloadBudget}
      />
    </>
  );
};

CostHeader.propTypes = {
  unit: PropTypes.object.isRequired,
  categories: PropTypes.array,
  children: PropTypes.any
};
