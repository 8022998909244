import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllUsersPermissions,
  deletePermission,
  savePermission,
  addUser,
} from '../../action/user';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import UserLink from '../common/UserLink';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { AddCircle } from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import TableHead from '@material-ui/core/TableHead';
import { PERMISSION_LEVEL, PERMISSION_RESOURCE_TYPE } from '../../../../../common/src/util/enum';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import lunr from 'lunr';

const { toReadablePermissionLevel } = require('../../../../../common/src/util/enum');

const useStyles = makeStyles((theme) => ({
  accordion: {},
  accordionDetails: {
    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
  },
  unitPermission: {
    margin: theme.spacing(1 / 4),
  },
  deleteChip: {
    color: theme.palette.primary.main,
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  addPermissionDialog: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  addPermissionText: {
    marginBottom: theme.spacing(2),
  },
  addControl: {
    minWidth: 400,
    marginBottom: theme.spacing(2),
  },
  addDialog: {
    height: '50vh',
    width: 400,
  },
  selectedUser: {
    backgroundColor: theme.palette.primary.light,
  },
  userColumn: {
    maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function UnitPermission(props) {
  const { permission, unitById, classes, user } = props;
  const { resourceId: unitId, level } = permission;
  const unit = unitById[unitId];
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const dialog = deleteDialogOpen && (
    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogContent>
        Are you sure you wish to delete the permission on {unit.name} for <UserLink user={user}/>?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => dispatch(deletePermission(permission.id))} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Chip
        className={classes.unitPermission}
        label={`${toReadablePermissionLevel(level)} for ${unit.name}`}
        onDelete={() => setDeleteDialogOpen(true)}
        deleteIcon={<CancelIcon className={classes.deleteChip}/>}
      />
      {dialog}
    </>
  );
}

function UserRow(props) {
  const { user, unitById, classes, nestedUnits } = props;
  const { permission: permissions } = user;
  const levels = Object.values(PERMISSION_LEVEL).sort();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedUnitId, setSelectedUnitId] = useState('');
  const dispatch = useDispatch();

  const units = useMemo(() => {
    let flattenedUnits = [];
    const flattenUnits = nestedUnits => {
      if (!nestedUnits) return;
      for (const unit of nestedUnits) {
        flattenedUnits.push(unit);
        flattenUnits(unit.children);
      }
    };
    flattenUnits(nestedUnits);
    return flattenedUnits;
  }, [nestedUnits]);

  const assignedUnitsIds = new Set(permissions.map(({ resourceId }) => resourceId));

  const unitPermissions = permissions.map(permission =>
    <UnitPermission
      key={permission.id}
      permission={permission}
      unitById={unitById}
      classes={classes}
      user={user}/>,
  );

  const dialog = addDialogOpen && (
    <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
      <DialogContent className={classes.addPermissionDialog}>
        <div className={classes.addPermissionText}>
          Please select new permission level and unit for <UserLink user={user}/>
        </div>

        <FormControl className={classes.addControl}>
          <InputLabel htmlFor="level">Permission level</InputLabel>
          <Select
            native
            value={selectedLevel}
            onChange={event => setSelectedLevel(event.target.value)}>
            <option value={''}/>
            {levels.map(level => <option value={level} key={level}>{toReadablePermissionLevel(level)}</option>)}
          </Select>
        </FormControl>

        <FormControl className={classes.addControl}>
          <InputLabel htmlFor="unit">Unit</InputLabel>
          <Select
            native
            value={selectedUnitId}
            onChange={event => setSelectedUnitId(event.target.value)}>
            <option value={''}/>
            {units.map(unit =>
              <option
                value={unit.id}
                key={unit.id}
                disabled={assignedUnitsIds.has(unit.id)}
                dangerouslySetInnerHTML={{ __html: `${'&nbsp;'.repeat(2 * unit.depth)}${unit.name}` }}/>,
            )}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddDialogOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          disabled={
            !selectedUnitId ||
            !selectedLevel ||
            assignedUnitsIds.has(parseInt(selectedUnitId))
          }
          onClick={() => {
            setAddDialogOpen(false);
            dispatch(savePermission({
              userId: user.id,
              resourceType: PERMISSION_RESOURCE_TYPE.UNIT,
              resourceId: parseInt(selectedUnitId),
              level: parseInt(selectedLevel),
            }))
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <TableRow>
        <TableCell className={classes.userColumn}>
          <UserLink user={user}/>
        </TableCell>
        <TableCell>
          {unitPermissions}
        </TableCell>
        <TableCell>
          <IconButton color="primary" component="span" onClick={() => setAddDialogOpen(true)}>
            <AddCircle/>
          </IconButton>
        </TableCell>
      </TableRow>
      {dialog}
    </>
  );
}

const _resolveNestedUnits = (units, parentId = null, depth = 0) => {
  const nestedUnits = [];
  const length = units.length;

  for (let i = 0; i < length; i++) {
    const unit = units[i];
    if (unit.parentUnitId === parentId) {
      const children = _resolveNestedUnits(units, unit.id, depth + 1);
      if (children.length > 0) {
        unit.children = children;
      }
      unit._foundParent = true;
      unit.depth = depth;
      nestedUnits.push(unit);
    }
  }

  return nestedUnits;
};

const resolveNestedUnits = units => {
  if (!units || units.length === 0)
    return [];

  const nestedUnits = _resolveNestedUnits(units);

  // Orphans
  for (let unit of units)
    if (!unit._foundParent) {
      unit.depth = 0;
      nestedUnits.push(unit);
    }

  return nestedUnits;
};

const buildUserIndex = users => {
  return users && lunr(function() {
    this.ref('username');
    this.field('displayName', { boost: 4 });
    this.field('username', { boost: 2 });
    this.field('mail', { boost: 1 });

    users.forEach(function(user) {
      this.add(user);
    }, this);
  });
};

const findUsernames = (userIndex, text) => {
  const queryString = text
    .replace(/[*?+~^-]/g, '')
    .split(' ')
    .map(token => token.trim())
    .filter(token => token)
    .map(token => `+${token}*`)
    .join(' ');
  return new Set(userIndex.search(queryString).map(entry => entry.ref));
};

export default function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allUsersPermissions } = useSelector(state => state.user);
  const { units } = allUsersPermissions || {};
  const nestedUnits = useMemo(() => units ? resolveNestedUnits(units) : [], [units]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [userSearchText, setUserSearchText] = useState('');
  const [selectedUsername, setSelectedUsername] = useState(undefined);
  const externalUsers = allUsersPermissions?.externalUsers || [];

  const userIndex = useMemo(() => buildUserIndex(externalUsers)
    , [externalUsers]);

  useEffect(() => {
    dispatch(fetchAllUsersPermissions());
  }, [dispatch]);

  const addableUsers = useMemo(() => {
      const foundUsernames = userSearchText && userIndex && findUsernames(userIndex, userSearchText);
      return externalUsers
        .filter(user => !userSearchText || foundUsernames.has(user.username))
        .map(user =>
          <ListItem
            key={user.username}
            button
            className={clsx(selectedUsername === user.username && classes.selectedUser)}
            onClick={() => setSelectedUsername(user.username)}
          >
            <UserLink user={user}/>
          </ListItem>,
        )
    },
    [allUsersPermissions?.externalUsers, selectedUsername, userSearchText]);

  let loader, content, dialog;
  if (!allUsersPermissions)
    loader = <CircularProgress size={16}/>;
  else {

    const { users, units } = allUsersPermissions;
    const unitById = {};
    units.forEach(unit => unitById[unit.id] = unit);

    const usersRows = users
      .sort((a, b) => a.displayName.toUpperCase().localeCompare(b.displayName.toUpperCase()))
      .map(user =>
        <UserRow
          user={user}
          unitById={unitById}
          nestedUnits={nestedUnits}
          classes={classes}
          key={user.username}/>,
      );

    const closeDialog = () => {
      setSelectedUsername(undefined);
      setAddUserDialogOpen(false);
    };


    dialog = addUserDialogOpen && (
      <Dialog open={addUserDialogOpen} onClose={closeDialog}>
        <DialogContent className={classes.addDialog}>
          <TextField
            label="Search"
            placeholder="Search user..."
            fullWidth
            variant="outlined"
            value={userSearchText}
            onChange={event => setUserSearchText(event.target.value)}
          />
          <List>
            {addableUsers}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeDialog();
              const user = externalUsers.find(user => user.username === selectedUsername);
              dispatch(addUser(user));
            }}
            color="primary" autoFocus
            disabled={!selectedUsername}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );

    content = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={clsx(classes.headColumn, classes.userColumn)}>User</TableCell>
            <TableCell colSpan={2} className={classes.headColumn}>Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersRows}
        </TableBody>
      </Table>
    );
  }

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={12} lg={12}>
        <Accordion defaultExpanded className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Title>Users {loader}</Title>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {content}
          </AccordionDetails>
          <Divider/>
          <AccordionActions>
            <Button
              id={'add-user-button'} // required for tutorial
              size="large"
              color="primary"
              onClick={() => setAddUserDialogOpen(true)}
              startIcon={<AddCircle/>}
            >
              Add user
            </Button>
          </AccordionActions>
        </Accordion>
        {dialog}
      </Grid>
    </Grid>
  );
}
