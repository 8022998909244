import axios from 'axios';
import config from '../config';

const { budgetApiUrl } = config;
const date = require('../../../../common/src/util/date');

export async function loadConfiguration() {
  try {
    const nextBudgetYearRes = await axios({
      method: 'get',
      url: `${budgetApiUrl}/configuration/next-budget-year`,
      withCredentials: true
    });
    const year = parseInt(nextBudgetYearRes.data.value);
    if (year) {
      date._setNextBudgetYear(year);
    }
  } catch (err) {
    console.error(err);
  }
}
