import React, {memo, useEffect, useState} from 'react';
import Text from '../common/text';
import Content from "../common/content";
import {makeStyles} from "@material-ui/core/styles";
import ClickCircle from '../common/clickCircle';
import StepDots from "../common/stepDots";
import scrollElementIntoView from '../common/scrollElementIntoView';
import sleep from "../common/sleep";
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  blurClass: {
    filter: 'blur(2px)'
  },
  disableClick: {
    pointerEvents: 'none',
    zIndex: '1301 !important'
  }
}));

const steps = [{
  focusElement: '#add-user-button',
  contentPosition: {
    top: (top) => top - 70,
    left: (left) => left - 390,
  },
  content: (
    <Text>
      <b>Add User</b> - Here you can add other VEIDEC users to Budget Tool.
    </Text>
  )
}];

const UsersTutorial = (props) => {
  const { openTutorial, showContent, setShowContent, children } = props;
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [positions, setPositions] = useState({});
  const rootElement = document.querySelector('#root');

  const focusElement = React.useRef();
  const clonedButton = React.useRef();

  const onKeyDown = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  };

  const removeClonedElements = () => {
    if (clonedButton.current) {
      clonedButton.current.remove();
    }

    const unnecessaryButton = document.querySelector('body > button');

    if (unnecessaryButton) {
      unnecessaryButton.remove();
    }
  }

  const resetElements = () => {
    if (focusElement.current) {
      openTutorial(false);
      setShowContent(false);
      rootElement.classList.remove(classes.blurClass);
      focusElement.current.classList.remove(classes.disableClick);
      removeClonedElements();
      document.removeEventListener('keydown', onKeyDown);
    }
  };

  const resetOnResize = () => {
    if (focusElement.current) {
      setShowContent(false);
      rootElement.classList.remove(classes.blurClass);
      focusElement.current.classList.remove(classes.disableClick);
      removeClonedElements();
    }
  }

  useEffect(() => {
    (async () => {
      await handleStepInit();
    })()

    const onResize = debounce(async () => {
      resetOnResize();
      await handleStepInit();
    }, 150);

    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('resize', onResize)

    return () => {
      rootElement.classList.remove(classes.blurClass);
      resetElements();
      window.removeEventListener('resize', onResize)
  }}, []);

  const handleStepInit = async () => {
    const focusElement = steps[step].focusElement && document.querySelector(steps[step].focusElement);

    if (focusElement) {
      await handleStepInstructions(focusElement);
    }
  }

  const handleStepInstructions = async (menuButton) => {
    await scrollElementIntoView(menuButton);
    await handleFocusElementAction();
  };

  const handleFocusElementAction = async () => {
    await sleep(150);
    focusElement.current = document.querySelector(steps[step].focusElement);

    if (focusElement.current) {
      const { left, top } = focusElement.current.getBoundingClientRect();

      setPositions({ top, left });
      await sleep(500);
      rootElement.classList.add(classes.blurClass);

      setShowContent(true);
      focusElement.current.classList.add(classes.disableClick);
      clonedButton.current = focusElement.current.cloneNode(true);
      clonedButton.current.style.cssText = `position: fixed; background-color: white; z-index: 1301 !important; top: ${top}px; left: ${left}px;`;
      document.querySelector('body').appendChild(clonedButton.current);
    }
  }

  if (!positions.top) return null;

  return (
    <>
      <ClickCircle
        top={positions.top + 15}
        left={positions.left + 50}
      />
      {
        showContent && (
          <>
            { React.cloneElement(children, { onClick: resetElements }) }
            <Content
              top={steps[step].contentPosition.top(positions.top)}
              left={steps[step].contentPosition.left(positions.left)}
              hasMoreSteps={steps.length}
              openTutorial={resetElements}
            >
              { steps[step].content }
            </Content>
            {
              steps.length > 1 && (
                <StepDots />
              )
            }
          </>
        )
      }
    </>
  )
};

export default memo(UsersTutorial);

