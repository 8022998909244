import React, {memo, useEffect, useState} from 'react';
import Text from '../common/text';
import Content from "../common/content";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ClickCircle from '../common/clickCircle';
import StepDots from "../common/stepDots";
import scrollElementIntoView from '../common/scrollElementIntoView';
import sleep from "../common/sleep";

const useStyles = makeStyles(theme => ({
  blurClass: {
    filter: 'blur(2px)'
  },
  disableClick: {
    pointerEvents: 'none',
    zIndex: '1301 !important'
  },
}));

const steps = [{
  dotsActionButton: '#sale-menu-button',
  menu: '#sale-menu',
  contentPosition: {
    top: (top) => top - 30,
    left: (left) => left + 230,
  },
  circlePosition: {
    top: (top) => top + 10,
    left: (left) => left + 10,
  },
  requiredElement: '#expand-sales-button',
  prepareStep: [
    {
      clickElement: '#expand-more-sales'
    },
    {
      clickElement: '#expand-sales-button'
    },
  ],
  content: (
    <Text>
      <b>Details</b> - In details you can read who are involved and follow the changes done. You can also read the messages.
      <br />
      <br />
      <b>Calculate from last year</b> - When budget for next year is empty you can use this function to calculate the budget based on last year total and increase or decrease with x%.
      <br />
      <br />
      <b>Request approval</b> - When contributor is ready a request for approval with a message is sent to the approver.
      <br />
      <br />
      <b>Delete</b> - Marks row with trash bin and block for contribution.
    </Text>
  )
}, {
  dotsActionButton: '#costs-menu-button',
  menu: '#costs-menu',
  contentPosition: {
    top: (top) => top - 30,
    left: (left) => left + 320,
  },
  circlePosition: {
    top: (top) => top + 10,
    left: (left) => left + 10,
  },
  requiredElement: '#expand-costs-button',
  prepareStep: [
    {
      clickElement: '#expand-more-costs'
    },
    {
      clickElement: '#expand-costs-button'
    },
  ],
  content: (
    <Text>
      <b>Details</b> - In details you can read who are involved and follow the changes done. You can also read the messages.
      <br />
      <br />
      <b>Return to request contribution status</b> - The approver either approves or sends back to contributor with a message.
      <br />
      <br />
      <b>Request approval</b> - When contributor is ready a request for approval with a message is sent to the approver.
      <br />
      <br />
      <b>Approve and finalize</b> - When budget can be accepted it will be approved and finalized.
    </Text>
  )
}, {
  dotsActionButton: '#notification-anchor-element',
  menu: '#notification-view-popover',
  contentPosition: {
    top: (top) => top + 48,
    left: (left) => left - 780,
  },
  circlePosition: {
    top: (top) => top + 20,
    left: (left) => left + 20,
  },
  content: (
    <Text>
      <b>Notifications</b> - You find the  Sales budget either via task or menu alternatives Sales or Units
    </Text>
  )
}];

const getAvailableSteps = (tasks) => {
  const taskList = [];

  steps.slice(0,2).forEach((step, index) => {
    if (document.querySelector(step.requiredElement)) {
      taskList.push(index);
    }
    else taskList.push(false);
  })
  taskList.push(2);
  return taskList;
}

const validItemsFilter = item => typeof item === 'number';

const TasksTutorial = (props) => {
  const { openTutorial, showContent, setShowContent, children } = props;
  const classes = useStyles();
  const { tasks } = useSelector(state => state.task);
  const [showClickSimulate, setShowClickSimulate] = useState(false);
  const [step, setStep] = useState(getAvailableSteps(tasks).find(validItemsFilter) || 0);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [positions, setPositions] = useState({});
  const rootElement = document.querySelector('#root');
  const menuWrapper = React.useRef();
  const prevStep = React.useRef(undefined);

  const onEscape = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  };

  const resetElements = () => {
    if (menuWrapper.current) {
      setShowContent(false);
      rootElement.classList.remove(classes.blurClass);
      if (menuWrapper.current) {
        menuWrapper.current.classList.remove(classes.disableClick);
        menuWrapper.current.firstChild.click();
      }
      document.removeEventListener('keydown', onEscape);
    }
    openTutorial(false);
  }

  const hideContentBeforeNextStep = () => {
    setShowContent(false);
    rootElement.classList.remove(classes.blurClass);

    if (menuWrapper.current) {
      menuWrapper.current.classList.remove(classes.disableClick);
      menuWrapper.current.firstChild.click();
    }
  }

  useEffect(() => {
    return () => {
      resetElements();
    }
  }, []);

  useEffect(() => {
    setAvailableSteps(getAvailableSteps(tasks));
    document.addEventListener('keydown', onEscape);
  }, [tasks]);

  useEffect(() => {
    (async () => {
      if (prevStep.current !== step) {
        hideContentBeforeNextStep();
        await handleStep();
      }
      prevStep.current = step;
    })();
  }, [step]);

  const handleStep = async () => {
    await sleep(250);
    const menuButton = document.querySelector(steps[step].dotsActionButton);
    let parentElement = steps[step].prepareStep && document.querySelector(steps[step].prepareStep[0].clickElement);
    const isParentArrowButtonExpanded = parentElement && parentElement.closest('div').className.includes('expanded');

    if (menuButton && (!parentElement || isParentArrowButtonExpanded)) {
      await handleStepInstructions(menuButton);
    }
    else if (steps[step].prepareStep) {
      if (parentElement && !isParentArrowButtonExpanded) {
        await scrollElementIntoView(parentElement);
        await sleep(1000);
        const { left, top } = parentElement.getBoundingClientRect();
        setPositions({ top, left });

        await handleClickSimulator();
        await sleep(250);

        parentElement.closest('div').click();
      }

      await sleep(500);
      await handlePrepareStep();
    }
  }

  const handleStepInstructions = async (menuButton) => {
    await scrollElementIntoView(menuButton);
    const { left, top } = menuButton.getBoundingClientRect();

    setPositions({ top, left });
    await handleClickSimulator();

    await sleep(500);
    menuButton.click();
    rootElement.classList.add(classes.blurClass);
    setShowContent(true);

    await sleep(150);
    menuWrapper.current = document.querySelector(steps[step].menu);
    if (menuWrapper.current) menuWrapper.current.classList.add(classes.disableClick);
  };

  const handlePrepareStep = async () => {
    const prepareStepPromise = new Promise((resolve, reject) => {
      steps[step].prepareStep.forEach((element, index) => {
        (async () => {
          let parentElement = document.querySelector(steps[step].prepareStep[1].clickElement);

          if (parentElement) {
            parentElement = parentElement.closest('.MuiCollapse-container');

            if (!index &&
              parentElement?.className &&
              !parentElement?.className.includes('hidden')
            ) {
              await sleep(500);
              return;
            }

            const clickElement = document.querySelector(element.clickElement);

            if (clickElement && clickElement.className.includes('expanded')) {
              await sleep(500);

              if (index === 1) {
                resolve(true);
              }
            }
            else {
              await scrollElementIntoView(clickElement);
              const { left, top } = clickElement.getBoundingClientRect();
              setPositions({ top, left });

              await handleClickSimulator();
              await sleep(250);
              clickElement.click();

              if (index === 1) {
                resolve(true);
              }
            }
            await sleep(2000);
          }
          else {
            await sleep(1000);
            await handlePrepareStep();
          }
        })();
      });
    });

    await prepareStepPromise;
    await sleep(250);
    const menuButton = document.querySelector(steps[step].dotsActionButton);

    if (menuButton) {
      await handleStepInstructions(menuButton);
    }
  }

  const handleClickSimulator = async () => {
    setShowClickSimulate(true);
    await sleep(750);
    setShowClickSimulate(false);
  }

  const onSepChange = (step) => {
    const availableFilteredSteps =  availableSteps.filter(validItemsFilter);
    let nextStep = availableFilteredSteps[step];

    if (!nextStep) {
      nextStep = availableFilteredSteps.indexOf(step);
    }
    setStep(nextStep);
  }

  if (!positions.top) return null;

  const stepsLength = availableSteps.filter(validItemsFilter).length;
  const stepIndex = availableSteps.filter(validItemsFilter).indexOf(step);

  return (
    <>
      {
        showClickSimulate && (
          <ClickCircle
            top={steps[step].circlePosition.top(positions.top)}
            left={steps[step].circlePosition.left(positions.left)}
          />
        )
      }
      {
        showContent && (
          <>
            { React.cloneElement(children, { onClick: resetElements }) }
            <Content
              step={stepIndex}
              stepsLength={stepsLength}
              top={steps[step].contentPosition.top(positions.top)}
              left={steps[step].contentPosition.left(positions.left)}
              onSepChange={onSepChange}
              openTutorial={resetElements}
            >
              { steps[step].content }
            </Content>
            {
              stepsLength > 1 && (
                <StepDots
                  stepsLength={stepsLength}
                  availableSteps={availableSteps.filter(validItemsFilter)}
                  activeStep={step}
                  onSepChange={onSepChange}
                />
              )
            }
          </>
        )
      }
    </>
  )
};

export default memo(TasksTutorial);

