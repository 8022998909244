import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, signInWithEmail, signInWithToken } from '../../action/user';
import { checkStatus } from '../../action/status';
import CircularProgressCentered from '../common/CircularProgressCentered';
import { useParams } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://veidec.com/">
        Veidec
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const user = useSelector(state => state.user);
  const {
    checkedStatus,
    backendStatus,
  } = useSelector(state => state.status);

  const { token } = useParams();

  const dispatch = useDispatch();

  // Status check
  useEffect(() => dispatch(checkStatus()), [dispatch, user]);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkStatus());
    }, 15000); // every 15 seconds
    return () => clearInterval(interval);
  }, [dispatch]);

  // Token auto sign in
  useEffect(() => {
    if (token)
      dispatch(signInWithToken(token));
  }, [dispatch, token]);

  const emailSignIn = checkedStatus && backendStatus?.activeDirectoryOk === false;

  function handleSubmit(event) {
    event.preventDefault();
    if (emailSignIn)
      dispatch(signInWithEmail(username));
    else
      dispatch(signIn(username, password));
  }

  if (!checkedStatus)
    return <CircularProgressCentered/>;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom={true}>
          Sign in
        </Typography>
        <Typography component="h2" variant="body2" color="error">
          {user.signInError}&nbsp;
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onInput={e => setUsername(e.target.value)}
          />
          {!emailSignIn && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onInput={e => setPassword(e.target.value)}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={user.signingIn}
          >
            {emailSignIn ? 'Send sign in email' : 'Sign In'}
          </Button>
          {emailSignIn && !user.signInError && (
            <Typography variant="body2" color="error">
              We are currently experiencing an issue with our authentication system.
              An email will be sent to you with a sign in link.
            </Typography>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  );
}
