import React, { memo } from 'react';
import PageTitle from '../common/PageTitle';
import ExchangeRatesPanel from './ExchangeRatesPanel';
import protectedCfoRoute from '../common/hocs/protectedCfoRoute';

const SystemSettings = memo(() => {
  return (
    <>
      <PageTitle title="System Settings" spacingSize={2}/>
      <ExchangeRatesPanel/>
    </>
  );
});

export default memo(protectedCfoRoute(SystemSettings));
