import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../common/Title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { SALE_STATUS } from '../../../../../common/src/util/enum';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Link from '@material-ui/core/Link';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import { fetchUser } from '../../action/user';
import { deepCopy } from '../../../../../common/src/util/object';
import Typography from '@material-ui/core/Typography';
import { now, timeAgo } from '../../util/time';
import { currentBudgetYear, formatDate } from '../../../../../common/src/util/date';
import UserLink from '../common/UserLink';
import moment from 'moment';
import * as _ from 'lodash';
import CostMenu from '../costs/CostMenu';
import CostStatusIcon from '../costs/CostStatusIcon';
import Tooltip from '@material-ui/core/Tooltip';
import MessageIcon from '@material-ui/icons/Email';
import areEqual from '../../util/areEqual';

const {
  DELETED, // eslint-disable-line no-unused-vars
  CONTRIBUTION_REQUESTED,
  APPROVAL_REQUESTED,
  FINAL, // eslint-disable-line no-unused-vars
} = SALE_STATUS;

const useStyles = makeStyles(theme => ({
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
  },
  iconColumn: {
    padding: 0,
    paddingTop: 5,
    margin: 0,
    width: 20,
  },
  rowDivider: {
    borderTop: `4px solid ${theme.palette.background.default}`,
  },
  countColumn: {
    textAlign: 'right',
  },
  expandColumn: {
    width: 20,
  },
  nameColumn: {
    whiteSpace: 'nowrap',
  },
  unitColumn: {
    whiteSpace: 'nowrap',
  },
  actionColumn: {
    whiteSpace: 'nowrap',
  },
  caption: {},
  highlighted: {
    backgroundColor: theme.palette.background.default,
  },
  menuColumn: {
    padding: 0,
    margin: 0,
    width: 30,
  },
  dueSoon: {
    color: theme.palette.secondary.main,
  },
}));

const evaluateTasksGroups = (userId, costs) => {
  const tasks = [];

  /**
   * Assigned to me
   */
  const contributionRequested = costs.filter(cost =>
    cost.contributorId === userId &&
    cost.statusId === CONTRIBUTION_REQUESTED);
  if (contributionRequested.length) {
    tasks.push({
      action: 'Waiting for your contribution',
      count: contributionRequested.length,
      costs: contributionRequested,
    });
  }
  const approvalRequested = costs.filter(cost =>
    cost.approverId === userId &&
    cost.statusId === APPROVAL_REQUESTED);
  if (approvalRequested.length) {
    tasks.push({
      action: 'Waiting for your approval',
      count: approvalRequested.length,
      costs: approvalRequested,
    });
  }

  /**
   * Waiting for others to complete
   */
  const waitingForContribution = costs.filter(cost =>
    cost.approverId === userId &&
    cost.statusId === CONTRIBUTION_REQUESTED &&
    cost.contributorId !== userId);
  if (waitingForContribution.length) {
    tasks.push({
      action: `Waiting for someone's contribution`,
      actionDetailed: `Waiting for contribution from :contributor:`,
      count: waitingForContribution.length,
      costs: waitingForContribution,
    });
  }
  const waitingForApproval = costs.filter(cost =>
    cost.statusId === APPROVAL_REQUESTED &&
    cost.approverId !== userId);
  if (waitingForApproval.length) {
    tasks.push({
      action: `Waiting for someone's approval`,
      actionDetailed: `Waiting for approval from :approver:`,
      count: waitingForApproval.length,
      costs: waitingForApproval,
    });
  }

  /**
   * Completed by me
   */
  const contributionCompleted = costs.filter(cost =>
    cost.contributorId === userId &&
    cost.statusId !== CONTRIBUTION_REQUESTED);
  if (contributionCompleted.length) {
    tasks.push({
      action: 'Your completed contributions',
      actionDetailed: `Completed contribution by you`,
      count: contributionCompleted.length,
      costs: contributionCompleted,
    });
  }
  const finaApprovalCompleted = costs.filter(cost =>
    cost.statusId === FINAL);
  if (finaApprovalCompleted.length) {
    tasks.push({
      action: 'Your completed final approvals',
      actionDetailed: `Completed final approval by you`,
      count: finaApprovalCompleted.length,
      costs: finaApprovalCompleted,
    });
  }

  return tasks;
};

const Costs = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const highlightCostsIds = location?.state?.highlightCostsIds;

  const dispatch = useDispatch();
  const { tasks, fetchingTasks } = useSelector(state => state.task);
  const { user, userById } = useSelector(state => state.user);

  const [expanded, setExpanded] = useState(true);
  const [expandedTasksGroups, setExpandedTasksGroups] = useState(new Set());

  const { costs, units } = tasks;
  const latestYear = _.maxBy(costs, cost => cost.year)?.year;

  const currentYear = currentBudgetYear();

  let loader, content;

  useEffect(() => {
    if (costs) {
      for (const cost of costs) {
        const { approverId, contributorId } = cost;
        if (!userById[approverId]) {
          dispatch(fetchUser(approverId));
        }
        if (!userById[contributorId]) {
          dispatch(fetchUser(contributorId));
        }
      }
    }
  }, [costs, dispatch, userById]);

  let tasksGroups = useMemo(() => {
    if (!fetchingTasks && user && costs && costs.length) {
      return evaluateTasksGroups(
        user.id,
        deepCopy(costs)
          .filter(({ year }) => year > currentYear)
          .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)),
      );
    }
    return [];
  }, [fetchingTasks, user, costs, latestYear]);

  useEffect(() => {
    if (!tasksGroups || !highlightCostsIds) {
      return;
    }
    const expandedTasksGroups = new Set();
    tasksGroups.forEach((taskGroup, t) => {
      const { costs } = taskGroup;
      if (costs.some(cost => highlightCostsIds?.includes(cost.id))) {
        expandedTasksGroups.add(t);
        setExpanded(true);
      }
    });
    setExpandedTasksGroups(expandedTasksGroups);
  }, [highlightCostsIds, tasksGroups]);

  if (fetchingTasks || !user) {
    loader = <CircularProgress size={16}/>;
  } else if (!costs || costs.length === 0 || tasksGroups.length === 0) {
    content = 'You are not assigned to any tasks.';
  } else {
    const unitById = {};
    units.forEach(unit => unitById[unit.id] = unitById[unit.id] || unit);

    const expandTaskGroup = taskGroup => event => {
      event.preventDefault();
      event.stopPropagation();
      if (!expandedTasksGroups.delete(taskGroup)) {
        expandedTasksGroups.add(taskGroup);
      }
      setExpandedTasksGroups(new Set(expandedTasksGroups));
    };

    content = (
      <div className={classes.table}>
        <Table>
          <TableBody>
            {
              tasksGroups.map((taskGroup, t) => {
                  const rows = [];
                  const { action, actionDetailed, count, costs } = taskGroup;

                  const isExpanded = expandedTasksGroups.has(t);
                  const expand = (
                    <IconButton
                      id={'expand-costs-button'}
                      className={clsx({
                        'expanded': isExpanded,
                      })}
                      size="small"
                    >
                      {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  );

                  const divider = clsx(t !== 0 && classes.rowDivider);

                  rows.push(
                    <TableRow key={`${t}-row`} onClick={expandTaskGroup(t)} style={{ cursor: 'pointer' }}>
                      <TableCell className={divider} colSpan={5}>{action}</TableCell>
                      <TableCell className={clsx(classes.countColumn, divider)}>{count}</TableCell>
                      <TableCell align="left" className={clsx(classes.expandColumn, divider)}>{expand}</TableCell>
                    </TableRow>,
                  );

                  if (isExpanded) {
                    rows.push(
                      ...costs.map(cost => {
                        const unit = unitById[cost.unitId];
                        if (!unit) {
                          return null;
                        }

                        const { id, contributorId, approverId, metadata } = cost;
                        const { messages, history: versions } = metadata;
                        const contributor = userById[contributorId];
                        const approver = userById[approverId];

                        let evaluatedAction = actionDetailed || action || '';

                        const contributorMatch = evaluatedAction.match(/(.*):contributor:(.*)/);
                        const approverMatch = evaluatedAction.match(/(.*):approver:(.*)/);

                        let hasMessage = false;
                        let message;
                        const lastMessageChange = _.findLast(versions,
                          version => !!version?.delta?.statusId || !!version.delta?.contributorId);
                        if (lastMessageChange) {
                          message = messages.find(m => m.at === lastMessageChange.at);
                          hasMessage = !!message;
                        }

                        if (contributorMatch)
                          evaluatedAction = (
                            <span>
                              {contributorMatch[1] || ''}
                              <UserLink user={contributor}/>
                              {contributorMatch[2] || ''}
                            </span>
                          );
                        else if (approverMatch)
                          evaluatedAction = (
                            <span>
                              {approverMatch[1] || ''}
                              <UserLink user={approver}/>
                              {approverMatch[2] || ''}
                            </span>
                          );

                        const isHighlighted = highlightCostsIds?.includes(id);

                        const navigateToCost = () => history.push({
                          pathname: `/unit/${unit.id}/costs`,
                          state: { highlightedCost: cost },
                        });

                        let due;
                        if (cost.due) {
                          const isDueSoon = moment(cost.due).diff(now(), 'days') <= 1;
                          const isFinal = cost.statusId === FINAL;
                          due = <span className={clsx(isDueSoon && !isFinal && classes.dueSoon)}>
                            {` Due ${formatDate(cost.due)}`}.
                          </span>;
                        }

                        return (
                          <TableRow key={`${t}-row-${id}`} className={clsx(isHighlighted && classes.highlighted)}>
                            <TableCell className={classes.menuColumn}>
                              <CostMenu cost={cost} userId={user.id} type={cost.type}/>
                            </TableCell>
                            <TableCell className={classes.unitColumn}>
                              <Link className={classes.link} onClick={navigateToCost}>{unit.name}</Link>
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              <CostStatusIcon statusId={cost.statusId}
                                contributorId={cost.contributorId}
                                approverId={cost.approverId}
                                userId={user.id}/>
                            </TableCell>
                            <TableCell className={classes.nameColumn}>
                              <Link className={classes.link} onClick={navigateToCost}>{cost.type.name}</Link>
                            </TableCell>
                            <TableCell className={classes.actionColumn} colSpan={2}>
                              {evaluatedAction}.<br/>
                              <Typography variant="caption" component="div" className={classes.caption}>
                                Updated {timeAgo(cost.updatedAt)}. {due}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.iconColumn}>
                              {
                                hasMessage &&
                                <Tooltip title={
                                  <>User left a message below. See details for more information.
                                    <br/><br/>
                                    {message.message}
                                  </>
                                }>
                                  <IconButton>
                                    <MessageIcon color="secondary" fontSize="small"/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    );
                  }
                  return rows;
                },
              )}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <>
      <Accordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon id={'expand-more-costs'}/>}>
          <Title>Costs Tasks {loader}</Title>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(Costs, areEqual);
