import config from '../config';
import axios from 'axios';
import { deepCopy, deepMerge } from '../../../../common/src/util/object';
import { handleAxiosError } from './error';

export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';
export const FETCHED_NOTIFICATIONS = 'FETCHED_NOTIFICATIONS';
export const UPDATED_NOTIFICATION = 'UPDATED_NOTIFICATION';
export const DELETED_NOTIFICATION = 'DELETED_NOTIFICATION';

const { budgetApiUrl } = config;

export const fetchingNotifications = () => ({
  type: FETCHING_NOTIFICATIONS,
});

export const fetchedNotifications = (data, append = false) => ({
  type: FETCHED_NOTIFICATIONS,
  data,
  append,
});

export const fetchNotifications = (params = {
    limit: 10,
    olderThanId: undefined,
    newerThanId: undefined,
    onlyNonSeen: false,
    append: false,
  },
) => async dispatch => {
  dispatch(fetchingNotifications());
  try {

    const res = await axios({
      url: `${budgetApiUrl}/notification`,
      method: 'GET',
      withCredentials: true,
      params,
    });
    dispatch(fetchedNotifications(res.data, params.append));
  } catch (err) {
    handleAxiosError(err, dispatch);
  }
};

export const updatedNotification = (notification, totalCount, seenCount) => ({
  type: UPDATED_NOTIFICATION,
  notification,
  totalCount,
  seenCount,
});

export const updateNotification = (inNotification, mutation) => async dispatch => {

  const oldNotification = deepCopy(inNotification);
  const newNotification = deepMerge(deepCopy(inNotification), mutation);

  try {
    dispatch(updatedNotification(newNotification));

    const res = await axios({
      url: `${budgetApiUrl}/notification/${inNotification.id}`,
      method: 'POST',
      withCredentials: true,
      data: mutation,
    });

    const { notification, totalCount, seenCount } = res.data;
    dispatch(updatedNotification(notification, totalCount, seenCount));
  } catch (err) {
    handleAxiosError(err, dispatch);
    dispatch(updatedNotification(oldNotification));
  }
};

export const updateNotifications = (mutation, params) => async dispatch => {
  try {
    await axios({
      url: `${budgetApiUrl}/notification`,
      method: 'POST',
      withCredentials: true,
      data: mutation,
    });
    dispatch(fetchNotifications(params));
  } catch (err) {
    handleAxiosError(err, dispatch);
    dispatch(fetchNotifications(params));
  }
};

export const deletedNotification = (id, totalCount, seenCount) => ({
  type: DELETED_NOTIFICATION,
  id,
  totalCount,
  seenCount,
});

export const deleteNotification = id => async dispatch => {

  try {
    dispatch(deletedNotification(id));

    const res = await axios({
      url: `${budgetApiUrl}/notification/${id}`,
      method: 'DELETE',
      withCredentials: true,
    });

    const { totalCount, seenCount } = res.data;
    dispatch(deletedNotification(id, totalCount, seenCount));
  } catch (err) {
    dispatch(fetchNotifications());
    handleAxiosError(err, dispatch);
  }
};
