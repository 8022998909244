import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { checkStatus } from '../../action/status';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { timeAgo } from '../../util/time';
import { formatTime } from '../../../../../common/src/util/date';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
}));

const STATUS_SITE = 'https://veidec.betteruptime.com/';

const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow)
    newWindow.opener = null
};

export default function Status() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const {
    checkedStatus,
    frontendStatus,
    backendStatus,
  } = useSelector(state => state.status);

  useEffect(() => dispatch(checkStatus()), [dispatch]);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkStatus());
    }, 15000); // every 15 seconds
    return () => clearInterval(interval);
  }, [dispatch]);

  let isVersionOk = true;
  let isBackendOk = true;
  if (checkedStatus) {
    isVersionOk = backendStatus.version ? frontendStatus.version === backendStatus.version : true;
    isBackendOk = backendStatus.allOk;
  }
  const isAllOk = isVersionOk && isBackendOk;

  let dialog;
  if (open && checkedStatus) {
    dialog = (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Application Status Information</DialogTitle>
        <DialogContent>
          <div>

            {!isVersionOk && (
              <div className={classes.errorMessage}>
                <ErrorIcon/>&nbsp;
                <Typography>
                  It appears that your application version is outdated. Simply reload the page to receive latest updates.
                </Typography>
              </div>
            )}

            {!isBackendOk && (
              <div className={classes.errorMessage}>
                <ErrorIcon/>&nbsp;
                <Typography>
                  We are currently experiencing server issues.
                </Typography>
              </div>
            )}

            {isAllOk && (
              <Typography gutterBottom>All systems are stable and your version is up to date</Typography>
            )}

            {showDetails && (
              <div>
                <Typography gutterBottom component="div">
                  <b>UI</b>
                  <ul>
                    <li>Version {frontendStatus.version}</li>
                    <li>Deployed {timeAgo(frontendStatus.deployedAt)}</li>
                    <li>Deployed on {formatTime(frontendStatus.deployedAt)}</li>
                  </ul>
                  <b>API</b>
                  <ul>
                    <li>Version {backendStatus.version}</li>
                    <li>Deployed {timeAgo(backendStatus.deployedAt)}</li>
                    <li>Deployed on {formatTime(backendStatus.deployedAt)}</li>
                  </ul>
                </Typography>

                {!isBackendOk && !backendStatus.databaseOk && (
                  <div className={classes.errorMessage}>
                    <ErrorIcon/>&nbsp;
                    <Typography>
                      We are currently experiencing database connection issues with PostgreSQL.
                    </Typography>
                  </div>
                )}

                {!isBackendOk && !backendStatus.activeDirectoryOk && (
                  <div className={classes.errorMessage}>
                    <ErrorIcon/>&nbsp;
                    <Typography>
                      We are currently experiencing Active Directory connection issues.
                    </Typography>
                  </div>
                )}

                {!isBackendOk && !backendStatus.databaseOk && (
                  <div className={classes.errorMessage}>
                    <ErrorIcon/>&nbsp;
                    <Typography>
                      We are currently experiencing database connection issues with MySQL.
                    </Typography>
                  </div>
                )}

                {!isBackendOk && !backendStatus.lastImportSuccessful && (
                  <div className={classes.errorMessage}>
                    <ErrorIcon/>&nbsp;
                    <Typography>
                      We are currently experiencing issues with automatic data sync.
                    </Typography>
                  </div>
                )}

              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openInNewTab(STATUS_SITE)} color="primary">
            Service status
          </Button>
          <Button onClick={() => setShowDetails(!showDetails)} color="primary">
            {showDetails ? 'Hide details' : 'Show details'}
          </Button>
          {!isVersionOk && (
            <Button onClick={() => location.reload()} color="primary">
              Reload page
            </Button>
          )}
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className={classes.root}>
      <Tooltip title={`Application Status Information (${isAllOk ? 'OK' : 'ERROR'})`}>
        <IconButton
          color="inherit"
          onClick={() => setOpen(true)}
        >
          {isAllOk ? <InfoIcon/> : (
            <Badge badgeContent="!" color="error">
              <InfoIcon/>
            </Badge>
          )}
        </IconButton>
      </Tooltip>
      {dialog}
    </div>
  );
}
