import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getPresentationAttributes, filterEventAttributes } from 'recharts/es6/util/ReactUtils';

class Dot extends PureComponent {
  static displayName = 'Dot';

  static propTypes = {
    className: PropTypes.string,
    cx: PropTypes.number,
    cy: PropTypes.number,
    r: PropTypes.number,
  };

  render() {
    const { cx, cy, r, className } = this.props;
    const layerClass = clsx('recharts-dot', className);
    const rectSize = 20;

    if (cx === +cx && cy === +cy && r === +r) {
      return (
        <g
          {...getPresentationAttributes(this.props)}
          {...filterEventAttributes(this.props, null, true)}
          className={layerClass}
          style={{ width: 30, height: 30 }}
          height={30}
          width={30}
          onMouseEnter={event => this.props.showTooltip(this.props.payload, event)}
          onMouseLeave={() => this.props.hideTooltip()}>
          <circle
            cx={cx}
            cy={cy}
            r={r}
          />
          <rect
            x={cx - rectSize / 2}
            y={cy - rectSize / 2}
            width={rectSize}
            height={rectSize}
            strokeWidth={0}
            fillOpacity={0}
            style={{ cursor: 'pointer' }}/>
        </g>
      );
    }

    return null;
  }
}

Dot.propTypes = {
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
};

export default Dot;
