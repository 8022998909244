import React, { useState } from 'react';
import { AccordionSummary, Grid, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import { Months, months, PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import { formatNumber } from '../../../../../common/src/util/number';
import { makeStyles } from '@material-ui/core/styles';
import summarize from '../../../../../common/src/util/summarize';
import ConsolidatedGroupMenu from './ConsolidatedGroupMenu';
import clsx from 'clsx';
import ConsolidatedRecordMenu from './ConsolidatedRecordMenu';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  menuColumn: {
    width: 20,
    padding: 0,
  },
  idColumn: {
    width: 60
  },
  rowHighlighted: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  error: {
    background: `${theme.palette.error.light}`
  }
}));

const ConditionalTooltip = ({ title, children, if: condition }) =>
  condition ? <Tooltip title={title}>{children}</Tooltip> : children;

const format = (record, value) => {
  return record.percentage ? (value ? value.toFixed(1) : null) : formatNumber(value);
};

export default function ConsolidatedRecords({ unit, groups }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState({});
  const toggleExpanded = id => setExpanded({ ...expanded, [id]: !expanded[id] });
  const isExpanded = id => !!expanded[id];

  for (const group of groups) {
    _.chain(group.records)
      .groupBy(record => record.id)
      .values()
      .forEach(group => group[0].first = true)
      .value();
  }

  const isCfo = unit?.permission?.level >= PERMISSION_LEVEL.CFO;

  return (
    <>
      {groups.map(group => (
        <Grid key={`grid-${group.name}`} item xs={12}>
          <Accordion defaultExpanded={!group.readOnly}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <div className={clsx(classes.accordionSummary, { [classes.withMenu]: !group.readOnly && isCfo })}>
                {!group.readOnly && isCfo &&
                <ConsolidatedGroupMenu unitId={unit.id} group={group} groupsLength={groups.length}/>}
                <Title>{group.name}</Title>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {isCfo &&
                      <TableCell className={clsx(classes.headColumn, classes.menuColumn)}>&nbsp;</TableCell>
                      }
                      <TableCell className={clsx(classes.headColumn, classes.idColumn)}>Record</TableCell>
                      <TableCell className={classes.headColumn}>Name</TableCell>
                      <TableCell className={classes.headColumn}>Year</TableCell>
                      <TableCell className={classes.headColumn}>&nbsp;</TableCell>
                      {Months.map(month => (
                        <TableCell key={`head-${month}`} align="right" className={classes.headColumn}>
                          {month}
                        </TableCell>
                      ))}
                      <TableCell align="right" className={classes.headColumn}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.records.map(record => {
                      if (!record.first && !isExpanded(record.id)) {
                        return null;
                      }
                      const rowDivider = record.first ? classes.rowDivider : null;
                      return (
                        <TableRow key={`row-${record.name}-${record.year}-${record.type}-${record.id}`}
                                  className={clsx(classes.tableRow, {
                                    [classes.error]: record.error,
                                    [classes.rowHighlighted]: record.highlighted,
                                  })}>
                          {isCfo &&
                          <TableCell className={clsx(classes.menuColumn, rowDivider)}>
                            {!group.readOnly && record.first &&
                            <ConsolidatedRecordMenu unitId={unit.id} group={group} record={record}/>
                            }
                          </TableCell>
                          }
                          <TableCell align="right" className={clsx(rowDivider, classes.idColumn)}>
                            {record.first && record.id}
                          </TableCell>
                          <TableCell className={rowDivider}>
                            <ConditionalTooltip if={!!record.formula} title={<h2>{record.formula}</h2>}>
                            <span>
                              {record.first && record.name}
                            </span>
                            </ConditionalTooltip>
                          </TableCell>
                          <TableCell className={rowDivider}>
                            {record.year} {record.type === 'budget' ? 'Budget' : 'Outcome'}
                          </TableCell>
                          <TableCell className={clsx(rowDivider, classes.yearExpanderColumn)}
                                     onClick={e => e.stopPropagation()}>
                            {record.first &&
                            <IconButton size="small"
                                        onClick={() => toggleExpanded(record.id)}
                                        disabled={false}>
                              {isExpanded(record.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                            }
                          </TableCell>
                          {!record.error ?
                            months.map(month => (
                              <TableCell key={`cell-${record.name}-${month}`}
                                         align="right"
                                         className={rowDivider}>
                                {format(record, record[month])}
                              </TableCell>
                            )) : (
                              <TableCell colSpan={12} align="center" className={rowDivider}>
                                {record.error}
                              </TableCell>
                            )}
                          <TableCell align="right" className={rowDivider}>
                            {format(record, record.total)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {group.showTotal && group.records.length > 0 &&
                    <TableRow className={classes.rowHighlighted}>
                      <TableCell className={clsx(classes.menuColumn, classes.rowDivider)}>&nbsp;</TableCell>
                      <TableCell className={clsx(classes.menuColumn, classes.rowDivider)}>&nbsp;</TableCell>
                      <TableCell className={clsx(classes.menuColumn, classes.rowDivider)}>&nbsp;</TableCell>
                      <TableCell className={classes.rowDivider}>Total</TableCell>
                      <TableCell className={clsx(classes.menuColumn, classes.rowDivider)}>&nbsp;</TableCell>
                      {months.map(month => (
                        <TableCell key={`total-${month}`} align="right" className={classes.rowDivider}>
                          {formatNumber(summarize(group.records.filter(record => record.first), month))}
                        </TableCell>
                      ))}
                      <TableCell align="right" className={classes.rowDivider}>
                        {formatNumber(summarize(group.records.filter(record => record.first)))}
                      </TableCell>
                    </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </>
  );
};

ConsolidatedRecords.propTypes = {
  groups: PropTypes.array.isRequired,
};
