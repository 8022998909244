import { deepCopy, deepMerge } from '../../../../common/src/util/object';

import {
  EXPAND_UNITS_NODES,
  FETCHED_INITATIONS,
  FETCHED_SALE_ASSIGNMENTS,
  FETCHED_SALE_ASSIGNMENTS_PER_UNIT,
  FETCHED_UNIT_SALES,
  FETCHED_UNIT_USERS,
  FETCHED_UNITS,
  FETCHING_UNIT_SALES,
  FETCHING_UNIT_USERS,
  FETCHING_UNITS,
  SAVED_UNIT_SALES,
  SAVING_UNIT_SALES,
  SELECT_UNIT,
  SHOW_SALES_ON_CHART,
  UPDATED_SALE,
  UPDATE_SHOW_EXCEL_LOADER
} from '../action/unit';

import { SIGNED_OUT, } from '../action/user';

const initialState = {
  fetchingUnits: false,
  selectedUnitId: undefined,
  fetchingUnitUsers: false,
  fetchedUnitUsers: {},
  expandedUnitsNodesIds: [],
  units: undefined,
  fetchingUnitSales: false,
  fetchedUnitSales: [],
  savingUnitSales: false,
  dataOnChart: {},
  saleAssignments: [],
  saleAssignmentsPerUnit: {},
  saleInitiations: [],
  showGeneratingExcelLoader: false
};

const reducer = (state = deepCopy(initialState), action) => {
  switch (action.type) {
    case UPDATE_SHOW_EXCEL_LOADER:
      return { ...state, showGeneratingExcelLoader: action.showLoader };

    case FETCHING_UNITS:
      return { ...state, fetchingUnits: true, units: [] };

    case FETCHED_UNITS:
      return { ...state, fetchingUnits: false, units: action.units };

    case SELECT_UNIT:
      return { ...state, selectedUnitId: action.unitId };

    case EXPAND_UNITS_NODES:
      return { ...state, expandedUnitsNodesIds: action.nodesIds };

    case FETCHING_UNIT_USERS:
      return { ...state, fetchingUnitUsers: true };

    case FETCHED_UNIT_USERS:
      return {
        ...state,
        fetchingUnitUsers: false,
        fetchedUnitUsers: {
          ...state.fetchedUnitUsers,
          [action.unitId]: action.users
        }
      };

    case FETCHING_UNIT_SALES:
      return { ...state, fetchingUnitSales: true };

    case FETCHED_UNIT_SALES:
      return { ...state, fetchingUnitSales: false, fetchedUnitSales: action.sales };

    case SAVING_UNIT_SALES:
      return { ...state, savingUnitSales: true };

    case SAVED_UNIT_SALES: {
      const { fetchedUnitSales } = state;
      const { sales: newUnitSalesList } = action;
      for (const newUnitSales of newUnitSalesList) {
        const { unit } = newUnitSales;
        const fetchedUnitSalesIndex = fetchedUnitSales.findIndex(unitSales => unitSales.unit.id === unit.id);
        if (fetchedUnitSalesIndex !== -1)
          fetchedUnitSales[fetchedUnitSalesIndex] = newUnitSales;
      }
      return { ...state, fetchedUnitSales, savingUnitSales: false };
    }

    case SHOW_SALES_ON_CHART:
      return { ...state, dataOnChart: action.dataOnChart };

    case UPDATED_SALE: {
      for (const unitSales of state.fetchedUnitSales)
        for (let s = 0; s < unitSales.sales.length; s++) {
          const sale = unitSales.sales[s];
          if (sale.id === action.sale.id)
            unitSales.sales[s] = action.sale;
        }

      // Update on chart
      const { sales } = state.dataOnChart;
      if (sales) {
        for (const sale of sales)
          if (sale.id === action.sale.id)
            deepMerge(sale, action.sale);
      }

      return { ...state };
    }

    case FETCHED_SALE_ASSIGNMENTS:
      return { ...state, saleAssignments: action.saleAssignments };

    case FETCHED_SALE_ASSIGNMENTS_PER_UNIT:
      return { ...state, saleAssignmentsPerUnit: action.saleAssignments };

    case FETCHED_INITATIONS:
      return { ...state, saleInitiations: action.saleInitiations };

    case SIGNED_OUT:
      return deepCopy(initialState);

    default:
      return state;
  }
};

export default reducer;
