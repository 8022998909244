import React from 'react';
import Grid from '@material-ui/core/Grid';
import Units from './Units';
import Detail from './Detail';
import People from './People';

export default function UnitsPage() {
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={12} lg={12}>
        <Units limitHeight={true} underlineLinks={false}/>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Detail/>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <People/>
      </Grid>
    </Grid>
  );
}
