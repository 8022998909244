import { SALE_STATUS, SALE_TYPE } from "../../../../../common/src/util/enum";
import * as _ from "lodash";
import { currentBudgetYear } from '../../../../../common/src/util/date';

export const excludePrevYearBudgets = (item => {
  if (item.year >= currentBudgetYear()) {
    return true;
  }
  if (item.year === currentBudgetYear() - 1 && item.saleTypeId === SALE_TYPE.DAYS) {
    return true;
  }
  return item.saleTypeId === SALE_TYPE.AMOUNT;
});

export const excludeDeletedRows = (row, isCfo, sales, user) => {
  const isValid = isCfo ? false : user?.id !== row.approverId;
  const firstOne = _.sortBy(sales, (item) => {
    return [item.name, item.year];
  }).reverse().find(item => item.name === row.name);

  if (firstOne) {
    return !(firstOne.saleStatusId === SALE_STATUS.DELETED && isValid);
  }
  return true;
};
