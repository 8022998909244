import {
  ADDED_USER,
  DELETED_PERMISSION,
  FETCHED_ALL_USERS_PERMISSIONS,
  FETCHED_USER,
  FETCHED_USER_PERMISSIONS,
  SAVED_PERMISSION,
  SIGNED_IN,
  SIGNED_OUT,
  SIGNING_IN,
  SIGNING_OUT,
  UPDATED_USER,
  FETCHED_USER_MODULES_PERMISSIONS,
} from '../action/user';
import { FETCHED_UNIT_USERS } from '../action/unit';
import { deepCopy } from '../../../../common/src/util/object';
import { PERMISSION_LEVEL } from '../../../../common/src/util/enum';
import { removeLocal, saveLocal } from '../util/storage';

const initialState = {
  checkedSignIn: false,
  signingIn: false,
  signedIn: false,
  signInError: undefined,
  user: undefined,
  permissions: [],
  userById: {},
  isCfoOf: unitId => false,
  returnUrl: window.location.pathname.indexOf('/sign-in') !== -1 ? '/' : window.location.pathname,
  allUsersPermissions: undefined,
  modulesPermissions: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNING_IN:
      return { ...state, signingIn: true, signedIn: false };
    case UPDATED_USER:
      return { ...state, user: action.user }
    case SIGNING_OUT:
    case SIGNED_OUT:
      removeLocal('user');
      removeLocal('permissions');
      return {
        ...state,
        user: undefined,
        signingIn: false,
        signedIn: false,
        signInError: action.signInError,
        checkedSignIn: true,
      };
    case SIGNED_IN: {
      const user = deepCopy(action.user);
      delete user.permission;
      saveLocal('user', user);
      return {
        ...state,
        user,
        userById: { ...state.userById, [user.id]: user },
        signingIn: false,
        signedIn: true,
        signInError: undefined,
        checkedSignIn: true,
      };
    }
    case FETCHED_UNIT_USERS: {
      const userById = { ...state.userById };
      action.users.forEach(user => {
        userById[user.id] = deepCopy(user);
        delete userById[user.id].permission;
      });
      return { ...state, userById: userById };
    }
    case FETCHED_USER: {
      const { user } = action;
      return {
        ...state,
        userById: { ...state.userById, [user.id]: user },
      };
    }
    case FETCHED_USER_PERMISSIONS: {
      saveLocal('permissions', action.permissions);
      return {
        ...state,
        permissions: action.permissions,
        isCfoOf: unitId => action.permissions.some(p => p.unitId === unitId && p.level >= PERMISSION_LEVEL.CFO),
      };
    }
    case FETCHED_ALL_USERS_PERMISSIONS: {
      const { users, externalUsers, units } = action;
      return { ...state, allUsersPermissions: { users, externalUsers, units } };
    }
    case DELETED_PERMISSION: {
      const { permissionId } = action;
      const allUsersPermissions = deepCopy(state.allUsersPermissions);
      const { users } = allUsersPermissions;
      users.forEach(user => user.permission = user.permission.filter(permission => permission.id !== permissionId));
      return { ...state, allUsersPermissions };
    }
    case SAVED_PERMISSION: {
      const { permission } = action;
      const allUsersPermissions = deepCopy(state.allUsersPermissions);
      const user = allUsersPermissions.users.find(user => user.id === permission.userId);
      user.permission.push(permission);
      return { ...state, allUsersPermissions };
    }
    case ADDED_USER: {
      const { user } = action;
      const allUsersPermissions = deepCopy(state.allUsersPermissions);
      allUsersPermissions.users.push({ ...user, permission: [] });
      allUsersPermissions.externalUsers = allUsersPermissions.externalUsers
        .filter(externalUser => externalUser.username !== user.username);
      return { ...state, allUsersPermissions };
    }
    case FETCHED_USER_MODULES_PERMISSIONS: {
      return { ...state, modulesPermissions: action.modulesPermissions }
    }
    default:
      return state;
  }
};

export default reducer;
