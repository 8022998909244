import history from '../util/history';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const showError = error => ({
  type: SHOW_ERROR,
  error,
});

export const hideError = error => ({
  type: HIDE_ERROR,
  error,
});

export const handleAxiosError = (err, dispatch, redirectToMainPage) => {
  console.log(err);
  if (err.isAxiosError) {
    if (err?.response?.status === 401) {
      dispatch(showError({ message: 'You have been signed out', onCloseSignOut: true }));
    } else if (err?.response?.status === 403 && redirectToMainPage) {
      history.replace('/');
    } else if (err?.response?.data?.error?.message) {
      dispatch(showError(err.response.data.error));
    } else {
      dispatch(showError({ message: 'Unknown error occurred' }));
    }
  }
};
