import React, { useEffect, useState, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import UserSearch from '../common/UserSearch';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton } from '@material-ui/core';
import * as _ from 'lodash';
import { AddCircle } from '@material-ui/icons';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import areEqual from "../../util/areEqual";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    minWidth: '300px',
    float: 'left',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}));

export const SaleAssignmentModal = memo((
  {
    open,
    onClose,
    onConfirm,
    assignments,
    users,
    unit,
  }) => {
  const classes = useStyles();

  const loadingUsers = useSelector(state => state.unit.fetchingUnitUsers);
  const [approvers, setApprovers] = useState([]);
  const [contributor, setContributor] = useState(null);
  const possibleApprovers = _.values(users).filter(user => user.permission.level >= PERMISSION_LEVEL.OWNER);
  const possibleContributors = _.values(users).filter(user => user.permission.level >= PERMISSION_LEVEL.WRITE);

  const setApprover = (user, index) => {
    const newApprovers = [...approvers];
    newApprovers[index] = user;
    setApprovers(newApprovers);
  }
  const addApprover = () => {
    setApprovers([...approvers, null]);
  }
  const removeApprover = index => {
    const newApprovers = [...approvers];
    newApprovers.splice(index, 1);
    setApprovers(newApprovers);
  }

  useEffect(() => {
    setApprovers(assignments.filter(assignment => assignment.level > 0).map(({ userId }) => users[userId]));
    setContributor(_.head(assignments.filter(assignment => assignment.level === 0).map(({ userId }) => users[userId])));
  }, [open, assignments, users]);

  const invalid = _.some(approvers, approver => approver === null);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>
        Sale assignments
      </DialogTitle>
      <DialogContent className={classes.inputs}>
        {(unit?.type === 'company' || unit?.type === 'distributor') && (
          <>
            {approvers.map((user, index) =>
              <div className={classes.input} key={index}>
                <UserSearch label={`Approver - Level ${index + 1}`}
                  loading={loadingUsers}
                  setUser={user => setApprover(user, index)}
                  user={user}
                  users={possibleApprovers}
                  style={{ float: 'left', minWidth: '300px' }}
                />
                <IconButton color="primary" component="span" onClick={() => removeApprover(index)}>
                  <CancelIcon/>
                </IconButton>
              </div>,
            )}
            {
              approvers.length < 2 && (
                <Button size="large"
                        color="primary"
                        onClick={addApprover}
                        startIcon={<AddCircle/>}>
                  Add Approver
                </Button>
              )
            }
          </>
        )}
        {(unit?.type === 'region' || unit?.type === 'distributor') && (
          <FormControl className={classes.input}>
            <UserSearch label="Contributor"
              loading={loadingUsers}
              setUser={setContributor}
              user={contributor}
              users={possibleContributors}/>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(approvers, contributor)} color="primary" autoFocus disabled={invalid}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}, areEqual);
