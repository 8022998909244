import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { currentBudgetYear, nextBudgetYear } from '../../../../../common/src/util/date';

const DownloadBudgetPopup = ({ open, anchorEl, onClose, onDownload, hasCurrentYear, hasNextYear }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem
        disabled={true}
      >
        Download budget
      </MenuItem>
      <Divider />
      { hasNextYear && (
        <MenuItem
          onClick={() => onDownload(nextBudgetYear())}
        >
          { nextBudgetYear() }
        </MenuItem>
      )}
      {
        hasCurrentYear && (
          <MenuItem
            onClick={() => onDownload(currentBudgetYear())}
          >
            { currentBudgetYear() }
          </MenuItem>
        )
      }
    </Menu>
  );
}

DownloadBudgetPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  hasCurrentYear: PropTypes.bool.isRequired,
  hasNextYear: PropTypes.bool.isRequired,
}

export default memo(DownloadBudgetPopup);
