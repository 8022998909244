import React, { memo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, Avatar, TableBody, TableHead, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from '../common/Title';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import AccountGroupMenu from './AccountGroupMenu';
import { useSelector } from 'react-redux';
import AccountMenu from './AccountMenu';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  withMenu: {
    marginLeft: -theme.spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  flags: {
    display: 'flex',
    '& :not(:last-child)': {
      marginRight: theme.spacing(0.5),
    }
  },
  flag: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  accordionDetails: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    overflow: 'auto',
  },
  menuColumn: {
    width: 20,
    padding: 0,
  },
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  numberColumn: {
    width: '5em'
  }
}));

const AccordionContent = memo(({ unitId, group }) => {
  const classes = useStyles();

  return (
    <AccordionDetails className={classes.accordionDetails}>
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.headColumn, classes.menuColumn)}>&nbsp;</TableCell>
              <TableCell className={clsx(classes.headColumn, classes.numberColumn)}>Number</TableCell>
              <TableCell className={classes.headColumn}>Name</TableCell>
              <TableCell className={classes.headColumn}>Assigned To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {group.types.map(account => (
              <TableRow key={account.id}>
                <TableCell className={classes.menuColumn}>
                  <AccountMenu unitId={unitId} group={group} account={account}/>
                </TableCell>
                <TableCell className={classes.numberColumn}>{account.number}</TableCell>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account?.contributor?.displayName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </AccordionDetails>
  )
})

const Flag = memo(({ condition, flag, tooltip }) => {
  const classes = useStyles();
  if (!condition) {
    return null;
  }
  return (
    <Tooltip title={tooltip}>
      <Avatar className={classes.flag}>{flag}</Avatar>
    </Tooltip>
  );
});

const AccountGroup = ({ unitId, group: { id: groupId } }) => {
  const classes = useStyles();
  const group = useSelector(state => state.account.groups.find(g => g.id === groupId));
  if (!group) {
    return null;
  }

  return (
    <>
      <Accordion className={`group-${group.id}`} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div className={clsx(classes.accordionSummary, classes.withMenu)}>
            <div className={classes.title}>
              <AccountGroupMenu unitId={unitId} group={group}/>
              <Title>{group.name}</Title>
            </div>
            <div className={classes.flags}>
              <Flag condition={group.income} flag="I" tooltip="Income"/>
              <Flag condition={group.credit} flag="C" tooltip="Credit"/>
            </div>
          </div>
        </AccordionSummary>
        <AccordionContent unitId={unitId} group={group}/>
      </Accordion>
    </>
  );
};

export default memo(AccountGroup);
