import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CostDetailsDialog from './CostDetailsDialog';
import {
  approve,
  calculateCost,
  deleteCost,
  requestApproval,
  requestContribution,
  submitCostMessage,
  updateCost
} from '../../action/cost';
import { months, SALE_STATUS } from '../../../../../common/src/util/enum';
import CalculateFromPrevious from './CalculateFromPrevious';
import CalculateCost from './CalculateCostDialog';

const Actions = {
  DETAILS: null,
  REQUEST_APPROVAL: null,
  REQUEST_CONTRIBUTION: null,
  APPROVE: null,
  DELETE: null
};
Object.keys(Actions).forEach(key => Actions[key] = key);
Object.freeze(Actions);

export default function CostMenu({ cost, type, userId, previousCost }) {
  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { userById, isCfoOf } = useSelector(state => state.user);
  const [contributor, setContributor] = useState(null);
  const [approver, setApprover] = useState(null);
  useEffect(() => {
    if (userById[cost.contributorId]) {
      setContributor(userById[cost.contributorId]);
    }
    if (userById[cost.approverId]) {
      setApprover(userById[cost.approverId]);
    }
  }, [userById, cost.contributorId, cost.approverId]);

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  }
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  }

  const [dialogOpen, setDialogOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [title, setTitle] = useState('');

  const openDetails = () => {
    closeMenu();
    setAction(Actions.DETAILS);
    setTitle('Details');
    setDialogOpen(true);
  }

  const blank = _.every(months, month => cost[month] === null);
  const calculated = !!type.formula;
  const isCfo = isCfoOf(cost.unitId);

  const [calculateFromPreviousDialogOpen, setCalculateFromPreviousDialogOpen] = useState(false);
  const openCalculateFromPrevious = () => {
    setMenuOpen(false);
    setCalculateFromPreviousDialogOpen(true);
  }
  const calculateFromPreviousEnabled =
    blank && previousCost &&
    cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED &&
    (cost.contributorId === userId || isCfo);
  const onCalculateFromPreviousDialogConfirm = percentage => {
    const mutation = {};
    const total = months.reduce((accumulator, month) => accumulator + previousCost[month], 0);
    const increase = total + total * (percentage / 100);
    const average = Math.round(increase / months.length);
    months.forEach(month => mutation[month] = average);
    dispatch(updateCost(cost, mutation, false));
    setCalculateFromPreviousDialogOpen(false);
  };

  const [calculateDialogOpen, setCalculateDialogOpen] = useState(false);
  const openCalculate = () => {
    setMenuOpen(false);
    setCalculateDialogOpen(true);
  }
  const calculateEnabled =
    calculated &&
    cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED &&
    (cost.contributorId === userId || isCfo);
  const onCalculateDialogConfirm = variables => {
    dispatch(calculateCost(cost, variables));
    setCalculateDialogOpen(false);
  }

  const requestContributionEnabled =
    (cost.statusId === SALE_STATUS.APPROVAL_REQUESTED && (cost.approverId === userId || isCfo)) ||
    (cost.statusId === SALE_STATUS.FINAL && (cost.contributorId === cost.approverId || isCfo));
  const openRequestContribution = () => {
    closeMenu();
    setAction(Actions.REQUEST_CONTRIBUTION);
    setTitle('Request contribution');
    setDialogOpen(true);
  }

  const requestApprovalEnabled =
    (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId !== cost.approverId) || isCfo)) ||
    (cost.statusId === SALE_STATUS.FINAL && isCfo);
  const openRequestApproval = () => {
    closeMenu();
    setAction(Actions.REQUEST_APPROVAL);
    setTitle(cost.statusId === SALE_STATUS.FINAL ? 'Return to request approval status' : 'Request approval');
    setDialogOpen(true);
  }

  const approvalEnabled =
    (cost.statusId === SALE_STATUS.APPROVAL_REQUESTED && (cost.approverId === userId || isCfo)) ||
    (cost.statusId === SALE_STATUS.CONTRIBUTION_REQUESTED && ((userId === cost.contributorId && userId === cost.approverId) || isCfo));
  const openApprove = () => {
    closeMenu();
    setAction(Actions.APPROVE);
    setTitle('Approve and finalize');
    setDialogOpen(true);
  }

  const onDialogConfirm = message => {
    setDialogOpen(false);
    switch (action) {
      case Actions.DETAILS: {
        if (message?.trim()) {
          dispatch(submitCostMessage(cost, message));
        }
        break;
      }
      case Actions.REQUEST_APPROVAL: {
        dispatch(requestApproval(cost, message));
        break;
      }
      case Actions.REQUEST_CONTRIBUTION: {
        dispatch(requestContribution(cost, message));
        break;
      }
      case Actions.APPROVE: {
        dispatch(approve(cost, message));
        break;
      }
      case Actions.DELETE: {
        dispatch(deleteCost(cost, message));
        break;
      }
      default:
        break;
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton
        id={'costs-menu-button'} // required for tutorial
        size="small"
        onClick={openMenu}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id={'costs-menu'} // required for tutorial
        open={menuOpen}
        anchorEl={menuAnchorEl}
        keepMounted
        onClose={closeMenu}
      >
        <MenuItem key={`item-details-${cost.id}`}
                  onClick={openDetails}>
          Details
        </MenuItem>
        {previousCost && !calculated &&
        <MenuItem key={`item-calculate-previous-${cost.id}`}
                  onClick={openCalculateFromPrevious}
                  disabled={!calculateFromPreviousEnabled}>
          Calculate from last year
        </MenuItem>
        }
        {calculated &&
        <MenuItem key={`item-calculate-${cost.id}`}
                  onClick={openCalculate}
                  disabled={!calculateEnabled}>
          Calculate from formula
        </MenuItem>
        }
        <MenuItem key={`item-request-contribution-${cost.id}`}
                  onClick={openRequestContribution}
                  disabled={!requestContributionEnabled}>
          Return to request contribution status
        </MenuItem>
        <MenuItem key={`item-request-approval-${cost.id}`}
                  onClick={openRequestApproval}
                  disabled={!requestApprovalEnabled}>
          {cost.statusId === SALE_STATUS.FINAL ? 'Return to request approval status' : 'Request approval'}
        </MenuItem>
        <MenuItem key={`item-approve-${cost.id}`}
                  onClick={openApprove}
                  disabled={!approvalEnabled}>
          Approve and finalize
        </MenuItem>
      </Menu>
      <CostDetailsDialog open={dialogOpen}
                         onCancel={() => setDialogOpen(false)}
                         onConfirm={onDialogConfirm}
                         title={title}
                         cost={cost}
                         type={type}
                         contributor={contributor}
                         approver={approver}/>
      <CalculateFromPrevious open={calculateFromPreviousDialogOpen}
                             onClose={() => setCalculateFromPreviousDialogOpen(false)}
                             onConfirm={onCalculateFromPreviousDialogConfirm}
                             cost={cost}
                             previousCost={{}}/>
      <CalculateCost open={calculateDialogOpen}
                     onClose={() => setCalculateDialogOpen(false)}
                     onConfirm={onCalculateDialogConfirm}
                     cost={cost}
                     type={type}/>
    </div>
  );
}

CostMenu.propTypes = {
  cost: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  userId: PropTypes.number,
  previousCost: PropTypes.object,
}
