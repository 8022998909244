import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import ConsolidatedRecordModal from './ConsolidatedRecordModal';
import ConsolidatedGroupModal from './ConsolidatedGroupModal';
import {
  addConsolidatedRecord,
  deleteConsolidatedGroup,
  moveConsolidatedGroup,
  toggleModal,
  updateConsolidatedGroup
} from '../../action/consolidated';
import ConfirmDeleteButtons from '../common/ConfirmDeleteButtons';

const ConsolidatedGroupMenu = ({ unitId, group, groupsLength }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const recordModalId = `record-create-${group.id}`;
  const recordModalOpen = useSelector(state => state.consolidated.openModals[recordModalId] || false);
  const [record, setRecord] = useState({});

  const groupModalId = `group-edit-${group.id}`;
  const consolidatedGroupModalOpen = useSelector(state => state.consolidated.openModals[groupModalId] || false);

  const menuRef = useRef();
  const [openConfirmDeleteMenu, setOpenConfirmDeleteMenu] = useState(false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };
  const onCloseMenus = () => {
    setOpenConfirmDeleteMenu(false);
    setMenuOpen(false);
  }

  const onNewRecordClicked = () => {
    setMenuOpen(false);
    setRecord({});
    dispatch(toggleModal(recordModalId, true));
  };
  const onNewRecordConfirm = record => {
    dispatch(addConsolidatedRecord(group, { ...record, unitId }));
  };

  const onEditGroupClicked = () => {
    setMenuOpen(false);
    dispatch(toggleModal(groupModalId, true));
  };
  const onEditGroupConfirm = group => {
    dispatch(updateConsolidatedGroup({ ...group, unitId }));
  };

  const onMoveGroupClicked = direction => {
    setMenuOpen(false);
    dispatch(moveConsolidatedGroup(group, direction));
  }

  const onDeleteGroupClicked = () => {
    setOpenConfirmDeleteMenu(true);
  };
  const onConfirmGroupDelete = () => {
    dispatch(deleteConsolidatedGroup(group));
    onCloseMenus();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu open={menuOpen}
            anchorEl={menuAnchorEl}
            keepMounted
            onClose={closeMenu}>
        <MenuItem key={`group-new-record-${group.id}`}
                  onClick={onNewRecordClicked}>
          New consolidated record
        </MenuItem>
        <MenuItem key={`group-edit-${group.id}`}
                  onClick={onEditGroupClicked}>
          Edit
        </MenuItem>
        <MenuItem key={`group-move-${group.id}-up`}
                  onClick={() => onMoveGroupClicked('up')}
                  disabled={group.position === 1}>
          Move up
        </MenuItem>
        <MenuItem key={`group-move-${group.id}-down`}
                  onClick={() => onMoveGroupClicked('down')}
                  disabled={group.position === groupsLength}>
          Move down
        </MenuItem>
        <MenuItem key={`group-delete-${group.id}`}
                  ref={menuRef}
                  onClick={onDeleteGroupClicked}
                  disabled={group.records.length > 0}>
          Delete
        </MenuItem>
      </Menu>
      <ConsolidatedRecordModal open={recordModalOpen}
                               onCancel={() => dispatch(toggleModal(recordModalId, false))}
                               onConfirm={onNewRecordConfirm}
                               title="New consolidated record"
                               record={record}
                               unitId={unitId}/>
      <ConsolidatedGroupModal open={consolidatedGroupModalOpen}
                              onCancel={() => dispatch(toggleModal(groupModalId, false))}
                              onConfirm={onEditGroupConfirm}
                              title="Edit consolidated group"
                              group={group}/>
      <ConfirmDeleteButtons open={menuOpen && openConfirmDeleteMenu}
                            anchorEl={menuRef}
                            onSuccess={onConfirmGroupDelete}
                            onClose={onCloseMenus}/>
    </div>
  );
};

ConsolidatedGroupMenu.propTypes = {
  group: PropTypes.object,
};

export default memo(ConsolidatedGroupMenu);
