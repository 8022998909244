import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import UserLink from '../common/UserLink';
import { NOTIFICATION_TYPE, titleRegardingToStatus } from '../../../../../common/src/util/enum';
import pluralize from 'pluralize';
import { deleteNotification, updateNotification } from '../../action/notification';
import { now, timeAgo } from '../../util/time';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ReadIcon from '@material-ui/icons/RadioButtonUnchecked';
import UnreadIcon from '@material-ui/icons/RadioButtonChecked';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
  },
  notificationSeenButton: {
    marginTop: theme.spacing(-0.75),
  },
  notification: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  notificationTitle: {
    marginBottom: theme.spacing(1),
  },
  notificationContent: {
    width: '100%',
  },
  notificationDetail: {
    marginBottom: theme.spacing(0.5),
  },
  userMessage: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  timeAgo: {
    marginTop: theme.spacing(0.5),
  },
  notificationActions: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
  },
  noPointer: {
    cursor: 'default !important',
    textDecoration: 'none !important'
  }
}));

export default function Notification(props) {
  const { notification } = props;
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    id,
    message, // eslint-disable-line no-unused-vars
    seenAt,
    createdAt,
    metadata,
  } = notification;

  const {
    type,
    unitId,
    unitName,
    byUserDisplayName,
    byUserId,
    salesIds,
    costId,
    message: userMessage,
    noRedirectToTasks,
    firstApproverId,
    firstApproverDisplayName
  } = metadata;


  const isSeen = Boolean(seenAt);
  let unitLink = null;
  switch (type) {
    case NOTIFICATION_TYPE.SALES_CONTRIBUTION_REQUEST:
    case NOTIFICATION_TYPE.SALES_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.SALES_FINAL_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.SALES_MESSAGE:
    case NOTIFICATION_TYPE.SALES_DUE_APPROVED:
    case NOTIFICATION_TYPE.SALES_DUE_CONTRIBUTION_REQUESTED:
    case NOTIFICATION_TYPE.SALES_DUE_APPROVAL_REQUESTED:
      unitLink = unitId && unitName ? (
        <Link
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            history.push({
              pathname: `/unit/${unitId}/sale`,
              state: {
                highlightUnitsIds: [unitId],
                highlightSalesIds: salesIds,
              },
            })
          }}
          className={classes.link}>sales in {unitName}</Link>
      ) : '';
      break;

    case NOTIFICATION_TYPE.SALES_INITIATION_REQUESTED:
    case NOTIFICATION_TYPE.SALES_INITIATION_COMPLETED:
      unitLink = unitId && unitName ? (
        <Link
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            history.push({
              pathname: `/unit/${unitId}/sale`,
              state: {
                highlightUnitsIds: [unitId],
              },
            })
          }}
          className={classes.link}>budget in {unitName}</Link>
      ) : '';
      break;

    case NOTIFICATION_TYPE.COSTS_CONTRIBUTION_REQUEST:
    case NOTIFICATION_TYPE.COSTS_FINAL_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.COSTS_MESSAGE:
    case NOTIFICATION_TYPE.COSTS_DUE_APPROVED:
    case NOTIFICATION_TYPE.COSTS_DUE_CONTRIBUTION_REQUESTED:
    case NOTIFICATION_TYPE.COSTS_DUE_APPROVAL_REQUESTED:
      unitLink = unitId && unitName ? (
        <Link
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            const highlightCostsIds = Array.isArray(costId) ? costId : [costId];
            history.push({
              pathname: `/unit/${unitId}/costs`,
              state: {
                highlightUnitsIds: [unitId],
                highlightCostsIds,
              },
            })
          }}
          className={classes.link}>costs in {unitName}</Link>
      ) : '';
      break;

    default:
      break;
  }

  const userLink = <UserLink userId={byUserId} userDisplayName={byUserDisplayName}/>;
  const firstApproverLink = firstApproverId && firstApproverDisplayName && <UserLink userId={firstApproverId} userDisplayName={firstApproverDisplayName}/>;

  let taskLink;
  switch (type) {
    case NOTIFICATION_TYPE.SALES_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.SALES_FINAL_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.SALES_MESSAGE:
    case NOTIFICATION_TYPE.SALES_CONTRIBUTION_REQUEST:
    case NOTIFICATION_TYPE.SALES_DUE_APPROVED:
    case NOTIFICATION_TYPE.SALES_DUE_CONTRIBUTION_REQUESTED:
    case NOTIFICATION_TYPE.SALES_DUE_APPROVAL_REQUESTED:
      taskLink = text => (
        <Link className={classes.link}
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            history.push({
              pathname: '/tasks',
              state: {
                highlightUnitsIds: [unitId],
                highlightSalesIds: salesIds,
              },
            })
          }}>
          {text}
        </Link>
      );
      break;

    case NOTIFICATION_TYPE.SALES_INITIATION_REQUESTED:
      taskLink = text => (
        <Link className={clsx(classes.link, { [classes.noPointer]: noRedirectToTasks })}
              onClick={() => {
                if (!isSeen) {
                  setNotificationRead(true)();
                }
                if (!noRedirectToTasks) {
                  history.push({
                    pathname: '/tasks',
                    state: {
                      highlightUnitsIds: [unitId],
                      highlightSalesIds: salesIds,
                    },
                  });
                }
              }}>
          {text}
        </Link>
      );
      break;

    case NOTIFICATION_TYPE.SALES_INITIATION_COMPLETED:
      taskLink = text => (
        <Link className={classes.link}
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            history.push({
              pathname: '/tasks',
              state: {
                highlightUnitsIds: [unitId],
                highlightSalesIds: salesIds,
              },
            });
          }}>
          {text}
        </Link>
      );
      break;

    case NOTIFICATION_TYPE.COSTS_CONTRIBUTION_REQUEST:
    case NOTIFICATION_TYPE.COSTS_FINAL_APPROVAL_REQUEST:
    case NOTIFICATION_TYPE.COSTS_MESSAGE:
    case NOTIFICATION_TYPE.COSTS_DUE_APPROVED:
    case NOTIFICATION_TYPE.COSTS_DUE_CONTRIBUTION_REQUESTED:
    case NOTIFICATION_TYPE.COSTS_DUE_APPROVAL_REQUESTED:
      taskLink = text => (
        <Link className={classes.link}
          onClick={() => {
            if (!isSeen) {
              setNotificationRead(true)();
            }
            const highlightCostsIds = Array.isArray(costId) ? costId : [costId];
            history.push({
              pathname: '/tasks',
              state: {
                highlightUnitsIds: [unitId],
                highlightCostsIds,
              },
            })
          }}>
          {text}
        </Link>
      )
      break;

    default:
      taskLink = () => '';
  }

  let detail, title;

  switch (type) {
    case NOTIFICATION_TYPE.SALES_DUE_CONTRIBUTION_REQUESTED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_DUE_CONTRIBUTION_REQUESTED);
      detail = <>
        {taskLink('Sales contribution requested')} by {userLink} for {unitLink}. Now close to due date.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_CONTRIBUTION_REQUEST:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_CONTRIBUTION_REQUEST);
      detail = <>
        {taskLink('Sales contribution requested')} by {userLink} for {unitLink}.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_DUE_APPROVAL_REQUESTED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_DUE_APPROVAL_REQUESTED);
      detail = <>
        {taskLink('Sales approval requested')} by {userLink} for {unitLink}. Now close to due date.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_APPROVAL_REQUEST:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_APPROVAL_REQUEST);
      detail = <>
        {taskLink('Sales approval requested')} by {userLink} for {unitLink}.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_FINAL_APPROVAL_REQUEST:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_FINAL_APPROVAL_REQUEST);
      detail = <>
        {taskLink('Sales final approval requested')} by {userLink} for {unitLink}.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_MESSAGE:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_MESSAGE);
      detail = <>
        {taskLink('Sales message received')} from {userLink} for {unitLink}.
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_DUE_APPROVED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_DUE_APPROVED);
      detail = <>
        {taskLink('Sales approved')} from {userLink} for {unitLink}.
      </>;
      break;

    case NOTIFICATION_TYPE.SALES_INITIATION_REQUESTED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_INITIATION_REQUESTED);
      detail = <>
        {taskLink('Budget initiation requested')} { firstApproverLink ? 'from ' : '' }{firstApproverLink ? firstApproverLink : ''} by {userLink} for {unitLink}{!firstApproverId ? '.' : ''} { firstApproverId ? 'where you are assigned as 2nd level approver.' : '' }
      </>;
      break;
    case NOTIFICATION_TYPE.SALES_INITIATION_COMPLETED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.SALES_INITIATION_COMPLETED);
      detail = <>
        {taskLink('Budget initiation completed')} by {userLink} for {unitLink}.
      </>;
      break;

    case NOTIFICATION_TYPE.COSTS_DUE_APPROVED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_DUE_APPROVED);
      detail = <>
        {taskLink('Costs approved')} by {userLink} for {unitLink}.
      </>;
      break;
    case NOTIFICATION_TYPE.COSTS_DUE_CONTRIBUTION_REQUESTED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_DUE_CONTRIBUTION_REQUESTED);
      detail = (<>{taskLink('Cost contribution requested')} by {userLink} for {unitLink}. Now close to due date.</>);
      break;
    case NOTIFICATION_TYPE.COSTS_CONTRIBUTION_REQUEST:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_CONTRIBUTION_REQUEST);
      detail = (<>{taskLink('Cost contribution requested')} by {userLink} for {unitLink}.</>);
      break;
    case NOTIFICATION_TYPE.COSTS_FINAL_APPROVAL_REQUEST:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_FINAL_APPROVAL_REQUEST);
      detail = (<>{taskLink('Cost approval requested')} by {userLink} for {unitLink}.</>);
      break;
    case NOTIFICATION_TYPE.COSTS_MESSAGE:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_MESSAGE);
      detail = <>
        {taskLink('Costs message received')} from {userLink} on cost entry for {unitLink}.
      </>
      break;
    case NOTIFICATION_TYPE.COSTS_DUE_APPROVAL_REQUESTED:
      title = titleRegardingToStatus(NOTIFICATION_TYPE.COSTS_DUE_APPROVAL_REQUESTED);
      detail = (<>{taskLink('Cost approval requested')} by {userLink} for {unitLink}. Now close to due date.</>);
      break;
    default:
      return null;
  }

  const setNotificationRead = isRead => () => dispatch(
    updateNotification(notification, { seenAt: isRead ? now() : null }),
  );

  let seenButton;
  if (isSeen) {
    seenButton = (
      <Tooltip title="Mark as unread">
        <IconButton
          onClick={setNotificationRead(false)}
          className={classes.notificationSeenButton}>
          <ReadIcon fontSize="small" color="primary"/>
        </IconButton>
      </Tooltip>
    );
  } else {
    seenButton = (
      <Tooltip title="Mark as read">
        <IconButton
          onClick={setNotificationRead(true)}
          className={classes.notificationSeenButton}>
          <UnreadIcon fontSize="small" color="primary"/>
        </IconButton>
      </Tooltip>
    );
  }

  const deleteButton =
    <Tooltip title="Delete">
      <IconButton onClick={() => dispatch(deleteNotification(id))}>
        <DeleteIcon fontSize="small"/>
      </IconButton>
    </Tooltip>;

  return (
    <>
      <div className={clsx(classes.notification)}>
        <div className={classes.notificationContent}>

          <Typography
            variant="subtitle2"
            className={classes.notificationTitle}>
            {title}
          </Typography>

          <Typography variant="body2" className={classes.notificationDetail}
            component="div">{detail}</Typography>
          {
            userMessage || message ?
              <Typography variant="body2" className={classes.userMessage}>{userMessage || message}</Typography>
              : ''
          }

          <Typography variant="caption" className={classes.timeAgo}>{timeAgo(createdAt)}</Typography>

        </div>
        <div className={classes.notificationActions}>
          {seenButton}
          {deleteButton}
        </div>
      </div>
      <Divider light/>
    </>
  );
}
