import React, { memo, useEffect, useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import expressions from '../../../../../common/src/util/expressions';
import { fetchConsolidatedStructure } from '../../action/consolidated';

const useStyles = makeStyles((theme) => ({
  input: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  table: {
    display: 'table',
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(1),
  },
  tableRow: {
    display: 'table-row',
  },
  tableKey: {
    display: 'table-cell',
    fontWeight: theme.typography.fontWeightMedium,
    width: 200,
  },
  tableValue: {
    display: 'table-cell',
  }
}));

const ConsolidatedRecordModal = ({ open, onCancel, onConfirm, title, record, unitId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const [name, setName] = useState(record.name || '');
  const onNameChange = ({ target: { value } }) => {
    setName(value);
    setErrors({ ...errors, name: !value });
  };

  const [highlighted, setHighlighted] = useState(false);
  const onHighlightedChange = ({ target: { checked } }) => {
    setHighlighted(checked);
  };

  const [formula, setFormula] = useState(record.formula || '');
  const onFormulaChange = ({ target: { value } }) => {
    setFormula(value);
  }

  const [accounts, setAccounts] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [sales, setSales] = useState(false);
  const [costs, setCosts] = useState(false);
  const [records, setRecords] = useState([]);

  const updating = useSelector(state => state.consolidated.updating);
  const structure = useSelector(state => state.consolidated.structure);

  useEffect(() => {
    setName(record.name || '');
    setFormula(record.formula || '');
    setHighlighted(record.highlighted || false);
  }, [open, record]);

  useEffect(() => {
    if (open) {
      dispatch(fetchConsolidatedStructure(unitId));
    }
  }, [open, dispatch]);

  useEffect(() => {
    try {
      const { accounts, accountGroups, sales, costs, records } = expressions.parse(formula, 'consolidated');
      setAccounts(accounts);
      setSales(sales);
      setCosts(costs);
      setAccountGroups(accountGroups);
      setRecords(records);
      setErrors({ ...errors, formula: false });
    } catch (err) {
      setErrors({ ...errors, formula: err.message });
    }
  }, [formula]);

  const valid = _.every(_.values(errors), v => !v);

  const mapAccount = account => {
    const name = structure.accounts[account]?.name;
    return name ? `${account} - ${name}` : account;
  }
  const mapGroup = group => {
    const name = structure.groups[group]?.name || group;
    return name ? `${group} - ${name}` : group;
  }
  const mapRecord = record => {
    const name = structure.records[record]?.name || record;
    return name ? `${record} - ${name}` : record;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <TextField label="Name"
                     value={name}
                     onChange={onNameChange}
                     error={!!errors.name}/>
        </FormControl>
        <FormControl component="fieldset" className={classes.input}>
          <FormControlLabel label="Highlight row"
                            control={
                              <Checkbox color="primary"
                                        checked={highlighted}
                                        onChange={onHighlightedChange}/>
                            }/>
        </FormControl>
        <FormControl component="fieldset" className={classes.fullWidth}>
          <TextField label="Formula"
                     value={formula}
                     onChange={onFormulaChange}
                     error={!!errors.formula}
                     helperText={errors.formula ||
                     `Available functions: 
                     sales(), costs(), account(number), accounts(number1, number2, ...), accountGroup(id), record(id),
                     records(id1, id2, ...)`}
          />
        </FormControl>

        {formula &&
        <div className={classes.table}>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent accounts:</div>
            <div className={classes.tableValue}>{accounts.map(mapAccount).join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent accounts groups:</div>
            <div className={classes.tableValue}>{accountGroups.map(mapGroup).join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent records:</div>
            <div className={classes.tableValue}>{records.map(mapRecord).join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent on sales:</div>
            <div className={classes.tableValue}>{sales ? 'yes' : 'no'}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent on costs:</div>
            <div className={classes.tableValue}>{costs ? 'yes' : 'no'}</div>
          </div>
        </div>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                disabled={updating}
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!valid || updating}
                onClick={() => onConfirm({ id: record.id, name, formula, highlighted })}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConsolidatedRecordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    formula: PropTypes.string,
  }),
  unitId: PropTypes.number
};

export default memo(ConsolidatedRecordModal);
