import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  canApproveBudgetSales,
  canContributeBudgetSales,
  canDeleteBudgetSales,
  canFinalizeBudgetSales,
  canInitiateBudgetSales,
  canRestoreBudgetSales,
} from '../../../../../common/src/util/permission';
import { deepValue } from '../../../../../common/src/util/object';
import {
  months,
  Months,
  PERMISSION_LEVEL,
  SALE_STATUS,
  SALE_TYPE,
  toReadableSaleStatus
} from '../../../../../common/src/util/enum';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateSale } from '../../action/unit';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Messages from './Messages';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Title from './Title';
import History from './History';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { fetchUser } from '../../action/user';
import { userDisplayName } from '../../util/user';
import UserLink from './UserLink';
import CalculateFromPrevious from '../sales/CalculateFromPrevious';

const useStyles = makeStyles((theme) => ({
  message: {
    width: '100%',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    width: 140,
    display: 'inline-block',
    padding: theme.spacing(1),
  },
}));

const mapping = {
  name: {
    order: 10,
  },
  year: {
    order: 20,
  },
  saleStatusId: {
    order: 30,
    label: 'sale status',
    translation: value => toReadableSaleStatus(value).toLowerCase(),
  },
  contributorId: {
    order: 100,
    label: 'contributor',
    translation: 'userId',
  },
  approverId: {
    order: 110,
    label: 'approver',
    translation: 'userId',
  },
  contributorNotifiedAt: {
    order: 120,
    label: 'contributor notification sending time',
    translation: value => moment(value).format('LLLL'),
  },
  approverNotifiedAt: {
    order: 130,
    label: 'approver notification sending time',
    translation: value => moment(value).format('LLLL'),
  },
  contributorReadAt: {
    order: 140,
    label: 'contributor notification seen time',
    translation: value => moment(value).format('LLLL'),
  },
  approverReadAt: {
    order: 150,
    label: 'approver notification seen time',
    translation: value => moment(value).format('LLLL'),
  },
};

for (let m = 0; m < months.length; m++)
  mapping[months[m]] = {
    label: Months[m],
    order: 40 + m,
  };


function ConfirmDialog(props) {
  const {
    open,
    onCancel,
    onConfirm,
    title,
    content,
    sale,
    message,
    setMessage,
    classes,
    approver,
    nextApprover,
    prevApprover,
    contributor,
    expandMessages,
  } = props;
  const [expandedMessages, setExpandedMessages] = useState(!!expandMessages);
  const metadata = sale?.metadata;

  if (!metadata) return null;
  const { messages, history } = (sale || {}).metadata;

  return (
    <Dialog
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <><Title>Details</Title></>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className={classes.label}>Name</div>
              {sale.name}<br/>
              <div className={classes.label}>Year</div>
              {sale.year}<br/>
              <div className={classes.label}>Type</div>
              {sale.type === SALE_TYPE.DAYS ? 'Sale days' : 'Amount'}<br/>
              <div className={classes.label}>Status</div>
              {toReadableSaleStatus(sale.saleStatusId)}<br/>
              <div className={classes.label}>Due date</div>
              {sale.due}<br/>
              <div className={classes.label}>Contributor</div>
              <UserLink user={contributor}/><br/>
              <div className={classes.label}>Approver</div>
              <UserLink user={approver}/>
              {nextApprover &&
              <>
                <br/>
                <div className={classes.label}>Next Approver</div>
                <UserLink user={nextApprover}/>
              </>
              }
              {prevApprover &&
              <>
                <br/>
                <div className={classes.label}>Previous Approver</div>
                <UserLink user={prevApprover}/>
              </>
              }
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedMessages}
          onChange={(_, isExpanded) => setExpandedMessages(isExpanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <><Title>Messages</Title></>
          </AccordionSummary>
          <AccordionDetails>
            <Messages messages={messages}/>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <><Title>History</Title></>
          </AccordionSummary>
          <AccordionDetails>
            <History history={history} mapping={mapping}/>
          </AccordionDetails>
        </Accordion>

        <br/>

        {content}

        <TextField
          label="Optional message"
          multiline
          value={message}
          onChange={event => setMessage(event.target.value)}
          className={classes.message}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function SaleMenu(props) {
  const classes = useStyles();

  const { sale, previousSale, days, unitId, validValues, expandMessages } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [deleteSaleDialogOpen, setDeleteSaleDialogOpen] = useState(false);
  const [restoreSaleDialogOpen, setRestoreSaleDialogOpen] = useState(false);

  const [requestContributionSaleDialogOpen, setRequestContributionSaleDialogOpen] = useState(false);
  const [requestApprovalSaleDialogOpen, setRequestApprovalSaleDialogOpen] = useState(false);
  const [returnToPrevApproverSaleDialogOpen, setReturnToPrevApproverSaleDialogOpen] = useState(false);
  const [approveSaleDialogOpen, setApproveSaleDialogOpen] = useState(false);
  const [finalizeSaleDialogOpen, setFinalizeSaleDialogOpen] = useState(false);
  const [detailsSaleDialogOpen, setDetailsSaleDialogOpen] = useState(false);
  const [message, setMessage] = useState('');

  const [approver, setApprover] = useState(null);
  const [nextApprover, setNextApprover] = useState(null);
  const [prevApprover, setPrevApprover] = useState(null);
  const [contributor, setContributor] = useState(null);

  const [initiateCalculateFromPreviousOpen, setInitiateCalculateFromPreviousOpen] = useState(false);

  const user = useSelector(state => state.user.user);
  const userById = useSelector(state => state.user.userById);
  const assignments = useSelector(state => state.unit.saleAssignmentsPerUnit[unitId] || []);

  const dispatch = useDispatch();

  const isContributionRequested = sale.saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED;
  const isApprovalRequested = sale.saleStatusId === SALE_STATUS.APPROVAL_REQUESTED;
  const isApproved = sale.saleStatusId === SALE_STATUS.APPROVED;
  const isFinal = sale.saleStatusId === SALE_STATUS.FINAL;
  const isDeleted = sale.saleStatusId === SALE_STATUS.DELETED;

  let hasData = false;
  for (const month of months) {
    if (sale[month] !== null) {
      hasData = true;
      break;
    }
  }

  const approvalAssignments = _.chain(assignments)
    .filter(assignment => assignment.level > 0)
    .sortBy(assignment => assignment.level)
    .value();
  const firstApproval = _.head(approvalAssignments);
  const isFirstApproval = !firstApproval || firstApproval.userId === sale.approverId;
  const isContributor = sale.contributorId === user.id;

  const permissionLevel = deepValue(sale, 'permission.level');

  const myAssignmentLevel = permissionLevel >= PERMISSION_LEVEL.CFO ?
    Number.MAX_SAFE_INTEGER :
    _.chain(assignments)
      .sortBy(assignment => assignment.level)
      .findLast(assignment => assignment.userId === user.id)
      .value()?.level || 0;
  const saleAssignmentLevel = assignments.find(assignment => assignment.userId === sale.approverId)?.level || 1;
  const hasHigherAssignmentLevel = myAssignmentLevel >= saleAssignmentLevel;

  useEffect(() => {
    const { approverId, contributorId } = sale;

    if (userById[approverId])
      setApprover(userById[approverId]);
    else
      dispatch(fetchUser(approverId));

    if (userById[contributorId])
      setContributor(userById[contributorId]);
    else
      dispatch(fetchUser(contributorId));

    const currentAssignment = assignments.find(assignment =>
      assignment.level > 0 && assignment.userId === sale.approverId);
    if (currentAssignment) {
      const nextAssignment = assignments.find(assignment => assignment.level > currentAssignment.level);
      if (nextAssignment) {
        const nextApproverId = nextAssignment.userId;
        if (userById[nextApproverId])
          setNextApprover(userById[nextApproverId]);
        else
          dispatch(fetchUser(nextApproverId));
      } else {
        setNextApprover(null);
      }

      const prevAssignment = assignments.find(assignment =>
        assignment.level > 0 && assignment.level < currentAssignment.level);
      if (prevAssignment) {
        const prevApproverId = prevAssignment.userId;
        if (userById[prevApproverId])
          setPrevApprover(userById[prevApproverId]);
        else
          dispatch(fetchUser(prevApproverId));
      } else {
        setPrevApprover(null);
      }
    }
  }, [userById, sale, assignments, dispatch]);

  const stopEvent = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const openMenu = event => {
    stopEvent(event);
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = event => {
    stopEvent(event);
    setMenuAnchorEl(null);
    setMessage('');
    setMenuOpen(false);
  };

  const openCalculateFromPrevious = event => {
    stopEvent(event);
    closeMenu();
    setInitiateCalculateFromPreviousOpen(true);
  };

  const closeCalculateFromPrevious = event => {
    stopEvent(event);
    setInitiateCalculateFromPreviousOpen(false);
  };

  const menuItems = [];

  menuItems.push(
    <MenuItem
      key={`detailsMenuItem${sale.id}`}
      onClick={() => {
        setMenuOpen(false);
        setDetailsSaleDialogOpen(true);
      }}
    >
      Details
    </MenuItem>,
  );

  if (isContributionRequested && canContributeBudgetSales(permissionLevel)) {
    menuItems.push(
      <MenuItem
        key={`calculateMenuItem${sale.id}`}
        onClick={openCalculateFromPrevious}
        disabled={hasData || !previousSale}
      >
        Calculate from last year
      </MenuItem>,
    );
  }

  if (isApprovalRequested && isFirstApproval && (canInitiateBudgetSales(permissionLevel) || isContributor))
    menuItems.push(
      <MenuItem
        key={`requestContributionMenuItem${sale.id}`}
        onClick={() => {
          setMenuOpen(false);
          setRequestContributionSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        Return to contribution requested status
      </MenuItem>,
    );

  if ((isApproved && hasHigherAssignmentLevel && canApproveBudgetSales(permissionLevel)) ||
    (isContributionRequested && canContributeBudgetSales(permissionLevel)))
    menuItems.push(
      <MenuItem
        key={`requestApprovalMenuItem${sale.id}`}
        onClick={() => {
          closeMenu();
          setRequestApprovalSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        {isApproved ? 'Return to request approval status' : 'Request approval'}
      </MenuItem>,
    );

  const hasPreviousApprover = prevApprover !== null;
  if (isApprovalRequested && hasPreviousApprover && hasHigherAssignmentLevel && canApproveBudgetSales(permissionLevel))
    menuItems.push(
      <MenuItem
        key={`returnToPreviousApprover${sale.id}`}
        onClick={() => {
          closeMenu();
          setReturnToPrevApproverSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        Return to previous approver
      </MenuItem>,
    );

  if ((isFinal && canFinalizeBudgetSales(permissionLevel)) ||
    (isApprovalRequested && hasHigherAssignmentLevel && canApproveBudgetSales(permissionLevel)))
    menuItems.push(
      <MenuItem
        key={`approveMenuItem${sale.id}`}
        onClick={() => {
          closeMenu();
          setApproveSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        {isFinal ? 'Return to approved status' : 'Approve'}
      </MenuItem>,
    );

  if (isApproved && canFinalizeBudgetSales(permissionLevel))
    menuItems.push(
      <MenuItem
        key={`finalizeMenuItem${sale.id}`}
        onClick={() => {
          closeMenu();
          setFinalizeSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        Finalize
      </MenuItem>,
    );

  if (!isDeleted && canDeleteBudgetSales(permissionLevel))
    menuItems.push(
      <MenuItem
        key={`deleteMenuItem${sale.id}`}
        onClick={() => {
          closeMenu();
          setDeleteSaleDialogOpen(true);
        }}
        disabled={isFinal || !validValues}
      >
        Delete
      </MenuItem>,
    );

  if (isDeleted && canRestoreBudgetSales(permissionLevel))
    menuItems.push(
      <MenuItem
        key={`restoreMenuItem${sale.id}`}
        onClick={() => {
          closeMenu();
          setRestoreSaleDialogOpen(true);
        }}
        disabled={!validValues}
      >
        Restore
      </MenuItem>,
    );

  const closeDialog = setDialogOpen => {
    setMessage('');
    setDialogOpen(false);
  };

  const onCloseDialog = () => {
    closeDialog(setInitiateCalculateFromPreviousOpen);
  };

  return (
    <>
      <IconButton
        id={'sale-menu-button'} // required for tutorial
        onClick={openMenu}
        size="small"
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id={'sale-menu'} // required for tutorial
        anchorEl={menuAnchorEl}
        keepMounted
        open={menuOpen}
        onClose={closeMenu}>
        {menuItems}
      </Menu>

      <ConfirmDialog
        title="Details"
        content="If confirmed, the message will be sent to all users involved."
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={detailsSaleDialogOpen}
        onCancel={() => closeDialog(setDetailsSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setDetailsSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
        expandMessages={expandMessages}
      />

      <ConfirmDialog
        title="Delete entry"
        content="Are you sure you wish to delete this sale entry?"
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={deleteSaleDialogOpen}
        onCancel={() => closeDialog(setDeleteSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setDeleteSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.DELETED, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <ConfirmDialog
        title="Restore entry"
        content="Are you sure you wish to restore this sale entry?"
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={restoreSaleDialogOpen}
        onCancel={() => closeDialog(setRestoreSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setRestoreSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.APPROVAL_REQUESTED, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <ConfirmDialog
        title="Request contribution"
        content={`If confirmed, a contribution request is sent to ${userDisplayName(contributor)}.`}
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={requestContributionSaleDialogOpen}
        onCancel={() => closeDialog(setRequestContributionSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setRequestContributionSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.CONTRIBUTION_REQUESTED, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <ConfirmDialog
        title="Return to previous approver"
        content={`If confirmed, an approval request is sent to ${userDisplayName(prevApprover)}.`}
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={returnToPrevApproverSaleDialogOpen}
        onCancel={() => closeDialog(setReturnToPrevApproverSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setReturnToPrevApproverSaleDialogOpen);
          dispatch(updateSale(unitId, sale, {
            saleStatusId: SALE_STATUS.APPROVAL_REQUESTED,
            message,
            forceStatusUpdate: true
          }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
        prevApprover={prevApprover}
      />

      <ConfirmDialog
        title="Request approval"
        content={(() => {
          const missingMonths = Months
            .filter(Month => sale[Month.toLowerCase()] === null)
            .map(Month => `"${Month}"`)
            .join(', ');
          const text = `If confirmed, an approval request is sent to ${userDisplayName(approver)}.`;
          if (missingMonths.length > 0)
            return (
              <>
                {text}
                <Typography color="error">Note that some values are missing for {missingMonths}</Typography>
              </>
            );
          return text;
        })()}
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={requestApprovalSaleDialogOpen}
        onCancel={() => closeDialog(setRequestApprovalSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setRequestApprovalSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.APPROVAL_REQUESTED, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <ConfirmDialog
        title="Approve"
        content={nextApprover ?
          `If confirmed, an approval approval request is sent to ${userDisplayName(
            sale.saleStatusId === SALE_STATUS.CONTRIBUTION_REQUESTED ?
              approver :
              nextApprover
          )}` :
          `If confirmed, a final approval request is sent to the CFO.`
        }
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={approveSaleDialogOpen}
        onCancel={() => closeDialog(setApproveSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setApproveSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.APPROVED, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <ConfirmDialog
        title="Finalize"
        content="Are you sure you wish to finalize this sale entry?"
        sale={sale}
        message={message}
        setMessage={setMessage}
        classes={classes}
        open={finalizeSaleDialogOpen}
        onCancel={() => closeDialog(setFinalizeSaleDialogOpen)}
        onConfirm={() => {
          closeDialog(setFinalizeSaleDialogOpen);
          dispatch(updateSale(unitId, sale, { saleStatusId: SALE_STATUS.FINAL, message }));
        }}
        contributor={contributor}
        approver={approver}
        nextApprover={nextApprover}
      />

      <CalculateFromPrevious
        open={initiateCalculateFromPreviousOpen}
        onClose={closeCalculateFromPrevious}
        onConfirm={onCloseDialog}
        unitId={unitId}
        sale={sale}
        previousSale={previousSale}
        days={days}
      />
    </>
  );
}
