import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import expressions from '../../../../../common/src/util/expressions';
import { fetchConsolidatedStructure } from '../../action/consolidated';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  input: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  table: {
    display: 'table',
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(1),
  },
  tableRow: {
    display: 'table-row',
  },
  tableKey: {
    display: 'table-cell',
    fontWeight: theme.typography.fontWeightMedium,
    width: 200,
  },
  tableValue: {
    display: 'table-cell',
  }
}));

const GroupsDropdown = ({ groups, selectedGroup, onSelectGroupChange }) => {
  const classes = useStyles();

  if (!selectedGroup) {
    return null;
  }

  return (
    <div>
      <FormControl className={classes.fullWidth}>
        <InputLabel id="groups-select-label">Groups</InputLabel>
        <Select
          labelId="groups-select"
          id="groups-select"
          value={selectedGroup.id}
          onChange={onSelectGroupChange}
          label="Group"
        >
          {
            groups.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  )
}

const AccountModal = (
  {
    open,
    onCancel,
    onConfirm,
    title,
    account,
    editing,
    unitId
  }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const groups = useSelector(state => state.account.groups)
    .filter(group => group.unitId === unitId);
  const structure = useSelector(state => state.consolidated.structure);

  const [errors, setErrors] = useState({});

  const [number, setNumber] = useState(account.number || '');
  const onNumberChange = ({ target: { value } }) => {
    setNumber(value);
    setErrors({ ...errors, number: !_.isInteger(parseInt(value)) })
  };

  const [name, setName] = useState(account.name || '');
  const onNameChange = ({ target: { value } }) => {
    setName(value);
    setErrors({ ...errors, name: !value });
  };

  const [formula, setFormula] = useState(account.formula || '');
  const onFormulaChange = ({ target: { value } }) => {
    setFormula(value);
  }

  const [automatic, setAutomatic] = useState(!!account.automatic);
  const onAutomaticChange = ({ target: { checked } }) => {
    setAutomatic(checked);
  }

  const [selectedGroup, setSelectedGroup] = useState(groups.find(group => group.id === account.categoryId));

  const [variables, setVariables] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [sales, setSales] = useState(false);

  const updating = useSelector(state => state.account.updatingAccount);

  useEffect(() => {
    if (open) {
      setNumber(account.number || '');
      setName(account.name || '');
      setFormula(account.formula || '');
      setAutomatic(!!account.automatic);
    }
  }, [open, account]);

  useEffect(() => {
    if (open) {
      dispatch(fetchConsolidatedStructure(unitId));
    }
  }, [open, dispatch]);

  useEffect(() => {
    try {
      const { variables, accounts, sales } = expressions.parse(formula);
      setVariables(variables);
      setAccounts(accounts);
      setSales(sales);
      setErrors({
        ...errors,
        formula: automatic && variables.length > 0 ? 'Automatically calculated cost accounts cannot have variables' : undefined,
        automatic: automatic && !formula ? 'Automatic calculation formula missing' : undefined
      });
    } catch (err) {
      setErrors({ ...errors, formula: err.message });
    }
  }, [formula, automatic]);

  const onSelectGroupChange = ({ target: { value } }) => {
    setSelectedGroup(groups.find(item => item.id === value));
  }

  const valid = _.every(_.values(errors), v => !v);

  const mapAccount = account => {
    const name = structure.accounts[account]?.name;
    return name ? `${account} - ${name}` : account;
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <GroupsDropdown
          groups={groups}
          selectedGroup={selectedGroup}
          onSelectGroupChange={onSelectGroupChange}
        />
        <FormControl component="fieldset" className={classes.input}>
          <TextField label="Number"
                     value={number}
                     disabled={editing}
                     onChange={onNumberChange}
                     error={!!errors.number}/>
        </FormControl>
        <FormControl component="fieldset" className={classes.input}>
          <TextField label="Name"
                     value={name}
                     onChange={onNameChange}
                     error={!!errors.name}/>
        </FormControl>
        <FormControl component="fieldset" className={classes.fullWidth}>
          <TextField label="Formula"
                     value={formula}
                     onChange={onFormulaChange}
                     error={!!errors.formula || !!errors.automatic}
                     helperText={
                       errors.formula || errors.automatic ||
                       `Examples: 'percentage * sales()', 'percentage * sum(account(1), account(2))'`}
          />
        </FormControl>

        {formula &&
        <div className={classes.table}>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent variables:</div>
            <div className={classes.tableValue}>{variables.join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent accounts:</div>
            <div className={classes.tableValue}>{accounts.map(mapAccount).join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent on sales:</div>
            <div className={classes.tableValue}>{sales ? 'yes' : 'no'}</div>
          </div>
        </div>
        }

        <FormControl component="fieldset" className={classes.input}>
          <FormControlLabel label="Automatically calculated"
                            control={
                              <Checkbox checked={automatic}
                                        onChange={onAutomaticChange}
                                        color="primary"
                              />
                            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                disabled={updating}
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!valid || updating}
                onClick={() => {
                  onConfirm({ id: account.id, number: parseInt(number), name, formula, automatic }, selectedGroup);
                }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    formula: PropTypes.string,
    categoryId: PropTypes.number
  }),
  unitId: PropTypes.number
};

GroupsDropdown.propTypes = {
  groups: PropTypes.array.isRequired,
  onSelectGroupChange: PropTypes.func.isRequired,
  selectedGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    categoryId: PropTypes.number
  })
}

export default memo(AccountModal);
