import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Menu from './Menu';
import SignIn from '../sign-in/SignIn';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkSignIn, signOut } from '../../action/user';
import { hideError } from '../../action/error';
import history from '../../util/history';

import Tasks from '../tasks';
import Units from '../units';
import Sales from '../sales';
import Costs from '../costs';
import Consolidated from '../consolidated';
import Users from '../users';
import Accounts from '../accounts';
import UserSettings from '../userSettings';
import SystemSettings from '../systemSettings';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import areEqual from '../../util/areEqual';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(2),
    '@media (min-width: 2000px)': {
      maxWidth: 2000
    },
    '& > div': {
      ['@media (min-width:1760px)']: {
        justifyContent: 'center',
      },
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const user = useSelector(state => state.user);
  const error = useSelector(state => state.error);
  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    if (document.location.hash) {
      sessionStorage.setItem('hash', document.location.hash);
    }
    dispatch(checkSignIn());
  }, [dispatch]);


  if (!user.checkedSignIn)
    return (<div className={classes.loader}><CircularProgress/></div>);

  const handleDrawerOpen = () => setMenuOpen(true);
  const handleDrawerClose = () => setMenuOpen(false);

  let redirect, header, menu;
  if (user.signedIn) {
    header = <Header handleDrawerOpen={handleDrawerOpen} menuOpen={menuOpen}/>;
    menu = <Menu handleDrawerClose={handleDrawerClose} menuOpen={menuOpen}/>;
    if (window.location.pathname.indexOf('/sign-in') !== -1) {
      redirect = <Redirect to={{ pathname: user.returnUrl }}/>;
    }
  } else {
    const pathname = window.location.pathname.indexOf('/sign-in') !== -1 ?
      window.location.pathname : '/sign-in';
    redirect = <Redirect to={{ pathname }}/>;
  }

  let errorSnackbar;
  if (error.error) {
    const { message, onCloseSignOut, autoHideDuration } = error.error;
    const snackbarClose = onCloseSignOut => () => {
      dispatch(hideError());
      if (onCloseSignOut)
        dispatch(signOut());
    };
    errorSnackbar = (
      <Snackbar open autoHideDuration={autoHideDuration || 5000} onClose={snackbarClose(onCloseSignOut)}>
        <Alert severity="error" onClose={snackbarClose(onCloseSignOut)}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Router history={history}>
      <div className={classes.root}>
        <CssBaseline/>

        {redirect}
        {header}
        {menu}
        {errorSnackbar}

        {/* id is used for programmatic scrolling */}
        <main id="main" className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>
            <Switch>

              {/* Temporary fix due to BT-226 to be removed in future */}
              <Route path="/sign-in/tasks">
                <Tasks/>
              </Route>

              <Route exact path="/">
                <Tasks/>
              </Route>
              <Route path="/tasks">
                <Tasks/>
              </Route>
              <Route path="/units">
                <Units/>
              </Route>
              <Route path="/unit/:unitId/sale">
                <Sales/>
              </Route>
              <Route path="/sales">
                <Sales/>
              </Route>
              <Route path="/costs">
                <Costs/>
              </Route>
              <Route path="/unit/:unitId/costs">
                <Costs/>
              </Route>
              <Route exact path="/unit/:unitId/accounts">
                <Accounts/>
              </Route>
              <Route exact path="/consolidated">
                <Consolidated/>
              </Route>
              <Route exact path="/unit/:unitId/consolidated">
                <Consolidated/>
              </Route>
              <Route exact path="/consolidated/distributors">
                <Consolidated/>
              </Route>
              <Route exact path="/consolidated/grouped">
                <Consolidated/>
              </Route>
              <Route exact path="/settings">
                <UserSettings/>
              </Route>
              <Route exact path="/sign-in">
                <SignIn/>
              </Route>
              <Route exact path="/sign-in/:token">
                <SignIn/>
              </Route>
              <Route path="/users">
                <Users/>
              </Route>
              <Route exact path="/system-settings">
                <SystemSettings/>
              </Route>
            </Switch>
          </Container>
        </main>
      </div>
    </Router>
  );
}

export default memo(Dashboard, areEqual);
