import React, {
  memo,
  useEffect,
  useMemo,
  useState
} from 'react';
import { toWordsOrdinal } from 'number-to-words';
import * as _ from 'lodash';
import {
  canInitiateBudgetSales,
  contributeBudgetSalesLevel
} from '../../../../../common/src/util/permission';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import {
  months,
  Months,
  SALE_STATUS,
  SALE_TYPE,
  UNIT_TYPE
} from '../../../../../common/src/util/enum';
import {
  DATE_FORMAT,
  isValidDateFormat,
  nextBudgetYear
} from '../../../../../common/src/util/date';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import clsx from 'clsx';
import { deepCopy } from '../../../../../common/src/util/object';
import Checkbox from '@material-ui/core/Checkbox';
import UserSearch from '../common/UserSearch';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  fetchInitiations,
  fetchUnitUsers,
  saveUnitSales
} from '../../action/unit';
import {
  formatNumber,
  parseNumber
} from '../../../../../common/src/util/number';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import EditableCell from '../common/EditableCell';
import areEqual from '../../util/areEqual';

const useStyles = makeStyles((theme) => ({
  dialog: {},
  headColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  nameColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  strongCell: {
    fontWeight: 500,
  },
  rowDivider: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  input: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  table: {
    marginTop: theme.spacing(2),
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  duplicate: {
    color: theme.palette.error.main,
    display: 'block',
    fontSize: 11,
  },
  yearColumn: {
    textAlign: 'right',
  },
  includedColumn: {
    width: 50,
  },
  message: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

const isNameValid = name => String(name).trim() !== '';
const isDistrictValid = district => String(district).trim() !== '';
const isValueValid = value => value === '' || value === null || !isNaN(parseNumber(value));

const SalesTable = props => {

  const classes = useStyles();

  const {
    increase,
    includedSales,
    setIncludedSales,
    hideMonths,
  } = props;

  const tableHead = (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.includedColumn, classes.headColumn)}>Included</TableCell>
        <TableCell className={classes.headColumn}>District</TableCell>
        <TableCell className={classes.headColumn}>Name</TableCell>
        <TableCell align="right" className={classes.headColumn}>Year</TableCell>
        {
          hideMonths ? null : Months.map(month =>
            <TableCell align="right" key={month} className={classes.headColumn}>
              {month}
            </TableCell>,
          )
        }
      </TableRow>
    </TableHead>
  );

  const includeSale = saleId => event => {
    const sale = includedSales.find(s => s.id === saleId);
    if (!sale)
      return;

    sale.included = event.target.checked;
    setIncludedSales(includedSales);
  };

  const onKeyDown = event => {
    if (event.key === 'Enter')
      event.target.blur();
  };

  const onBlur = (saleId, property) => event => {
    event.preventDefault();

    const sale = includedSales.find(s => s.id === saleId);
    if (!sale)
      return;

    const strippedHtml = event.target.innerText.replace(/<[^>]+>/g, '');
    const newValue = (property === 'name' || property === 'district') ?
      strippedHtml :
      parseNumber(strippedHtml) || strippedHtml;

    const oldValue = sale[property];
    if (newValue === oldValue)
      return;
    sale[property] = newValue;
    setIncludedSales(includedSales);
  };

  const onFocus = () => setTimeout(() => document.execCommand('selectAll', false, null));

  const tableBody = (
    <TableBody>
      {includedSales.map(sale => {
        const { district, name } = sale;

        const checkbox = (
          <Checkbox
            checked={sale.included}
            onChange={event => includeSale(sale.id)(event)}
            name="checkedB"
            color="primary"
          />
        );

        return (
          <TableRow key={sale.id}>
            <TableCell className={clsx(classes.includedColumn)}>
              {checkbox}
            </TableCell>
            <TableCell className={clsx(classes.nameColumn)}>
              <EditableCell
                value={district}
                onBlur={onBlur(sale.id, 'district')}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                editable
                valid={!sale.included || isDistrictValid(district)}
              />
            </TableCell>
            <TableCell className={clsx(classes.nameColumn)}>
              <EditableCell
                value={name}
                onBlur={onBlur(sale.id, 'name')}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                editable
                valid={!sale.included || isNameValid(name)}
              />
              {sale.duplicate ? <div className={classes.duplicate}>Duplicate entry</div> : ''}
            </TableCell>
            <TableCell className={clsx(classes.yearColumn)}>
              {sale.year}
            </TableCell>
            {
              hideMonths ? null : months.map(month => {

                let value = sale[month];
                const valid = isValueValid(value);
                const isModifiedManually = typeof value === 'string';
                if (value !== null && valid && !isModifiedManually)
                  value = Math.round(value + increase * value / 100);

                return (
                  <TableCell align="right" key={month}>
                    <EditableCell
                      value={valid ? formatNumber(value) : value}
                      onBlur={onBlur(sale.id, month)}
                      onFocus={onFocus}
                      onKeyDown={onKeyDown}
                      editable={true}
                      valid={!sale.included || valid}
                    />
                  </TableCell>
                );
              })
            }
          </TableRow>
        );
      })}
    </TableBody>
  );

  return (
    <Table className={classes.table}>
      {tableHead}
      {tableBody}
    </Table>
  );
};

const evaluateInitialSales = (sales, nextYear, unit) => {
  const initialDistricts = new Set();
  const initialSales = deepCopy(sales)
    .sort((sale1, sale2) => sale1.district?.localeCompare(sale2.district) || sale1.name?.localeCompare(sale2.name))
    .filter(sale => sale.saleTypeId !== SALE_STATUS.DELETED &&
      (sale.saleTypeId === SALE_TYPE.AMOUNT || sale.saleTypeId === SALE_TYPE.AMOUNT_BUDGET))
    .map(sale => {
      const previouslyDeleted = sales.find(s => s.district === sale.district &&
        s.saleStatusId === SALE_STATUS.DELETED);
      sale.included = !previouslyDeleted;
      months.forEach(month => sale[month] = null);
      sale.year = nextYear;
      if (sale.saleTypeId === SALE_TYPE.AMOUNT)
        sale.saleTypeId = SALE_TYPE.AMOUNT_BUDGET;
      return sale;
    })
    .filter(sale => {
      if (isDuplicate(sale, sales) || initialDistricts.has(sale.district))
        return false;
      initialDistricts.add(sale.district);
      return true;
    });

  if (sales.length === 0 && [UNIT_TYPE.DISTRIBUTOR, UNIT_TYPE.REGION].includes(unit ? unit.type : null)) {
    const emptyRow = {
      id: 'new-row',
      name: '',
      year: nextYear,
      unitId: unit ? unit.id : null,
      saleStatusId: SALE_STATUS.CONTRIBUTION_REQUESTED,
      saleTypeId: SALE_TYPE.AMOUNT_BUDGET,
      included: true,
    };
    months.forEach(month => emptyRow[month] = null);
    initialSales.push(emptyRow)
  }
  return initialSales;
};

const isDuplicate = (sale, sales) => sale.included &&
  sales
    .filter(s =>
      s.saleTypeId === sale.saleTypeId &&
      s.year === sale.year &&
      s.unitId === sale.unitId &&
      (sale.saleTypeId === SALE_TYPE.AMOUNT || sale.saleTypeId === SALE_TYPE.AMOUNT_BUDGET ?
        (s.name === sale.name || s.district === sale.district) : true),
    ).length > 1
;

const InitiateSalesBudget = props => {
  const classes = useStyles();

  const {
    open,
    onClose,
    unit,
    sales,
    assignments,
  } = props;

  const dispatch = useDispatch();
  const [year, setYear] = useState('');
  const [error, setError] = useState({});
  const { user: signedInUser } = useSelector(state => state.user);
  const users = useSelector(state => state.unit.fetchedUnitUsers[unit.id]);
  const loadingUsers = useSelector(state => state.unit.fetchingUnitUsers);

  const initialDueDate = moment().add(7, 'days').format(DATE_FORMAT);
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [dueDateInputValue, setDueDateInputValue] = useState(initialDueDate);
  const [message, setMessage] = useState('');

  const [contributor, setContributor] = useState(null);
  const [approver, setApprover] = useState(null);
  const [secondaryApprovers, setSecondaryApprovers] = useState([]);

  const nextYear = nextBudgetYear();

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!users) {
      dispatch(fetchUnitUsers(unit.id));
    } else if (signedInUser) {
      const self = users.find(user => user.id === signedInUser.id);

      const assignedContributorId = assignments.find(assignment => assignment.level === 0)?.userId;
      const assignedContributor = users.find(user => user.id === assignedContributorId);
      let contributor;
      if (assignedContributor) {
        contributor = assignedContributor;
      } else {
        const otherContributor = users.find(user => user.id !== self.id &&
          user.permission.level >= contributeBudgetSalesLevel);
        contributor = otherContributor || self;
      }
      setContributor(contributor);

      const assignedApprovers = _.chain(assignments)
        .filter(assignment => assignment.level > 0)
        .orderBy(assignment => assignment.level)
        .map(assignment => users.find(user => user.id === assignment.userId))
        .filter(user => !!user)
        .value();
      const assignedImmediateApprover = _.head(assignedApprovers);
      if (assignedImmediateApprover) {
        setApprover(assignedImmediateApprover);
      } else {
        setApprover(self);
      }

      setSecondaryApprovers(assignedApprovers.filter(approver => approver !== assignedImmediateApprover));
    }

    setYear(nextYear);

  }, [dispatch, users, unit, signedInUser, open, assignments]);

  useEffect(() => {
    dispatch(fetchInitiations(unit.id));
  }, [unit]);

  const initialIncludedSales = useMemo(() => evaluateInitialSales(sales, nextYear, unit), []);
  const [_includedSales, _setIncludedSales] = useState(initialIncludedSales);

  const setIncludedSales = includedSales => {
    const _includedSales = deepCopy(includedSales);
    for (const sale of _includedSales) {
      sale.duplicate = isDuplicate(sale, [
        ...sales,
        ..._includedSales.filter(({ included }) => included),
      ]);
      const areAllAmountsValid = months.every(month => isValueValid(sale[month]));
      sale.error = !isNameValid(sale.name) || !isDistrictValid(sale.district) || !areAllAmountsValid;
    }
    _setIncludedSales(_includedSales);
  };

  if (!canInitiateBudgetSales || !unit || !sales || !open)
    return <></>;

  const onConfirm = () => {
    let sales;
    if (unit.type !== 'distributor') {
      sales = _includedSales.filter(sale => sale.included);
    } else {
      sales = [{
        district: unit.countryCode,
        name: 'Total',
        year,
        saleTypeId: SALE_TYPE.AMOUNT_BUDGET,
        ..._.zipObject(months, months.map(() => null)),
      }];
    }

    for (const sale of sales)
      months.forEach(month => {
        const value = sale[month];
        if (value === '')
          sale[month] = null;
        else if (/^\d+$/.test(String(value)))
          sale[month] = parseInt(value);
      });

    dispatch(saveUnitSales({
      sales,
      unitId: unit.id,
      contributorId: contributor.id,
      approverId: approver.id,
      due: dueDate,
      message,
    }));

    setMessage('');
    onClose();
  };

  const onAddAmount = () => {
    const sale = {
      id: Math.round(10E6 * Math.random()),
      district: '',
      name: '',
      year,
      saleTypeId: SALE_TYPE.AMOUNT_BUDGET,
      saleStatusId: SALE_STATUS.CONTRIBUTION_REQUESTED,
      unitId: unit.id,
      included: true,
    };
    months.forEach(month => sale[month] = null);
    _includedSales.push(sale);
    setIncludedSales(_includedSales);
  };

  const onDueDateChange = (date, value) => {
    setDueDate(moment(date).format(DATE_FORMAT));
    setDueDateInputValue(value);

    if (isValidDateFormat(date))
      delete error.due;
    else
      error.due = 'Invalid date format';
    setError(error);
  };

  const dateFormatter = str => {
    return str;
  };

  const hasAtLeastOneSale = _includedSales.some(sale => sale.included);
  const hasNoErrors = Object.keys(error).length === 0;
  const hasApproverSelected = !!approver;
  const hasContributorSelected = !!contributor;
  const hasValidTableEntries = !_includedSales.find(sale => sale.included && sale.error);
  const hasNoDuplicates = !_includedSales.find(sale => sale.duplicate);

  const validInputs =
    (unit.type === 'distributor' || hasAtLeastOneSale) &&
    hasNoErrors &&
    hasApproverSelected &&
    hasContributorSelected &&
    hasValidTableEntries &&
    hasNoDuplicates;

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Initiate sales budget</DialogTitle>
      <DialogContent>
        <div className={classes.inputs}>
          <TextField
            label="Year"
            type="number"
            value={year}
            className={classes.input}
            inputProps={{ readOnly: true }}
          />
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              label="Due date"
              autoOk={true}
              showTodayButton={false}
              value={dueDate}
              format={DATE_FORMAT}
              inputValue={dueDateInputValue}
              onChange={onDueDateChange}
              error={!!error.due}
              helperText={error.due}
              rifmFormatter={dateFormatter}
              className={classes.input}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <UserSearch
              loading={loadingUsers}
              label="Assigned contributor"
              setUser={setContributor}
              user={contributor}
              users={users}
              error={!hasContributorSelected ? 'Required' : ''}/>
          </div>
          <div className={classes.input}>
            <UserSearch
              loading={loadingUsers}
              label="Assigned first approver"
              setUser={setApprover}
              user={approver}
              users={users}
              error={!hasApproverSelected ? 'Required' : ''}/>
          </div>
        </div>
        {secondaryApprovers.map((secondaryApprover, index) => (
          <div key={secondaryApprover.id} className={classes.inputs}>
            <div className={classes.input}/>
            <div className={classes.input}>
              <TextField
                label={`Assigned ${toWordsOrdinal(index + 2)} approver`}
                InputProps={{ readOnly: true }}
                value={secondaryApprover.displayName}
                style={{ width: '100%' }}/>
            </div>
          </div>
        ))}

        {unit.type !== 'distributor' && (
          <SalesTable
            includedSales={_includedSales}
            setIncludedSales={setIncludedSales}
            unit={unit}
            hideMonths={true}/>
        )}

        <TextField
          label="Optional message"
          multiline
          value={message}
          onChange={event => setMessage(event.target.value)}
          className={classes.message}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary"> Cancel </Button>
        {unit.type !== 'distributor' && (
          <Button onClick={onAddAmount} color="primary"> Add row </Button>
        )}
        <Button onClick={onConfirm} color="primary" autoFocus disabled={!validInputs}> Confirm </Button>
      </DialogActions>
    </Dialog>);
};

export default memo(InitiateSalesBudget, areEqual);
