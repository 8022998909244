import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Units from '../units/Units';
import { fetchConsolidated, fetchConsolidatedDistributors, fetchConsolidatedGrouped } from '../../action/consolidated';
import ConsolidatedHeader from './ConsolidatedHeader';
import { fetchUnits } from '../../action/unit';
import ConsolidatedSales from './ConsolidatedSales';
import ConsolidatedCosts from './ConsolidatedCosts';
import ConsolidatedRecords from './ConsolidatedRecords';
import { useLocation } from 'react-router';
import { Backdrop, Button, CircularProgress, Paper } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';
import protectedRoute from '../common/hocs/protectedRoute';
import ConsolidatedGrouped from './ConsolidatedGrouped';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Consolidated = () => {
  const params = useParams();
  const unitId = parseInt(params.unitId);

  const location = useLocation();
  const distributors = location.pathname.endsWith('/distributors');
  const grouped = location.pathname.endsWith('/grouped');

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (distributors) {
      dispatch(fetchConsolidatedDistributors());
    } else if (unitId) {
      dispatch(fetchUnits());
      dispatch(fetchConsolidated(unitId));
    } else if (consolidatedGrouped) {
      dispatch(fetchConsolidatedGrouped());
    }
  }, [dispatch, unitId, distributors]);

  const fetching = useSelector(state => state.consolidated.fetching);
  const consolidated = useSelector(state => state.consolidated.consolidated);
  const unit = useSelector(state => state.unit.units?.find(u => u.id === unitId));
  const consolidatedGrouped = useSelector(state => state.consolidated.consolidatedGrouped);

  if (unit || distributors) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={fetching}>
          <CircularProgress color="inherit"/>
        </Backdrop>
        <ConsolidatedHeader unit={unit} root={consolidated.root}/>
        <Grid container spacing={2}>
          {consolidated.sales && <ConsolidatedSales sales={consolidated.sales}/>}
          {consolidated.costs && <ConsolidatedCosts costs={consolidated.costs}/>}
          {consolidated.groups && <ConsolidatedRecords unit={unit} groups={consolidated.groups}/>}
        </Grid>
      </>
    );
  } else if (grouped) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={fetching}>
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Grid container spacing={2}>
          <ConsolidatedGrouped companies={consolidatedGrouped.companies} records={consolidatedGrouped.records}/>
        </Grid>
      </>
    )
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <Button color="primary"
                    startIcon={<BusinessIcon/>}
                    onClick={() => history.push('/consolidated/grouped')}>
              Group Summary
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Units
            hideActions={true}
            onSelect={unitId => unitId > 0 ?
              history.push(`/unit/${unitId}/consolidated`) :
              history.push(`/consolidated/distributors`)
            }
            expandAll={true}
            companiesOnly
            addDistributorsNode
          />
        </Grid>
      </Grid>
    );
  }
}

export default protectedRoute(Consolidated, PERMISSION_LEVEL.MANAGER);
