import React, { memo, useEffect, useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import expressions from '../../../../../common/src/util/expressions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  table: {
    display: 'table',
    marginLeft: theme.spacing(1),
  },
  tableRow: {
    display: 'table-row',
  },
  tableKey: {
    display: 'table-cell',
    fontWeight: theme.typography.fontWeightMedium,
    width: 200,
  },
  tableValue: {
    display: 'table-cell',
  }
}));

const ConsolidatedRecordModal = ({ open, onCancel, onConfirm, title, record, companies }) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({});

  const [name, setName] = useState(record.name || '');
  const onNameChange = ({ target: { value } }) => {
    setName(value);
    setErrors({ ...errors, name: !value });
  };

  const [global, setGlobal] = useState(true);
  const onGlobalChange = ({ target: { checked } }) => {
    setGlobal(checked);
  };

  const [formula, setFormula] = useState(record.formula || '');
  const onFormulaChange = ({ target: { value } }) => {
    setFormula(value);
  }

  const [formulae, setFormulae] = useState({});
  const onFormulaeChange = (companyId) => ({ target: { value } }) => {
    setFormulae({ ...formulae, [companyId]: value });
    setFormula(value);
  }
  const onFormulaeFocus = (companyId) => ({ target: { value } }) => {
    setFormula(formulae[companyId]);
  }

  const [records, setRecords] = useState([]);
  const [groupRecords, setGroupRecords] = useState([]);

  const updating = useSelector(state => state.consolidated.updating);
  const structure = useSelector(state => state.consolidated.consolidatedGrouped.structure);

  useEffect(() => {
    const recordStructure = structure.groupRecords[record.id];
    if (recordStructure) {
      setName(recordStructure.name || '');
      setFormula(recordStructure.formula || '');
      setGlobal(!!formula);
      if (!global) {
        const formulae = {};
        for (const entry of recordStructure?.entries || []) {
          formulae[entry.unitId] = entry.formula;
        }
        setFormulae(formulae);
      }
    }
  }, [open, record, structure]);

  useEffect(() => {
    try {
      const { records, groupRecords } = expressions.parse(formula, 'groupConsolidated');
      setRecords(records);
      setGroupRecords(groupRecords);
      setErrors({ ...errors, formula: false });
    } catch (err) {
      setRecords([]);
      setGroupRecords([]);
      setErrors({ ...errors, formula: err.message });
    }
  }, [formula]);
  useEffect(() => {
    const mutation = {};
    for (const [key, value] of Object.entries(formulae)) {
      const errorKey = 'formula' + key;
      try {
        expressions.parse(value, 'groupConsolidated');
        mutation[errorKey] = false;
      } catch (err) {
        mutation[errorKey] = err.message;
      }
    }
    setErrors({ ...errors, ...mutation });
  }, [formulae]);

  const valid = _.every(_.values(errors), v => !v);

  const mapRecord = record => {
    const name = structure.records[record]?.name || record;
    return name ? `${record} - ${name}` : record;
  }
  const mapGroupRecord = record => {
    const name = structure.groupRecords[record]?.name || record;
    return name ? `${record} - ${name}` : record;
  }

  const getRecord = () => {
    const r = { id: record.id, name };
    if (global) {
      r.formula = formula
      r.entries = [];
    } else {
      r.formula = null;
      r.entries = Object.entries(formulae).map(([unitId, formula]) => ({ unitId: parseInt(unitId), formula }));
    }
    return r;
  }

  return (
    <Dialog fullWidth
            open={open}
            onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.input}>
          <TextField label="Name"
                     value={name}
                     onChange={onNameChange}
                     error={!!errors.name}/>
        </FormControl>
        <FormControl component="fieldset" className={clsx(classes.input)}>
          <FormControlLabel label="Same formula for all companies"
                            control={
                              <Checkbox color="primary"
                                        checked={global}
                                        onChange={onGlobalChange}/>
                            }/>
        </FormControl>
        {global ? (
          <FormControl component="fieldset" className={classes.fullWidth}>
            <TextField label="Formula"
                       value={formula}
                       onChange={onFormulaChange}
                       error={!!errors.formula}
                       helperText={errors.formula ||
                         `Available functions: 
                     record(id), records(id1, id2, ...), groupRecord(id), groupRecords(id1, id2, id3...)`}
            />
          </FormControl>
        ) : (
          <div className={classes.table}>
            {companies.map(company => (
              <FormControl component="fieldset" className={classes.fullWidth}>
                <TextField label={company.name}
                           value={formulae[company.id]}
                           onChange={onFormulaeChange(company.id)}
                           onFocus={onFormulaeFocus(company.id)}
                           error={!!errors['formula' + company.id]}
                           helperText={errors['formula' + company.id]}
                />
              </FormControl>
            ))}
          </div>
        )}

        <div className={classes.table}>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent records:</div>
            <div className={classes.tableValue}>{records.map(mapRecord).join(', ')}</div>
          </div>
          <div className={classes.tableRow}>
            <div className={classes.tableKey}>Dependent group records:</div>
            <div className={classes.tableValue}>{groupRecords.map(mapGroupRecord).join(', ')}</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
                disabled={updating}
                onClick={() => {
                  onCancel();
                }}>
          Cancel
        </Button>
        <Button color="primary"
                autoFocus
                disabled={!valid || updating}
                onClick={() => onConfirm(getRecord())}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConsolidatedRecordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    formula: PropTypes.string,
    entries: PropTypes.shape({
      unitId: PropTypes.number,
      formula: PropTypes.string,
    })
  }),
};

export default memo(ConsolidatedRecordModal);
