import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import expressions from '../../../../../common/src/util/expressions';
import { parseNumber } from '../../../../../common/src/util/number';

const useStyles = makeStyles((theme) => ({
  input: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  }
}));

export default function CalculateCost({ open, onConfirm, onClose, cost, type }) {
  const classes = useStyles();

  const variables = !!type.formula ? expressions.parse(type.formula).variables : [];

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({});
  const setValue = (e, variable, value) => {
    const clean = value === '' || /^-?\d*([.,]\d*)?%?$/g.test(value);
    if (!clean) {
      e.preventDefault();
      return;
    }

    const valid = /^-?\d+([.,]?\d+)?%?$/g.test(value);
    const percentage = value.endsWith('%');
    const stripped = value.replace('%', '');
    if (valid && !stripped.endsWith('0')) {
      const numeric = parseNumber(stripped);
      if (!_.isNaN(numeric)) {
        setValues({ ...values, [variable]: percentage ? `${numeric}%` : `${numeric}` });
      }
    } else {
      setValues({ ...values, [variable]: value });
    }
    setErrors({ ...errors, [variable]: !valid });
  };
  const onBlur = (e, variable, value) => {
    const percentage = value.endsWith('%');
    const numeric = parseNumber(value.replace('%', ''));
    if (!isNaN(numeric)) {
      setValues({ ...values, [variable]: percentage ? `${numeric}%` : `${numeric}` });
    }
  };

  useEffect(() => {
    if (open) {
      setValues({});
      setErrors({});
      setValues(cost.metadata.variables || {});
    }
  }, [open]);

  const valid = Object.values(errors).every(value => !value);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Calculate cost</DialogTitle>
      <DialogContent>
        <FormControl className={classes.fullWidth}>
          <TextField label="Formula"
                     value={type.formula}
                     inputProps={{ readOnly: true }}/>
        </FormControl>
        {variables.map(variable => (
          <FormControl className={classes.input}
                       key={variable}>
            <TextField label={variable}
                       value={values[variable]}
                       onChange={e => setValue(e, variable, e.target.value)}
                       onBlur={e => onBlur(e, variable, e.target.value)}
                       error={errors[variable]}/>
          </FormControl>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={() => onConfirm(values)} color="primary" disabled={!valid} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
