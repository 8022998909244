import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DATE_FORMAT, isValidDateFormat, nextBudgetYear } from '../../../../../common/src/util/date';
import UserSearch from '../common/UserSearch';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { initiateCosts } from '../../action/cost';
import { FormLabel } from '@material-ui/core';
import { PERMISSION_LEVEL } from '../../../../../common/src/util/enum';

const useStyles = makeStyles(theme => ({
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  input: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  message: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  tasks: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    width: 150,
    display: 'inline-block',
    marginTop: theme.spacing(1),
  },
}));

export const CostInitiateDialog = ({ open, onClose, unitId, groupId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [errors, setErrors] = useState({});

  const [year, setYear] = useState(nextBudgetYear());

  const initialDueDate = moment().add(7, 'days').format(DATE_FORMAT);
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [dueDateInputValue, setDueDateInputValue] = useState();
  const onChangeDueDate = (date, value) => {
    setDueDate(moment(date).format(DATE_FORMAT));
    setDueDateInputValue(value);
    setErrors({ ...errors, dueDate: isValidDateFormat(date) ? undefined : 'Invalid date format' });
  }

  const loadingUsers = useSelector(state => state.unit).fetchingUnitUsers;
  const users = useSelector(state => state.unit.fetchedUnitUsers[unitId]);
  const potentialApprovers = users?.filter(user => user.permission.level >= PERMISSION_LEVEL.OWNER) || [];

  const { user: myself } = useSelector(state => state.user);
  const [approver, setApprover] = useState(null);
  const onChangeApproved = approver => {
    setApprover(approver);
    setErrors({ ...errors, approver: approver ? undefined : 'Required' });
  }

  const [message, setMessage] = useState('');

  const dryRunInitiatedCosts = useSelector(state => state.cost.dryRunInitiatedCosts);

  useEffect(() => {
    setYear(year);
    setApprover(myself);
    setDueDate(initialDueDate);
  }, [open, year, myself, initialDueDate]);
  useEffect(() => {
    if (open) {
      dispatch(initiateCosts(unitId, year, approver.id, message, dueDate, groupId, true));
    }
  }, [open, year]);

  const valid = _.chain(errors).values().every(v => v === undefined).value();
  const hasTasks = dryRunInitiatedCosts.added.length > 0 || dryRunInitiatedCosts.reassigned.length > 0;

  const onConfirm = () => {
    dispatch(initiateCosts(unitId, year, approver.id, message, dueDate, groupId, false));
    onClose();
  }

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>Initiate costs</DialogTitle>
      <DialogContent>
        <div className={classes.inputs}>
          <TextField label="Year"
                     type="number"
                     value={year}
                     className={classes.input}
                     inputProps={{ readOnly: true }}/>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker label="Due date"
                                autoOk={true}
                                showTodayButton={false}
                                value={dueDate}
                                format={DATE_FORMAT}
                                inputValue={dueDateInputValue}
                                onChange={onChangeDueDate}
                                error={!!errors.dueDate}
                                helperText={errors.dueDate}
                                rifmFormatter={value => value}
                                className={classes.input}/>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <UserSearch loading={loadingUsers}
                        label="Assigned approver"
                        setUser={onChangeApproved}
                        user={approver}
                        users={potentialApprovers}
                        error={errors.approver}/>
          </div>
        </div>

        <TextField label="Optional message"
                   multiline
                   value={message}
                   onChange={event => setMessage(event.target.value)}
                   className={classes.message}/>

        <div className={classes.tasks}>
          <FormLabel>Tasks</FormLabel><br/>
          <div>
            <div className={classes.label}>To be added:</div>
            {dryRunInitiatedCosts.added.length}
          </div>
          {dryRunInitiatedCosts.reassigned.length >= 0 && (
            <div>
              <div className={classes.label}>To be reassigned:</div>
              {dryRunInitiatedCosts.reassigned.length}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={!valid || !hasTasks}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
