import React, {memo, useEffect, useState} from 'react';
import Text from '../common/text';
import Content from "../common/content";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ClickCircle from '../common/clickCircle';
import StepDots from "../common/stepDots";
import scrollElementIntoView from '../common/scrollElementIntoView';
import sleep from "../common/sleep";

const useStyles = makeStyles(theme => ({
  blurClass: {
    filter: 'blur(2px)'
  },
  disableClick: {
    pointerEvents: 'none'
  }
}));

const steps = [{
  dotsActionButton: '#unit-id',
  menu: '#unit-id-menu',
  contentPosition: {
    top: (top) => top - 30,
    left: (left) => left + 200,
  },
  content: (
    <Text>
      <b>Initiate sale budget</b> - Create a task and attach a message for the contributor to provide budget per sales district.
      <br />
      <br />
      <b>Request approval</b> - When contributor is ready a request for approval with a message is sent to the approver.
      <br />
      <br />
      <b>Approve</b> - The approver either approves or sends back to contributor with a message.
      <br />
      <br />
      <b>Finalize</b> - When all approvers have approved CFO can finalize
    </Text>
  )
}, {
  dotsActionButton: '#sale-menu-button',
  menu: '#sale-menu',
  contentPosition: {
    top: (top) => top - 110,
    left: (left) => left + 230,
  },
  content: (
    <Text>
      <b>Details</b> - In details you can read who are involved and follow the changes done. You can also read the messages.
      <br />
      <br />
      <b>Calculate from last year</b> - When budget for next year is empty you can use this function to calculate the budget based on last year total and increase or decrease with x%.
      <br />
      <br />
      <b>Request approval</b> - When contributor is ready a request for approval with a message is sent to the approver.
      <br />
      <br />
      <b>Delete</b> - Marks row with trash bin and block for contribution.
    </Text>
  )
}, {
  prepareSteps: [{
    checkElementSelector: '.has-child-percentage-button',
    clickElementSelector: '.expand-child-row-button'
  }],
  contentPosition: {
    top: (top) => top + 65,
    left: (left) => left,
  },
  content: (
    <Text>
      With the percentage feature you can compare the new budget with either the outcome or the budget for the year before. Click again to toggle back to currency values.
    </Text>
  )
}];

const getAvailableSteps = () => {
  const stepsNumber = [];
  steps.forEach((item, index) => {
   if (index === 0) {
     stepsNumber.push(0);
     return;
   }

   if (item.dotsActionButton && document.querySelector(item.dotsActionButton)){
     stepsNumber.push(1);
   }
   else {
     stepsNumber.push(false);
   }

   if (item.prepareSteps) {
     if (document.querySelector(item.prepareSteps[0].checkElementSelector)) {
       stepsNumber.push(2);
     }
     else stepsNumber.push(false);
   }
  });
  return stepsNumber;
}

const validItemsFilter = item => typeof item === 'number';

const SalesTutorial = (props) => {
  const { openTutorial, showContent, setShowContent, children } = props;
  const classes = useStyles();

  const { fetchedUnitSales } = useSelector(state => state.unit);
  const [step, setStep] = useState(0);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [positions, setPositions] = useState({});
  const [showClickSimulate, setShowClickSimulate] = useState(false);
  const rootElement = document.querySelector('#root');
  const menuWrapper = React.useRef();
  const prevStep = React.useRef(undefined);
  const clonedElement = React.useRef(undefined);

  const onEscape = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  };

  const resetElements = () => {
    if (menuWrapper.current) {
      setShowContent(false);
      menuWrapper.current.classList.remove(classes.disableClick);
      menuWrapper.current.firstChild.click();
      document.removeEventListener('keydown', onEscape);
    }
    if (clonedElement.current) {
      clonedElement.current.remove();
    }
    openTutorial(false);
  }

  const onKeyDown = ({ key }) => {
    if (key === 'Escape') openTutorial(false);
  }

  useEffect(() => {
    setAvailableSteps(getAvailableSteps());
    document.addEventListener('keydown', onKeyDown);

    return () => {
      rootElement.classList.remove(classes.blurClass);
      resetElements();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (prevStep.current !== step) {
        await hideContentBeforeNextStep();
        await sleep(500);
        await handleInitStep();
      }

      prevStep.current = step;
    })();
  }, [step]);

  const handleInitStep = async () => {
    if (fetchedUnitSales.length) {
      if (steps[step].dotsActionButton) {
        await handleStepWithoutPrepareSteps();
      }
      else if (steps[step].prepareSteps) {
        await handlePrepareSteps();
      }
      document.addEventListener('keydown', onEscape);
    }
  };

  const hideContentBeforeNextStep = () => {
    setShowContent(false);
    rootElement.classList.remove(classes.blurClass);

    if (menuWrapper.current) {
      menuWrapper.current.classList.remove(classes.disableClick);
      menuWrapper.current.firstChild.click();
    }
    if (clonedElement.current) {
      clonedElement.current.remove();
    }
  }

  const handlePrepareSteps = async () => {
    steps[step].prepareSteps.forEach((prepareStep, index) => {
      (async () => {
        const parentRow = document.querySelector(prepareStep.checkElementSelector);

        if (parentRow) {
          const expandElement = parentRow.querySelector(prepareStep.clickElementSelector);
          let positions;
          let left, top;

          if (!expandElement.className.includes('expanded-button')) {
            await scrollElementIntoView(expandElement);
            positions = expandElement.getBoundingClientRect();
            left = positions.left;
            top = positions.top;
            setPositions({ top, left });

            await handleClickSimulator();
            await sleep(250);
            expandElement.click();
          }

          await sleep(500);
          const percentageButton = document.querySelector('.toggle-percentage-button');
          await scrollElementIntoView(percentageButton);

          positions = percentageButton.getBoundingClientRect();
          left = positions.left;
          top = positions.top;
          setPositions({ top, left });
          await handleClickSimulator();

          if (!percentageButton.className.includes('active')) {
            percentageButton.click();
            await sleep(250);
          }
          rootElement.classList.add(classes.blurClass);
          setShowContent(true);

          await sleep(750);

          const table = parentRow.closest('table');
          positions = parentRow.getBoundingClientRect();
          clonedElement.current = table.cloneNode(true);

          let rows = clonedElement.current.querySelectorAll('tr');

          excludeUnnecessaryRows(rows);

          clonedElement.current.style.cssText = `position: fixed; z-index: 1301 !important; pointer-events: none; width: ${positions.width}px; top: ${positions.top}px; left: ${positions.left}px; background-color: white; box-shadow: 0 3px 5px rgba(0,0,0,0.3);`;
          document.querySelector('body').appendChild(clonedElement.current);
        }
      })();
    });
  };

  const handleClickSimulator = async () => {
    setShowClickSimulate(true);
    await sleep(750);
    setShowClickSimulate(false);
  }

  const excludeUnnecessaryRows = (rows) => {
    let foundRows = 0;
    rows.forEach((row, index) => {
      if (foundRows == 2 || (!row.className.includes('has-child-percentage-button') && !row.querySelector('.toggle-percentage-button')) || index === rows.length - 1) {
        row.remove();
      }
      else {
        foundRows++;
      }
    });
  }

  const handleStepWithoutPrepareSteps = async () => {
    const menuButton = document.querySelector(steps[step].dotsActionButton);

    if (menuButton) {
      await scrollElementIntoView(menuButton);
      const { left, top } = menuButton.getBoundingClientRect();
      setPositions({ top, left });

      await handleClickSimulator();
      await sleep(250);

      menuButton.click();
      rootElement.classList.add(classes.blurClass);
      setShowContent(true);

      await sleep(150);
      menuWrapper.current = document.querySelector(steps[step].menu);
      if (menuWrapper.current) menuWrapper.current.classList.add(classes.disableClick);
    }
  }

  const onSepChange = (step) => {
    const availableFilteredSteps =  availableSteps.filter(validItemsFilter);
    let nextStep = availableFilteredSteps[step];

    if (!nextStep) {
      nextStep = availableFilteredSteps.indexOf(step);
    }
    setStep(nextStep);
  }

  if (!positions.top) return null;

  const stepsLength = availableSteps.filter(validItemsFilter).length;
  const stepIndex = availableSteps.filter(validItemsFilter).indexOf(step);

  return (
    <>
      {
        showClickSimulate && (
          <ClickCircle
            top={positions.top + 10}
            left={positions.left + 10}
          />
        )
      }
      {
        showContent && (
          <>
            { React.cloneElement(children, { onClick: resetElements }) }
            <Content
              step={stepIndex}
              top={steps[step].contentPosition.top(positions.top)}
              left={steps[step].contentPosition.left(positions.left)}
              stepsLength={stepsLength}
              onSepChange={onSepChange}
              openTutorial={resetElements}
              onSkip={resetElements}
            >
              { steps[step].content }
            </Content>
            {
              stepsLength > 1 && (
                <StepDots
                  stepsLength={stepsLength}
                  activeStep={availableSteps.filter(validItemsFilter).indexOf(step)}
                  onSepChange={onSepChange}
                />
              )
            }
          </>
        )
      }
    </>
  )
};

export default memo(SalesTutorial);

