import React, { memo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Chart from '../../util/Chart';
import Sale from './Sale';
import TotalSalesByRegion from './TotalSalesByRegion';
import { fetchUnitSales } from '../../action/unit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { UNIT_TYPE } from '../../../../../common/src/util/enum';
import Units from '../units/Units';
import protectedRoute from '../common/hocs/protectedRoute';
import PageTitle from '../common/PageTitle';
import areEqual from '../../util/areEqual';

function Sales() {
  const { unitId: paramsUnitId } = useParams();
  const isUnitSelected = !!paramsUnitId;

  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const showSingleUnitOnly = true;

  useEffect(() => {
    if (isUnitSelected)
      dispatch(fetchUnitSales(paramsUnitId));
  }, [dispatch, paramsUnitId, isUnitSelected]);

  const { fetchedUnitSales, fetchingUnitSales } = useSelector(state => state.unit);

  if (fetchingUnitSales)
    return <CircularProgress size={16}/>;

  const padding = {};
  let content;

  if (isUnitSelected) {
    const unit = fetchedUnitSales.find(({ unit: { id } }) => id === parseInt(paramsUnitId));

    content = (
      <>
        {unit && (
          <PageTitle
            title={`Sales - ${unit?.unit.name}`}
            newLineChildren={<Chart isSaleChart={true}/>}
          />
        )}
        {
          fetchedUnitSales.map(unitSales => {
              const { unit } = unitSales;
              if (showSingleUnitOnly && unit.id !== parseInt(paramsUnitId))
                return null;
              const parentPadding = padding[unit.parentUnitId];
              let paddingLeft;
              if (showSingleUnitOnly)
                paddingLeft = theme.spacing(1);
              else
                paddingLeft = parentPadding === undefined ? theme.spacing(1) : parentPadding + theme.spacing(2);
              padding[unit.id] = paddingLeft;

              return (
                <Grid item xs={12} key={unit.id} style={{ paddingLeft, maxWidth: 'inherit' }}>
                  {
                    unit.type === UNIT_TYPE.COMPANY ? (
                      <TotalSalesByRegion unitId={unit.id}/>
                    ) : (
                      <Sale unitId={unit.id}/>
                    )
                  }
                </Grid>
              );
            },
          )
        }
      </>
    );
  } else
    content = (
      <Grid item xs={12} md={12} lg={12}>
        <Units
          hideActions={true}
          onSelect={unitId => history.push(`/unit/${unitId}/sale`)}
        />
      </Grid>
    );

  return (
    <Grid container spacing={2}>
      {content}
    </Grid>
  );
}

export default protectedRoute(memo(Sales, areEqual));
