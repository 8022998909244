import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { userDisplayName } from '../../util/user';
import CircularProgress from '@material-ui/core/CircularProgress';
import User from './User';

export default function UserSearch(props) {
  const {
    loading,
    users,
    user,
    setUser,
    label,
    style,
    error,
  } = props;

  return (
    <Autocomplete
      value={user}
      options={users || []}
      getOptionLabel={userDisplayName}
      onChange={(event, value) => setUser(value)}
      style={style}
      loading={loading}
      renderOption={user => <User user={user}/>}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

UserSearch.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  error: PropTypes.string,
};


