import React, { memo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import AccountModal from './AccountModal';
import ConfirmDeleteButtons from '../common/ConfirmDeleteButtons';
import { assignAccount, deleteAccount, toggleModal, unassignAccount, updateAccount } from '../../action/account';
import AccountAssignmentModal from './AccountAssignmentModal';

const AccountMenu = ({ unitId, group, account }) => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openConfirmDeleteMenu, setOpenConfirmDeleteMenu] = useState(false);
  const [openConfirmUnassignMenu, setOpenConfirmUnassignMenu] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [accountAssignmentModalOpen, setAccountAssignmentModalOpen] = useState(false);
  const [user, setUser] = useState({});

  const modalId = `account-edit-${account.id}`;
  const accountModalOpen = useSelector(state => state.account.openModals[modalId] || false);

  const dispatch = useDispatch();

  const openMenu = e => {
    setMenuAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
    setOpenConfirmDeleteMenu(false);
  };
  const onCloseMenus = () => {
    setOpenConfirmDeleteMenu(false);
    setOpenConfirmUnassignMenu(false);
    setMenuOpen(false);
  }

  const onEditClicked = () => {
    onCloseMenus();
    dispatch(toggleModal(modalId, true));
  };
  const onEditAccountConfirm = (account, moveToGroup) => {
    dispatch(updateAccount(group, { ...account, unitId }, moveToGroup));
  };

  const onDeleteClicked = () => {
    setOpenConfirmDeleteMenu(true);
  };
  const onConfirmDelete = () => {
    dispatch(deleteAccount(group, account));
    onCloseMenus();
  }

  const onAssignAccountClicked = () => {
    onCloseMenus();
    setUser({});
    setAccountAssignmentModalOpen(true);
  };
  const onAssignAccountConfirmed = user => {
    setAccountAssignmentModalOpen(false);
    dispatch(assignAccount({
      id: account.assignmentId,
      unitId: unitId,
      // categoryId: groupId,
      typeId: account.id,
      contributorId: user.id
    }));
  };

  const onUnassignAccountClicked = () => {
    setOpenConfirmUnassignMenu(true);
  };
  const onConfirmUnassign = () => {
    dispatch(unassignAccount({
      id: account.assignmentId,
      unitId: unitId
    }));
    onCloseMenus();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton size={'small'} onClick={openMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchorEl}
        keepMounted
        onClose={closeMenu}
      >
        <MenuItem
          key={`item-edit-${account.id}`}
          onClick={onEditClicked}
        >
          Edit
        </MenuItem>
        <MenuItem
          ref={menuRef}
          key={`item-delete-${account.id}`}
          disabled={!!account.assignmentId}
          onClick={onDeleteClicked}
        >
          Delete
        </MenuItem>
        <MenuItem
          key={`account-assign-${account.id}`}
          onClick={onAssignAccountClicked}
        >
          Assign
        </MenuItem>
        <MenuItem
          ref={menuRef}
          key={`account-unassign-${account.id}`}
          disabled={!account.assignmentId}
          onClick={onUnassignAccountClicked}
        >
          Unassign
        </MenuItem>
      </Menu>

      <AccountModal
        open={accountModalOpen}
        onCancel={() => dispatch(toggleModal(modalId, false))}
        onConfirm={onEditAccountConfirm}
        title="Edit account"
        account={account}
        unitId={unitId}
        editing
      />
      <ConfirmDeleteButtons
        open={menuOpen && openConfirmDeleteMenu}
        anchorEl={menuRef}
        onSuccess={onConfirmDelete}
        onClose={onCloseMenus}
      />

      <AccountAssignmentModal
        open={accountAssignmentModalOpen}
        onCancel={() => setAccountAssignmentModalOpen(false)}
        onConfirm={onAssignAccountConfirmed}
        title="Assign account"
        unitId={unitId}
        user={user}
      />
      <ConfirmDeleteButtons
        open={menuOpen && openConfirmUnassignMenu}
        anchorEl={menuRef}
        onSuccess={onConfirmUnassign}
        onClose={onCloseMenus}
      />
    </div>
  );
};

AccountMenu.propTypes = {
  group: PropTypes.object,
  account: PropTypes.object,
};

export default memo(AccountMenu);
