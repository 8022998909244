import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover/Popover';
import Link from '@material-ui/core/Link';
import User from './User';
import Paper from '@material-ui/core/Paper';
import { userDisplayName } from '../../util/user';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function UserLink(props) {
  let { user, userId, userDisplayName: name, username } = props;

  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => setAnchorEl(open ? null : event.currentTarget);

  name = name || userDisplayName(user);
  username = username || user?.username;

  return (
    <>
      <Link
        onClick={handleClick}
        className={classes.link}
      >
        {name}
      </Link>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.paper}>
          <User
            user={user}
            userId={userId}
            userDisplayName={name}
            username={username}
          />
        </Paper>
      </Popover>
    </>
  );
}

UserLink.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.number,
  userDisplayName: PropTypes.string,
};
