import React, { memo } from 'react';
import ContentEditable from 'react-contenteditable';
import clsx from 'clsx';
import areEqual from '../../util/areEqual';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  contentEditable: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    whiteSpace: 'nowrap',
  },
  invalidCell: {
    border: `2px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.light,
    borderRadius: 4,
  },
  editablePlaceholder: {
    borderRadius: 4,
    border: '2px solid rgba(0,0,0,0.03)',
  },
}));


const EditableCell = props => {

  const {
    value,
    onBlur,
    onKeyDown,
    onFocus,
    editable,
    valid,
  } = props;

  const classes = useStyles();

  const noop = () => {};

  return (
    <ContentEditable
      html={value}
      onBlur={onBlur || noop}
      onKeyDown={onKeyDown || noop}
      onFocus={onFocus || noop}
      className={clsx(classes.contentEditable,
        { [classes.editablePlaceholder]: editable && !value },
        !valid && classes.invalidCell,
      )}
      disabled={!editable}
    />
  );
};

export default memo(EditableCell, areEqual);
